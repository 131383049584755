import { InfoCircleOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { AggregatedDailyEvent, AggregatedDailyEventData, AggregatedEventMetrics } from '../../../../types/events.interfaces';

interface Props {
    loading: boolean,
    events?: AggregatedDailyEvent
}

const RetailerChartEvents = ({ loading, events }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const getChartData = (dailyEvents: AggregatedDailyEvent) => {

        if (dailyEvents?.data) {
            const _dates: string[] = dailyEvents.data.map((value: AggregatedDailyEventData) => moment(value.item.odate).format("DD-MM-YYYY"));
            const _events: AggregatedEventMetrics[] = dailyEvents.data.map((value: AggregatedDailyEventData) => value.metrics);

            const _chartOptions: Highcharts.Options = {
                title: {
                    text: ''
                },
                xAxis: {
                    categories: _dates,
                },
                yAxis: {
                    minTickInterval: 1,
                    tickInterval: 1,
                    title: {
                        text: 'Clicks',
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle'
                },
                series: [
                    // {
                    //     type: 'line',
                    //     name: 'Auto Popup',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onautopopup)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Main button',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onmainbuttonclick)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Save offer',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onoffersaveclick)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Read more close',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onreadmoreclose)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Send email',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onsendemailclick)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Template close',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.ontemplateclose)
                    // },
                    // {
                    //     type: 'line',
                    //     name: 'Template open',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.ontemplateopen)
                    // }
                    // {
                    //     type: 'line',
                    //     name: 'Impressions',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onscriptload)
                    // },

                    // {
                    //     type: 'line',
                    //     name: 'Offer impressions',
                    //     data: _events.map((value: AggregatedEventMetrics) => value.onofferview)
                    // },
                    {
                        type: 'line',
                        name: 'Read more',
                        data: _events.map((value: AggregatedEventMetrics) => value.onreadmoreclick)
                    },
                    {
                        type: 'line',
                        name: 'Interested',
                        data: _events.map((value: AggregatedEventMetrics) => value.onselectoffer)
                    },
                    {
                        type: 'line',
                        name: 'Get offer',
                        data: _events.map((value: AggregatedEventMetrics) => value.totalOfferClicks)
                    },
                ],
                chart: {
                    height: containerRef?.current?.clientHeight,
                    width: containerRef?.current?.clientWidth,
                },
                accessibility: {
                    enabled: false
                },
                credits: {
                    enabled: false
                }
            }

            setOptions(_chartOptions);
        }
    }

    useEffect(() => {
        events && getChartData(events);
    }, [events]);

    return <>
        <div className="home-widget">
            <div className="widget-title">
                Daily activity <InfoCircleOutlined id="guide-retailer-chart" />
            </div>
            <div className="widget-description">
            </div>
            {events?.data && events?.data.length > 0 ?
                <div ref={containerRef} className="widget-content">
                    {options &&
                        <div id="chart-container" className="chart-container">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </div>
                    }
                </div>
                :
                <>
                    <Empty className="mtop20 mbot20" />
                </>
            }
            <div className="widget-footer">
            </div>
        </div>
    </>
}

export default RetailerChartEvents;