import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useExpress from "../../../hooks/useExpress.hook";
import { ExpressPage } from "../const/views";
import sIconSource from '../../../assets/images/icons/s-icon.png';
import "../css/express.scss";
import useAxios from "../../../hooks/useAxios.hook";
import { ExpressBankAccountResponse, ExpressCommisionResponse } from "../types/express.types";
import { BANK_ACCUNT_STATUS } from "../types/express.enum";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Modal from "antd/lib/modal";

const ExpressDashboard = () => {
    const { company, setExpressPage, refreshState } = useExpress();
    const [loading, setLoading] = useState<boolean>();
    const navigation = useNavigate();

    const { myAxios } = useAxios();

    const [commision, setCommision] = useState<ExpressCommisionResponse[]>([]);

    const params = new URLSearchParams(document.location.search);

    const postPaymentOnboardComplete = async (acc: string, comp: string) => {
        const { response } = await myAxios({ method: 'POST', url: `payments/onboarding/complete/${acc}/${comp}` })

        if (response?.data.status) {
            message.success('Stripe account is connected');
        } else {
            message.error('Stripe account failed to connect');
        }

        refreshState();
    }

    useEffect(() => {
        const accountNr = params.get('acc');
        const companyNr = params.get('comp');

        if (accountNr && companyNr) {
            postPaymentOnboardComplete(accountNr, companyNr);
        }

        document.title = "BrandSwap Express - Home";
        setExpressPage(ExpressPage.dashboard);
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        company?.id && getInsightsRetailerCommisionById(company.id);
        // eslint-disable-next-line
    }, [company]);

    const connectToStripe = async (id: string) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'GET', url: `/payments/onboarding/${id}` });

        if (response?.data?.status) {
            const bankResponse: ExpressBankAccountResponse = response.data.result;

            switch (bankResponse.status) {
                case BANK_ACCUNT_STATUS.notstarted:

                    bankResponse.accountUrl && openStripeConnection(bankResponse.accountUrl);
                    break;
                case BANK_ACCUNT_STATUS.notcompleted:

                    createLinkToStripe(bankResponse.refreshUrl);
                    break;
                default:
                    break;
            }

        } else {
            message.error(response?.data?.result[0]?.message || "Failed to update placements", 1);
        }

        setLoading(false);
    }

    const createLinkToStripe = async (url: string) => {
        setLoading(true);

        const { response } = await myAxios({ method: 'GET', url });

        if (response?.data?.status) {
            const bankResponse: ExpressBankAccountResponse = response.data.result;
            bankResponse.accountUrl && openStripeConnection(bankResponse.accountUrl);
        } else {
            message.error(response?.data?.result[0]?.message || "Failed to update placements", 1);
        }

        setLoading(false);
    }

    const openStripeConnection = async (url: string) => {
        Modal.destroyAll();
        Modal.confirm({
            title: 'Redirecting you to Stripe.com to connect your bank account',
            content: 'We pay commission/revenue using Stripe’s secure payment platform. In order to register your bank details against your BrandSwap account we are redirecting you to Stripe to complete the setup process.',
            okText: 'Continue',
            icon: <></>,
            maskClosable: true,
            width: 520,
            closable: true,
            closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-success'
            },
            onOk: () => window.location.replace(url)
        });
    }

    const postPaymentsDelete = async () => {
        setLoading(true);
        if (company?.id) {
            const { response } = await myAxios({ method: 'POST', url: `payments/clearpayments/${company.id}` });

            if (response?.data?.status) {
                message.success('Stripe account is deleted');
            } else {
                message.error('Something went wrong');
            }
            refreshState();
            setLoading(false);
        }
    }

    const getInsightsRetailerCommisionById = async (id: string) => {
        const { response } = await myAxios({ method: 'POST', url: `insights/retailer/commision/${id}` });
        if (response?.data.status) {
            setCommision(response.data.result);
        } else {
            message.error('Getting commision data failed');
        }
    }

    const getCommisionPayDate = () => {

        // today's date
        const today = new Date();

        // Get next month's index(0 based)
        const nextMonth = today.getMonth() + 1;

        // Get year
        const year = today.getFullYear() + (nextMonth === 12 ? 1 : 0);

        // Get first day of the next month
        const firstDayOfNextMonth = new Date(year, nextMonth % 12, 1);

        return firstDayOfNextMonth.toDateString();;
    }

    return <>
        <div className="d-grid-box">

            <div className="d-box widget widget-express">
                <div className="d-box-content">
                    <div className="d-box-title">
                        Company Settings <InfoCircleOutlined id="guide-express-company-box" />
                    </div>
                    <div className="d-box-subtitle">
                        {/* You can edit some company information */}
                    </div>
                    <div className="d-box-description">
                        Here you can edit information about your company. You can also edit your personal details, change password and email address
                    </div>
                    <div className="d-box-button  d-box-space-b">
                        <Button type="primary" className="express-button-success" onClick={() => navigation('/express/settings')}>Go to Settings</Button>

                        <div className={`d-box-payment}`} >
                            {loading && <LoadingOutlined className="mright10" />}
                            {company?.payments?.completionStatus === BANK_ACCUNT_STATUS.completed ?
                                <>
                                    <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                                        return <div className="dropdown">
                                            <ul>
                                                <li>
                                                    <span className="font-hover font-xsmall" onClick={postPaymentsDelete}>Delete bank account
                                                        {/* <DeleteOutlined /> */}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    }}>
                                        <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                                            <Checkbox className="no-pointer-event mright10" checked={true} />Bank account connected
                                        </span>
                                    </Dropdown>
                                </>
                                :
                                <>
                                    <span onClick={() => company && !loading && connectToStripe(company.id)} className="mright10 font-hover">
                                        <img src={sIconSource} alt="Stripe payment" title="Stripe" />
                                        <span className="font-active mleft10">Connect bank account</span>
                                    </span>
                                    <InfoCircleOutlined id="guide-express-connect-payment" />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-box-icon">
                    <div className="d-icon-holder">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.5429 13.7982C17.9571 14.384 17.9571 15.3337 18.5429 15.9195L22.7455 20.1221C23.3313 20.7079 24.2811 20.7079 24.8669 20.1221L29.0695 15.9195C29.6553 15.3337 29.6553 14.384 29.0695 13.7982L24.8669 9.59559C24.2811 9.0098 23.3313 9.0098 22.7455 9.59559L18.5429 13.7982ZM0.656137 13.7982C0.0703501 14.384 0.0703504 15.3337 0.656137 15.9195L4.85875 20.1221C5.44454 20.7079 6.39429 20.7079 6.98007 20.1221L11.1827 15.9195C11.7685 15.3337 11.7685 14.384 11.1827 13.7982L6.98007 9.59559C6.39428 9.0098 5.44454 9.0098 4.85875 9.59559L0.656137 13.7982Z" fill="#DCDCDC" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M9.5995 4.8568C9.01371 5.44258 9.01371 6.39233 9.5995 6.97812L13.8021 11.1807C14.3879 11.7665 15.3376 11.7665 15.9234 11.1807L20.126 6.97812C20.7118 6.39233 20.7118 5.44258 20.126 4.8568L15.9234 0.654183C15.3376 0.068397 14.3879 0.0683974 13.8021 0.654184L9.5995 4.8568ZM9.5995 22.7436C9.01371 23.3294 9.01371 24.2791 9.5995 24.8649L13.8021 29.0675C14.3879 29.6533 15.3376 29.6533 15.9234 29.0675L20.126 24.8649C20.7118 24.2791 20.7118 23.3294 20.126 22.7436L15.9234 18.541C15.3376 17.9552 14.3879 17.9552 13.8021 18.541L9.5995 22.7436Z" fill="#B2B7C3" />
                        </svg>

                    </div>
                </div>
            </div>

            <div className="d-box widget widget-express">
                <div className="d-box-content">
                    <div className="d-box-title">
                        Deal Pages and Presentation
                        <InfoCircleOutlined id="guide-express-placements-box" />
                    </div>
                    <div className="d-box-subtitle">
                        {/* You can edit your placements and layouts */}
                    </div>
                    <div className="d-box-description">
                        Identify the pages in your website in which you want to promote Deals and then configure how the Deals are presented.
                    </div>
                    <div className="d-box-button">
                        <Button type="primary" className="express-button-success" onClick={() => navigation('/express/campaign')}>Go to Placements</Button>
                    </div>
                </div>
                <div className="d-box-icon">
                    <div className="d-icon-holder">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.4587 5.05103C26.0361 5.62844 26.0455 6.56164 25.4799 7.15063L13.7 19.4182C13.4247 19.7048 13.0469 19.8705 12.6495 19.8789L7.68997 19.9829L7.79435 15.0082C7.80249 14.6198 7.96096 14.2498 8.2364 13.9759L20.2868 1.99441C20.8731 1.41149 21.8205 1.41284 22.4051 1.99744L25.4587 5.05103Z" fill="#B2B7C3" />
                            <path d="M14.17 1C14.888 1 15.47 1.58203 15.47 2.3C15.47 3.01797 14.888 3.6 14.17 3.6H5.2C3.76406 3.6 2.6 4.76406 2.6 6.2V21.8C2.6 23.2359 3.76406 24.4 5.2 24.4H20.8C22.2359 24.4 23.4 23.2359 23.4 21.8V15.3C23.4 14.582 23.982 14 24.7 14C25.418 14 26 14.582 26 15.3V21.8C26 24.6719 23.6719 27 20.8 27H5.2C2.32812 27 0 24.6719 0 21.8V6.2C0 3.32812 2.32812 1 5.2 1H14.17Z" fill="#DCDCDC" />
                        </svg>
                    </div>
                </div>
            </div>

            <div className="d-box widget widget-express">
                <div className="d-box-content">
                    <div className="d-box-title">
                        Insights <InfoCircleOutlined id="guide-express-earnings-box" />
                    </div>
                    <div className="d-box-subtitle">
                        See how your selected ad partner deals are performing
                    </div>
                    <div className="d-box-table">
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            Deal Partner
                                        </th>
                                        <th>
                                            Pending sales
                                        </th>
                                        <th>
                                            Confirmed sales
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {commision?.map((item: ExpressCommisionResponse) => {
                                        return <tr key={item._id}>
                                            <td>
                                                {item.advertiser || '-'}
                                            </td>
                                            <td>
                                                {item.totalAmountPending} {item.currency}
                                            </td>
                                            <td>
                                                {item.totalAmountApproved} {item.currency}
                                            </td>
                                        </tr>
                                    })}
                                    {(!commision || commision?.length === 0) &&
                                        <tr>
                                            <td className="text-center" colSpan={6}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>

                            </table>
                        </div>
                        <ul className="d-box-list">
                            <li className="success">
                                Next payout date: <span>{getCommisionPayDate()}</span>
                            </li>
                            <li className="warning">
                                You need to reach the <span>50 {company?.registrationCountry.currencySymbol || '$'} threshold</span> to get paid
                            </li>
                        </ul>
                        {/* Your earnings for the last month
                        <span className="d-box-big">$15,568</span> */}
                    </div>
                    <div className="d-box-button">
                        <Button type="primary" className="express-button-success" onClick={() => navigation('/express/insights')}>Go to Insights</Button>

                    </div>
                </div>
                <div className="d-box-icon">
                    <div className="d-icon-holder">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="14" width="5" height="26" rx="2" fill="#B2B7C3" />
                            <rect x="7" y="8" width="5" height="18" rx="2" fill="#DCDCDC" />
                            <rect x="21" y="11" width="5" height="15" rx="2" fill="#DCDCDC" />
                            <rect y="15" width="5" height="11" rx="2" fill="#DCDCDC" />
                        </svg>

                    </div>
                </div>
            </div>

            <div className="d-box widget widget-express">
                <div className="d-box-content">
                    <div className="d-box-title">
                        Partners and Deals <InfoCircleOutlined id="guide-express-deals-box" />
                    </div>
                    <div className="d-box-subtitle">
                        {/* Select options in the offers section */}
                    </div>
                    <div className="d-box-description">
                        Select the Ad Partners Deals that are aligned with your brand and objectives
                    </div>
                    <div className="d-box-button">
                        <Button type="primary" className="express-button-success" onClick={() => navigation('/express/deals')}>Go to Deals</Button>
                    </div>
                </div>
                <div className="d-box-icon">
                    <div className="d-icon-holder">
                        <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 10.4448L11.1804 16.8998C11.3008 16.9693 11.427 17.0196 11.5555 17.0514V29.5706L0.797389 23.2039C0.303146 22.9114 0 22.3798 0 21.8055V10.4448ZM25.9996 10.2734V21.8057C25.9996 22.38 25.6965 22.9116 25.2022 23.2041L14.4441 29.5708V16.942C14.4703 16.9289 14.4963 16.9149 14.522 16.9001L25.9996 10.2734Z" fill="#B2B7C3" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.351563 7.31518C0.488127 7.14273 0.660497 6.99698 0.861458 6.88994L12.2364 0.831378C12.714 0.577041 13.2867 0.577041 13.7643 0.831378L25.1393 6.88994C25.2942 6.97245 25.4321 7.07797 25.5497 7.20099L13.0783 14.4014C12.9963 14.4487 12.9206 14.5029 12.8517 14.5628C12.7828 14.5029 12.7072 14.4487 12.6252 14.4014L0.351563 7.31518Z" fill="#DCDCDC" />
                        </svg>
                    </div>
                </div>
            </div>

        </div>
    </>;
}

export default ExpressDashboard;
