import { Modal, Tabs } from "antd";
import React from "react";
import { CampaignType } from "../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../types/offer.interfaces";
import OfferConditions from "./offerSettingsCondition.component";
import OfferFrequencyCapping from "./offerSettingsFrequency.component";
import OfferBadges from "./offerBadges.component";
import OfferDeviceAndScheduling from "./offerDeviceAndScheduling.component";
interface Props {
  isConditionModalOpen: boolean;
  setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offer: Offer;
  campaign: CampaignType;
  setOffer: React.Dispatch<React.SetStateAction<Offer | undefined>>;
  setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>;
}

const ModalOfferSettings = ({
  campaign,
  isConditionModalOpen,
  setConditionModalOpen,
  offer,
  setOffer,
  setSelectedOffers,
}: Props) => {
  const items = [
    {
      label: "Conditions",
      key: "item-1",
      children: (
        <OfferConditions
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setSelectedOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferConditions>
      ),
    },
    {
      label: "Frequency capping",
      key: "item-2",
      disabled: !campaign.generalOptions.frequencyCapping.isEnabled,
      children: (
        <OfferFrequencyCapping
          campaign={campaign}
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setSelectedOffers}
          offer={offer}
        ></OfferFrequencyCapping>
      ),
    },
    {
      label: "Device & Scheduling",
      key: "item-3",
      children: (
        <OfferDeviceAndScheduling
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setSelectedOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferDeviceAndScheduling>
      ),
    },
    {
      label: "Badges",
      key: "item-4",
      children: (
        <OfferBadges
          setConditionModalOpen={setConditionModalOpen}
          setSelectedOffers={setSelectedOffers}
          offer={offer}
          selectedOffers={campaign.offers}
        ></OfferBadges>
      ),
    },
  ];

  return (
    <>
      <Modal
        className="modal-default"
        title={<></>}
        footer={<></>}
        maskClosable={true}
        open={isConditionModalOpen}
        width={1200}
        closable={true}
        afterClose={() => {
          setConditionModalOpen(false);
          setOffer(undefined);
        }}
        onCancel={() => setConditionModalOpen(false)}
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
      >
        <Tabs items={items} className="modal-tabs" />
      </Modal>
    </>
  );
};

export default ModalOfferSettings;
