import "./css/style.scss";
import logoIcon from "../../../assets/images/logo-dark-bg-light.svg";
import unauthImage from "../../../assets/images/unauth-layout.png";
import { NavLink, Outlet } from "react-router-dom";

const UnauthLayout = () => {
  return (
    <div className="unauth">
      <div className="unauth-sidebar">
        <div className="unauth-sidebar-content">
          <div className="unauth-logo">
            <img src={logoIcon} alt="Brandswop logo" />
          </div>
          <div className="unauth-sidebar-description">
            Generate incremental revenue for your eCommerce
            store by enabling brand partnerships
          </div>
        </div>
        <div className="unauth-sidebar-image">
          <img src={unauthImage} alt="brandswop app" />
        </div>
      </div>
      <div className="unauth-content">
        <div className="unauth-header">
          <div className="unauth-container">
            <ul>
              <li>
                <NavLink to="/login">Existing user</NavLink>
              </li>
              {/* <li>
                <NavLink to="/register">Registration</NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        <Outlet />

      </div>
    </div>
  );
}

export default UnauthLayout;
