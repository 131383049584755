import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { LayoutSettings, PlacementSettings } from "../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../const/views";
import InlineElementSettings from "../options/inline/InlineElementSettings.component";
import InlineGeneralSettings from "../options/inline/InlineGeneralSettings.component";
import InlineHeaderSettings from "../options/inline/InlineHeaderSettings.component";
import InlineReadMoreSettings from "../options/inline/InlineGetButtonSettings.component";
import InlineTextImagesSettings from "../options/inline/InlineTextImagesSettings.component";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    currentPlacement: PlacementSettings,
    defaultTitle: string,
    campaignId: string,
}

const InlineView = ({ updateSettings, layoutDevice, campaignId, currentPlacement, defaultTitle }: Props) => {

    return <>
        <div className="settings-options-list">
            <Collapse defaultActiveKey={['element']} accordion>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Element <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="element">
                    <div className="settings-option">
                        <InlineElementSettings campaignId={campaignId} settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="general">
                    <div className="settings-option">
                        <InlineGeneralSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Header <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="header">
                    <div className="settings-option">
                        <InlineHeaderSettings placement={currentPlacement} defaultTitle={defaultTitle} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Text & images <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="text">
                    <div className="settings-option">
                        <InlineTextImagesSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Image representation <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="image">
                    <div className="settings-option">
                        <InlineImageRepresentationSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}


                {!currentPlacement.displayOnly &&
                    <CollapsePanel
                        showArrow={false}
                        header={
                            <div className="option-title">
                                Get button settings <span className="icon icon-collapse-mix"></span>
                            </div>
                        }
                        key="readmore">
                        <div className="settings-option">
                            <InlineReadMoreSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                        </div>
                    </CollapsePanel>
                }
            </Collapse>
        </div>
    </>
}

export default InlineView