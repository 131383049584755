import { message } from "antd";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.hook";
import useAxios from "../../../hooks/useAxios.hook";
import useLogout from "../../../hooks/useLogout.hook";
import { setExternalScript } from "../../../hooks/useScript.hook";
import { getOnboardingExpress } from "../../../services/express/express.service";
import { UserCompany } from "../../../types/company.interfaces";
import { UserAuth } from "../../../types/user.interfaces";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import { ExpressPage } from "../const/views";
import { PLATFORM_VIEW_MODE } from "../types/express.enum";
import { ExpressCampaign, ExpressCompany, ExpressOnboardingContextType, ExpressOnboardingRequest, ExpressOnboardingStatus } from "../types/express.types";

export const ExpressOnboardingContext = createContext<ExpressOnboardingContextType>({
    campaign: undefined,
    setCampaign: () => { },
    company: undefined,
    setCompany: () => { },
    password: undefined,
    setPassword: () => { },
    onboardingStatus: undefined,
    setOnboardingStatus: () => { },
    updateOnboardingExpress: () => { },
    updateExpress: () => { },
    expressPage: ExpressPage.dashboard,
    setExpressPage: () => { },
    refreshState: () => { },
    userEmail: '',
    loading: false,
});

export const ExpressOnboardingProvider = ({ children }: any) => {
    const { id } = useParams();
    const { setAuth, setCompany: setAuthcompany, company: authCompany } = useAuth();
    const [campaign, setCampaign] = useState<ExpressCampaign>();
    const [company, setCompany] = useState<ExpressCompany>();
    const [onboardingStatus, setOnboardingStatus] = useState<ExpressOnboardingStatus>();
    const [password, setPassword] = useState<string>();
    const [userEmail, setUserEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [expressPage, setExpressPage] = useState<ExpressPage>(ExpressPage.dashboard);
    const { myAxios } = useAxios();
    const navigation = useNavigate();
    const logout = useLogout();

    const updateCampaignExpress = async (_msg: string) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'POST', url: `onboarding/express/campaign`, data: { company, campaign, onboardingStatus, password: '' } });
        if (response?.data?.status) {
            message.success({
                content: _msg,
                className: 'express-message',
                duration: 1,
            });
        } else {
            message.error("Failed to save settings!", 1);
        }
        setLoading(false);
    }

    const updateExpress = () => {
        updateCampaignExpress("Your campaign is updated");
    }

    const getUserById = async (id: string) => {
        setLoading(true);
        const response = await getOnboardingExpress(id);
        if (response?.data?.status) {
            setAuth(undefined);
            localStorage.removeItem('company');
            localStorage.removeItem('user');

            const _onBoardingStatus: ExpressOnboardingStatus = response?.data?.result.onboardingStatus;

            if (!_onBoardingStatus.completed) {
                setOnboardingStatus(response?.data?.result.onboardingStatus);
                setCampaign(response?.data?.result.campaign);
                setCompany(response?.data?.result.company);
                const _userCompany = response?.data?.result.company;
                const _company: UserCompany = {
                    company_Id: _userCompany.id,
                    companyType: COMPANY_TYPES.publisher,
                    companyCurrentType: COMPANY_TYPES.publisher,
                    name: _userCompany.name,
                    platformViewMode: PLATFORM_VIEW_MODE.express,
                };
                localStorage.setItem('company', JSON.stringify(_company));
                setAuthcompany(_company);
                const _auth: UserAuth = {
                    tokens: response?.data?.result.token,
                    networks: [],
                    companies: [_company] || [],
                    id: _userCompany.id,
                    express: true,
                    email: _userCompany.email,
                    firstName: _userCompany.name,
                    onboardingStatus: response?.data?.result.onboardingStatus,
                };


                setAuth(_auth);
                localStorage.setItem('user', JSON.stringify(_auth));
            } else {
                message.error('Please login with your email and password', 1);
                navigation('/');
            }
        } else {
            message.error(response?.data?.result.length > 0 ? response?.data?.result[0].message : 'Something went wrong', 1);
            navigation('/');
        }
        setLoading(false);
    }

    const refreshState = () => {
        getUserFromStorage();
    }

    const getUserFromStorage = async () => {
        if (authCompany) {
            setLoading(true);
            const { response } = await myAxios({ method: 'GET', url: `/express/${authCompany.company_Id}` });
            if (response?.data?.status) {
                setOnboardingStatus(response?.data?.result.onboardingStatus);
                setCampaign(response?.data?.result.campaign);
                setCompany(response?.data?.result.company);
                setUserEmail(response?.data?.result.email);
            } else {
                message.error(response?.data?.result[0].message, 1);
                logout();
            }

            setLoading(false);
        }

    }

    useEffect(() => {
        if (id) {
            getUserById(id);
        } else {
            getUserFromStorage();
        }

        // eslint-disable-next-line 
    }, [id]);

    useEffect(() => {
        if (company?.email) {
            setExternalScript({ id: 'hs-script-loader', url: '//js-eu1.hs-scripts.com/26993522.js', email: company.email, enable: process.env.PUBLIC_URL === "https://adminapi.brandswap.com" });
        }
    }, [company?.email])

    const updateOnboardingExpress = useCallback(async () => {
        setLoading(true);
        if (campaign && company && onboardingStatus) {
            const request: ExpressOnboardingRequest = { campaign, company, onboardingStatus, password: password || "" };
            const { response } = await myAxios({ method: 'post', url: `/onboarding/express/${company.id}`, data: request });

            if (response?.data?.status) {
                // message.success('Your data are saved');

                if (response?.data?.result.onboardingStatus.completed) {

                    if (company) {
                        const _company: UserCompany = {
                            company_Id: company.id,
                            companyType: COMPANY_TYPES.publisher,
                            companyCurrentType: COMPANY_TYPES.publisher,
                            name: company.name,
                            platformViewMode: PLATFORM_VIEW_MODE.express,
                        };
                        localStorage.setItem('company', JSON.stringify(_company));
                        setAuthcompany(_company);
                    }
                    const _auth: UserAuth = {
                        tokens: response?.data?.result.token,
                        networks: [],
                        companies: [],
                        id: company.id,
                        express: true,
                        email: company.email,
                        firstName: company.name,
                        onboardingStatus: {
                            next: "admin",
                            completed: true
                        },
                    };
                    setAuth(_auth);
                    localStorage.setItem('user', JSON.stringify(_auth));
                }
            } else {
                message.error('Failed to save', 1);
            }
        }
        setLoading(false);
        // eslint-disable-next-line 
    }, [campaign, company, onboardingStatus, password]);

    return <ExpressOnboardingContext.Provider value={{
        campaign,
        setCampaign,
        company,
        setCompany,
        password,
        setPassword,
        onboardingStatus,
        setOnboardingStatus,
        updateOnboardingExpress,
        updateExpress,
        expressPage,
        setExpressPage,
        refreshState,
        userEmail,
        loading,
    }}>
        {children}
    </ExpressOnboardingContext.Provider>

}

export default ExpressOnboardingContext;