import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Select } from "antd";
import { BaseOptionType } from "antd/lib/select";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios.hook";
import useExpress from "../../../hooks/useExpress.hook";
import { VALIDATION } from "../../../utility/enums/validation.enums";
import { CAMPAIGNSTEPS, expressOrderConfirmationSettings, expressSettings, PLACEMENT_IDENTIFIER } from "../const/campaigns.enum";
import { EXPRESS_ONBOARDING } from "../types/express.enum";
import { ExpressPlacement, ExpressPlacementSettings } from "../types/express.types";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>,
}

const ExpressPlacements = ({ setCurrentStep }: Props) => {
    const { campaign, setCampaign, company, refreshState } = useExpress();
    const { myAxios } = useAxios();

    const [loading, setLoading] = useState<boolean>(false);

    const [isAddPagesOpen, setAddPagesOpen] = useState<boolean>(false);
    const [pagesList, setPages] = useState<ExpressPlacement[]>([]);
    const [isEditPageOpen, setEditPageOpen] = useState<boolean>(false);
    const { response: responsePageTypes, axiosFetch: fetchPageTypes } = useAxios();
    const navigate = useNavigate();

    const emptyPage: ExpressPlacement = {
        id: '',
        name: '',
        identifier: PLACEMENT_IDENTIFIER.product,
        pageType_Id: '',
        url: ''
    }

    const [currentPage, setCurrentPage] = useState<ExpressPlacement>(emptyPage);

    const fetchPages = async () => {
        await fetchPageTypes({ method: 'get', url: `pagetype` });
    }

    useEffect(() => {
        company?.pagePlacements && campaign && setCampaign({ ...campaign, placementSettings: getCampaignPlacementsFromCompany(company.pagePlacements) });
        // eslint-disable-next-line
    }, [company?.pagePlacements])

    const getCampaignPlacementsFromCompany = (p: ExpressPlacement[]): ExpressPlacementSettings[] => {
        const placementSettings: ExpressPlacementSettings[] = p.map((i: ExpressPlacement) => {
            return {
                id: i.id,
                templateSettings: i.identifier === PLACEMENT_IDENTIFIER.orderconfirmation ? expressOrderConfirmationSettings : expressSettings,
                hasExitView: false,
                displayOnly: i.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation,
                name: i.name,
                isEnabled: true,
                promptMessage: i.identifier === PLACEMENT_IDENTIFIER.orderconfirmation ? "You have unlocked the following rewards" : "Unlock these rewards when you buy"
            }
        });

        return placementSettings;
    }

    // Init request for get pageTypes and company placement pages
    useEffect(() => {
        document.title = "BrandSwap Express - Onboarding placements";
        fetchPages();
        // eslint-disable-next-line
    }, []);

    // Set all page types
    useEffect(() => {
        if (responsePageTypes?.status) {
            setPages(responsePageTypes.result);
        }
        // eslint-disable-next-line
    }, [responsePageTypes]);

    const openConfirmRemovePlacementByIndex = (id: string) => {
        Modal.confirm({
            title: 'Do you want to remove this placement?',
            content: 'You can add it again by the “Add placement” button',
            okText: 'Remove',
            icon: <></>,
            maskClosable: true,
            width: 420,
            closable: true,
            className: 'express-modal-confirm',
            closeIcon: <span className="icon icon-remove"></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-danger'
            },
            onOk: () => removePlacementById(id)
        })
    }

    const removePlacementById = async (id: string) => {
        if (company && campaign) {
            const { response } = await myAxios({
                method: 'DELETE', url: `/company/pageplacementswcamp/${id}/${company.id}/${campaign.id}`
            });

            if (response?.data?.status) {
                refreshState();
                message.success({
                    content: "Placements are updated",
                    className: 'express-message',
                    duration: 1,
                });
            } else {
                setAddPagesOpen(true);
                message.error("Failed to update placements", 1);
            }
        }
    }

    const isCurrentPageDisabled = (page: ExpressPlacement): boolean => {
        return !page.name || !page.url || !page.pageType_Id || !isUrl(page.url)
    }

    const isUrl = (str: string) => {
        var pattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!]))?/;
        return pattern.test(str);
    }

    const updatePlacementsCompanyCampaign = async (companyId: string, campaignId: string) => {
        const { response } = await myAxios({
            method: 'POST', url: `/company/pageplacementswcamp/${companyId}/${campaignId}`, data: currentPage
        });

        if (response?.data?.status) {
            refreshState();
            setAddPagesOpen(false);
            setCurrentPage(emptyPage);
            message.success({
                content: "Placements are updated",
                className: 'express-message',
                duration: 1,
            });
        } else {
            message.error(response?.data?.result[0]?.message || "Failed to update placements", 1);
        }
    }

    // Generate items for dropdown of page Types
    const pageTypes: BaseOptionType[] = useMemo(() => {
        if (pagesList.length > 0) {
            const list: BaseOptionType[] = [];
            pagesList.forEach((page: ExpressPlacement) => {
                page.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation && list.push({ label: page.name, value: page.id });
            })
            return list;
        }
        return [{ label: 'Company do not have page type', key: 'no pages', disabled: true }]
    }, [pagesList]);

    // Open pages modal
    const onAddPages = () => {
        setAddPagesOpen(true);
    }

    const handleOpenEdit = (placement: ExpressPlacement) => {
        setCurrentPage(placement);
        setEditPageOpen(true);
    }

    // Create company placement pages and refetch them
    const handleCreatePage = async () => {
        if (company && campaign) {
            updatePlacementsCompanyCampaign(company.id, campaign.id);
        }
    }

    // Edit company placement pages and refetch them
    const handleEditPage = async () => {
        if (company) {
            const { response } = await myAxios({
                method: 'PUT', url: `/company/pageplacements/${company.id}`, data: currentPage
            });

            if (response?.data?.status) {
                refreshState();
                setEditPageOpen(false);
                setCurrentPage(emptyPage);

                message.success({
                    content: "Placements are updated",
                    className: 'express-message',
                    duration: 1,
                });
            } else {
                message.error(response?.data?.result?.length > 0 ? response?.data?.result[0]?.message : "Failed to update placements", 1);
            }
        }
    }

    const handleBack = () => {
        setCurrentStep(EXPRESS_ONBOARDING.partners);
    }

    const handleContinue = () => {
        postComanyExpress();
    }

    const checkValidPlacements = (p: ExpressPlacement[] | undefined): boolean => {
        return p ? p.filter(f => f.url === "" || f.url === "undeinfed").length > 0 : false;
    }

    const postComanyExpress = async () => {
        setLoading(true);
        if (company) {
            const { response } = await myAxios({ method: 'POST', url: `onboarding/express/company`, data: { company, campaign, onboardingStatus: { next: CAMPAIGNSTEPS.layout, completed: true }, password: '' } });
            if (response?.data?.status) {
                message.success({
                    content: "Placements are updated",
                    className: 'express-message',
                    duration: 1,
                });
                setCurrentStep(EXPRESS_ONBOARDING.layout);
                navigate('/express/campaign?new=1');
            } else {
                message.error("Failed to get categories", 1);
            }
        }
        setLoading(false);
    }

    return <>
        <div className="express-placements">
            <div id="guide-placements-info" className="express-placements-info">
                {/* You can add new placements and edit them */}
            </div>
            <div className="express-placements-add">
                <span id="guide-add-new-placements" className="font-active font-hover" onClick={onAddPages}> Add new Deal Page</span> <InfoCircleOutlined className="font-active" id="guide-placement-addnew" />
            </div>
        </div>
        {company?.pagePlacements.map((placement: ExpressPlacement, index: number) => {
            return placement.identifier === PLACEMENT_IDENTIFIER.orderconfirmation ? <div key={`placementconfirm${index}`} className="express-placement-item">

                <div className="item-info">
                    <div className="item-title">
                        {placement.name}
                    </div>
                    <div className={`item-description ${placement.url ? 'item-valid' : 'item-invalid'}`}>
                        {placement.url ? placement.url : 'The page URL is missing. Please click “Edit” to provide the URL'}
                    </div>
                </div>
                <div className="item-acions">
                    <div className="item-toggle">
                        Required
                    </div>

                    <div className="item-button" onClick={() => handleOpenEdit(placement)}>
                        Edit
                    </div>
                </div>
            </div>
                :
                <React.Fragment key={`nonplacementconfirm${index}`}></React.Fragment>
        })}
        {company?.pagePlacements.map((placement: ExpressPlacement, index: number) => {
            return placement.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation ? <div key={`placement${index}`} className="express-placement-item">

                <div className="item-info">
                    <div className="item-title">
                        {placement.name}
                    </div>
                    <div className={`item-description ${placement.url ? 'item-valid' : 'item-invalid'}`}>
                        {placement.url ? placement.url : 'The page URL is missing. Please click “Edit” to provide the URL'}
                    </div>
                </div>
                <div className="item-acions">

                    <div className="item-button" onClick={() => handleOpenEdit(placement)}>
                        Edit
                    </div>

                    <div className="item-delete">
                        <DeleteOutlined onClick={() => openConfirmRemovePlacementByIndex(placement.id)} />
                    </div>
                </div>
            </div>
                :
                <React.Fragment key={`nonplacement${index}`}></React.Fragment>
        })}
        <div className="express-button">
            <Button className="express-button-back mright10" onClick={handleBack} type="default">Back</Button>
            <Button className="express-button-success" loading={loading} disabled={checkValidPlacements(company?.pagePlacements)} onClick={handleContinue} type="default">Almost there - Click to finalise setup</Button>
        </div>

        {/* Create placement page */}
        <Modal
            className="modal-default modal-express"
            title={<></>}
            footer={<></>}
            maskClosable={true}
            open={isAddPagesOpen}
            width={400}
            closable={true}
            afterClose={() => {
                setCurrentPage(emptyPage);
            }}
            onCancel={() => setAddPagesOpen(false)}
            closeIcon={<span className="icon icon-remove"></span>}
        >
            <form onSubmit={e => e.preventDefault()}>
                <div className="modal-title medium">
                    Add placement
                </div>
                <div className="modal-content-space">

                    <div className="express-row">

                        <div className="express-label">
                            Page name <InfoCircleOutlined id="guide-placement-pagename" />
                        </div>
                        <div className="express-input">
                            <Input
                                value={currentPage.name || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, name: e?.target.value }))}
                                autoComplete="off"
                                placeholder=""
                            />

                            <p
                                id="eide"
                                className={`error-line ${currentPage.name && currentPage.name.length < 2
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.nameValid}
                            </p>
                        </div>
                    </div>

                    <div className="express-row">

                        <div className="express-label">
                            Page type <InfoCircleOutlined id="guide-placement-pagetype" />
                        </div>
                        <div className="express-input">
                            <Select
                                placeholder="Select page"
                                value={currentPage.pageType_Id || undefined}
                                optionFilterProp="children"
                                suffixIcon={<span className="icon icon-arrow-down"></span>}
                                options={pageTypes}
                                onChange={(value: string) => {
                                    setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, pageType_Id: value }))
                                }}
                            />
                        </div>
                    </div>

                    <div className="express-row">

                        <div className="express-label">
                            Page URL <InfoCircleOutlined id="guide-placement-pageurl" />
                        </div>
                        <div className="express-input">
                            <Input
                                value={currentPage.url || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage(prevState => ({ ...prevState, url: e?.target.value }))}
                                autoComplete="off"
                                placeholder="https://"
                            />

                            <p
                                id="eide"
                                className={`error-line ${currentPage.url && !isUrl(currentPage.url)
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                the URL should start with https://
                            </p>
                        </div>
                    </div>
                </div>

                <div className="modal-buttons flex-center express-button">
                    <Button type="primary" className="button-default" onClick={() => setAddPagesOpen(false)}>Cancel</Button>
                    <Button type="primary" htmlType="submit" disabled={isCurrentPageDisabled(currentPage)} className="express-button-success mleft10" onClick={handleCreatePage}>Save</Button>
                </div>
            </form>
        </Modal>

        {/* Edit placement page */}
        <Modal
            className="modal-default modal-express"
            title={<></>}
            footer={<></>}
            maskClosable={true}
            open={isEditPageOpen}
            width={400}
            closable={true}
            afterClose={() => setCurrentPage(emptyPage)}
            onCancel={() => setEditPageOpen(false)}
            closeIcon={<span className="icon icon-remove"></span>}
        >
            <div className="modal-title medium">
                Edit placement
            </div>
            <form onSubmit={e => e.preventDefault()}>
                <div className="modal-content-space">

                    <div className="express-row">

                        <div className="express-label">
                            Page name <InfoCircleOutlined id="guide-placement-pagename" />
                        </div>
                        <div className="express-input">
                            <Input
                                value={currentPage.name || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, name: e?.target.value }))}
                                autoComplete="off"
                                placeholder=""
                            />

                            <p
                                id="eide"
                                className={`error-line ${currentPage.name && currentPage.name.length < 2
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.nameValid}
                            </p>
                        </div>
                    </div>

                    {currentPage.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation &&
                        <div className="express-row">

                            <div className="express-label">
                                Page type <InfoCircleOutlined id="guide-placement-pagetype" />
                            </div>
                            <div className="express-input">
                                <Select
                                    placeholder="Select page"
                                    optionFilterProp="children"
                                    suffixIcon={<span className="icon icon-arrow-down"></span>}
                                    options={pageTypes}
                                    value={currentPage.name}
                                    onChange={(value: string) => {
                                        setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, pageType_Id: value }))
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div className="express-row">

                        <div className="express-label">
                            Page URL <InfoCircleOutlined id="guide-placement-pageurl" />
                        </div>
                        <div className="express-input">
                            <Input
                                value={currentPage.url || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage(prevState => ({ ...prevState, url: e?.target.value }))}
                                autoComplete="off"
                                placeholder="https://"
                            />

                            <p
                                id="eide"
                                className={`error-line ${currentPage.url && !isUrl(currentPage.url)
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                the URL should start with https://
                            </p>
                        </div>
                    </div>

                </div>

                <div className="modal-buttons flex-center express-button">
                    <Button type="primary" className="button-default" onClick={() => setEditPageOpen(false)}>Cancel</Button>
                    <Button type="primary" htmlType="submit" disabled={isCurrentPageDisabled(currentPage)} className="express-button-success mleft10" onClick={handleEditPage}>Save</Button>
                </div>
            </form>
        </Modal>
    </>;
}

export default ExpressPlacements;
