import { Select, Switch } from "antd";
import { Dispatch, useMemo } from "react";
import {
  CompanyEmailTemplate,
  CompanyNetwork,
} from "../../../types/company.interfaces";
import { SelectOption } from "../../../types/global.interfaces";
import { OfferCreate } from "../../../types/offer.interfaces";

interface Props {
  offer: OfferCreate;
  setOffer: Dispatch<React.SetStateAction<OfferCreate>>;
  networks: CompanyNetwork[];
  emailTemplates: CompanyEmailTemplate[];
}

const CreateOfferSettings = ({
  offer,
  setOffer,
  networks,
  emailTemplates,
}: Props) => {
  const networksList: SelectOption[] = useMemo(() => {
    const _list = networks.map((value: CompanyNetwork) => {
      return {
        label: value.name || value.uniqueIdentifier,
        value: value.id,
      };
    });
    return _list;
  }, [networks]);

  const emailTemplateList: SelectOption[] = useMemo(() => {
    const _list = emailTemplates.map((value: CompanyEmailTemplate) => {
      return {
        label: value.emailTemplateName,
        value: value.emailTemplateId,
      };
    });
    _list.unshift({ label: "Brandswap email", value: "" });
    return _list;
  }, [emailTemplates]);

  return (
    <>
      <div className="widget-header">
        <div className="widget-icon-box mtop5">
          <span className="icon icon-settings"></span>
        </div>
        <div className="grow mleft10">
          <h3 className="widget-s-title">Offer settings</h3>

          <div className="widget-form-description">
            Settings fields for offer
          </div>
        </div>
      </div>

      <div className="widget-content">
        <div className="widget-icon-content">
          <div className="label-wrapper">
            <Switch
              id="allowstacked"
              size="small"
              className="displayswitch"
              checked={offer.offerSettings.allowStacked}
              onChange={(checked: boolean) => {
                setOffer((prevState) => ({
                  ...prevState,
                  offerSettings: {
                    ...offer.offerSettings,
                    allowStacked: checked,
                  },
                }));
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
            <label htmlFor="allowstacked" className="font-hover mleft5">
              Allow stacked
            </label>
            <Switch
              id="b2c"
              size="small"
              className="displayswitch mleft10"
              checked={offer.offerSettings.isB2C}
              onChange={(checked: boolean) => {
                setOffer((prevState) => ({
                  ...prevState,
                  offerSettings: { ...offer.offerSettings, isB2C: checked },
                }));
              }}
              checkedChildren="B2C"
              unCheckedChildren="B2B"
            />
          </div>

          <div className="label-input-wrapper">
            <label htmlFor="page">
              <span className="label-input-name">Network</span>
              <Select
                suffixIcon={<span className="icon icon-arrow-down"></span>}
                className="label-select"
                value={offer.advertiser.affNetwork_Id || networksList[0]?.value}
                onChange={(id: string) => {
                  setOffer((prevState) => ({
                    ...prevState,
                    advertiser: { ...prevState.advertiser, affNetwork_Id: id },
                  }));
                }}
                options={networksList}
              ></Select>
            </label>
          </div>

          <div className="label-input-wrapper">
            <label htmlFor="page">
              <span className="label-input-name">Email template</span>
              <Select
                suffixIcon={<span className="icon icon-arrow-down"></span>}
                className="label-select"
                value={offer.advertiser.emailTemplateId}
                onChange={(id: string) => {
                  setOffer((prevState) => ({
                    ...prevState,
                    advertiser: {
                      ...prevState.advertiser,
                      emailTemplateId: id,
                    },
                  }));
                }}
                options={emailTemplateList}
              ></Select>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOfferSettings;
