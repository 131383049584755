import { AxiosResponse } from "axios";
import { ApiResponse } from "../../types/response.interfaces";
import apiConfig from "../config/apiConfig";


export async function postOfferImage(data: File): Promise<void | AxiosResponse<ApiResponse, any>> {
    return await apiConfig
        .post<ApiResponse>(`/offers/image`, { file: data }, { headers: { "Content-Type": "multipart/form-data" } })
        .catch((error) => {
            console.log(error);
        });
}

export async function postOfferVoucherFile(data: File, companyId: string, offerId: string): Promise<void | AxiosResponse<ApiResponse, any>> {
    return await apiConfig
        .post<ApiResponse>(`/offers/vouchers/upload/${companyId}/${offerId}`, { file: data }, { headers: { "Content-Type": "multipart/form-data" } })
        .catch((error) => {
            console.log(error);
        });
}