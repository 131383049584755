import React, { useEffect } from "react";
import usePage from "../../hooks/usePage.hook";
import Footer from "../footer/footer.component";
import "./css/users.scss";

const Users = () => {
  const { setPage } = usePage();

  useEffect(() => {
    setPage({
      title: <>Users</>
    });
    // eslint-disable-next-line
  }, [])

  return <>
    <div className="content">
      <div className="content-layout">
        
      </div>

      <Footer />
    </div>
  </>;
}

export default Users;
