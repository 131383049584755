
const NotFound = () => {
    return <>
        <div className="unauth-container-wrapper scroll-dark unauth-login">
            <div className="unauth-container">
                <div className="unauth-content">

                    <div className="widget unauth-widget">
                        <div className="not-found">
                            <h3>404</h3>
                            <h4>Page not found</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default NotFound;
