import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ButtonNumberSettingsT } from "../../../../../templates-previews/types/params.interfaces";
import { uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
}

const ButtonNumberSettings = ({ updateSettings, layoutDevice, settings }: Props) => {

    const setNewSettings = (key: keyof ButtonNumberSettingsT, value: string | boolean) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                button: {
                    ...settings[layoutDevice].button,
                    number: {
                        ...settings[layoutDevice].button?.number,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }

    const handleDisplayChange = (e: CheckboxChangeEvent) => {
        setNewSettings('display', e.target.checked);
    }

    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-single-col">
                    <div className="option-label">
                        <Checkbox className="checkbox-active" defaultChecked={settings[layoutDevice].button?.number?.display || false} onChange={handleDisplayChange}> <span className="font-xsmall font-color">Display number of offers</span></Checkbox>
                    </div>
                </div>
            </div>

            {settings[layoutDevice].button?.number?.display &&
                <div className="option-content">
                    <div className="option-two-col">
                        <div className="option-col">
                            <input
                                type="color"
                                className="color-box"
                                id={`buttonNumberBgColor${uniqueKey}`}
                                value={settings[layoutDevice]?.button?.number?.backgroundColor || '#ff0000'}
                                onChange={handleBgChange}
                            />
                            <label htmlFor={`buttonNumberBgColor${uniqueKey}`}>
                                Background: {settings[layoutDevice]?.button?.number?.backgroundColor || '#ff0000'}
                            </label>
                        </div>
                        <div className="option-col">
                            <input
                                type="color"
                                className="color-box"
                                id={`buttonNumberText${uniqueKey}`}
                                value={settings[layoutDevice]?.button?.number?.textColor || '#ffffff'}
                                onChange={handleTextChange}
                            />
                            <label htmlFor={`buttonNumberText${uniqueKey}`}>
                                Text: {settings[layoutDevice]?.button?.number?.textColor || '#ffffff'}
                            </label>
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
}

export default ButtonNumberSettings
