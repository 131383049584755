import { Dropdown, Slider, Typography } from "antd";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { BehaviourSettings, DisplayBy } from "../../../../../templates-previews/types/params.interfaces";
import { itemsDisplayBy } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
}

const ActionBehaviourSettings = ({ updateSettings, layoutDevice, settings }: Props) => {

    const setNewSettings = (key: keyof BehaviourSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                action: {
                    ...settings[layoutDevice].action,
                    behaviour: {
                        ...settings[layoutDevice].action?.behaviour,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeAfterTime = (value: number) => {
        setNewSettings('afterTime', value);
    }

    const handleChangeDisplayBy = (value: DisplayBy) => {
        setNewSettings('displayBy', value);
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="option-single-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsDisplayBy,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.action.behaviour.displayBy],
                            onClick: (prop) => handleChangeDisplayBy(prop.key as DisplayBy)
                        }} trigger={['click']}>
                            <Typography.Link className="start-capital">
                                Display popup by: {settings[layoutDevice]?.action.behaviour.displayBy}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

                <div className="option-single-col">

                    <div className="option-col">
                        <Dropdown trigger={['click']} dropdownRender={
                            () =>
                                <div className="dropdown min150">
                                    <div className="dropdown-title option-label">Set seconds:</div>
                                    <div className="flex-center-space-between">
                                        <Slider
                                            min={0}
                                            max={100}
                                            className="min100"
                                            onChange={handleChangeAfterTime}
                                            value={settings[layoutDevice]?.action.behaviour.afterTime}
                                        />
                                    </div>
                                </div>
                        }>
                            <Typography.Link>
                                After time: {settings[layoutDevice]?.action.behaviour.afterTime}s
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default ActionBehaviourSettings