import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, message, Modal, Slider, Switch } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect, useMemo, useState } from "react";
import useAxios from "../../../../../hooks/useAxios.hook";
import { DropdownMenuProps } from "../../../../../types/antd.interfaces";
import {
  CampaignType,
  OrderExtractor,
  OrderExtractorProduct,
  PlacementSettings,
  postHeaderText,
  postSubHeaderText,
  preHeaderText,
  preSubHeaderText,
  templateDefaultSettings,
} from "../../../../../types/campaign.interfaces";
import {
  ImageSize,
  LayoutType,
  PlacementStage,
  Template,
  TemplateFilters,
  TemplateFiltersResponse,
  templateUltimate,
} from "../../../../../types/templates.interfaces";
import { LayoutClass, LayoutDevices } from "../../../const/views";
import TemplateC13 from "../items/templatec13/TemplateC13.component";
import TemplateEmbeddedCompact from "../items/templateEmbeddedCompact/TemplateEmbeddedCompact.component";
import TemplateEs from "../items/templateEs/TemplateEs.component";
import TemplateEsTop from "../items/templateEsTop/TemplateEsTop.component";
import TemplateFilters48 from "../items/templateFilters48/TemplateFilters48.component";
import TemplateFiltersM48 from "../items/templateFiltersMobile8/TemplateFiltersM48.component";
import TemplateN13 from "../items/templaten13/TemplateN13.component";
import TemplateN36 from "../items/templaten36/TemplateN36.component";
import TemplateNewLook from "../items/templateNewLook/TemplateNewLook.component";
import TemplateNewLookHorizontal from "../items/templateNLH/TemplateNewLookHorizontal.component";
import TemplateUltimate from "../items/ultimate/TemplateUltimate.component";
import TemplateList from "../list/templateList.component";
import TemplateDME6 from "../items/templateDME6/TemplateDME6.component";
import TemplateFeature from "../items/templatenFeature/TemplateFeature.component";

interface Props {
  isSingle: boolean;
  currentPlacement: PlacementSettings;
  setCurrentPlacement: React.Dispatch<
    React.SetStateAction<PlacementSettings | undefined>
  >;
  campaign: CampaignType;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  setSavedCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  handlePut: (campaign: CampaignType, move: boolean) => void;
}

const TemplateDialog = ({
  currentPlacement,
  campaign,
  setCampaign,
  setSavedCampaign,
  setCurrentPlacement,
  handlePut,
}: Props) => {
  const [list, setList] = useState<Template[]>();
  const [isChoosedTemplate, setChoosedTemplate] = useState<boolean>(false);
  const [template, setTemplate] = useState<Template | undefined>(undefined);
  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | undefined
  >();
  const { myAxios } = useAxios();
  const [filtersResponse, setFiltersResponse] =
    useState<TemplateFiltersResponse>();
  const [advanceOptions, setAdvanceOptions] = useState<boolean>(false);
  const [filters, setFilters] = useState<TemplateFilters>({
    deviceType: LayoutDevices.desktop,
    imageSize: ImageSize.all,
    stage: currentPlacement.displayOnly
      ? PlacementStage.pre
      : PlacementStage.post,
    layoutType: LayoutType.all,
    offerCount: "all",
  });

  const getTemplates = async () => {
    const { response } = await myAxios({
      method: "POST",
      url: "/templates/all",
      data: filters,
    });

    if (response?.data?.status) {
      const _list: Template[] = response.data.result;

      setList(_list);
    } else {
      message.error(
        response?.data.result.length > 0
          ? response?.data.result[0].message
          : "Failed to get templates list",
        1e3
      );
    }
  };

  const getFilters = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "/templates/filters",
    });

    if (response?.data?.status) {
      setFiltersResponse(response.data.result);
    }
  };

  const handleCheckAdvanceOptionsValidation = (
    _orderExtractor: OrderExtractor
  ) => {
    return (
      _orderExtractor?.allowExtraction &&
      (!_orderExtractor?.productExtractorKeys?.htmlElement ||
        !_orderExtractor?.productExtractorKeys?.productNameSelector ||
        !_orderExtractor?.productExtractorKeys?.productPriceSelector ||
        !_orderExtractor?.productExtractorKeys?.productsSelector ||
        !_orderExtractor?.productExtractorKeys?.totalPriceSelector)
    );
  };

  useEffect(() => {
    if (isChoosedTemplate) {
      setCurrentPlacement(
        (prevState) =>
          prevState && {
            ...prevState,
            displayOnly: filters.stage === PlacementStage.pre,
          }
      );
    }

    getTemplates();
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (!isChoosedTemplate) {
      const _filtered = list?.find(
        (t: Template) => t.identifier === selectedTemplate?.identifier
      );
      setTemplate(_filtered ? _filtered : templateUltimate);
    }
    // eslint-disable-next-line
  }, [list]);

  const setDefaultTemplate = (type: LayoutDevices) => {
    const _device = currentPlacement.templateSettings[type];
    const _template = _device?.template;
    setChoosedTemplate(_template ? true : false);
    setTemplate(_template || templateUltimate);
    _template && setSelectedTemplate(_template);
  };

  useEffect(() => {
    getTemplates();
    setDefaultTemplate(filters.deviceType);
    getFilters();
    // eslint-disable-next-line
  }, []);

  const getTemplateComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    const _template =
      currentPlacement.templateSettings[filters.deviceType].template;
    switch (_template?.identifier || template?.identifier) {
      case "templateUlt":
        jsxElm = (
          <TemplateUltimate
            layoutView={filters.deviceType}
            setCurrentPlacement={setCurrentPlacement}
            currentPlacement={currentPlacement}
            campaign={campaign}
            setCampaign={setCampaign}
            setTemplate={setTemplate}
          />
        );
        break;
      // Default Slider template with large images
      case "template-dlp1pre":
      case "template-dlp2pre":
      case "template-dlp3pre":
      case "template-dlp1post":
      case "template-dlp2post":
      case "template-dlp3post":
        jsxElm = (
          <TemplateN13
            layoutView={filters.deviceType}
            offersCount={_template?.offerCount}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            setCampaign={setCampaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      // Compact view template with 3 items
      case "template-msp3pre":
      case "template-msp3post":
      case "template-dsp3post":
      case "template-dsp3pre":
        jsxElm = (
          <TemplateC13
            layoutView={filters.deviceType}
            offersCount={_template?.offerCount}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            setCampaign={setCampaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      // Six pack template with load more
      case "template-dlp3+pre":
      case "template-dlp3+post":
        jsxElm = (
          <TemplateN36
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
            defaultSix={false}
          />
        );
        break;
      case "template-dlp6pre":
      case "template-dlp6post":
        jsxElm = (
          <TemplateN36
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
            defaultSix={true}
          />
        );
        break;
      case "template-Es":
        jsxElm = (
          <TemplateEs
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;

      case "template-NLH":
        jsxElm = (
          <TemplateNewLookHorizontal
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-EsTop":
        jsxElm = (
          <TemplateEsTop
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-Ec1":
      case "template-Ec2":
      case "template-Ec3":
        jsxElm = (
          <TemplateEmbeddedCompact
            layoutView={filters.deviceType}
            offersCount={_template?.offerCount || "3"}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-NL":
        jsxElm = (
          <TemplateNewLook
            layoutView={filters.deviceType}
            offersCount={_template?.offerCount || "3"}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-feature-pre":
      case "template-feature-post":
        jsxElm = (
          <TemplateFeature
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-dmp8pre":
      case "template-dmp8post":
        jsxElm = (
          <TemplateFilters48
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-msp8pre":
      case "template-msp8post":
        jsxElm = (
          <TemplateFiltersM48
            layoutView={filters.deviceType}
            offersCount={_template?.offerCount}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;
      case "template-DME6":
        jsxElm = (
          <TemplateDME6
            layoutView={filters.deviceType}
            displayOnly={filters.stage === PlacementStage.pre}
            setTemplate={setTemplate}
            campaign={campaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
          />
        );
        break;

      default:
        jsxElm = <></>;
        break;
    }
    return jsxElm;
    // eslint-disable-next-line
  }, [template, currentPlacement]);

  const filterLayoutType: ItemType[] = useMemo(() => {
    const arr: ItemType[] = [];
    if (filtersResponse) {
      for (const [key, value] of Object.entries(
        filtersResponse?.placementLayoutType
      )) {
        typeof value === "string" && arr.push({ label: value, key });
      }
    }

    return arr;
  }, [filtersResponse]);

  const filterImageSize: ItemType[] = useMemo(() => {
    const arr: ItemType[] = [];
    if (filtersResponse) {
      for (const [key, value] of Object.entries(
        filtersResponse?.templateImageSize
      )) {
        typeof value === "string" && arr.push({ label: value, key });
      }
    }

    return arr.reverse();
  }, [filtersResponse]);

  const filterCount: ItemType[] = useMemo(() => {
    const arr: ItemType[] = [{ label: "all", key: "all" }];
    if (filtersResponse) {
      for (const [key, value] of Object.entries(
        filtersResponse?.templateOfferCountFilter
      )) {
        value !== "all" && arr.push({ label: value?.toString(), key });
      }
    }
    return arr;
  }, [filtersResponse]);

  const getValueFromKey = (obj: any, objKey: string): string => {
    let response = "not found";

    if (obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (objKey === key) {
          if (typeof value === "string") {
            response = value;
          }
        }
      }
    }

    return response;
  };

  const handleSelect = (t: Template | undefined) => {
    setSelectedTemplate(t);
    setCurrentPlacement({
      ...currentPlacement,
      templateSettings: {
        ...currentPlacement.templateSettings,
        [filters.deviceType]: {
          ...currentPlacement.templateSettings[filters.deviceType],
          template: t || templateUltimate,
          type: LayoutClass.action,
          settings: {
            ...templateDefaultSettings,
            ...currentPlacement.templateSettings[filters.deviceType]
              .settings,
          },
        },
      },
    });
    setChoosedTemplate(true);
  };

  const handleClickChangeTemplate = () => {
    getTemplates();
    setChoosedTemplate(false);
  };

  const handleGoToSettings = () => {
    setChoosedTemplate(true);
  };

  const handleUpdateLayout = () => {
    const placementSettings = campaign.placementSettings.map(
      (placement: PlacementSettings) => {
        return placement.id === currentPlacement?.id
          ? {
            ...currentPlacement,
            displayOnly: filters.stage === PlacementStage.pre,
          }
          : placement;
      }
    );
    const newCampaign: CampaignType = {
      ...campaign,
      placementSettings: placementSettings,
    };
    handlePut(newCampaign, false);
    setCurrentPlacement(undefined);
  };

  const handleChangeStage = (value: boolean) => {
    const newStage: PlacementStage = value
      ? PlacementStage.post
      : PlacementStage.pre;
    if (selectedTemplate) {
      openConfirmTypeFilterModal(newStage);
    } else {
      const newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [filters.deviceType]: {
            ...currentPlacement.templateSettings[filters.deviceType],
            type: LayoutClass.action,
            settings: {
              ...templateDefaultSettings,
              mainLayout: {
                ...templateDefaultSettings.mainLayout,
                headerText: value ? postHeaderText : preHeaderText,
                subHeaderText: value ? postSubHeaderText : preSubHeaderText,
              },
            },
          },
        },
      };
      setFilters((prevState) => ({ ...prevState, stage: newStage }));
      setCurrentPlacement(newPlacement);
    }
  };

  const handleChangeDevicetype = (type: LayoutDevices) => {
    const _template = currentPlacement.templateSettings[type]?.template;

    if (!_template && selectedTemplate && isChoosedTemplate) {
      openConfirmDeviceFilterModal(type);
    } else {
      if (_template) {
        const _device = currentPlacement.templateSettings[type];
        const _template = _device?.template;
        setTemplate(_template || templateUltimate);
        _template && setSelectedTemplate(_template);
      } else {
        setSelectedTemplate(undefined);
      }
      setFilters((prevState) => ({ ...prevState, deviceType: type }));
    }
  };

  const openConfirmTypeFilterModal = (stage: PlacementStage) => {
    Modal.confirm({
      title:
        "When changing transaction type you will have to select a new template",
      content:
        "Changing a template won’t affect the deals you have selected or the design (colours, fonts etc) you used in your previous template.",
      okText: "Continue",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-express-success",
      },
      onOk: () => {
        const displayOnly: boolean = stage === PlacementStage.pre;
        setFilters((prevState) => ({ ...prevState, stage }));
        setCurrentPlacement({
          ...currentPlacement,
          displayOnly: displayOnly,
          templateSettings: {
            ...currentPlacement.templateSettings,
            [filters.deviceType]: {
              ...currentPlacement.templateSettings[filters.deviceType],
              type: LayoutClass.action,
              settings: {
                ...currentPlacement.templateSettings[filters.deviceType]
                  .settings,
                mainLayout: {
                  ...currentPlacement.templateSettings[filters.deviceType]
                    .settings?.mainLayout,
                  headerText: displayOnly ? preHeaderText : postHeaderText,
                  subHeaderText: displayOnly ? preHeaderText : preSubHeaderText,
                },
              },
            },
          },
        });
        setSelectedTemplate(undefined);
        setTemplate(undefined);
        setChoosedTemplate(false);
      },
    });
  };

  const openConfirmDeviceFilterModal = (deviceType: LayoutDevices) => {
    Modal.confirm({
      title: "When changing device type you will have to select a new template",
      content:
        "Changing a template won’t affect the deals you have selected or the design (colours, fonts etc) you used in your previous template.",
      okText: "Continue",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-express-success",
      },
      onOk: () => {
        setFilters((prevState) => ({ ...prevState, deviceType }));
        setSelectedTemplate(undefined);
        setTemplate(undefined);
        setChoosedTemplate(false);
      },
    });
  };

  const handleChangeAllocation = (value: number) => {
    setCurrentPlacement(
      (prevState) => prevState && { ...prevState, allocation: value }
    );
  };

  const handleChangeOrderExtractorInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    prop: keyof OrderExtractorProduct
  ) => {
    setCurrentPlacement(
      (prevState) =>
        prevState && {
          ...prevState,
          templateSettings: {
            ...prevState.templateSettings,
            [filters.deviceType]: {
              ...prevState.templateSettings[filters.deviceType],
              orderExtractor: {
                ...prevState.templateSettings[filters.deviceType]
                  ?.orderExtractor,
                productExtractorKeys: {
                  ...prevState.templateSettings[filters.deviceType]
                    ?.orderExtractor?.productExtractorKeys,
                  [prop]: e.target.value,
                },
              },
            },
          },
        }
    );
  };

  return (
    <>
      <div className="modal-layout-header">
        <div className="modal-layout-type">
          {isChoosedTemplate && selectedTemplate ? (
            <span>
              {" "}
              <InfoCircleOutlined
                id="transaction-type"
                className="font-hover mright5"
              />{" "}
              Transaction type
            </span>
          ) : (
            <>
              {selectedTemplate ? (
                <span className="bold mleft10"> {selectedTemplate?.name}</span>
              ) : (
                "no template selected"
              )}{" "}
            </>
          )}
          <div className="modal-layout-type-toggle">
            <label htmlFor="filterLayoutType" className="font-hover mleft5">
              <span
                className={
                  filters?.stage === PlacementStage.pre ? "pre-active" : ""
                }
              >
                Pre
              </span>
              <Switch
                id="filterLayoutType"
                size="small"
                checked={filters?.stage === PlacementStage.post}
                onChange={handleChangeStage}
              />
              <span
                className={
                  filters?.stage === PlacementStage.post ? "post-active" : ""
                }
              >
                Post
              </span>
            </label>
          </div>
          <div className="modal-layout-allocation">
            <label>
              <InfoCircleOutlined
                id="traffic-allocation"
                className="font-hover mright5"
              />{" "}
              Traffic allocation{" "}
              <span className="semibold">{currentPlacement.allocation}%</span>
            </label>
            <Slider
              min={0}
              max={100}
              step={5}
              className="min100"
              onChange={handleChangeAllocation}
              value={currentPlacement.allocation}
            />
          </div>
        </div>
        <div className="modal-layout-device">
          <div
            className={`icon-holder flex-center ${filters.deviceType === "desktop" ? "active" : ""
              }`}
            onClick={() => handleChangeDevicetype(LayoutDevices.desktop)}
          >
            <span
              className={`icon icon-desktop mright10  ${filters.deviceType === "desktop" ? "active" : ""
                }`}
            ></span>{" "}
            Desktop
          </div>
          <div
            className={`icon-holder flex-center ${filters.deviceType === "mobile" ? "active" : ""
              }`}
            onClick={() => handleChangeDevicetype(LayoutDevices.mobile)}
          >
            <span
              className={`icon icon-mobile mright10 ${filters.deviceType === "mobile" ? "active" : ""
                }`}
            ></span>{" "}
            Mobile
          </div>
        </div>
        <div className="modal-layout-advance">
          {isChoosedTemplate && selectedTemplate && (
            <div
              className="advance-trigger font-hover"
              onClick={() => setAdvanceOptions((prevState) => !prevState)}
            >
              <span className="mright5">Advanced options</span>
              {advanceOptions ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            </div>
          )}
        </div>
      </div>

      {isChoosedTemplate && selectedTemplate ? (
        <>
          {advanceOptions && (
            <div className="modal-layout-advance-options">
              <div className="advance-options-title">
                <span>
                  Advanced options{" "}
                  <InfoCircleOutlined
                    id="advanced-options"
                    className="font-hover mleft"
                  />
                </span>
              </div>
              <div className="advance-options-description">
                <span>Product information extracting html elements</span>

                <div className="font-small mleft20">
                  <Switch
                    id="allowextraction"
                    size="small"
                    className="displayswitch"
                    checked={
                      currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.allowExtraction
                    }
                    onChange={(checked: boolean) =>
                      setCurrentPlacement(
                        (prevState) =>
                          prevState && {
                            ...prevState,
                            templateSettings: {
                              ...prevState.templateSettings,
                              [filters.deviceType]: {
                                ...prevState.templateSettings[
                                filters.deviceType
                                ],
                                orderExtractor: {
                                  ...prevState.templateSettings[
                                    filters.deviceType
                                  ]?.orderExtractor,
                                  allowExtraction: checked,
                                },
                              },
                            },
                          }
                      )
                    }
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                  <label
                    htmlFor="allowextraction"
                    className="font-hover mleft5"
                  >
                    Allow extraction
                  </label>
                </div>
              </div>
              <div className="advance-options-row">
                <div className="advance-options-inputs">
                  <div className="font-xsmall">Html element</div>
                  <div className="option-col">
                    <input
                      type="text"
                      className="default-input"
                      placeholder="html-class"
                      onChange={(e) =>
                        handleChangeOrderExtractorInput(e, "htmlElement")
                      }
                      value={
                        currentPlacement?.templateSettings[filters.deviceType]
                          ?.orderExtractor?.productExtractorKeys?.htmlElement ||
                        ""
                      }
                    />
                    {currentPlacement?.templateSettings[filters.deviceType]
                      ?.orderExtractor?.allowExtraction &&
                      !currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.productExtractorKeys?.htmlElement && (
                        <div className="label-error">required</div>
                      )}
                  </div>
                </div>

                <div className="advance-options-inputs">
                  <div className="font-xsmall">Products selector</div>
                  <div className="option-col">
                    <input
                      type="text"
                      className="default-input"
                      placeholder="html-class"
                      onChange={(e) =>
                        handleChangeOrderExtractorInput(e, "productsSelector")
                      }
                      value={
                        currentPlacement?.templateSettings[filters.deviceType]
                          ?.orderExtractor?.productExtractorKeys
                          ?.productsSelector || ""
                      }
                    />
                    {currentPlacement?.templateSettings[filters.deviceType]
                      ?.orderExtractor?.allowExtraction &&
                      !currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.productExtractorKeys
                        ?.productsSelector && (
                        <div className="label-error">required</div>
                      )}
                  </div>
                </div>

                <div className="advance-options-inputs">
                  <div className="font-xsmall">Product name selector</div>
                  <div className="option-col">
                    <input
                      type="text"
                      className="default-input"
                      placeholder="html-class"
                      onChange={(e) =>
                        handleChangeOrderExtractorInput(
                          e,
                          "productNameSelector"
                        )
                      }
                      value={
                        currentPlacement?.templateSettings[filters.deviceType]
                          ?.orderExtractor?.productExtractorKeys
                          ?.productNameSelector || ""
                      }
                    />
                    {currentPlacement?.templateSettings[filters.deviceType]
                      ?.orderExtractor?.allowExtraction &&
                      !currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.productExtractorKeys
                        ?.productNameSelector && (
                        <div className="label-error">required</div>
                      )}
                  </div>
                </div>

                <div className="advance-options-inputs">
                  <div className="font-xsmall">Product price selector</div>
                  <div className="option-col">
                    <input
                      type="text"
                      className="default-input"
                      placeholder="html-class"
                      onChange={(e) =>
                        handleChangeOrderExtractorInput(
                          e,
                          "productPriceSelector"
                        )
                      }
                      value={
                        currentPlacement?.templateSettings[filters.deviceType]
                          .orderExtractor?.productExtractorKeys
                          ?.productPriceSelector || ""
                      }
                    />
                    {currentPlacement?.templateSettings[filters.deviceType]
                      ?.orderExtractor?.allowExtraction &&
                      !currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.productExtractorKeys
                        ?.productPriceSelector && (
                        <div className="label-error">required</div>
                      )}
                  </div>
                </div>

                <div className="advance-options-inputs">
                  <div className="font-xsmall">Total price selector</div>
                  <div className="option-col">
                    <input
                      type="text"
                      className="default-input"
                      placeholder="html-class"
                      onChange={(e) =>
                        handleChangeOrderExtractorInput(e, "totalPriceSelector")
                      }
                      value={
                        currentPlacement?.templateSettings[filters.deviceType]
                          ?.orderExtractor?.productExtractorKeys
                          ?.totalPriceSelector || ""
                      }
                    />
                    {currentPlacement?.templateSettings[filters.deviceType]
                      ?.orderExtractor?.allowExtraction &&
                      !currentPlacement?.templateSettings[filters.deviceType]
                        ?.orderExtractor?.productExtractorKeys
                        ?.totalPriceSelector && (
                        <div className="label-error">required</div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="modal-layout-filters">
            <div className="filters-page">
              <div className="layout-name">
                <span>{selectedTemplate.name}</span>
              </div>
              <div className="layout-url">
                <span className="layout-globe-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 8C15 11.866 11.866 15 8 15M15 8C15 4.13401 11.866 1 8 1M15 8H1M8 15C4.13401 15 1 11.866 1 8M8 15C9.7509 13.0832 10.7459 10.5956 10.8 8C10.7459 5.40442 9.7509 2.91685 8 1M8 15C6.2491 13.0832 5.25407 10.5956 5.2 8C5.25407 5.40442 6.2491 2.91685 8 1M1 8C1 4.13401 4.13401 1 8 1"
                      stroke="#C9CFD6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                {currentPlacement?.page}
              </div>
            </div>
            <div className="filter-action">
              <div
                className="filter-label font-hover"
                onClick={handleClickChangeTemplate}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 5.33333H14M5.33333 14V5.33333M2.44444 1H12.5556C13.3533 1 14 1.6467 14 2.44444V12.5556C14 13.3533 13.3533 14 12.5556 14H2.44444C1.6467 14 1 13.3533 1 12.5556V2.44444C1 1.6467 1.6467 1 2.44444 1Z"
                    stroke="#B2B7C3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="mleft5">Change template</span>
              </div>

              <Button
                type="primary"
                disabled={handleCheckAdvanceOptionsValidation(
                  currentPlacement.templateSettings[filters.deviceType]
                    .orderExtractor
                )}
                onClick={handleUpdateLayout}
              >
                Update layout
              </Button>
            </div>
          </div>
          {getTemplateComponent}
        </>
      ) : (
        <>
          <div className="modal-layout-filters">
            <div className="filters-page">
              <div
                className={`layout-name semibold font-hover`}
                onClick={handleGoToSettings}
              >
                <span className="template-choose-icon">
                  <svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 12L1 6.5L7 1"
                      stroke="#A3A8B9"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span>Go to settings</span>
              </div>
            </div>

            <div className="filter-action">
              <div className="filter-label font-hover">
                <Dropdown
                  menu={{
                    items: filterCount,
                    selectable: true,
                    selectedKeys: [filters?.offerCount || ""],
                    onClick: (props: DropdownMenuProps) => {
                      setFilters((prevState) => ({
                        ...prevState,
                        offerCount: props.key,
                      }));
                    },
                  }}
                  trigger={["click"]}
                >
                  <span className="mleft5">Offers: {filters?.offerCount}</span>
                </Dropdown>
              </div>

              <div className="filter-label font-hover">
                <Dropdown
                  menu={{
                    items: filterImageSize,
                    selectable: true,
                    selectedKeys: [filters.imageSize],
                    onClick: (props: DropdownMenuProps) =>
                      setFilters((prevState) => ({
                        ...prevState,
                        imageSize: props.key as ImageSize,
                      })),
                  }}
                  trigger={["click"]}
                >
                  <span className="mleft5">
                    Image Size:{" "}
                    {getValueFromKey(
                      filtersResponse?.templateImageSize,
                      filters?.imageSize || "all"
                    )}
                  </span>
                </Dropdown>
              </div>

              {/* <div className="filter-label font-hover">
                            <Dropdown menu={{
                                items: filterTransaction,
                                selectable: true,
                                selectedKeys: [filters.stage],
                                onClick: (props: DropdownMenuProps) => setFilters(prevState => ({ ...prevState, stage: props.key as PlacementStage }))
                            }} trigger={['click']}>

                                <span className="mleft5">Transaction: {filters.stage}</span>
                            </Dropdown>
                        </div> */}

              <div className="filter-label font-hover">
                <Dropdown
                  menu={{
                    items: filterLayoutType,
                    selectable: true,
                    selectedKeys: [filters.layoutType],
                    onClick: (props: DropdownMenuProps) =>
                      setFilters((prevState) => ({
                        ...prevState,
                        layoutType: props.key as LayoutType,
                      })),
                  }}
                  trigger={["click"]}
                >
                  <span className="mleft5">
                    Layout:{" "}
                    {getValueFromKey(
                      filtersResponse?.placementLayoutType,
                      filters.layoutType
                    )}
                  </span>
                </Dropdown>
              </div>

              {/* <div className="filter-label font-hover">
                            <Dropdown menu={{
                                items: filterDevice,
                                selectable: true,
                                selectedKeys: [filters.deviceType],
                                onClick: (props: DropdownMenuProps) => setFilters(prevState => ({ ...prevState, deviceType: props.key as LayoutDevices }))
                            }} trigger={['click']}>

                                <span className="mleft5">Device: {filters.deviceType}</span>
                            </Dropdown>
                        </div> */}

              {/* <Button type="primary">Save</Button> */}
            </div>

            <div className="filter-state">
              <Button
                type="primary"
                disabled={selectedTemplate?.identifier === template?.identifier}
                onClick={() => handleSelect(template)}
              >
                {selectedTemplate &&
                  selectedTemplate?.identifier === template?.identifier
                  ? "Selected"
                  : "Select"}
              </Button>
            </div>
          </div>
          <TemplateList
            filters={filters}
            setCurrentPlacement={setCurrentPlacement}
            offersCount={template?.offerCount}
            selectedTemplate={selectedTemplate}
            setTemplate={setTemplate}
            template={template || templateUltimate}
            list={list}
            campaign={campaign}
            currentPlacement={currentPlacement}
            isSingle={false}
          />
        </>
      )}
    </>
  );
};

export default TemplateDialog;
