import { Dropdown, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { itemsFontFamily, itemsFontSize } from "../../../../../campaigns/const/layout-options.enum";
import { ButtonSettings, ButtonStyleSettingsT, LocationPosition } from "../../../../../templates-previews/types/params.interfaces";
import {  LayoutDevices } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ButtonTextStyleSettings = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {

    const uniqueKey = new Date().getTime();

    const setNewSettings = (key: keyof ButtonSettings, value: string | LocationPosition | number) => {
        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                button: {
                    ...currentPlacement.templateSettings[layoutView].button,
                    [key]: value
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        [key]: value
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        [key]: value
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewSettings('text', value);
    }

    const setNewStyleSettings = (key: keyof ButtonStyleSettingsT, value: string | LocationPosition | number) => {
        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                button: {
                    ...currentPlacement.templateSettings[layoutView].button,
                    style: {
                        ...currentPlacement.templateSettings[layoutView].button?.style,
                        [key]: value
                    }
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        style: {
                            ...currentPlacement.templateSettings[layoutView].button?.style,
                            [key]: value
                        }
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        style: {
                            ...currentPlacement.templateSettings[layoutView].button?.style,
                            [key]: value
                        }
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewStyleSettings('backgroundColor', color);
    }

    const handleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewStyleSettings('textColor', color);
    }

    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        setNewStyleSettings('fontFamily', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewStyleSettings('fontSize', props.key);
    }


    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-single-col">
                    <input
                        type="text"
                        className="default-input"
                        placeholder="Offers"
                        onChange={handleChangeText}
                        value={currentPlacement.templateSettings[layoutView].button.text || ''}
                    />
                </div>
            </div>

            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonBgColor${uniqueKey}${layoutView}`}
                            onChange={handleBgChange}
                            value={currentPlacement.templateSettings[layoutView].button.style.backgroundColor || ''}
                        />
                        <label htmlFor={`buttonBgColor${uniqueKey}${layoutView}`}>
                            Background: {currentPlacement.templateSettings[layoutView].button.style.backgroundColor}
                        </label>
                    </div>
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonText${uniqueKey}${layoutView}`}
                            onChange={handleTextColorChange}
                            value={currentPlacement.templateSettings[layoutView].button.style.textColor || ''}
                        />
                        <label htmlFor={`buttonText${uniqueKey}${layoutView}`}>
                            Text: {currentPlacement.templateSettings[layoutView].button.style.textColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            onClick: handleChangeFontFamily,
                            defaultSelectedKeys: [currentPlacement.templateSettings[layoutView].button.style.fontFamily],
                        }} trigger={['click']}>
                            <Typography.Link>
                                {currentPlacement.templateSettings[layoutView].button.style.fontFamily}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            onClick: handleChangeFontSize,
                            defaultSelectedKeys: [currentPlacement.templateSettings[layoutView].button.style.fontSize],
                        }} trigger={['click']}>
                            <Typography.Link>
                                {currentPlacement.templateSettings[layoutView].button.style.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default ButtonTextStyleSettings
