import { Dispatch, useState } from "react";
import {
  CompanySettingsInterface,
  EmailTemplate,
} from "../../../types/company.interfaces";
import { Button, Empty, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./css/email-templates-settings.scss";
import failIcon from "../../../assets/images/icons/fail.svg";
import successIcon from "../../../assets/images/icons/success.svg";
interface Props {
  company: CompanySettingsInterface;
  setCompany: Dispatch<React.SetStateAction<CompanySettingsInterface>>;
}

const EmailTemplatesSettings = ({ company, setCompany }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({
    emailTemplateId: "",
    emailTemplateName: "",
  });

  const [editEmailTemplate, setEditEmailTemplate] = useState<EmailTemplate>({
    emailTemplateId: "",
    emailTemplateName: "",
  });

  const handleOpenEditModal = (item: EmailTemplate) => {
    setEdit(true);
    setEmailTemplate(item);
    setEditEmailTemplate(item);
    setModalOpen(true);
  };

  const handleOpenAddModal = () => {
    setEdit(false);
    setModalOpen(true);
  };

  const openConfirmModal = (id: string) => {
    Modal.confirm({
      title: "Are you sure you want to remove this email template?",
      content:
        "By removing this your offers will receive the default email from Brandswap",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  const handleRemove = (id: string) => {
    setLoading(true);
    const emailTemplates = company.emailTemplates.filter(
      (et) => et.emailTemplateId !== id
    );

    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          emailTemplates: emailTemplates,
        }
    );
    setLoading(false);
  };

  const onAdd = () => {
    setLoading(true);

    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          emailTemplates: [...prevState.emailTemplates, emailTemplate],
        }
    );
    setModalOpen(false);
    setLoading(false);
    message.success("Email Template was added successfully", 1);
  };

  const onEdit = () => {
    setLoading(true);
    const emailTemplates = (company.emailTemplates || []).map((et) =>
      et.emailTemplateId === editEmailTemplate.emailTemplateId
        ? { ...et, ...emailTemplate }
        : et
    );

    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          emailTemplates: emailTemplates,
        }
    );

    setModalOpen(false);
    setLoading(false);
    message.success("Email Template edited successfully", 1);
  };

  return (
    <>
      <div>
        <div className="layout-options">
          <div className="filters">
            <div className="filter-dropdown"></div>
          </div>
          <div className="layout-actions"></div>
        </div>
        <div className="table-wrapper table-scrollable-wrapper">
          <div className="email-table-header">
            <div className="table-title">
              Email Templates: {company.emailTemplates.length}
            </div>

            <Button
              type="primary"
              onClick={handleOpenAddModal}
              className="button-success"
            >
              Create email template
            </Button>
          </div>
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Template Name</th>
                  <th>ID</th>
                  <th>Actions</th>
                </tr>
              </thead>

              <tbody>
                <>
                  {company.emailTemplates.map(
                    (item: EmailTemplate, index: number) => {
                      return (
                        <tr key={`offer-${index}`}>
                          <td className="td-min-100">
                            {item.emailTemplateName}
                          </td>
                          <td className="td-min-100">{item.emailTemplateId}</td>
                          <td className="td-min-100">
                            <div className="actions">
                              <Button
                                className="edit-button"
                                onClick={() => {
                                  handleOpenEditModal(item);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                className="remove-button mleft20"
                                onClick={() =>
                                  openConfirmModal(item.emailTemplateId)
                                }
                              >
                                Remove
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}

                  {loading ? (
                    <tr
                      className={
                        company.emailTemplates.length > 0
                          ? "loader-absolute"
                          : ""
                      }
                    >
                      <td className="text-center" colSpan={7}>
                        <LoadingOutlined />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {company.emailTemplates.length === 0 && (
                        <tr>
                          <td className="text-center" colSpan={7}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="No email templates."
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        title={<></>}
        closable={true}
        width="35vw"
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
        maskClosable={true}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        afterClose={() => {
          setEdit(false);
          setEmailTemplate({
            emailTemplateId: "",
            emailTemplateName: "",
          });
        }}
        footer={<></>}
      >
        <div className="modal-title">
          {isEdit ? "Edit email template" : "Create email template"}
        </div>
        <div className="modal-description">
          Fill in the basic info of the email template below
        </div>

        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Template Name*</span>
            {isEdit && (
              <span className="unauth-label-error">
                <span>
                  <img
                    src={
                      emailTemplate.emailTemplateName.length
                        ? successIcon
                        : failIcon
                    }
                    alt={
                      emailTemplate.emailTemplateName.length
                        ? "success"
                        : "fail"
                    }
                  />
                </span>
                {!emailTemplate.emailTemplateName.length && (
                  <span id="nide">Template name is required.</span>
                )}
              </span>
            )}
          </div>
          <div className="widget-input widget-input-invalid">
            <input
              type="text"
              id="template-ID"
              name="template-ID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailTemplateName: e.target.value,
                })
              }
              value={emailTemplate?.emailTemplateName}
              aria-describedby="wide"
              autoComplete="off"
              placeholder="e.g. ABC Template"
            />
          </div>
        </div>
        <div className="widget-form-row">
          <div className="unauth-label">
            <span>Template ID*</span>
            {isEdit && (
              <span className="unauth-label-error">
                <span>
                  <img
                    src={
                      emailTemplate.emailTemplateId.length
                        ? successIcon
                        : failIcon
                    }
                    alt={
                      emailTemplate.emailTemplateId.length
                        ? successIcon
                        : failIcon
                    }
                  />
                </span>
                {!emailTemplate.emailTemplateId.length && (
                  <span id="nide">Template ID is required.</span>
                )}
              </span>
            )}
          </div>
          <div className="widget-input widget-input-invalid">
            <input
              type="text"
              id="company-name"
              name="company-name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmailTemplate({
                  ...emailTemplate,
                  emailTemplateId: e.target.value,
                })
              }
              value={emailTemplate?.emailTemplateId}
              aria-describedby="cnide"
              autoComplete="off"
              placeholder="e.g. 934942-0059511523"
            />
          </div>
        </div>

        <div className="widget-button mbot10 mtop10">
          <Button
            className="cancel-btn mright10"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>

          <Button
            type="primary"
            disabled={
              !emailTemplate?.emailTemplateName ||
              !emailTemplate?.emailTemplateId
            }
            className="button-success success-btn"
            onClick={() => (isEdit ? onEdit() : onAdd())}
          >
            {isEdit ? "Update" : "Save"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EmailTemplatesSettings;
