export enum VALIDATION {
    required = "Field is required",
    notValid = "Field is not valid",
    tooShort = "Field is too short",
    nameValid = "Must be valid 2 to 40 characters",
    emailValid = "Must be valid email",
    sameAsPrevious = "Must be same as previous",
    fieldTooShort = "Field is too short",
    validPhone = "Must be a valid phone number!",
    passwordValid = "Minimum 8 characters that include uppercase, lowercase, number and symbol",
}