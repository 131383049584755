import { useState } from "react";
import { getOnboardingStepByNr, getOnboardingStepIndex, ONBOARDING } from "./const/onboarding.enum";
import "./css/style.scss";
import { OnboardingSteps } from "./interfaces";
import StepAffiliate from "./steps/affiliate/stepAffiliate.component";
import StepCompanyDetails from "./steps/company-details/stepCompanyDetails.component";
import StepType from "./steps/type/stepType.component";
import StepUserDetails from "./steps/user-details/stepUserDetails.component";
import StepVerification from "./steps/verification/stepVerification.component";
import checkIcon from "../../assets/images/icons/check-green.svg";
import { OnboardingCompnay } from "../../types/onboarding.interfaces";
import useAuth from "../../hooks/useAuth.hook";
import { UserLoginResponse } from "../../types/user.interfaces";
import Footer from "../footer/footer.component";

const Onboarding = () => {
    const { auth } = useAuth();
    const [onboardingSteps, setOnboardingSteps] = useState<OnboardingSteps>({ currentStep: !auth?.onboardingStatus?.completed ? getOnboardingStepByNr(auth?.onboardingStatus?.next) : ONBOARDING.userdetails });
    const [onboardingUser, setOnboardingUser] = useState<Partial<UserLoginResponse> | undefined>(auth ? auth : undefined);
    const [onboardingCompany, setOnboardingCompany] = useState<OnboardingCompnay | undefined>();

    const getCurrentComponent = (step: ONBOARDING): JSX.Element => {
        let jsxElm = <></>;
        switch (step) {
            case ONBOARDING.userdetails:
                jsxElm = <StepUserDetails changeStep={setOnboardingSteps} setUser={setOnboardingUser} />
                break;
            case ONBOARDING.verification:
                jsxElm = onboardingUser ? <StepVerification changeStep={setOnboardingSteps} user={onboardingUser} /> : <></>
                break;
            case ONBOARDING.usertype:
                jsxElm = <StepType changeStep={setOnboardingSteps} setCompany={setOnboardingCompany} />
                break;
            case ONBOARDING.companydetails:
                jsxElm = onboardingUser && onboardingCompany ? <StepCompanyDetails changeStep={setOnboardingSteps} setCompany={setOnboardingCompany} user={onboardingUser}  company={onboardingCompany} /> : <></>
                break;
            case ONBOARDING.affiliate:
                jsxElm = onboardingCompany && onboardingUser ? <StepAffiliate changeStep={setOnboardingSteps} user={onboardingUser} company={onboardingCompany} /> : <></>
                break;
            default:
                jsxElm = <></>
                break;
        }

        return jsxElm
    }

    const getCompletedClass = (step: number): string => {
        const stepIndex = getOnboardingStepIndex(onboardingSteps.currentStep);
        return stepIndex === step ? "current-step" : stepIndex > step ? "completed-step" : "";
    }

    return <>
        <div className="unauth-container-wrapper scroll-dark unauth-onboard">
            <div className="unauth-container">
                <div className="onboarding-header">
                    <ul>
                        <li className={getCompletedClass(0)}>
                            <img src={checkIcon} alt="completed" />
                            User details
                        </li>
                        <li className={getCompletedClass(1)}>
                            <img src={checkIcon} alt="completed" />
                            Verification
                        </li>
                        <li className={getCompletedClass(2)}>
                            <img src={checkIcon} alt="completed" />
                            User type
                        </li>
                        <li className={getCompletedClass(3)}>
                            <img src={checkIcon} alt="completed" />
                            Company details
                        </li>
                        <li className={getCompletedClass(4)}>
                            <img src={checkIcon} alt="completed" />
                            Affiliate
                        </li>
                    </ul>
                </div>
                <div className="onboarding-content">
                    {onboardingSteps?.currentStep && getCurrentComponent(onboardingSteps.currentStep)}
                </div>
                <Footer />
            </div>
        </div>
    </>
}

export default Onboarding;