export enum EXPRESS_ONBOARDING {
    details = 'details',
    partners = 'partners',
    placements = 'placements',
    layout = 'layout',
}

export enum EXPRESS_LAYOUT_TYPE {
    easy = 'Easy',
    advanced = 'Advanced'
}

export enum PLATFORM_VIEW_MODE {
    express = 'express',
    full = 'full'
}

export enum BANK_ACCUNT_STATUS {
    notstarted = "notstarted",
    notcompleted = "notcompleted",
    completed = "completed"
}

export const registered_COUNTRY = {
    currencySymbol: "",
    id:  "",
    isoCode: "",
    name:  "",
}