import { Button, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios.hook";
import useExpress from "../../../hooks/useExpress.hook";
import { ExpressPage } from "../const/views";
import "../css/express.scss";

const ExpressStripeReturn = () => {
  const { setExpressPage, refreshState } = useExpress();

  const navigate = useNavigate();

  const { myAxios } = useAxios();
  const params = new URLSearchParams(document.location.search);


  const postPaymentOnboardComplete = async (acc: string, comp: string) => {
    const { response } = await myAxios({ method: 'POST', url: `payments/onboarding/complete/${acc}/${comp}` })

    if (response?.data.status) {
      message.success('Stripe account added');
    } else {
      message.error('Stripe account failed to add');
    }

    refreshState();
  }

  useEffect(() => {
    document.title = "BrandSwap Express - Stripe";
    setExpressPage(ExpressPage.stripe);

    const accountNr = params.get('acc');
    const companyNr = params.get('comp');

    if (accountNr && companyNr) {
      postPaymentOnboardComplete(accountNr, companyNr);
    }
    // eslint-disable-next-line
  }, []);


  return <>
    <div className="widget express-widget widget-small">
      <div className="widget-title flex-center">
        Connected to stripe account
      </div>
      <div className="widget-description mbot20">
        You have successfully connected to your stripe account, now you will accept your earnings automatically from our services
      </div>
      <div className="widget-content">
        <Button type="primary" onClick={() => navigate('/express/dashboard')}>Go to dashboard</Button>
      </div>
    </div>
  </>;
}

export default ExpressStripeReturn;
