import { useMemo } from "react";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../../../campaigns/const/views";
import { LocationPosition, LocationSettings } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutClass } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ExpressPopupLocationSettings = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {

    const _type = useMemo(() => {
        if (currentPlacement) {
            var _type: LayoutClass.popup | LayoutClass.action = currentPlacement.templateSettings[layoutView].type === LayoutClass.action ? LayoutClass.action : LayoutClass.popup;
            return _type
        }
        return LayoutClass.popup
    }, [currentPlacement, layoutView])

    const setNewSettings = (key: keyof LocationSettings, value: string | LocationPosition | number, reset: boolean) => {
        const location = {
            ...currentPlacement.templateSettings[layoutView][_type].location,
            [key]: value,
        }

        if (reset) {
            location.x = 0;
            location.y = 0;
        }

        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                [_type]: {
                    ...currentPlacement.templateSettings[layoutView][_type],
                    location
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        location
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        location
                    }
                }
            }
        }


        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings })
    }

    const handleChangeLocation = (location: LocationPosition) => {
        setNewSettings('location', location, true);
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="vertical-tabs">
                    <div className="vertical-tab-list">

                        <div className="option-title">
                            Location
                        </div>

                    </div>
                    <div className="vertical-tab-content">

                        <div className="top-content">
                            <div className="desktop-screen">
                                <div className="desktop-placement">

                                    {currentPlacement.templateSettings[layoutView][_type]?.minimized &&
                                        <span
                                            onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                            className={`placement-topLeft ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.topLeft ? 'active' : ''}`}
                                        ></span>
                                    }
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.top)}
                                        className={`placement-top ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.top ? 'active' : ''}`}
                                    ></span>
                                    {currentPlacement.templateSettings[layoutView][_type]?.minimized &&
                                        <span
                                            onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                            className={`placement-topRight ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.topRight ? 'active' : ''}`}
                                        ></span>
                                    }
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.left)}
                                        className={`placement-left ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.left ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.center)}
                                        className={`placement-center ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.center ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.right)}
                                        className={`placement-right ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.right ? 'active' : ''}`}
                                    ></span>
                                    {currentPlacement.templateSettings[layoutView][_type]?.minimized &&
                                        <span
                                            onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                            className={`placement-bottomLeft ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                        ></span>
                                    }
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                        className={`placement-bottom ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.bottom ? 'active' : ''}`}
                                    ></span>
                                    {currentPlacement.templateSettings[layoutView][_type]?.minimized &&
                                        <span
                                            onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                            className={`placement-bottomRight ${currentPlacement.templateSettings[layoutView][_type].location.location === LocationPosition.bottomRight ? 'active' : ''}`}
                                        ></span>
                                    }
                                </div>
                            </div>
                            <div className="desktop-tail">

                            </div>
                            <div className="desktop-legs">

                            </div>
                        </div>
                        {/* <div className="bottom-content">
                                    <div className="mobile-screen">
                                        <div className="mobile-placement">
                                            <span
                                                className={`placement-topLeft ${location === LocationPosition.topLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                            ></span>
                                            <span
                                                className={`placement-left ${location === LocationPosition.left ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.left)}
                                            ></span>
                                            <span
                                                className={`placement-top ${location === LocationPosition.top ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.top)}
                                            ></span>
                                            <span
                                                className={`placement-topRight ${location === LocationPosition.topRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                            ></span>
                                            <span
                                                className={`placement-right ${location === LocationPosition.right ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.right)}
                                            ></span>
                                            <span
                                                className={`placement-bottomLeft ${location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                            ></span>
                                            <span
                                                className={`placement-bottom ${location === LocationPosition.bottom ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                            ></span>
                                            <span
                                                className={`placement-bottomRight ${location === LocationPosition.bottomRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                            ></span>
                                        </div>
                                    </div>
                                </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ExpressPopupLocationSettings
