import { Layouts } from "react-grid-layout";

export enum INSIGHT_WIDGET {
    tableEvents = "tableEvents",
    table2Events = "table2Events",
    chartEvents = "chartEvents",
}

export const insightLayout: Layouts = {
    desktop: [
        { i: INSIGHT_WIDGET.tableEvents, x: 0, y: 0, w: 12, h: 6, minW: 5, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET.table2Events, x: 0, y: 6, w: 12, h: 6, minW: 5, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET.chartEvents, x: 0, y: 12, w: 12, h: 5, minW: 5, maxW: 12, minH: 4, maxH: 12 },
    ],
    mobile: [
        { i: INSIGHT_WIDGET.tableEvents, x: 0, y: 0, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET.table2Events, x: 0, y: 6, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET.chartEvents, x: 0, y: 12, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
    ],
}

export enum INSIGHT_WIDGET_ADMIN {
    tableEvents = "tableEvents",
    table2Events = "table2Events",
    chartEvents = "chartEvents",
    adminChartEvents = "adminChartEvents",
}

export const insightAdminLayout: Layouts = {
    desktop: [
        { i: INSIGHT_WIDGET_ADMIN.tableEvents, x: 0, y: 0, w: 12, h: 6, minW: 5, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.table2Events, x: 0, y: 6, w: 12, h: 6, minW: 5, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.chartEvents, x: 0, y: 12, w: 12, h: 6, minW: 5, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.adminChartEvents, x: 0, y: 18, w: 12, h: 8, minW: 5, maxW: 12, minH: 4, maxH: 12 },
    ],
    mobile: [
        { i: INSIGHT_WIDGET_ADMIN.tableEvents, x: 0, y: 0, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.table2Events, x: 0, y: 6, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.chartEvents, x: 0, y: 12, w: 12, h: 6, minW: 6, maxW: 12, minH: 4, maxH: 12 },
        { i: INSIGHT_WIDGET_ADMIN.adminChartEvents, x: 0, y: 18, w: 12, h: 8, minW: 6, maxW: 12, minH: 4, maxH: 12 },
    ],
}
