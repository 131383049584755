import { Empty } from "antd";
import React from "react";

const Invoices: React.FC = () => {
  return (
    <div>
      <div>
        <div className="table-wrapper table-scrollable-wrapper">
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center" colSpan={7}>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="There are no invoices."
                    />
                  </td>
                </tr>
              </tbody>

              {/* <tbody>
            <>
              {company.emailTemplates.map(
                (item: EmailTemplate, index: number) => {
                  return (
                    <tr key={`offer-${index}`}>
                      <td className="td-min-100">
                        {item.emailTemplateName}
                      </td>
                      <td className="td-min-100">
                        {item.emailTemplateId}
                      </td>
                      <td className="td-min-100">
                        <div className="actions">
                          <Button
                            className="edit-button"
                            onClick={() => {
                              handleOpenEditModal(item);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            className="remove-button mleft20"
                            onClick={() =>
                              openConfirmModal(item.emailTemplateId)
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}

              {loading ? (
                <tr
                  className={
                    company.emailTemplates.length > 0
                      ? "loader-absolute"
                      : ""
                  }
                >
                  <td className="text-center" colSpan={7}>
                    <LoadingOutlined />
                  </td>
                </tr>
              ) : (
                <>
                  {company.emailTemplates.length === 0 && (
                    <tr>
                      <td className="text-center" colSpan={7}>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description="No email templates."
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </>
          </tbody> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
