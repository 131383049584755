import React from "react";
import { CampaignType } from "../../../../types/campaign.interfaces";
import { Offer } from "../../../../types/offer.interfaces";
import { LayoutDevices } from "../../../campaigns/const/views";
import { InlineSettings } from "../../types/params.interfaces";
import InlineItem from "./item/InlineItem.component";

interface Props {
  description: string,
  settings: InlineSettings,
  campaign: CampaignType,
  displayOnly: boolean,
  layoutView: LayoutDevices
}

const TemplateInline = ({ layoutView, description, settings, campaign, displayOnly }: Props) => {

  return <>
    <div className={`opl-wrapper opl-wrapper-inline opl-wrapper-image-${settings?.imageRepresentation?.position} ${settings?.minimized ? 'opl-inline-minimized' : ''} ${layoutView === LayoutDevices.mobile ? 'opl-vertical-order' : ''} ${campaign?.generalOptions.emailCaptureOnOffers || !displayOnly ? 'opl-email-capture' : ''}`}>
      <div className={`opl-box opl-box-${settings.general.align} opl-box-${settings.image.size}`} style={{ backgroundColor: settings.general.backgroundColor }}>
        <div className="opl-inner-box">
          <div className="opl-title" style={{
            color: settings.header.textColor,
            fontSize: `${settings.header.fontSize}px`,
            fontFamily: settings.header.fontFamily,
            fontWeight: settings.header.fontWeight,
            textTransform: settings.header.textTransform
          }}>
            <div className="opl-title-label">
              <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" stroke={settings.header.textColor} d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
              </svg>
              <span className={`${settings?.minimized ? 'opl-max-2lines' : ''}`}>
                {description}
              </span>
            </div>
          </div>
          <div className="opl-list">
            <ul className="opl-ul" style={{ color: settings.header.textColor }}>
              {campaign?.offers.map((o: Offer, i: number) => {
                return <React.Fragment key={i}>
                  {i + 1 <= Number(settings.general.offers) &&
                    <InlineItem offer={o} settings={settings} displayOnly={displayOnly} />
                  }
                </React.Fragment>
              })}
            </ul>
            {displayOnly ? <>
              {campaign?.generalOptions.emailCaptureOnOffers &&
                <div className={`opl-list-info ${displayOnly ? '' : 'opl-list-info-small'}`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="12" fill="white" />
                    <path d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125" stroke="#B0B0B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <span>Pick your gifts and receive by e-mail after purchase</span>
                </div>
              }
            </>
              :
              <>
                <div className={`opl-list-info opl-list-info-small`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                  <span>By selecting the option above you will be directed to a third party website. Any use of third party websites are not subject to our Privacy Policy, and we encourage you to also read their privacy statements</span>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  </>
}

export default TemplateInline;