import { Button, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import usePage from "../../../hooks/usePage.hook";
import { Company } from "../../../types/company.interfaces";
import { OfferCreate } from "../../../types/offer.interfaces";
import CreateOfferContent from "./CreateOfferContent.component";
import CreateOfferSettings from "./CreateOfferSettings.component";
import { emptyOffer } from "../../../utility/enums/offer.enums";
import useAuth from "../../../hooks/useAuth.hook";
import { useNavigate, useParams } from "react-router-dom";
import { HTTPS_URL_REGEX } from "../../../utility/regexp";
import { ACCESS_TYPES } from "../../../utility/enums/user.enums";
import { LoadingOutlined } from "@ant-design/icons";
import CreateOfferRedemtion from "../../offers/create/CreateOfferRedemtion";

const tabs = ["Settings", "Host", "Exclusive"];

const CreateHostOffer = () => {
  const { id } = useParams();
  const { setPage } = usePage();
  const [offer, setOffer] = useState<OfferCreate>(emptyOffer);
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [loading, setLoading] = useState<boolean>(false);

  const { company } = useAuth();

  const { myAxios } = useAxios();

  const getOfferById = async (id: string) => {
    const { response } = await myAxios({ method: "GET", url: `offers/${id}` });

    if (response?.data?.status) {
      setOffer(response?.data?.result);
    } else {
      message.error("Failed to single offer", 1);
    }
  };

  const getCompanyById = async (companyId: string) => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/${companyId}`,
    });
    if (response?.data.status || response?.status === 200) {
      const _currentCompany: Company = response?.data;
      const _networkId =
        _currentCompany.networks?.length > 0
          ? _currentCompany.networks[0].id
          : "";

      setOffer((prevState) => ({
        ...prevState,
        advertiser: {
          ...prevState.advertiser,
          affNetwork_Id: prevState.advertiser.affNetwork_Id || _networkId,
          company_Id: _currentCompany?.id,
        },
      }));

      setCurrentCompany(_currentCompany);
    } else {
      message.error("Failed to get company", 1);
    }
  };

  const handleCreateOffer = async () => {
    setLoading(true);
    const payload: OfferCreate = {
      ...offer,
      exclusiveTo: {
        ...offer.exclusiveTo,
        retailers: [
          {
            id: company?.company_Id || "",
            name: company?.name || "",
            logoUrl: "",
          },
        ],
      },
    };
    const { response } = await myAxios({
      method: "POST",
      url: "offers/host",
      data: payload,
    });
    if (response?.data?.status) {
      const _id = response.data?.result?.id;
      message.success("Offer is created successfully", 1);
      _id && navigate(`/offers-host/edit/${_id}`);
    } else {
      message.error("Failed to create offer", 1);
    }
    setLoading(false);
  };

  const handleEditOffer = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "PUT",
      url: `offers/host/${id}`,
      data: offer,
    });
    if (response?.data?.status) {
      message.success("Offer is updated successfully", 1);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
    setLoading(false);
  };

  const handleStartOffer = async (offerId: string) => {
    const { response } = await myAxios({
      method: "POST",
      url: `offers/active/${offerId}/true`,
    });
    if (response?.data?.status) {
      message.success("Offer is started", 1);
      getOfferById(offerId);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
  };

  const handleStopOffer = async (offerId: string) => {
    const { response } = await myAxios({
      method: "POST",
      url: `offers/active/${offerId}/false`,
    });
    if (response?.data?.status) {
      message.success("Offer is stopped", 1);
      getOfferById(offerId);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
  };

  const handleValidation = useMemo((): boolean => {
    let isValid =
      !offer.title ||
      !offer.description ||
      !offer.longDescription ||
      !HTTPS_URL_REGEX.test(offer.destinationUrl);

    return isValid;
  }, [offer]);

  useEffect(() => {
    setPage({
      title: <>Create Offer</>,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getOfferById(id);
      setPage({
        title: <>Save Offer</>,
      });
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (company?.company_Id) {
      getCompanyById(company.company_Id);
    }
    // eslint-disable-next-line
  }, [company]);

  return (
    <>
      <div className="content">
        <div className="content-header">
          <ul className="content-list">
            <li
              className={activeTab === "Settings" ? "active" : ""}
              onClick={() => setActiveTab("Settings")}
            >
              Settings
            </li>
            {/* <li className={activeTab === 'Host' ? 'active' : ''} onClick={() => setActiveTab('Host')}>
                        Host Specific
                    </li>
                    <li className={activeTab === 'Exclusive' ? 'active' : ''} onClick={() => setActiveTab('Exclusive')}>
                        Exclusive to
                    </li> */}
          </ul>
          <div className="content-action">
            {company?.userAccess?.access.includes(ACCESS_TYPES.offerwrite) && (
              <>
                {id && (
                  <>
                    {offer?.status === "active" ? (
                      <Button
                        type="primary"
                        className="button-default thin button-icon"
                        icon={<span className="icon-stop icon"></span>}
                        onClick={() => handleStopOffer(id)}
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="button-default thin button-icon"
                        icon={<span className="icon-start icon"></span>}
                        onClick={() => handleStartOffer(id)}
                      >
                        Start
                      </Button>
                    )}
                  </>
                )}

                <Button
                  type="primary"
                  disabled={handleValidation || loading}
                  onClick={id ? handleEditOffer : handleCreateOffer}
                  className={`${id ? "button-active" : "button-success"}`}
                >
                  {id ? <>Save</> : <>Create</>}{" "}
                  {loading && <LoadingOutlined />}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="content-layout content-mix-details content-offers">
          <div className="mright20 widget-wrapper grow">
            <div className="widget mbot20 widget-icon">
              <CreateOfferSettings
                networks={currentCompany?.networks || []}
                emailTemplates={currentCompany?.emailTemplates || []}
                offer={offer}
                setOffer={setOffer}
              />
            </div>

            <div className="widget mbot20 widget-icon">
              <CreateOfferRedemtion
                emailTemplates={currentCompany?.emailTemplates || []}
                offer={offer}
                setOffer={setOffer}
              ></CreateOfferRedemtion>
            </div>
          </div>
          <div className="widget mbot20 widget-icon">
            <CreateOfferContent offer={offer} setOffer={setOffer} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateHostOffer;
