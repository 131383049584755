import { Checkbox, Collapse, DatePicker, InputNumber, message, Radio, Switch } from "antd"
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { VALIDATION } from "../../../../utility/enums/validation.enums";
import failIcon from "../../../../assets/images/icons/fail.svg";
import successIcon from "../../../../assets/images/icons/success.svg";
import { CampaignAccessOptions, CampaignCookieOptions, CampaignGeneralOptions, CampaignSaveOfferOptions, CampaignSharingOptions, CampaignType } from "../../../../types/campaign.interfaces";
import usePage from "../../../../hooks/usePage.hook";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { stringDateToMoment } from "../../../../utility/date";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Offer } from "../../../../types/offer.interfaces";
import { RedeemBy } from "../../../../utility/enums/offer.enums";
import { DATALAYER_TYPE } from "../../../express/const/campaigns.enum";

interface Props {
    campaign: CampaignType,
    setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>,
}

const CampaignDetails = ({ campaign, setCampaign, setDisabled }: Props) => {
    const { setPage } = usePage();
    const userRef = useRef<HTMLInputElement>(null);

    const [campaignName, setCampaignName] = useState<string>(campaign?.name || "");
    const [validCampaignName, setValidCampaignName] = useState<boolean>(false);
    const [campaignNameFocus, setCampaignNameFocus] = useState<boolean>(false);

    const [description, setDescription] = useState<string>(campaign?.description || "");
    const [validDescription, setValidDescription] = useState<boolean>(false);
    // const [descriptionFocus, setDescriptionFocus] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string>(campaign?.startDate || "");
    const [validStartDate, setValidStartDate] = useState<boolean>(false);
    const [startDateFocus, setStartDateFocus] = useState<boolean>(false);

    const [endDate, setEndDate] = useState<string>(campaign?.endDate || "");
    const [validEndDate, setValidEndDate] = useState<boolean>(false);
    const [endDateFocus, setEndDateFocus] = useState<boolean>(false);

    const [accessParam, setAccessParam] = useState<string>(campaign?.campaignAccessOptions.accessParam || "");
    const [validAccessParam, setValidAccessParam] = useState<boolean>(false);

    useEffect(() => {
        if (campaign?.id) {
            setCampaignName(campaign?.name || "");
            setDescription(campaign?.description || "");
            setStartDate(campaign?.startDate || "");
            setEndDate(campaign?.endDate || "");
            setAccessParam(campaign?.campaignAccessOptions.accessParam);
            setDisabled(false);
        }
        // eslint-disable-next-line
    }, [campaign.id]);

    // Set focus to company name
    useEffect(() => {
        if (userRef.current) userRef.current.focus();
    }, []);

    // Validate company name
    useEffect(() => {
        setPage({
            title: <>{campaignName}</>
        });
        const isValid = campaignName.length > 1;
        setValidCampaignName(isValid);
        // eslint-disable-next-line
    }, [campaignName]);

    // Validate start date
    useEffect(() => {
        const isValid = startDate.length > 0;
        setValidStartDate(isValid);
    }, [startDate]);

    // Validate company name
    useEffect(() => {
        const isValid = endDate.length > 0;
        setValidEndDate(isValid);
    }, [endDate]);

    // Validate company description
    useEffect(() => {
        const isValid = description.length > 0;
        setValidDescription(isValid);
    }, [description]);

    // Validate start date
    useEffect(() => {
        const isValid = accessParam?.length > 0;
        setValidAccessParam(isValid);
    }, [accessParam]);

    const disabledStartDate: RangePickerProps['disabledDate'] = (current: moment.Moment) => {
        // to recheck
        // const _endDate = endDate ? stringDateToMoment(endDate).add(-1, 'day') : stringDateToMoment(endDate);
        // Can not select days before today and after end date
        return current && (current < moment().add(-1, 'day') || current > stringDateToMoment(endDate));
    };

    const disabledEndDate: RangePickerProps['disabledDate'] = (current: moment.Moment) => {
        // To recheck
        // const _startDate = startDate ? stringDateToMoment(startDate).add(-1, 'day') : stringDateToMoment(startDate);
        // Can not select date before today and before start date
        return current && (current < stringDateToMoment(startDate) || current < moment().add(-1, 'day'));
    };

    useEffect(() => {
        if (campaignName &&
            startDate &&
            endDate) {

            !campaign.campaignAccessOptions.openToEveryone && accessParam?.length === 0 ? setDisabled(true) : setDisabled(false);

            if (campaign.generalOptions.frequencyCapping.isEnabled &&
                (typeof campaign.generalOptions.frequencyCapping.frequency !== 'number' ||
                    typeof campaign.generalOptions.frequencyCapping.reset !== 'number')) {
                setDisabled(true);
            }

            setCampaign(prevState => ({
                ...prevState,
                name: campaignName,
                startDate: startDate,
                endDate: endDate,
                description: description,
                campaignAccessOptions: {
                    ...campaign.campaignAccessOptions,
                    accessParam: accessParam
                }
            }));
        } else {
            setDisabled(true);
        }
        // eslint-disable-next-line
    }, [campaignName, startDate, endDate, description, accessParam, campaign.campaignAccessOptions.openToEveryone, campaign.generalOptions.frequencyCapping]);

    const checkOffersWithRedemtion = (_offers: Offer[]): boolean => {
        let hasOffersEmailRedemtion = false;
        _offers.forEach(_offer => _offer.offerSettings.redeemBy === RedeemBy.email && (hasOffersEmailRedemtion = true));
        return hasOffersEmailRedemtion
    }

    const hanldeCampaignAccessOptions = (key: keyof CampaignAccessOptions, value: boolean | string) => {

        !value && !validAccessParam && setDisabled(true);

        setCampaign(prevState => ({
            ...prevState,
            campaignAccessOptions: {
                ...prevState.campaignAccessOptions,
                [key]: value
            }
        }));
    }

    const hanldeCampaignGeneralOptions = (key: keyof CampaignGeneralOptions, value: boolean | any) => {
        if (key === 'emailCaptureOnOffers' && checkOffersWithRedemtion(campaign.offers)) {
            message.error('The campaign contains offers redeemed by email. Please remove them if you want to switch off Email Capture on offers.');
            return;
        }

        setCampaign(prevState => ({
            ...prevState,
            generalOptions: {
                ...prevState.generalOptions,
                [key]: value
            }
        }));
    }

    const hanldeCampaignSaveOfferOptions = (key: keyof CampaignSaveOfferOptions, value: boolean) => {
        setCampaign(prevState => ({
            ...prevState,
            generalOptions: {
                ...prevState.generalOptions,
                saveOfferOptions: {
                    ...prevState.generalOptions.saveOfferOptions,
                    [key]: value
                }
            }
        }));
    }

    const hanldeCampaignDataLayerTypeOptions = (value: boolean) => {
        setCampaign(prevState => ({
            ...prevState,
            generalOptions: {
                ...prevState.generalOptions,
                dataLayer: {
                    ...prevState.generalOptions.dataLayer,
                    type: value ? DATALAYER_TYPE.html : DATALAYER_TYPE.none
                }
            }
        }));
    }

    const hanldeCampaignDataLayerHtmlLocationOptions = (value: string) => {
        setCampaign(prevState => ({
            ...prevState,
            generalOptions: {
                ...prevState.generalOptions,
                dataLayer: {
                    ...prevState.generalOptions.dataLayer,
                    htmlLocation: value
                }
            }
        }));
    }

    const hanldeCampaignSharingOptions = (key: keyof CampaignSharingOptions, value: boolean | string | any) => {
        setCampaign(prevState => ({
            ...prevState,
            sharingOptions: {
                ...prevState.sharingOptions,
                [key]: value
            }
        }));
    }

    const hanldeCampaignCookieOptions = (key: keyof CampaignCookieOptions, value: boolean | string | any) => {

        let _campaign = {
            ...campaign, cookieOptions: {
                ...campaign.cookieOptions,
                [key]: value
            }
        }

        switch (key) {
            case 'allowSessionCookies':
                _campaign = { ..._campaign, cookieOptions: { ..._campaign.cookieOptions, allowPersistentCookies: !value } }
                break;
            case 'allowPersistentCookies':
                _campaign = { ..._campaign, cookieOptions: { ..._campaign.cookieOptions, allowSessionCookies: !value } }
                break;
            default:
                break;
        }

        setCampaign(_campaign);
    }

    const handleChangeSharingPlatform = (checked: boolean, platform: string) => {
        const platforms = campaign.sharingOptions.sharingPlatforms?.platforms ? campaign.sharingOptions.sharingPlatforms.platforms : [];
        checked ? platforms.push(platform) : platforms.filter(f => f !== platform);

        setCampaign(prevState => ({
            ...prevState,
            sharingOptions: {
                ...prevState.sharingOptions,
                sharingPlatforms: {
                    platforms: platforms
                }
            }
        }));
    }

    return <>
        <div className="content-layout content-mix-details">
            <div className="widget widget-full mright20 mbot20">
                <form className="widget-form small-form">

                    <h3 className="widget-form-title">Campaign details</h3>
                    <div className="widget-form-description">
                        Please fill in the details below
                    </div>

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Campaign name</span>
                            {campaignName.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validCampaignName ? successIcon : failIcon}
                                            alt={validCampaignName ? "success" : "fail"}
                                        />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${campaignName && !validCampaignName ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="text"
                                ref={userRef}
                                value={campaignName}
                                id="campaign-name"
                                name="campaign-name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setCampaignName(e.target.value)
                                }
                                onFocus={() => setCampaignNameFocus(true)}
                                onBlur={() => setCampaignNameFocus(false)}
                                aria-invalid={validCampaignName ? "true" : "false"}
                                aria-describedby="cnide"
                                autoComplete="off"
                            />
                            <p
                                id="cnide"
                                className={`error-line ${campaignName && campaignNameFocus && !validCampaignName
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.tooShort}
                            </p>
                        </div>
                    </div>

                    <div className="widget-multiple-form-row">

                        <div className="widget-form-row">
                            <div className="unauth-label">
                                <span>Start date</span>
                                {startDate.length > 0 && (
                                    <span className="unauth-label-error">
                                        <span>
                                            <img
                                                src={validStartDate ? successIcon : failIcon}
                                                alt={validStartDate ? "success" : "fail"}
                                            />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div
                                className={`
          widget-input ${startDate && !validStartDate ? "widget-input-invalid" : ""
                                    }`}
                            >
                                <DatePicker
                                    value={startDate ? stringDateToMoment(startDate) : undefined}
                                    onChange={(value: moment.Moment | null) => {
                                        setStartDate(value?.format("YYYY-MM-DDTHH:mm:ss") || '');
                                    }
                                    }
                                    disabledDate={disabledStartDate}
                                    onFocus={() => setStartDateFocus(true)}
                                    onBlur={() => setStartDateFocus(false)}
                                    showTime={{ format: 'HH:mm' }}
                                    format="HH:mm DD-MM-YYYY"
                                />
                                <p
                                    id="sdide"
                                    className={`error-line ${startDate.length < 1 && startDateFocus
                                        ? "error-show"
                                        : "error-hide"
                                        }`}
                                >
                                    {VALIDATION.required}
                                </p>
                            </div>
                        </div>

                        <div className="widget-form-row">
                            <div className="unauth-label">
                                <span>End date</span>
                                {endDate.length > 0 && (
                                    <span className="unauth-label-error">
                                        <span>
                                            <img
                                                src={validEndDate ? successIcon : failIcon}
                                                alt={validEndDate ? "success" : "fail"}
                                            />
                                        </span>
                                    </span>
                                )}
                            </div>
                            <div
                                className={`
          widget-input ${endDate && !validEndDate ? "widget-input-invalid" : ""
                                    }`}
                            >
                                <DatePicker
                                    value={endDate ? stringDateToMoment(endDate) : undefined}
                                    showTime={{ format: 'HH:mm' }}
                                    format="HH:mm DD-MM-YYYY"
                                    onChange={(value: moment.Moment | null) =>
                                        setEndDate(value?.format("YYYY-MM-DDTHH:mm:ss") || '')
                                    }
                                    disabledDate={disabledEndDate}
                                    onFocus={() => setEndDateFocus(true)}
                                    onBlur={() => setEndDateFocus(false)}
                                />
                                <p
                                    id="edide"
                                    className={`error-line ${endDate.length < 1 && endDateFocus
                                        ? "error-show"
                                        : "error-hide"
                                        }`}
                                >
                                    {VALIDATION.required}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Description</span>
                            {/* {description.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validDescription ? successIcon : failIcon}
                                            alt={validDescription ? "success" : "fail"}
                                        />
                                    </span>
                                </span>
                            )} */}
                        </div>
                        <div className="widget-input">
                            <textarea
                                id="description"
                                name="description"
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setDescription(e.target.value)
                                }
                                value={description}
                                // onFocus={() => setDescriptionFocus(true)}
                                // onBlur={() => setDescriptionFocus(false)}
                                aria-invalid={validDescription ? "true" : "false"}
                                aria-describedby="dide"
                                autoComplete="off"
                            />
                            {/* <p
                                id="dide"
                                className={`error-line ${descriptionFocus && !validDescription
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.required}
                            </p> */}
                        </div>
                    </div>

                </form>

            </div>

            {(campaign?.campaignAccessOptions && campaign.sharingOptions && campaign.generalOptions && campaign.cookieOptions) &&
                <div className="settings-widgets">
                    <div className="widget mbot20 widget-icon">
                        <Collapse defaultActiveKey={['']} ghost>
                            <CollapsePanel
                                showArrow={false}
                                header={
                                    <div className="widget-header font-hover">
                                        <div className="widget-icon-box mtop5">
                                            <span className="icon icon-lock"></span>
                                        </div>

                                        <div className="grow mleft10">
                                            <h3 className="widget-s-title">
                                                Access
                                            </h3>

                                            <div className="widget-form-description">
                                                Restrict access to only specified users
                                            </div>
                                        </div>

                                        <div className="widget-side-close">
                                            <div className="widget-close-box flex-center font-hover">
                                                <span className="icon icon-minus"></span>
                                                <span className="icon icon-plus"></span>
                                            </div>
                                        </div>

                                    </div>
                                    // <div className="widget-header">
                                    //     <div className="widget-icon-box mtop5 mtop5 font-hover">
                                    //         <span className="icon icon-lock"></span>
                                    //     </div>

                                    //     <div className="widget-switch-row mleft10">
                                    //         <div className="mright10 font-hover">
                                    //             <h3 className="widget-s-title">
                                    //                 Access
                                    //             </h3>

                                    //             <div className="widget-form-description">
                                    //                 Campaign is open to everyone
                                    //             </div>
                                    //         </div>

                                    //         <div className="widget-header-switch" onClick={(e) => e.stopPropagation()}>
                                    //             <Switch size="default" />
                                    //         </div>
                                    //     </div>

                                    //     <div className="widget-side-close">
                                    //         <div className="widget-close-box flex-center font-hover">
                                    //             <span className="icon icon-minus"></span>
                                    //             <span className="icon icon-plus"></span>
                                    //         </div>
                                    //     </div>

                                    // </div>
                                }
                                key="access">
                                <div className="widget-content">
                                    <div className="flex-space-between mbot15">
                                        <span className="label-normal">Campaign is open to everyone <span id="campaign-open-to-everyone" className="icon icon-info mleft5 font-hover"></span></span>

                                        <span className="flex-align-center min70">
                                            <Switch id="open-access" size="default" checked={campaign.campaignAccessOptions.openToEveryone} onChange={((value: boolean) => hanldeCampaignAccessOptions('openToEveryone', value))} />
                                            <label className="mleft5 font-hover" htmlFor="open-access">
                                                {campaign.campaignAccessOptions.openToEveryone ? 'on' : 'off'}
                                            </label>
                                        </span>
                                    </div>
                                    {!campaign.campaignAccessOptions.openToEveryone &&
                                        <div className="widget-info-row">
                                            <span className="font-color mright20 grow">
                                                Only show if the following exists in the URL: bsw_access=
                                            </span>
                                            <input type="text" onChange={(e) => setAccessParam(e.target.value)} className="default-input" value={accessParam} />
                                        </div>
                                    }
                                </div>
                            </CollapsePanel>
                        </Collapse>

                    </div>

                    <div className="widget mbot20 widget-icon">

                        <Collapse defaultActiveKey={['']} ghost>
                            <CollapsePanel
                                showArrow={false}
                                header={
                                    <div className="widget-header font-hover">
                                        <div className="widget-icon-box mtop5">
                                            <span className="icon icon-email"></span>
                                        </div>

                                        <div className="grow mleft10">
                                            <h3 className="widget-s-title">
                                                General & Email
                                            </h3>

                                            <div className="widget-form-description">
                                                Email and other settings
                                            </div>
                                        </div>

                                        <div className="widget-side-close">
                                            <div className="widget-close-box flex-center font-hover">
                                                <span className="icon icon-minus"></span>
                                                <span className="icon icon-plus"></span>
                                            </div>
                                        </div>

                                    </div>
                                }
                                key="general">
                                <div className="widget-content">
                                    <div className="widget-icon-content">
                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Show “Selected” option <span id="campaign-show-selected-option" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.showAddToOrder} onChange={((value: boolean) => hanldeCampaignGeneralOptions('showAddToOrder', value))} id="show-interested" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="show-interested">
                                                    {campaign.generalOptions.showAddToOrder ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Email capture on offers <span id="campaign-email-capture-on-offers" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.emailCaptureOnOffers} onChange={((value: boolean) => hanldeCampaignGeneralOptions('emailCaptureOnOffers', value))} id="email-capture" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="email-capture">
                                                    {campaign.generalOptions.emailCaptureOnOffers ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        {campaign.generalOptions.showAddToOrder && campaign.generalOptions.emailCaptureOnOffers &&
                                            <>
                                                <div className="flex-space-between mbot15">
                                                    <span className="label-normal">
                                                        Email capture on “Selected” <span id="campaign-email-capture-on-selected" className="icon icon-info mleft5 font-hover"></span>
                                                    </span>

                                                    {/* <span className="flex-align-center min70">
                                                        <Switch checked={campaign.generalOptions.emailCaptureOnAddToOrder} onChange={((value: boolean) => hanldeCampaignGeneralOptions('emailCaptureOnAddToOrder', value))} id="interested-capture" size="default" />
                                                        <label className="mleft5 font-hover" htmlFor="interested-capture">
                                                            {campaign.generalOptions.emailCaptureOnAddToOrder ? 'on' : 'off'}
                                                        </label>
                                                    </span> */}
                                                </div>

                                                <div className="mbot15">
                                                    <Radio.Group onChange={(e) => {
                                                        hanldeCampaignGeneralOptions('emailCaptureOnAddToOrder', e.target.value)
                                                    }} value={campaign.generalOptions.emailCaptureOnAddToOrder}>
                                                        <Radio value={true}>Manual</Radio>
                                                        <Radio value={false}>Automatic</Radio>
                                                    </Radio.Group>
                                                </div>

                                                {!campaign.generalOptions.emailCaptureOnAddToOrder &&
                                                    <div className="widget-info-row mbot15">
                                                        <span className="grow">
                                                            <span className="font-color mright10">
                                                                Html selector
                                                            </span>
                                                            <span id="campaign-email-capture-html-selector" className="icon icon-info mright10 font-hover"></span>
                                                        </span>
                                                        <input type="text" value={campaign?.generalOptions?.htmlSelector || ''} onChange={((e: ChangeEvent<HTMLInputElement>) => hanldeCampaignGeneralOptions('htmlSelector', e.target.value))} className="default-input grow" placeholder="#id or .class" />
                                                    </div>
                                                }
                                            </>
                                        }

                                        {(campaign.generalOptions.emailCaptureOnAddToOrder || campaign.generalOptions.emailCaptureOnOffers) &&
                                            <>
                                                {/* <div className="flex-space-between mbot15">
                                                    <span className="label-normal">
                                                        Store emails for future use <span className="icon icon-info mleft5 font-hover"></span>
                                                    </span>

                                                    <span className="flex-align-center min70">
                                                        <Switch checked={campaign.generalOptions.storeEmailForFuture} onChange={((value: boolean) => hanldeCampaignGeneralOptions('storeEmailForFuture', value))} id="store-email" size="default" />
                                                        <label className="mleft5 font-hover" htmlFor="store-email">
                                                            {campaign.generalOptions.storeEmailForFuture ? 'on' : 'off'}
                                                        </label>
                                                    </span>
                                                </div> */}

                                                {/* <div className="flex-space-between mbot15">
                                                    <span className="label-normal">
                                                        Send one email per offer <span className="icon icon-info mleft5 font-hover"></span>
                                                    </span>

                                                    <span className="flex-align-center min70">
                                                        <Switch checked={campaign.generalOptions.sendOneEmailPerOffer} onChange={((value: boolean) => hanldeCampaignGeneralOptions('sendOneEmailPerOffer', value))} id="one-email" size="default" />
                                                        <label className="mleft5 font-hover" htmlFor="one-email">
                                                            {campaign.generalOptions.sendOneEmailPerOffer ? 'on' : 'off'}
                                                        </label>
                                                    </span>
                                                </div> */}
                                            </>
                                        }

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Allow users to save offers <span id="campaign-allow-user-save-offers" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.saveOfferOptions?.allowSavingOffers} onChange={((value: boolean) => hanldeCampaignSaveOfferOptions('allowSavingOffers', value))} id="allow-save" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="allow-save">
                                                    {campaign.generalOptions.saveOfferOptions?.allowSavingOffers ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        {campaign.generalOptions.saveOfferOptions?.allowSavingOffers &&
                                            <div className="mbot15">
                                                <Checkbox checked={campaign.generalOptions.saveOfferOptions?.allowEmail} onChange={((e: CheckboxChangeEvent) => hanldeCampaignSaveOfferOptions('allowEmail', e.target.checked))} className="checkbox-active mright30">Email</Checkbox>
                                                {/* <Checkbox checked={campaign.generalOptions.saveOfferOptions?.allowSms} onChange={((e: CheckboxChangeEvent) => hanldeCampaignSaveOfferOptions('allowSms', e.target.checked))} className="checkbox-active mright30">Sms</Checkbox> */}
                                            </div>
                                        }

                                        {/* <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Grab email from data layer? <span className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.useEmailFromDataLayer} onChange={((value: boolean) => hanldeCampaignGeneralOptions('useEmailFromDataLayer', value))} id="grab-email" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="grab-email">
                                                    {campaign.generalOptions.useEmailFromDataLayer ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div> */}

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Show selected offers only <span id="campaign-show-selected-offers" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.showSelectedOffers} onChange={((value: boolean) => hanldeCampaignGeneralOptions('showSelectedOffers', value))} id="auto-opt" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="auto-opt">
                                                    {campaign.generalOptions.showSelectedOffers ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Automatically check opt-in <span id="campaign-auto-check-opt-in" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.automaticallyCheckOptIn} onChange={((value: boolean) => hanldeCampaignGeneralOptions('automaticallyCheckOptIn', value))} id="auto-opt" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="auto-opt">
                                                    {campaign.generalOptions.automaticallyCheckOptIn ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Data layer <span id="campaign-data-layer" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.generalOptions.dataLayer.type === DATALAYER_TYPE.html} onChange={((value: boolean) => hanldeCampaignDataLayerTypeOptions(value))} id="data-layer-type" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="data-layer-type">
                                                    {campaign.generalOptions.dataLayer.type === DATALAYER_TYPE.html ? 'html' : 'none'}
                                                </label>
                                            </span>

                                        </div>
                                        {campaign.generalOptions.dataLayer.type === DATALAYER_TYPE.html &&
                                            <div className="widget-info-row mbot15">
                                                <span className="grow">
                                                    <span className="font-color mright10">
                                                        Html location
                                                    </span>
                                                    <span id="campaign-data-layer-html-location" className="icon icon-info mright10 font-hover"></span>
                                                </span>
                                                <input type="text" value={campaign?.generalOptions?.dataLayer.htmlLocation || ''} onChange={((e: ChangeEvent<HTMLInputElement>) => hanldeCampaignDataLayerHtmlLocationOptions(e.target.value))} className="default-input grow" placeholder="#id or .class" />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </CollapsePanel>
                        </Collapse>
                    </div>

                    <div className="widget mbot20 widget-icon">

                        <Collapse defaultActiveKey={['']} ghost>
                            <CollapsePanel
                                showArrow={false}
                                header={
                                    <div className="widget-header font-hover">
                                        <div className="widget-icon-box mtop5 mtop5">
                                            <span className="icon icon-share"></span>
                                        </div>

                                        <div className="grow mleft10">

                                            <h3 className="widget-s-title">
                                                Sharing
                                            </h3>

                                            <div className="widget-form-description">
                                                You can set the sharing options below
                                            </div>

                                        </div>

                                        <div className="widget-side-close">
                                            <div className="widget-close-box flex-center font-hover">
                                                <span className="icon icon-minus"></span>
                                                <span className="icon icon-plus"></span>
                                            </div>
                                        </div>

                                    </div>
                                }
                                key="sharing">
                                <div className="widget-content">
                                    <div className="widget-icon-content">
                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Activate social sharing <span id="campaign-activate-social-sharing" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.sharingOptions.allowSharing} onChange={((value: boolean) => hanldeCampaignSharingOptions('allowSharing', value))} id="social-share" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="social-share">
                                                    {campaign.sharingOptions.allowSharing ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>
                                        {campaign.sharingOptions.allowSharing && <>
                                            <div className="flex-space-between mbot15">
                                                <span className="label-normal">
                                                    Share advertiser offer directly <span id="campaign-show-advertiser-offer" className="icon icon-info mleft5 font-hover"></span>
                                                </span>

                                                <span className="flex-align-center min70">
                                                    <Switch checked={campaign.sharingOptions.shareAdvertiserOffer} disabled={true} onChange={((value: boolean) => hanldeCampaignSharingOptions('shareAdvertiserOffer', value))} id="advertiser-share" size="default" />
                                                    <label className="mleft5 font-hover" htmlFor="advertiser-share">
                                                        {campaign.sharingOptions.allowSharing ? 'on' : 'off'}
                                                    </label>
                                                </span>
                                            </div>

                                            {/* <div className="widget-info-row mbot15">
                                                <span className="font-color mright20 grow">
                                                    Message to share
                                                </span>
                                                <input type="text" value={campaign.sharingOptions.shareMessage} onChange={((e: ChangeEvent<HTMLInputElement>) => hanldeCampaignSharingOptions('shareMessage', e.target.value))} className="default-input grow" placeholder="Buy on X today and get Y" />
                                            </div> */}

                                            <div className="social-grid">
                                                {/* <div className="checkbox-wrapper">
                                                    <Checkbox className="checkbox-active" onChange={(e) => handleChangeSharingPlatform(e.target.checked, 'Facebook')}>Facebook</Checkbox>
                                                </div>

                                                <div className="checkbox-wrapper">
                                                    <Checkbox className="checkbox-active" onChange={(e) => handleChangeSharingPlatform(e.target.checked, 'Twitter')}>Twitter</Checkbox>
                                                </div> */}

                                                <div className="checkbox-wrapper">
                                                    <Checkbox className="checkbox-active" checked={campaign.sharingOptions.sharingPlatforms?.platforms?.includes('whatsapp')} onChange={(e) => handleChangeSharingPlatform(e.target.checked, 'whatsapp')}>WhatsApp</Checkbox>
                                                </div>

                                                {/* <div className="checkbox-wrapper">
                                                    <Checkbox className="checkbox-active">Email</Checkbox>
                                                </div> */}
                                            </div>

                                        </>
                                        }

                                    </div>
                                </div>
                            </CollapsePanel>
                        </Collapse>

                    </div>

                    <div className="widget mbot20 widget-icon">


                        <Collapse defaultActiveKey={['']} ghost>
                            <CollapsePanel
                                showArrow={false}
                                header={
                                    <div className="widget-header font-hover">
                                        <div className="widget-icon-box mtop5 mtop5">
                                            <span className="icon icon-settings"></span>
                                        </div>

                                        <div className="grow mleft10">

                                            <h3 className="widget-s-title">
                                                Cookies
                                            </h3>

                                            <div className="widget-form-description">
                                                Advanced settings
                                            </div>

                                        </div>

                                        <div className="widget-side-close">
                                            <div className="widget-close-box flex-center font-hover">
                                                <span className="icon icon-minus"></span>
                                                <span className="icon icon-plus"></span>
                                            </div>
                                        </div>

                                    </div>
                                }
                                key="cookies">
                                <div className="widget-content">
                                    <div className="widget-icon-content">
                                        {/* <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                No cookies <span id="campaign-allow-cookies" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.cookieOptions.allowCookies} onChange={((value: boolean) => hanldeCampaignCookieOptions('allowCookies', value))} id="cookies" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="cookies">
                                                    {campaign.cookieOptions.allowCookies ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div> */}

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Session cookies only <span id="campaign-allow-session-cookies" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.cookieOptions.allowSessionCookies} onChange={((value: boolean) => hanldeCampaignCookieOptions('allowSessionCookies', value))} id="cookies-session" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="cookies-session">
                                                    {campaign.cookieOptions.allowSessionCookies ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                        <div className="flex-space-between mbot15">
                                            <span className="label-normal">
                                                Persistent cookies <span id="campaign-persistent-cookies" className="icon icon-info mleft5 font-hover"></span>
                                            </span>

                                            <span className="flex-align-center min70">
                                                <Switch checked={campaign.cookieOptions.allowPersistentCookies} onChange={((value: boolean) => hanldeCampaignCookieOptions('allowPersistentCookies', value))} id="cookies-persistent" size="default" />
                                                <label className="mleft5 font-hover" htmlFor="cookies-persistent">
                                                    {campaign.cookieOptions.allowPersistentCookies ? 'on' : 'off'}
                                                </label>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </CollapsePanel>
                        </Collapse>
                    </div>

                    <div className="widget mbot20 widget-icon">
                        <Collapse defaultActiveKey={['']} ghost>
                            <CollapsePanel
                                showArrow={false}
                                header={
                                    <div className="widget-header font-hover">
                                        <div className="widget-icon-box mtop5">
                                            <span className="icon icon-lock"></span>
                                        </div>

                                        <div className="grow mleft10">
                                            <h3 className="widget-s-title">
                                                Frequency capping
                                            </h3>

                                            <div className="widget-form-description">
                                                Add limit access to campaign
                                            </div>
                                        </div>

                                        <div className="widget-side-close">
                                            <div className="widget-close-box flex-center font-hover">
                                                <span className="icon icon-minus"></span>
                                                <span className="icon icon-plus"></span>
                                            </div>
                                        </div>

                                    </div>
                                }
                                key="access">
                                <div className="widget-content">
                                    <div className="flex-space-between mbot15">
                                        <span className="label-normal">All offers frequency capping<span id="campaign-frequency-cap-enable" className="icon icon-info mleft5 font-hover"></span></span>
                                        <span className="flex-align-center min70">
                                            <Switch id="open-access" size="default" checked={campaign.generalOptions.frequencyCapping.isEnabled} onChange={((value: boolean) => setCampaign(prevState => ({ ...prevState, generalOptions: { ...prevState.generalOptions, frequencyCapping: { ...prevState.generalOptions.frequencyCapping, isEnabled: value } } })))} />
                                            <label className="mleft5 font-hover" htmlFor="open-access">
                                                {campaign.generalOptions.frequencyCapping.isEnabled ? 'on' : 'off'}
                                            </label>
                                        </span>
                                    </div>


                                    {campaign.generalOptions.frequencyCapping.isEnabled &&
                                        <>
                                            <div className="widget-form-info">You have already set frequency capping rules on one or more offers, which will override this general campaign rule.</div>
                                            <div className="widget-info-row mbot10">
                                                <span className="font-color mright20 grow">
                                                    Max times the offers appear to each user
                                                </span>
                                                <div className="grow relative ptop15 pbot15">
                                                    <InputNumber status={typeof campaign.generalOptions.frequencyCapping.frequency !== 'number' ? 'error' : ''} onChange={(value: number | null) => setCampaign(prevState => ({ ...prevState, generalOptions: { ...prevState.generalOptions, frequencyCapping: { ...prevState.generalOptions.frequencyCapping, frequency: value } } }))} style={{ width: 100, borderRadius: 6 }} value={campaign.generalOptions.frequencyCapping.frequency}></InputNumber>
                                                    {typeof campaign.generalOptions.frequencyCapping.frequency !== 'number' &&
                                                        <div className="label-error">
                                                            Required
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="widget-info-row">
                                                <span className="font-color mright20 grow">
                                                    Number of days that the offer will not appear to the user after reaching the above limit
                                                </span>
                                                <div className="grow relative ptop15 pbot15">
                                                    <InputNumber status={typeof campaign.generalOptions.frequencyCapping.reset !== 'number' ? 'error' : ''} onChange={(value: number | null) => setCampaign(prevState => ({ ...prevState, generalOptions: { ...prevState.generalOptions, frequencyCapping: { ...prevState.generalOptions.frequencyCapping, reset: value } } }))} style={{ width: 100, borderRadius: 6 }} value={campaign.generalOptions.frequencyCapping.reset}></InputNumber>
                                                    {typeof campaign.generalOptions.frequencyCapping.reset !== 'number' &&
                                                        <div className="label-error">
                                                            Required
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </CollapsePanel>
                        </Collapse>

                    </div>
                </div>
            }
        </div >
    </>
}


export default CampaignDetails