import { Dropdown, Slider, Typography } from "antd";
import { BehaviourSettings, DisplayBy  } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateDelaySettings: (value: BehaviourSettings) => void,
    layoutDevice: LayoutDevices,
    behaviourSettings: BehaviourSettings | undefined,
}

const DelaySettingsComponent = ({ updateDelaySettings, behaviourSettings }: Props) => {

    const handleChangeAfterTime = (value: number) => {
        const newSettings: BehaviourSettings = {
            displayBy: DisplayBy.time,
            afterTime: value
        }

        updateDelaySettings(newSettings);
    }

    return <>
        <div className="option-box">
            <div className="option-col">
                <Dropdown trigger={['click']} dropdownRender={
                    () =>
                        <div className="dropdown min150">
                            <div className="dropdown-title option-label">Set seconds:</div>
                            <div className="flex-center-space-between">
                                <Slider
                                    min={0}
                                    max={100}
                                    className="min100"
                                    onChange={handleChangeAfterTime}
                                    value={behaviourSettings?.afterTime || 0}
                                />
                            </div>
                        </div>
                }>
                    <Typography.Link>
                        After time: {behaviourSettings?.afterTime || 0}s
                    </Typography.Link>
                </Dropdown>
            </div>
        </div>
    </>
}

export default DelaySettingsComponent
