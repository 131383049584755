import './css/style.scss';
import phoneSrc from './../../assets/images/phone.jpg';
import laptopSrc from './../../assets/images/laptop.jpg';
import TemplateInline from './templates/template-inline/TemplateInline.component';
import { InlineSettings } from './types/params.interfaces';
import { LayoutDevices } from '../campaigns/const/views';
import { CampaignType } from '../../types/campaign.interfaces';

interface Props {
    isInline: boolean,
    description?: string,
    campaign?: CampaignType,
    settings?: InlineSettings,
    displayOnly?: boolean,
    layoutView: LayoutDevices
}

const PreviewTemplate = ({ isInline, description, campaign, settings, displayOnly, layoutView }: Props) => {

    return <>
        <div className="adv-body">
            {!isInline &&
                <>
                    <div className="adv-browser-line">
                        <span className="adv-broswer-circle close"></span>
                        <span className="adv-broswer-circle minimize"></span>
                        <span className="adv-broswer-circle resize"></span>
                    </div>
                    <div className="adv-header">
                        <div className="adv-logo">
                            Logo
                        </div>
                        <div className="adv-header-menu">
                            <ul>
                                <li>
                                    Home
                                </li>
                                <li>
                                    About
                                </li>
                                <li>
                                    Shop
                                </li>
                            </ul>

                            <div className="adv-header-cart">
                                5 items, $349
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="adv-page">
                {!isInline &&
                    <ul>
                        <li className="active">
                            1. Order confirmation
                        </li>
                    </ul>
                }

                <div className="adv-content">
                    {isInline && description && campaign && settings ?
                        <div className="adv-product-row">
                            <div style={{
                                width: settings.element.fullWidth ? '100%' : settings.element.width,
                                height: settings.element.height
                            }}>
                                <TemplateInline layoutView={layoutView} description={description} settings={settings} campaign={campaign} displayOnly={displayOnly || false} />
                            </div>
                        </div>
                        :
                        <>
                            <div className="adv-product-row">
                                <div className="adv-product-image">
                                    <img src={laptopSrc} alt="Laptop" />
                                </div>
                                <div className="adv-product-content">
                                    <span className="adv-pre-content large"></span>
                                    <span className="adv-pre-content"></span>
                                    <span className="adv-pre-content"></span>
                                </div>
                                <div className="adv-product-actions">
                                    <span className="adv-pre-action"></span>
                                    <span className="adv-pre-action"></span>
                                </div>
                            </div>
                        </>
                    }

                    <div className="adv-product-row">
                        <div className="adv-product-image">
                            <img src={phoneSrc} alt="Phone" />
                        </div>
                        <div className="adv-product-content">
                            <span className="adv-pre-content large"></span>
                            <span className="adv-pre-content"></span>
                            <span className="adv-pre-content"></span>
                        </div>
                        <div className="adv-product-actions">
                            <span className="adv-pre-action"></span>
                            <span className="adv-pre-action"></span>
                        </div>
                    </div>

                    <div className="adv-shopping-buttons">
                        <div className="adv-buttons">
                            <span className="adv-success-button">Next</span>
                            <span className="adv-default-button">Back to shop</span>
                        </div>
                        <div className="adv-shopping-actions">
                            <span className="adv-pre-action"></span>
                            <span className="adv-pre-action"></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>;
}

export default PreviewTemplate;
