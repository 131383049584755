import OfferItem from './offer-item/OfferItem.component';
import './css/style.scss';
import { ButtonShapes, PopupSettings } from '../../types/params.interfaces';
import { Offer } from '../../../../types/offer.interfaces';
import React from 'react';
import { CampaignType } from '../../../../types/campaign.interfaces';

interface Props {
  description: string,
  settings: PopupSettings,
  campaign: CampaignType,
  displayOnly: boolean,
  buttonShape: ButtonShapes,
}

const TemplatePopup = ({ description, settings, campaign, displayOnly, buttonShape }: Props) => {

  return <>
    <div className={`opl-wrapper opl-wrapper-${settings.location.location} opl-wrapper-image-${settings?.imageRepresentation?.position} ${settings?.minimized ? 'opl-minimized' : ''}  ${campaign?.generalOptions.emailCaptureOnOffers || !displayOnly ? 'opl-email-capture' : ''}`}>
      <div className={`opl-box opl-box-${settings.general.align} opl-box-${settings.image.size}`}
        style={{
          backgroundColor: settings.general.backgroundColor,
          transform: `translate(${settings.minimized ? settings.location.x : 0}px, ${settings.minimized ? settings.location.y : 0}px)`
        }}
      >
        <div className="opl-inner-box">
          <div className={`opl-title ${buttonShape === ButtonShapes.squareImageLock || buttonShape === ButtonShapes.squareImage ? 'opl-title-double' : ''}`} style={{
            color: settings.header.textColor,
            fontSize: `${settings.header.fontSize}px`,
            fontFamily: settings.header.fontFamily,
            fontWeight: settings.header.fontWeight,
            textTransform: settings.header.textTransform
          }}>
            <div className="opl-title-label">
              {buttonShape === ButtonShapes.squareImage &&
                <>
                  <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.5 23.5V46H5.5V23.5M23.5 46V12.25M23.5 12.25H13.375C11.8832 12.25 10.4524 11.6574 9.39752 10.6025C8.34263 9.54758 7.75 8.11684 7.75 6.625C7.75 5.13316 8.34263 3.70242 9.39752 2.64752C10.4524 1.59263 11.8832 1 13.375 1C21.25 1 23.5 12.25 23.5 12.25ZM23.5 12.25H33.625C35.1168 12.25 36.5476 11.6574 37.6025 10.6025C38.6574 9.54758 39.25 8.11684 39.25 6.625C39.25 5.13316 38.6574 3.70242 37.6025 2.64752C36.5476 1.59263 35.1168 1 33.625 1C25.75 1 23.5 12.25 23.5 12.25ZM1 12.25H46V23.5H1V12.25Z" stroke={settings.header.textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </>
              }
              {buttonShape === ButtonShapes.squareImageLock &&
                <>
                  {displayOnly ?
                    <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z" stroke={settings.header.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    :
                    <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z" stroke={settings.header.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  }
                </>
              }
              <span className={`${settings?.minimized ? 'opl-max-2lines' : ''}`}>
                {description}
              </span>
            </div>
            <svg className="minus-circle" width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="10" height="2" rx="1" fill={settings.header.textColor} />
            </svg>
          </div>
          <div className="opl-list">
            <ul className="opl-ul" style={{ color: settings.header.textColor }}>
              {campaign?.offers.map((o: Offer, i: number) => {
                return <React.Fragment key={i}>
                  {i + 1 <= Number(settings.general.offers) &&
                    <OfferItem offer={o} settings={settings} displayOnly={displayOnly} />
                  }
                </React.Fragment>
              })}
            </ul>
            {displayOnly ? <>
              {campaign?.generalOptions.emailCaptureOnOffers &&
                <div className={`opl-list-info ${displayOnly ? '' : 'opl-list-info-small'}`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="12" fill="white" />
                    <path d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125" stroke="#B0B0B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <span>Pick your gifts and receive by e-mail after purchase</span>
                </div>
              }
            </>
              :
              <>
                <div className={`opl-list-info opl-list-info-small`} style={{ color: settings?.header.textColor, fontFamily: settings.text.fontFamily }}>
                  <span>By selecting the option above you will be directed to a third party website. Any use of third party websites are not subject to our Privacy Policy, and we encourage you to also read their privacy statements</span>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  </>
}

export default TemplatePopup;