import useAxios from "../../hooks/useAxios.hook";
import "./css/dashboard.scss";

import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { useEffect, useRef, useState } from "react";
import { HOME_WIDGET, dashboardLayout } from "../../utility/enums/layout-dashboard.enums";
import OffersWidget from "./widgets/offers/offersWidget.component";
import CampaignsWidget from "./widgets/campaigns/campaignsWidget.component";
import usePage from "../../hooks/usePage.hook";
import Footer from "../footer/footer.component";
import useAuth from "../../hooks/useAuth.hook";
import { COMPANY_TYPES } from "../../utility/enums/user.enums";
import { Pagination } from "../../types/global.interfaces";
import { CampaignType } from "../../types/campaign.interfaces";
import { message } from "antd";
import { Offer } from "../../types/offer.interfaces";
import { ApiErrorMessages } from "../../types/response.interfaces";
import { OFFER_TYPES } from "../../utility/enums/offer.enums";

const Dashboard = () => {
  const { setPage } = usePage();
  const { company } = useAuth();
  const { axiosFetch: postDashboardsettings } = useAxios();
  const { response: responseUser, axiosFetch: getUserDashboardsettings } = useAxios();
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [layoutWidth, setLayoutWidth] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const [layouts, setLayouts] = useState<Layouts>();
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);
  // const [dailyCampaignsEvent, setDailyCampaignsEvent] = useState<AggregatedDailyEvent>();
  // const [advertiserDailyOffersEvent, setAdvertiserDailyOffersEvent] = useState<AggregatedDailyEvent>();

  const [pagination] = useState<Pagination>({
    page: 1,
    pageSize: 20
  });

  const offerFilters = {
    advertiserIds: [],
    categories: [],
    exclusiveToCategories: [],
    exclusiveToRegions: [],
    brandswopExclusive: "all",
    status: "all",
    type: OFFER_TYPES.advertiser
  };

  // const [filters] = useState({
  //   dateFilterType: "custom",
  //   fromDate: moment(new Date()).subtract(7, 'd').format("YYYY-MM-DD"),
  //   endDate: moment(new Date()).subtract(1, 'd').format("YYYY-MM-DD")
  // });

  const fetchUser = async () => {
    await getUserDashboardsettings({ method: 'get', url: `/user/dashboardsettings` });
  }

  const fetchOffers = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({ method: 'post', url: `offers/getoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: offerFilters });

    if (response?.data?.status) {
      const _offers = response.data.result?.data;
      setOffers(_offers);
    } else {
      response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
        if (value.errorCode !== 12000) {
          message.error(value.message || `Something went wrong!`);
        }
      });
    }
    setLoading(false);
  }

  const fetchCampaigns = async () => {
    if (company) {
      setLoading(true);
      const _currentCompanyId = company.company_Id
      const { response } = await myAxios({ method: 'get', url: `campaign/company/${_currentCompanyId}?page=${pagination.page}&pageSize=${pagination.pageSize}` });
      if (response?.data?.status) {
        setCampaigns(response.data.result?.data);
        setLoading(false);
      }
      setLoading(false);
    }
  }

  // const fetchRetailerCampaignsDaily = async (companyId: string) => {
  //   setLoading(true);
  //   const { response } = await myAxios({ method: 'post', url: `insights/retailer/campaignstotaldaily/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

  //   if (response?.status === 200) {
  //     setDailyCampaignsEvent(response.data.result);
  //   } else {
  //     response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
  //       if (value.errorCode !== 12000) {
  //         message.error(value.message || `Something went wrong!`);
  //       }
  //     });
  //   }

  //   setLoading(false);
  // }

  // const fetchAdvertiserOffersDaily = async (companyId: string) => {
  //   setLoading(true);
  //   const { response } = await myAxios({ method: 'post', url: `insights/advertiser/offerstotaldaily/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

  //   if (response?.status === 200) {
  //     setAdvertiserDailyOffersEvent(response.data.result);
  //   } else {
  //     response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
  //       if (value.errorCode !== 12000) {
  //         message.error(value.message || `Something went wrong!`);
  //       }
  //     });
  //   }

  //   setLoading(false);
  // }

  // Set page title and Get current user data on load of component
  useEffect(() => {
    setPage({
      title: <>Dashboard</>
    });
    fetchUser();

    if (company?.company_Id) {
      if (company.companyCurrentType === COMPANY_TYPES.advertiser) {
        // fetchAdvertiserOffersDaily(company.company_Id);
        fetchOffers(company?.company_Id);
      } else {
        // fetchRetailerCampaignsDaily(company.company_Id);
        fetchCampaigns();
      }
    }

    // eslint-disable-next-line
  }, []);

  // Handle current user data reponse
  useEffect(() => {
    if (responseUser?.status) {
      const _layouts: Layouts = responseUser.result;
      setLayouts(_layouts?.desktop ? _layouts : dashboardLayout)
    }
    // eslint-disable-next-line
  }, [responseUser]);

  const handleLayoutChange = async (_layouts: Layout[], allLayouts: Layouts) => {
    if (JSON.stringify(allLayouts) !== JSON.stringify(layouts)) {
      setLayouts(allLayouts);
      await postDashboardsettings({ method: 'post', url: `/user/dashboardsettings`, data: { uiSettings: allLayouts } });
    }
  }

  // Setting width if wrapper size has changed
  const handleElementResized = () => {
    if (wrapperRef?.current?.offsetWidth && wrapperRef?.current?.offsetWidth !== layoutWidth) {
      setLayoutWidth(wrapperRef?.current?.offsetWidth || 0);
    }
  }

  // Set callback function on resize observer
  const resizeObserver = new ResizeObserver(handleElementResized);


  // Observe element and react to it if its changing
  useEffect(() => {
    if (wrapperRef?.current) {
      resizeObserver.observe(wrapperRef.current)
    }
    return () => {
      resizeObserver.disconnect();
    }
  });

  const switchWidgetComponent = (widget: HOME_WIDGET) => {
    let component = <></>;

    switch (widget) {
      // case HOME_WIDGET.reminders:
      //   component = <RemindersWidget />
      //   break;
      case HOME_WIDGET.insight:
        component = <></>;
        // <InsightWidget advertiserDailyOffersEvent={advertiserDailyOffersEvent} dailyCampaignsEvent={dailyCampaignsEvent} isLoading={loading} />
        break;
      // case HOME_WIDGET.settings:
      //   component = <SettingsWidget />

      //   break;
      case HOME_WIDGET.company:

        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <OffersWidget isLoading={loading} offers={offers} /> : <CampaignsWidget fetchCampaigns={fetchCampaigns} isLoading={loading} campaigns={campaigns} />
        break;

      // case HOME_WIDGET.users:
      //   component = <UsersWidget />

      //   break;
      default:
        break;
    }
    return component;
  }

  return <>
    <div className="content">
      <div className="content-layout">
        <div className="layout-wrapper" ref={wrapperRef}>

          {layouts &&
            <ResponsiveGridLayout
              className="layout"
              isResizable={true}
              layouts={layouts}
              onLayoutChange={handleLayoutChange}
              rowHeight={50}
              isDraggable={layoutWidth > 700}
              margin={{ desktop: [15, 15], mobile: [15, 15] }}
              breakpoints={{ desktop: 700, mobile: 1 }}
              cols={{ desktop: 12, mobile: 6, }}
              width={layoutWidth}
            >
              {layoutWidth > 700 ?
                layouts?.desktop?.map((value: Layout) => {
                  return value.i !== 'insight' && <div key={value.i} className="widget">
                    {switchWidgetComponent(value.i as HOME_WIDGET)}
                  </div>
                })
                :
                layouts?.mobile?.map((value: Layout) => {
                  return value.i !== 'insight' && <div key={value.i} className="widget">
                    {switchWidgetComponent(value.i as HOME_WIDGET)}
                  </div>
                })
              }
            </ResponsiveGridLayout>
          }
        </div>
      </div>

      <Footer />

    </div>
  </>;
}

export default Dashboard;
