import { useState, useEffect, useMemo } from "react";
import usePage from "../../hooks/usePage.hook";
import { CustomizationsType } from "../../types/templates.interfaces";
import Footer from "../footer/footer.component";
import Badges from "./badges/Badges";

const Customizations = () => {
  const { setPage } = usePage();
  const [customizationsType, setCustomizationsType] = useState<
    CustomizationsType | CustomizationsType.BADGES
  >(CustomizationsType.BADGES);

  useEffect(() => {
    setPage({
      title: <>Customisation</>,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (customizationsType) {
      case CustomizationsType.BADGES:
        jsxElm = <Badges />;
        break;
      default:
        break;
    }
    return jsxElm;
  }, [customizationsType]);

  return (
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          <li
            className={
              customizationsType === CustomizationsType.BADGES ? "active" : ""
            }
            onClick={() => setCustomizationsType(CustomizationsType.BADGES)}
          >
            {CustomizationsType.BADGES}
          </li>
        </ul>
      </div>

      <div className="content-layout">{getCurrentComponent}</div>
      <Footer />
    </div>
  );
};

export default Customizations;
