import { Button } from "antd";
import { Link } from "react-router-dom";
import { LeftOutlined, MailOutlined } from "@ant-design/icons"
import { FormEvent, useEffect, useRef, useState } from "react";
import { EMAIL_REGEX } from "../../utility/regexp";
import { UserForgotPassword } from "../../types/user.interfaces";
import { ApiErrorMessages } from "../../types/response.interfaces";
import failIcon from "./../../assets/images/icons/fail.svg";
import successIcon from "./../../assets/images/icons/success.svg";
import { VALIDATION } from "../../utility/enums/validation.enums";
import useAxios from "../../hooks/useAxios.hook";
import Footer from "../footer/footer.component";

function ForgotPassword() {
  const userRef = useRef<HTMLInputElement>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSent, setSent] = useState<boolean>(false);
  const { response, axiosFetch } = useAxios();
  const [email, setEmail] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  
  // Focus user to the first input
  useEffect(() => {
    userRef?.current?.focus();
  }, [])

  // Validate email on change
  useEffect(() => {
    setSent(false);
    const isValid = EMAIL_REGEX.test(email);
    setValidEmail(isValid);
  }, [email]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (validEmail) {
      const data: UserForgotPassword = {
        email: email
      }
      await axiosFetch({ method: 'post', url: '/user/forgotpassword', data })
    }

    setLoading(false);
  }

  // Handel response of forgot password
  useEffect(() => {
    if (response && response?.status) {
      setSent(true);
    } else {
      response?.result?.length > 0 && response?.result.forEach((value: ApiErrorMessages) => {
        // setErrorMessage(value.message);
        console.log(value.message);
      });
    }
    // eslint-disable-next-line 
  }, [response])

  return (
    <div className="unauth-container-wrapper scroll-dark unauth-login">
      <div className="unauth-container">
        <div className="unauth-content">

          <div className="widget unauth-widget">
            <form className="widget-form small-form forgot-password" onSubmit={handleSubmit}>
              <div className="unauth-back">
                <Link to="/login" className="font-color">
                  <Button type="default" icon={<LeftOutlined />} className="button-default button-nopadding">
                  </Button>
                  <span className="">Back to login</span>
                </Link>
              </div>
              <h3 className="widget-form-title">Forgot password?</h3>
              <div className="widget-form-description">
                <span>Enter your email to get a link for a new password</span>
              </div>

              {isSent ? <>
                <div className="widget-form-row unauth-link">
                  <div className="flex-center">
                    <MailOutlined className="email-sent" />
                    <span className="font-color font-small">Email is sent to </span>
                    <span className="email-sent-label"> {email}</span>
                  </div>
                </div>
              </> : <>

                <div className="widget-form-row">
                  <div className="unauth-label">
                    <span>Email</span>
                    {email.length > 0 && (
                      <span className="unauth-label-error">
                        <span>
                          <img
                            src={validEmail ? successIcon : failIcon}
                            alt={validEmail ? "success" : "fail"}
                          />
                        </span>
                      </span>
                    )}
                  </div>
                  <div
                    className={`
          widget-input ${email && !validEmail ? "widget-input-invalid" : ""
                      }`}
                  >
                    <input
                      type="text"
                      id="Email"
                      name="Email"
                      value={email}
                      ref={userRef}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }

                      aria-invalid={validEmail ? "true" : "false"}
                      aria-describedby="eide"
                    />
                    <p
                      id="eide"
                      className={`error-line ${email && !validEmail
                        ? "error-show"
                        : "error-hide"
                        }`}
                    >
                      {VALIDATION.emailValid}
                    </p>
                  </div>

                </div>
                <div className="widget-form-actions unauth-button">
                  <div className="widget-form-button">
                    <Button
                      type="default"
                      htmlType="submit"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={!validEmail}
                      className="button-success">Restore password</Button>
                  </div>
                </div>
              </>
              }

            </form>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ForgotPassword;
