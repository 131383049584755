import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Input, message, Typography } from "antd/lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { CompanyCategory } from "../../../types/company.interfaces";
import { EMAIL_REGEX, PWD_REGEX } from "../../../utility/regexp";
import failIcon from "../../../assets/images/icons/fail.svg";
import { VALIDATION } from "../../../utility/enums/validation.enums";
import useAxios from "../../../hooks/useAxios.hook";
import { ExpressCategoryItem } from "../types/express.types";
import { Country, Timezone } from "../../../types/global.interfaces";
import { EXPRESS_ONBOARDING, registered_COUNTRY } from "../types/express.enum";
import useExpress from "../../../hooks/useExpress.hook";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>,
}

const ExpressDetails = ({ setCurrentStep }: Props) => {
    const { campaign, company, onboardingStatus, setCompany, password, setPassword, setOnboardingStatus } = useExpress();

    const [loading, setLoading] = useState<boolean>(false);

    const { myAxios } = useAxios();
    const [categories, setCategories] = useState<CompanyCategory[]>();
    const [filteredCategories, setFilteredCategories] = useState<CompanyCategory[]>([]);
    const [searchCategories, setSearchCategories] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<ExpressCategoryItem[]>(company?.categories || []);
    const categoriesRef = useRef<HTMLInputElement>(null);

    const [timezones, setTimezones] = useState<Timezone[]>();
    const [filteredTimezones, setFilteredTimezones] = useState<Timezone[]>([]);
    const [searchTimezones, setSearchTimezones] = useState<string>('');
    const [selectedTimezone, setSelectedTimezone] = useState<Timezone>();
    const timezoneRef = useRef<HTMLInputElement>(null);

    const [countries, setCountries] = useState<Country[]>([]);
    // const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
    // const [searchCountries, setSearchCountries] = useState<string>('');
    // const [selectedCountries, setSelectedCountries] = useState<Country[]>(company?.countries || []);
    // const countriesRef = useRef<HTMLInputElement>(null);

    const [filteredregistered, setFilteredRegistered] = useState<Country[]>([]);
    const [searchRegistered, setSearchRegistered] = useState<string>('');
    const [selectedRegisteredCountry, setSelectedRegisteredCountry] = useState<Country | undefined>(company?.registrationCountry || undefined);
    const registeredRef = useRef<HTMLInputElement>(null);

    const [validPassword, setValidPassword] = useState<boolean>(false);

    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [validRepeatPassword, setValidRepeatPassword] = useState<boolean>(false);

    const [name, setName] = useState<string>(company?.name || '');
    const [validName, setValidName] = useState<boolean>(false);

    const [url, setUrl] = useState<string>(company?.url || '');

    const [email, setEmail] = useState<string>(company?.email || '');
    const [validEmail, setValidEmail] = useState<boolean>(false);

    // Validate last name
    useEffect(() => {
        const isValid = name.length > 1;
        setValidName(isValid);
    }, [name]);

    // Validate password and repeat password
    useEffect(() => {
        if (password) {
            const isValid = PWD_REGEX.test(password);
            setValidPassword(isValid);
            const isRepeatPassword = password === repeatPassword;
            setValidRepeatPassword(isRepeatPassword);
        }
    }, [password, repeatPassword]);

    // Validate email on change
    useEffect(() => {
        const isValid = EMAIL_REGEX.test(email);
        setValidEmail(isValid);
    }, [email]);

    const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSelectedCategories([]);
        categoriesRef?.current?.focus();
    }

    // const handleClearCountries = (event: MouseEvent<HTMLSpanElement>) => {
    //     event.preventDefault();
    //     setSelectedCountries([]);
    //     countriesRef?.current?.focus();
    // }

    // const handleClearregisteredCounty = (event: MouseEvent<HTMLSpanElement>) => {
    //     event.preventDefault();
    //     // setregisteredCountry([]);
    //     registeredRef?.current?.focus();
    // }

    const handleChangeCategory = (checked: boolean, category: CompanyCategory) => {
        let _selectedCategories: ExpressCategoryItem[] = [];

        if (checked) {
            _selectedCategories = [...selectedCategories, { categoryId: category.id, name: category.name }];
        } else {
            selectedCategories?.forEach((value: ExpressCategoryItem) => {
                if (value.categoryId !== category.id) {
                    _selectedCategories.push(value);
                }
            });
        }

        setSelectedCategories(_selectedCategories);
    }

    // const handleChangeCountries = (checked: boolean, country: Country) => {
    //     let _selectedCountries: Country[] = [];

    //     if (checked) {
    //         _selectedCountries = [...selectedCountries, { ...country, id: country.id, name: country.name }];
    //     } else {
    //         selectedCountries?.forEach((value: Country) => {
    //             if (value.id !== country.id) {
    //                 _selectedCountries.push(value);
    //             }
    //         });
    //     }

    //     setSelectedCountries(_selectedCountries);
    // }

    const handleChangeTimezone = (t: Timezone) => {
        setSelectedTimezone(t);
    }

    const handleRemoveCategory = (categoryId: string) => {
        const _selectedCategories: ExpressCategoryItem[] = structuredClone(selectedCategories).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
        setSelectedCategories(_selectedCategories);
    }

    // const handleRemoveCountry = (id: string) => {
    //     const _selectedCountries: Country[] = structuredClone(selectedCountries).filter((f: Country) => f.id !== id);
    //     setSelectedCountries(_selectedCountries);
    // }

    // const getCountriesName = (countries: Country[]): string => {
    //     const catName: string[] = countries ? countries.map((value: Country) => value.name) : [];
    //     return catName.join(', ')
    // }

    const getOfferCategoriesName = (cat?: ExpressCategoryItem[] | undefined): string => {
        const catName: string[] = cat ? cat.map((value: ExpressCategoryItem) => value.name) : [];
        return catName.join(', ');
    }

    const handleCheckedCategories = (id: string): boolean => {
        const isChecked: boolean = !!selectedCategories?.find((value: ExpressCategoryItem) => value.categoryId === id);
        return isChecked
    }

    // const handleCheckCountry = (id: string): boolean => {
    //     const isChecked: boolean = !!selectedCountries?.find((value: Country) => value.id === id);
    //     return isChecked
    // }

    const getCompanyCategories = async () => {
        const { response } = await myAxios({ method: 'GET', url: 'CompanyCategory' });
        if (response?.data?.status) {
            setCategories(response?.data?.result);
            setFilteredCategories(response?.data?.result);
        } else {
            message.error("Failed to get categories", 1);
        }
    }

    const getTimezones = async () => {
        const { response } = await myAxios({ method: 'GET', url: 'support/timezones' });

        if (response?.data?.status) {
            setTimezones(response?.data?.result);
            setFilteredTimezones(response?.data?.result);
            if (company?.timezoneId) {
                response?.data?.result.map((t: Timezone) => t.id === company.timezoneId && setSelectedTimezone(t));
            }

        } else {
            message.error("Failed to get timezones", 1);
        }
    }

    const getCountries = async () => {
        const { response } = await myAxios({ method: 'GET', url: 'support/countries' });

        if (response?.data?.status) {
            setCountries(response?.data?.result);
            // setFilteredCountries(response?.data?.result);
            setFilteredRegistered(response?.data?.result);
        } else {
            message.error("Failed to get regions", 1);
        }
    }

    const postCompanyExpress = async () => {
        setLoading(true);
        const { response } = await myAxios({ method: 'POST', url: `/onboarding/express/company/details`, data: { password, campaign, company, onboardingStatus } });
        if (response?.data?.status) {
            message.success({
                content: "Your details are saved",
                className: 'express-message',
                duration: 1,
            });
            setCompany(response?.data.result.company);
            setCurrentStep(EXPRESS_ONBOARDING.partners);
        } else {
            message.error("Failed to get categories", 1);
        }
        setLoading(false);
    }

    // // Generate items for dropdown of page Types
    // const timezoneList: BaseOptionType[] = useMemo(() => {
    //     if (filteredTimezones.length > 0) {
    //         const list: BaseOptionType[] = [];
    //         filteredTimezones.forEach((t: Timezone) => {
    //             company?.timezoneId === t.id && setSelectedTimezone(t);
    //             list.push({ label: t.name, value: t.id });
    //         })
    //         return list;
    //     }
    //     return [{ label: 'There are no timezones available', key: 'no timezone', disabled: true }]
    // }, [filteredTimezones, company?.timezoneId]);

    useEffect(() => {
        if (categories) {
            if (searchCategories) {
                const newCategories: CompanyCategory[] = structuredClone(categories);
                setFilteredCategories(newCategories.filter(f => f.name.toLowerCase().includes(searchCategories.toLowerCase())) || []);
            } else {
                setFilteredCategories(categories);
            }
        }
        // eslint-disable-next-line
    }, [searchCategories]);


    useEffect(() => {
        if (timezones) {
            if (searchTimezones) {
                const newTimezones: Timezone[] = structuredClone(timezones);
                setFilteredTimezones(newTimezones.filter(f => f.displayName.toLowerCase().includes(searchTimezones.toLowerCase())) || []);
            } else {
                setFilteredTimezones(timezones);
            }
        }
        // eslint-disable-next-line
    }, [searchTimezones]);


    // useEffect(() => {
    //     if (countries) {
    //         if (searchCountries) {
    //             const newCountries: Country[] = structuredClone(countries);
    //             setFilteredCountries(newCountries.filter(f => f.name.toLowerCase().includes(searchCountries.toLowerCase())) || []);
    //         } else {
    //             setFilteredCountries(countries);
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [searchCountries]);


    useEffect(() => {
        if (countries) {
            if (searchRegistered) {
                const newCountries: Country[] = structuredClone(countries);
                setFilteredRegistered(newCountries.filter(f => f.name.toLowerCase().includes(searchRegistered.toLowerCase())) || []);
            } else {
                setFilteredRegistered(countries);
            }
        }
        // eslint-disable-next-line
    }, [searchRegistered]);


    const isValid = (): boolean => {
        return validEmail && validRepeatPassword && validPassword && validName && isUrl(url)
    }

    const isUrl = (str: string) => {
        var pattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!]))?/;
        return pattern.test(str);
    }

    const handleContinue = () => {
        if (isValid()) {
            postCompanyExpress();
        }
    }

    useEffect(() => {
        setCompany(prevState => (prevState && {
            ...prevState,
            name,
            email,
            url,
            timezoneId: selectedTimezone?.id || selectedTimezone?.id || '',
            categories: selectedCategories ? selectedCategories : [],
            registrationCountry: selectedRegisteredCountry ? selectedRegisteredCountry : registered_COUNTRY
        }));
        // eslint-disable-next-line
    }, [selectedCategories, name, email, url, selectedTimezone, selectedRegisteredCountry])

    useEffect(() => {
        getCompanyCategories();
        getCountries();
        getTimezones();
        document.title = "BrandSwap Express - Onboarding details";
        setOnboardingStatus(prevState => (prevState && { ...prevState, next: EXPRESS_ONBOARDING.partners }))
        // eslint-disable-next-line 
    }, []);

    return <>
        <div className="widget widget-express">
            <form className="express-form" onSubmit={e => e.preventDefault()}>

                <div className="express-title">
                    Access credentials
                </div>
                <div className="express-descrpiton">
                    Your details for logging into BrandSwap
                </div>

                <div className="express-row">
                    <div className="express-label">
                        Email

                        {email && !validEmail && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={failIcon}
                                        alt={"fail"}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="express-input">
                        <Input
                            type="email"
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setEmail(e.target.value)
                            }
                            placeholder="example@company.com" />

                        <p
                            id="eide"
                            className={`error-line ${email && !validEmail
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.emailValid}
                        </p>
                    </div>
                </div>

                <div className="express-row">
                    <div className="express-label">
                        Password

                        {password && !validPassword && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={failIcon}
                                        alt={"fail"}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="express-input">
                        <Input.Password
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setPassword(e.target.value)
                            }
                            autoComplete="off"
                            placeholder="Minimum 8 characters that include uppercase, lowercase, number and symbol"
                            visibilityToggle={false} />

                        <p
                            id="eide"
                            className={`error-line ${password && !validPassword
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.passwordValid}
                        </p>
                    </div>
                </div>
                <div className="express-row">
                    <div className="express-label">
                        Confirm password

                        {repeatPassword && !validRepeatPassword && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={failIcon}
                                        alt={"fail"}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="express-input">
                        <Input.Password

                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setRepeatPassword(e.target.value)
                            }
                            autoComplete="off"
                            placeholder="Confirm your password"
                            visibilityToggle={false} />

                        <p
                            id="eide"
                            className={`error-line ${repeatPassword && !validRepeatPassword
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.sameAsPrevious}
                        </p>
                    </div>
                </div>

                <hr className="widget-devider" />

                <div className="express-title">
                    Company details
                </div>
                <div className="express-descrpiton">
                    Basic information about your company will allow us to identify the most relevant partners for you.
                </div>

                <div className="express-row">
                    <div className="express-label">
                        Company name

                        {name && !validName && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={failIcon}
                                        alt={"fail"}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="express-input">
                        <Input
                            value={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setName(e.target.value)
                            }
                            placeholder="Your company name"
                        />

                        <p
                            id="eide"
                            className={`error-line ${name && !validName
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.nameValid}
                        </p>
                    </div>
                </div>

                <div className="express-row">
                    <div className="express-label">
                        Company website

                        {name && !validName && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={failIcon}
                                        alt={"fail"}
                                    />
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="express-input">
                        <Input
                            value={url}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setUrl(e.target.value)
                            }
                            placeholder="Your company website url"
                        />

                        <p
                            id="eide"
                            className={`error-line ${url && !isUrl(url)
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            the URL should start with https://
                        </p>
                    </div>
                </div>

                <div className="express-row">
                    <div className="express-label">
                        Ad Partner Match Categories: {selectedCategories.length} <InfoCircleOutlined id="guide-details-categories" color="#007a5c" />
                    </div>
                    <div className="express-input express-dropdown">

                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={categoriesRef} type="text" placeholder="Search" className="default-input" value={searchCategories} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCategories(event.target.value)} />
                                        </div>
                                        {selectedCategories.length > 0 &&

                                            <>
                                                <div className="dropdown-title">Selected Categories:</div>
                                                <ul className="list-tags">
                                                    {selectedCategories?.map((category: ExpressCategoryItem) => {
                                                        return <li key={`selected-cat-${category.categoryId}`}>
                                                            {category.name}
                                                            <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCategory(category.categoryId)}></span>

                                                        </li>
                                                    })}
                                                    <span className="font-xsmall font-hover clear-categories" onClick={handleClearCategories}>Clear all</span>
                                                </ul>
                                            </>

                                        }
                                        <div className="dropdown-title">Categories found:</div>
                                        {filteredCategories.length > 0 ?
                                            <>
                                                <ul>
                                                    {filteredCategories?.map((category: CompanyCategory) => {
                                                        return <li key={`details-cat-${category.id}`}>
                                                            <Checkbox checked={handleCheckedCategories(category.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCategory(e.target.checked, category)} className="checkbox-express">{category.name}</Checkbox>
                                                        </li>
                                                    })}
                                                </ul>
                                            </>
                                            :
                                            <>
                                                <span className="font-xsmall">No item found</span>
                                            </>}
                                    </div>
                                }}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="categories">
                                            <Input id="categories" disabled={true} value={getOfferCategoriesName(selectedCategories)} className="express-disabled" placeholder="Select ad partner match categories" />
                                            <span className="express-dropdown-icon"></span>
                                            {/* <label htmlFor="page" tabIndex={0}>
                                                    <span className="label-input-name">Categories: {categories?.length || 0}</span>
                                                    <span className="icon icon-arrow-down"></span>
                                                    <input disabled value={getOfferCategoriesName(categories)} type="text" className="label-input" />
                                                </label> */}
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>

                    </div>
                </div>


                <div className="express-row">
                    <div className="express-label">
                        Timezone: <InfoCircleOutlined id="guide-details-timezone" color="#007a5c" />
                    </div>
                    <div className="express-input express-dropdown">

                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={timezoneRef} type="text" placeholder="Search" className="default-input" value={searchTimezones} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTimezones(event.target.value)} />
                                        </div>
                                        <div className="dropdown-title">Timezones found:</div>
                                        {selectedTimezone &&

                                            <>

                                                {filteredTimezones.length > 0 ?
                                                    <>
                                                        <ul>
                                                            {filteredTimezones?.map((t: Timezone) => {
                                                                return <li key={`details-cat-${t.id}`}>
                                                                    <span onClick={() => { handleChangeTimezone(t); }} className={`font-hover ${t.id === selectedTimezone?.id ? 'font-active' : ''}`}>{t.displayName}</span>
                                                                </li>
                                                            })}
                                                        </ul>
                                                    </>
                                                    :
                                                    <>
                                                        <span className="font-xsmall">No item found</span>
                                                    </>}
                                            </>

                                        }
                                    </div>
                                }}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="categories">
                                            <Input disabled={true} value={selectedTimezone?.displayName} className="express-disabled" placeholder="Select countries" />
                                            <span className="express-dropdown-icon"></span>
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                {/* <div className="express-row">
                    <div className="express-label">
                        Regions: {selectedCountries.length} <InfoCircleOutlined id="guide-details-country" color="#007a5c" />
                    </div>
                    <div className="express-input express-dropdown">

                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={countriesRef} type="text" placeholder="Search" className="default-input" value={searchCountries} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCountries(event.target.value)} />
                                        </div>

                                        {selectedCountries.length > 0 && <>
                                            <div className="dropdown-title">Selected Countries:</div>
                                            <ul className="list-tags">
                                                {selectedCountries?.map((country: Country) => {
                                                    return <li key={`selected-cat-${country.id}`}>
                                                        {country.name}
                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCountry(country.id)}></span>

                                                    </li>
                                                })}
                                                <span className="font-xsmall font-hover clear-categories" onClick={handleClearCountries}>Clear all</span>
                                            </ul>
                                        </>}
                                        <div className="dropdown-title">Countries found:</div>

                                        {filteredCountries.length > 0 ?
                                            <>
                                                <ul>
                                                    {filteredCountries?.map((country: Country) => {
                                                        return <li key={`details-cat-${country.id}`}>
                                                            <Checkbox checked={handleCheckCountry(country.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCountries(e.target.checked, country)} className="checkbox-express">{country.name}</Checkbox>
                                                        </li>
                                                    })}
                                                </ul>
                                            </>
                                            :
                                            <>
                                                <span className="font-xsmall">No item found</span>
                                            </>}
                                    </div>
                                }}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="categories">
                                            <Input disabled={true} value={getCountriesName(selectedCountries)} className="express-disabled" placeholder="Select regions" />
                                            <span className="express-dropdown-icon"></span>
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>

                    </div>
                </div> */}

                <div className="express-row">
                    <div className="express-label">
                        Registered country: <InfoCircleOutlined id="guide-details-registered-country" color="#007a5c" />
                    </div>
                    <div className="express-input express-dropdown">

                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={registeredRef} type="text" placeholder="Search" className="default-input" value={searchRegistered} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchRegistered(event.target.value)} />
                                        </div>

                                        {/* {registeredCountry.length > 0 && <>
                                            <div className="dropdown-title">Selected Countries:</div>
                                            <ul className="list-tags">
                                                {registeredCountry?.map((country: Country) => {
                                                    return <li key={`selected-cat-${country.id}`}>
                                                        {country.name}
                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCountry(country.id)}></span>
                                                    </li>
                                                })}
                                                <span className="font-xsmall font-hover clear-categories" onClick={handleClearCountries}>Clear all</span>
                                            </ul>
                                        </>} */}
                                        <div className="dropdown-title">Countries found:</div>

                                        {filteredregistered.length > 0 ?
                                            <>
                                                <ul>
                                                    {filteredregistered?.map((country: Country) => {
                                                        return <li key={`details-cat-${country.id}`}>
                                                            <span onClick={() => { setSelectedRegisteredCountry(country); }} className={`font-hover ${country.id === selectedRegisteredCountry?.id ? 'font-active' : ''}`}>{country.name}</span>
                                                        </li>
                                                    })}
                                                </ul>
                                            </>
                                            :
                                            <>
                                                <span className="font-xsmall">No item found</span>
                                            </>}
                                    </div>
                                }}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="categories">
                                            <Input disabled={true} value={selectedRegisteredCountry?.name || ''} className="express-disabled" placeholder="Select company country" />
                                            <span className="express-dropdown-icon"></span>
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>

                    </div>
                </div>

                <div className="express-button">
                    <Button className="express-button-success" loading={loading} disabled={!isValid()} type="default" onClick={handleContinue}>Continue</Button>
                </div>

            </form >

        </div >
    </>;
}

export default ExpressDetails;
