import { Dropdown, Slider, Switch, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { DealLayoutSettings } from "../../../../../../types/campaign.interfaces";
import { compactTemplates, emailSwitchTemplates, Template } from "../../../../../../types/templates.interfaces";
import { TextAlign } from "../../../../../templates-previews/types/params.interfaces";
import { getNumberFromFontWeight, itemsAlign, itemsFontSize, itemsFontWeight, uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateDealLayoutSettings: (value: DealLayoutSettings) => void,
    layoutDevice: LayoutDevices,
    template: Template | undefined,
    dealLayout: DealLayoutSettings | undefined,
    displayOnly: boolean
}

const DealLayoutSettingsComponent = ({ updateDealLayoutSettings, displayOnly, dealLayout, template }: Props) => {

    const setNewSettings = (key: keyof DealLayoutSettings, value: string | number | boolean) => {
        if (dealLayout) {
            const newSettings: DealLayoutSettings = {
                ...dealLayout,
                [key]: value
            }

            updateDealLayoutSettings(newSettings);
        }
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleBorderColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('borderColor', color);
    }

    const handleButtonBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('buttonBackgroundColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }

    const handleButtonTextColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('buttonTextColor', color);
    }

    const handleButtonBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('buttonBorderColor', color);
    }

    const handleChangeButtonFontSize = (props: DropdownMenuProps) => {
        setNewSettings('buttonFontSize', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewSettings('fontSize', props.key);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewSettings('buttonFontWeight', props.key);
    }

    const handleChangeRounding = (value: number) => {
        setNewSettings('buttonRounding', value);
    }

    const handleChangeAlign = (props: DropdownMenuProps) => {
        setNewSettings('align', props.key);
    }

    const handleChangeShowEmail = (value: boolean) => {
        setNewSettings('emailOpen', value);
    }

    const handleChangeEmailIconColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('emailIconColor', color);
    }

    const handleChangeEmailTextColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('emailTextColor', color);
    }

    const handleChangeEmailButtonBackgroundColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('emailButtonBackgroundColor', color);
    }

    const handleChangeEmailButtonColor = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('emailButtonTextColor', color);
    }

    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-two-col">

                    <div className="option-col">
                        <input type="color" className="color-box" id={`dealLayoutBg${uniqueKey}`} value={dealLayout?.backgroundColor || ''} onChange={handleBgChange} />
                        <label htmlFor={`dealLayoutBg${uniqueKey}`}>
                            Background: {dealLayout?.backgroundColor || ''}
                        </label>
                    </div>

                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`dealLayoutTextColor${uniqueKey}`}
                            value={dealLayout?.textColor || '' || ''}
                            onChange={handleTextChange}
                        />
                        <label htmlFor={`dealLayoutTextColor${uniqueKey}`}>
                            Text: {dealLayout?.textColor || ''}
                        </label>
                    </div>

                </div>

                <div className="option-two-col">

                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            defaultSelectedKeys: [dealLayout?.fontSize || '14'],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                Font size: {dealLayout?.fontSize || '14'}
                            </Typography.Link>
                        </Dropdown>
                    </div>

                    {template?.identifier && ( template.identifier === "template-NL" || template.identifier === "template-NLH") &&
                        <div className="option-col">
                            <input type="color" className="color-box" id={`dealLayoutBorder${uniqueKey}`} value={dealLayout?.borderColor || '#ffffff'} onChange={handleBorderColorChange} />
                            <label htmlFor={`dealLayoutBorder${uniqueKey}`}>
                                Border: {dealLayout?.borderColor || '#ffffff'}
                            </label>
                        </div>
                    }

                    {template?.identifier === "template-Es" &&

                        <div className="option-col">
                            <Dropdown menu={{
                                items: itemsAlign,
                                selectable: true,
                                defaultSelectedKeys: [dealLayout?.align || TextAlign.center],
                                onClick: handleChangeAlign
                            }} trigger={['click']}>
                                <Typography.Link className="start-capital">
                                    Align: {dealLayout?.align || TextAlign.center}
                                </Typography.Link>
                            </Dropdown>
                        </div>
                    }

                </div>


                {!displayOnly &&
                    <>

                        <div className="sub-option">
                            <div className="option-label">
                                Button
                            </div>

                            <div className="option-two-col">
                                <div className="option-col">
                                    <input
                                        type="color"
                                        className="color-box"
                                        id={`dealBg${uniqueKey}`}
                                        value={dealLayout?.buttonBackgroundColor || '#ffffff'}
                                        onChange={handleButtonBgChange}
                                    />
                                    <label htmlFor={`dealBg${uniqueKey}`}>
                                        Background: {dealLayout?.buttonBackgroundColor}
                                    </label>
                                </div>

                                <div className="option-col">
                                    <input
                                        type="color"
                                        className="color-box"
                                        id={`dealBorder${uniqueKey}`}
                                        value={dealLayout?.buttonBorderColor || dealLayout?.backgroundColor}
                                        onChange={handleButtonBorderChange}
                                    />
                                    <label htmlFor={`dealBorder${uniqueKey}`}>
                                        Border: {dealLayout?.buttonBorderColor || dealLayout?.backgroundColor}
                                    </label>
                                </div>

                            </div>

                            <div className="option-two-col">
                                <div className="option-col">
                                    <input
                                        type="color"
                                        className="color-box"
                                        id={`dealButtonText${uniqueKey}`}
                                        value={dealLayout?.buttonTextColor || '#aaaaaa'}
                                        onChange={handleButtonTextColor}
                                    />
                                    <label htmlFor={`dealButtonText${uniqueKey}`}>
                                        Text: {dealLayout?.buttonTextColor}
                                    </label>
                                </div>
                                <div className="option-col">
                                    <Dropdown menu={{
                                        items: itemsFontSize,
                                        selectable: true,
                                        defaultSelectedKeys: [dealLayout?.buttonFontSize || '14'],
                                        onClick: handleChangeButtonFontSize
                                    }} trigger={['click']}>
                                        <Typography.Link>
                                            Font size: {dealLayout?.buttonFontSize || '14'}
                                        </Typography.Link>
                                    </Dropdown>
                                </div>
                            </div>

                            <div className="option-two-col">
                                <div className="option-col">
                                    <Dropdown menu={{
                                        items: itemsFontWeight,
                                        selectable: true,
                                        defaultSelectedKeys: [dealLayout?.buttonFontWeight?.toString() || 'Normal'],
                                        onClick: handleChangeFontWeight
                                    }} trigger={['click']}>
                                        <Typography.Link>
                                            {getNumberFromFontWeight(dealLayout?.buttonFontWeight?.toString() || 'Normal')}
                                        </Typography.Link>
                                    </Dropdown>
                                </div>
                                <div className="option-col">
                                    <Dropdown trigger={['click']} dropdownRender={
                                        () =>
                                            <div className="dropdown min150">
                                                <div className="dropdown-title option-label">Set Rounding</div>
                                                <div className="flex-center-space-between">
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        className="min100"
                                                        onChange={handleChangeRounding}
                                                        value={dealLayout?.buttonRounding}
                                                    />
                                                </div>
                                            </div>
                                    }>
                                        <Typography.Link>
                                            Rounding: {dealLayout?.buttonRounding}
                                        </Typography.Link>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        {template &&
                            <>
                                {!compactTemplates.includes(template?.identifier) &&
                                    <>
                                        <div className="sub-option">
                                            <div className="option-label">
                                                Email
                                            </div>

                                            {emailSwitchTemplates.includes(template?.identifier) &&
                                                <div className="option-single-col">
                                                    <div className="option-col">
                                                        <Switch id="emailDefaultOpen" className="mleft5" size="small" defaultChecked={dealLayout?.emailOpen || false} onChange={handleChangeShowEmail} />
                                                        <label htmlFor="emailDefaultOpen" className="font-hover mleft5">
                                                            Default {dealLayout?.emailOpen ? 'open' : 'closed'}
                                                        </label>
                                                    </div>
                                                </div>
                                            }

                                            <div className="option-two-col">

                                                <div className="option-col">
                                                    <input
                                                        type="color"
                                                        className="color-box"
                                                        id={`dealEmailIconColor${uniqueKey}`}
                                                        value={dealLayout?.emailIconColor || '#888888'}
                                                        onChange={handleChangeEmailIconColor}
                                                    />
                                                    <label htmlFor={`dealEmailIconColor${uniqueKey}`}>
                                                        Icon: {dealLayout?.emailIconColor || '#888888'}
                                                    </label>
                                                </div>

                                                <div className="option-col">
                                                    <input
                                                        type="color"
                                                        className="color-box"
                                                        id={`dealEmailLabelColor${uniqueKey}`}
                                                        value={dealLayout?.emailTextColor || '#888888'}
                                                        onChange={handleChangeEmailTextColor}
                                                    />
                                                    <label htmlFor={`dealEmailLabelColor${uniqueKey}`}>
                                                        Text: {dealLayout?.emailTextColor || '#888888'}
                                                    </label>
                                                </div>

                                            </div>

                                        </div>
                                    </>}

                                {(compactTemplates.includes(template?.identifier) || emailSwitchTemplates.includes(template?.identifier)) &&
                                    <div className="sub-option">
                                        <div className="option-label">
                                            Email button
                                        </div>

                                        <div className="option-two-col">
                                            <div className="option-col">
                                                <input
                                                    type="color"
                                                    className="color-box"
                                                    id={`dealEmailButtonBg${uniqueKey}`}
                                                    value={dealLayout?.emailButtonBackgroundColor || '#888888'}
                                                    onChange={handleChangeEmailButtonBackgroundColor}
                                                />
                                                <label htmlFor={`dealEmailButtonBg${uniqueKey}`}>
                                                    Background: {dealLayout?.emailButtonBackgroundColor || '#888888'}
                                                </label>
                                            </div>

                                            <div className="option-col">
                                                <input
                                                    type="color"
                                                    className="color-box"
                                                    id={`dealEmailButtonText${uniqueKey}`}
                                                    value={dealLayout?.emailButtonTextColor || '#ffffff'}
                                                    onChange={handleChangeEmailButtonColor}
                                                />
                                                <label htmlFor={`dealEmailButtonText${uniqueKey}`}>
                                                    Text: {dealLayout?.emailButtonTextColor || '#ffffff'}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                }

                            </>
                        }

                    </>}
            </div>

        </div>
    </>
}

export default DealLayoutSettingsComponent