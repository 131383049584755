export enum LayoutDevices {
    desktop = "desktop",
    mobile = "mobile"
}

export enum Views {
    initial = "initial",
    main = "main"
}   

export enum LayoutClass {
    button = "button",
    action = "action",
    inline = "inline",
    popup = "popup",
}

export enum LayoutChildClass {
    button = "button",
    popup = "popup"
}

export enum ExpressPage {
    dashboard = "dashboard",
    insights = "insights",
    settings = "settings",
    offers = "offers",
    campaign = "campaign",
    layout = "layout",
    stripe = "stripe"
}