import { Button, Checkbox, message } from "antd";
import React, { useEffect, useState } from "react";
import { Offer } from "../../../../../types/offer.interfaces";
import {
  Badge,
  OfferBadge,
  emptyOfferBadge,
} from "../../../../../types/customizations.interfaces";
import useAxios from "../../../../../hooks/useAxios.hook";
import checkGreenRound from "../../../../../assets/images/icons/check-green-round.svg";

interface Props {
  setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offer: Offer;
  selectedOffers: Offer[];
  setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>;
}

const OfferBadges = ({
  setConditionModalOpen,
  offer,
  setSelectedOffers,
}: Props) => {
  const { myAxios } = useAxios();
  const [defaultBadges, setDefaultBadges] = useState<Badge[]>([]);
  const [companyBadges, setCompanyBadges] = useState<Badge[]>([]);
  const [selectedBadge, setSelectedBadge] = useState<OfferBadge>(
    offer.offerBadge ? offer.offerBadge : emptyOfferBadge
  );
  const [useBorder, setUseBorder] = useState<boolean>(
    offer.offerBadge ? offer.offerBadge.useBorder : false
  );

  const companyId = JSON.parse(
    localStorage.getItem("company") || ""
  ).company_Id;

  useEffect(() => {
    getBages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = () => {
    setSelectedOffers((prevState: Offer[]) =>
      prevState.map((o: Offer) =>
        o.id === offer.id
          ? {
              ...offer,
              offerBadge: selectedBadge.id
                ? {
                    ...selectedBadge,
                    useBorder: useBorder,
                  }
                : null,
            }
          : o
      )
    );
    setConditionModalOpen(false);
  };

  const handleUseBorder = () => {
    setUseBorder(!useBorder);
  };

  const getBages = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/badges/${companyId}?merge=true`,
    });

    if (response?.status) {
      setDefaultBadges(
        response?.data?.result?.filter((badge: Badge) => badge.isDefault)
      );
      setCompanyBadges(
        response?.data?.result.filter((badge: Badge) => !badge.isDefault)
      );
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  return (
    <div className="campaign-badges">
      <div className="campaign-badges-subtitle mbot20">
        Select a badge for this offer. You can customise the badge’s colors on
        the campaign’s Layout section.
      </div>
      <div className="condition-rules-inputs mbot20">
        <div className="frame">
          <div className="text-wrapper">Preset badges</div>
          <div className="div-2">
            {defaultBadges.map((badge: Badge) => {
              return (
                <div
                  key={badge.id}
                  className="group"
                  onClick={() => {
                    if (badge.id === selectedBadge.id) {
                      setSelectedBadge(emptyOfferBadge);
                    } else {
                      setSelectedBadge({
                        ...badge,
                        useBorder: useBorder,
                      });
                    }
                  }}
                  style={{
                    border:
                      badge.id === selectedBadge.id
                        ? "2px solid #3ab78f"
                        : "2px solid transparent",
                  }}
                >
                  <div
                    className="line"
                    style={{
                      backgroundColor: badge.backgroundColor,
                      color: badge.fontColor,
                    }}
                  >
                    <div className="text-wrapper-2">{badge.name}</div>
                  </div>
                  {badge.id === selectedBadge.id && (
                    <img
                      src={checkGreenRound}
                      alt="check"
                      className="campaign-badges-checkmark"
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className="rectangle-2" />
          <div className="rectangle-3" />
          <div className="rectangle-2" />
          <div className="text-wrapper-4">Custom badges</div>
          <div className="div-2">
            {companyBadges.length ? (
              companyBadges.map((badge: Badge) => {
                return (
                  <div
                    key={badge.id}
                    className="group"
                    onClick={() => {
                      if (badge.id === selectedBadge.id) {
                        setSelectedBadge(emptyOfferBadge);
                      } else {
                        setSelectedBadge({
                          ...badge,
                          useBorder: useBorder,
                        });
                      }
                    }}
                    style={{
                      border:
                        badge.id === selectedBadge.id
                          ? "2px solid #3ab78f"
                          : "2px solid transparent",
                    }}
                  >
                    <div
                      className="line"
                      style={{
                        backgroundColor: badge.backgroundColor,
                        color: badge.fontColor,
                      }}
                    >
                      <div className="text-wrapper-2">{badge.name}</div>
                    </div>
                    {badge.id === selectedBadge.id && (
                      <img
                        src={checkGreenRound}
                        alt="check"
                        className="campaign-badges-checkmark"
                      />
                    )}
                  </div>
                );
              })
            ) : (
              <div className="campaign-badges-subtitle">
                No custom badges available.
              </div>
            )}
          </div>
        </div>
        <Checkbox
          defaultChecked={useBorder}
          onChange={handleUseBorder}
          className="campaign-badges-checkbox checkbox-active mright10 mtop40"
        >
          I want the offer with the selected badge to stand out more by having
          the badge’s color as border color.
        </Checkbox>
      </div>
      <div className="ant-modal-footer">
        <Button
          onClick={handleUpdate}
          type="primary"
          className="success-button mtop20"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default OfferBadges;
