import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Input, message, Modal, Typography } from "antd/lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { CompanyCategory } from "../../../types/company.interfaces";
import failIcon from "../../../assets/images/icons/fail.svg";
import successIcon from "../../../assets/images/icons/success.svg";
import { VALIDATION } from "../../../utility/enums/validation.enums";
import useAxios from "../../../hooks/useAxios.hook";
import { ExpressCategoryItem } from "../types/express.types";
import { Country, Timezone } from "../../../types/global.interfaces";
import useExpress from "../../../hooks/useExpress.hook";
import { ExpressPage } from "../const/views";
import { EMAIL_REGEX, PWD_REGEX } from "../../../utility/regexp";
import useLogout from "../../../hooks/useLogout.hook";
import { registered_COUNTRY } from "../types/express.enum";

const ExpressSettings = () => {
  const logout = useLogout();
  const { campaign, company, onboardingStatus, userEmail, setCompany, setExpressPage, refreshState } = useExpress();

  const [loading, setLoading] = useState<boolean>(false);
  const { myAxios } = useAxios();
  const [categories, setCategories] = useState<CompanyCategory[]>();
  const [filteredCategories, setFilteredCategories] = useState<CompanyCategory[]>([]);
  const [searchCategories, setSearchCategories] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState<ExpressCategoryItem[]>(company?.categories || []);
  const categoriesRef = useRef<HTMLInputElement>(null);

  const [timezones, setTimezones] = useState<Timezone[]>();
  const [filteredTimezones, setFilteredTimezones] = useState<Timezone[]>([]);
  const [searchTimezones, setSearchTimezones] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>();
  const timezoneRef = useRef<HTMLInputElement>(null);

  const [countries, setCountries] = useState<Country[]>([]);
  // const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  // const [searchCountries, setSearchCountries] = useState<string>('');
  // const [selectedCountries, setSelectedCountries] = useState<Country[]>(company?.countries || []);
  // const countriesRef = useRef<HTMLInputElement>(null);

  const [filteredRegistered, setFilteredRegistered] = useState<Country[]>([]);
  const [searchRegistered, setSearchRegistered] = useState<string>('');
  const [selectedRegisteredCountry, setSelectedRegisteredCountry] = useState<Country | undefined>(company?.registrationCountry || undefined);
  const registeredRef = useRef<HTMLInputElement>(null);

  const [isEmailOpen, setEmailOpen] = useState<boolean>(false);
  const [isPasswordOpen, setPasswordOpen] = useState<boolean>(false);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [validPassword, setValidPassword] = useState<boolean>(false);

  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [validRepeatPassword, setValidRepeatPassword] = useState<boolean>(false);

  const [name, setName] = useState<string>(company?.name || '');
  const [validName, setValidName] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const [repeatEmail, setRepeatEmail] = useState<string>("");
  const [validRepeatEmail, setValidRepeatEmail] = useState<boolean>(false);

  // Validate last name
  useEffect(() => {
    const isValid = name.length > 1;
    setValidName(isValid);
  }, [name]);

  // Validate email on change
  useEffect(() => {
    const isValid = EMAIL_REGEX.test(email);
    setValidEmail(isValid);
    const isRepeatEmail = repeatEmail === email;
    setValidRepeatEmail(isRepeatEmail);
  }, [email, repeatEmail]);

  // Validate last name
  useEffect(() => {
    setValidName(name.length > 1);
  }, [name]);

  // Validate password and repeat password
  useEffect(() => {
    if (password) {
      const isValid = PWD_REGEX.test(password);
      setValidPassword(isValid);
      const isRepeatPassword = password === repeatPassword;
      setValidRepeatPassword(isRepeatPassword);
    }
  }, [password, repeatPassword]);


  const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedCategories([]);
    categoriesRef?.current?.focus();
  }

  // const handleClearCountries = (event: MouseEvent<HTMLSpanElement>) => {
  //   event.preventDefault();
  //   setSelectedCountries([]);
  //   countriesRef?.current?.focus();
  // }

  const handleChangeCategory = (checked: boolean, category: CompanyCategory) => {
    let _selectedCategories: ExpressCategoryItem[] = [];

    if (checked) {
      _selectedCategories = [...selectedCategories, { categoryId: category.id, name: category.name }];
    } else {
      selectedCategories?.forEach((value: ExpressCategoryItem) => {
        if (value.categoryId !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
  }

  // const handleChangeCountries = (checked: boolean, country: Country) => {
  //   let _selectedCountries: Country[] = [];

  //   if (checked) {
  //     _selectedCountries = [...selectedCountries, { ...country, id: country.id, name: country.name }];
  //   } else {
  //     selectedCountries?.forEach((value: Country) => {
  //       if (value.id !== country.id) {
  //         _selectedCountries.push(value);
  //       }
  //     });
  //   }

  //   setSelectedCountries(_selectedCountries);
  // }

  const handleChangeTimezone = (t: Timezone) => {
    setSelectedTimezone(t);
  }

  const handleRemoveCategory = (categoryId: string) => {
    const _selectedCategories: ExpressCategoryItem[] = structuredClone(selectedCategories).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
    setSelectedCategories(_selectedCategories);
  }

  // const handleRemoveCountry = (id: string) => {
  //   const _selectedCountries: Country[] = structuredClone(selectedCountries).filter((f: Country) => f.id !== id);
  //   setSelectedCountries(_selectedCountries);
  // }

  // const getCountriesName = (countries: Country[]): string => {
  //   const catName: string[] = countries ? countries.map((value: Country) => value.name) : [];
  //   return catName.join(', ')
  // }

  const getOfferCategoriesName = (cat?: ExpressCategoryItem[] | undefined): string => {
    const catName: string[] = cat ? cat.map((value: ExpressCategoryItem) => value.name) : [];
    return catName.join(', ');
  }

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedCategories?.find((value: ExpressCategoryItem) => value.categoryId === id);
    return isChecked
  }

  // const handleCheckCountry = (id: string): boolean => {
  //   const isChecked: boolean = !!selectedCountries?.find((value: Country) => value.id === id);
  //   return isChecked
  // }

  const getCompanyCategories = async () => {
    const { response } = await myAxios({ method: 'GET', url: 'CompanyCategory' });
    if (response?.data?.status) {
      setCategories(response?.data?.result);
      setFilteredCategories(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
  }

  const getTimezones = async () => {
    const { response } = await myAxios({ method: 'GET', url: 'support/timezones' });

    if (response?.data?.status) {
      setTimezones(response?.data?.result);
      setFilteredTimezones(response?.data?.result);
      if (company?.timezoneId) {
        response?.data?.result.map((t: Timezone) => t.id === company.timezoneId && setSelectedTimezone(t));
      }

    } else {
      message.error("Failed to get timezones", 1);
    }
  }

  const getCountries = async () => {
    const { response } = await myAxios({ method: 'GET', url: 'support/countries' });

    if (response?.data?.status) {
      setCountries(response?.data?.result);
      // setFilteredCountries(response?.data?.result);
      setFilteredRegistered(response?.data?.result);
    } else {
      message.error("Failed to get regions", 1);
    }
  }

  const postCompanyExpress = async () => {
    setLoading(true);
    const { response } = await myAxios({ method: 'POST', url: `/onboarding/express/company/details`, data: { password: "", campaign, company, onboardingStatus } });
    if (response?.data?.status) {
      message.success({
        content: "Your details are saved",
        className: 'express-message',
        duration: 1,
      });
    } else {
      message.error("Failed to save details", 1);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setFilteredCategories(newCategories.filter(f => f.name.toLowerCase().includes(searchCategories.toLowerCase())) || []);
      } else {
        setFilteredCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);


  useEffect(() => {
    if (timezones) {
      if (searchTimezones) {
        const newTimezones: Timezone[] = structuredClone(timezones);
        setFilteredTimezones(newTimezones.filter(f => f.displayName.toLowerCase().includes(searchTimezones.toLowerCase())) || []);
      } else {
        setFilteredTimezones(timezones);
      }
    }
    // eslint-disable-next-line
  }, [searchTimezones]);


  // useEffect(() => {
  //   if (countries) {
  //     if (searchCountries) {
  //       const newCountries: Country[] = structuredClone(countries);
  //       setFilteredCountries(newCountries.filter(f => f.name.toLowerCase().includes(searchCountries.toLowerCase())) || []);
  //     } else {
  //       setFilteredCountries(countries);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [searchCountries]);


  useEffect(() => {
    if (countries) {
      if (searchRegistered) {
        const newCountries: Country[] = structuredClone(countries);
        setFilteredRegistered(newCountries.filter(f => f.name.toLowerCase().includes(searchRegistered.toLowerCase())) || []);
      } else {
        setFilteredRegistered(countries);
      }
    }
    // eslint-disable-next-line
  }, [searchRegistered]);

  const isValid = (): boolean => {
    return validName
  }

  const handleSave = () => {
    if (isValid()) {
      postCompanyExpress();
    }
  }

  const handleChangeEmail = async () => {
    const { response } = await myAxios({ method: 'POST', url: `/user/newemail`, data: { currentEmail: userEmail, email } });

    if (response?.data.status) {
      message.success({
        content: "Your email is changed",
        className: 'express-message',
        duration: 1,
      });
      refreshState();
      setEmailOpen(false);
    } else {
      message.success({
        content: response?.data?.result[0].message || "Failed to change email",
        className: 'express-message',
        duration: 1,
      });
    }

  }

  const handleChangePassword = async () => {
    const { response } = await myAxios({ method: 'POST', url: `/user/newpassword`, data: { password, currentpassword: currentPassword } });

    if (response?.data.status) {
      message.success({
        content: "Your password is changed",
        className: 'express-message',
        duration: 1,
      });
      refreshState();
      setPasswordOpen(false);
      setCurrentPassword('');
      setRepeatEmail('');
      setPassword('');
    } else {
      message.error({
        content: response?.data?.result[0].message || "Failed to change password",
        className: 'express-message',
        duration: 1,
      });
    }
  }

  const handleLogout = () => {
    logout();
  }

  useEffect(() => {
    setCompany(prevState => (prevState && {
      ...prevState,
      name,
      timezoneId: selectedTimezone?.id || selectedTimezone?.id || '',
      categories: selectedCategories ? selectedCategories : [],
      registrationCountry: selectedRegisteredCountry ? selectedRegisteredCountry : registered_COUNTRY
    }));
    // eslint-disable-next-line
  }, [selectedCategories, name, selectedTimezone, selectedRegisteredCountry])

  useEffect(() => {
    getCompanyCategories();
    getCountries();
    getTimezones();
    document.title = "BrandSwap Express - Settings";
    setExpressPage(ExpressPage.settings);
    // eslint-disable-next-line
  }, [company?.id])

  useEffect(() => {
    if (company) {
      setName(company?.name);
      company.categories && setSelectedCategories(company.categories);
      company.registrationCountry && setSelectedRegisteredCountry(company.registrationCountry)
      // company.countries && setSelectedCountries(company.countries);
    }
  }, [company]);

  return <>
    <div className="express-content express-content-flex">
      <div className="widget widget-express">
        <form className="express-form" onSubmit={e => e.preventDefault()}>

          <div className="express-title">
            Company details
          </div>
          <div className="express-descrpiton">
          </div>

          <div className="express-row">
            <div className="express-label">
              Name

              {name && (
                <span className="unauth-label-error">
                  <span>
                    <img
                      src={validName ? successIcon : failIcon}
                      alt={validName ? "success" : "fail"}
                    />
                  </span>
                </span>
              )}
            </div>
            <div className="express-input">
              <Input
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
                placeholder="Your company name"
              />

              <p
                id="eide"
                className={`error-line ${name && !validName
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.nameValid}
              </p>
            </div>
          </div>

          <div className="express-row">
            <div className="express-label">
              Ad Partner Match Categories: {selectedCategories.length} <InfoCircleOutlined id="guide-details-categories" color="#007a5c" />
            </div>
            <div className="express-input express-dropdown">

              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return <div className="dropdown">
                      <div className="dropdown-search">
                        <SearchOutlined className="search-icon" />
                        <input ref={categoriesRef} type="text" placeholder="Search" className="default-input" value={searchCategories} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCategories(event.target.value)} />
                      </div>
                      {selectedCategories.length > 0 &&

                        <>
                          <div className="dropdown-title">Selected Categories:</div>
                          <ul className="list-tags">
                            {selectedCategories?.map((category: ExpressCategoryItem) => {
                              return <li key={`selected-cat-${category.categoryId}`}>
                                {category.name}
                                <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCategory(category.categoryId)}></span>

                              </li>
                            })}
                            <span className="font-xsmall font-hover clear-categories" onClick={handleClearCategories}>Clear all</span>
                          </ul>
                        </>

                      }
                      <div className="dropdown-title">Categories found:</div>
                      {filteredCategories.length > 0 ?
                        <>
                          <ul>
                            {filteredCategories?.map((category: CompanyCategory) => {
                              return <li key={`details-cat-${category.id}`}>
                                <Checkbox checked={handleCheckedCategories(category.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCategory(e.target.checked, category)} className="checkbox-express">{category.name}</Checkbox>
                              </li>
                            })}
                          </ul>
                        </>
                        :
                        <>
                          <span className="font-xsmall">No item found</span>
                        </>}
                    </div>
                  }}
                  trigger={['click']}>
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input id="categories" disabled={true} value={getOfferCategoriesName(selectedCategories)} className="express-disabled" placeholder="Select ad partner match categories" />
                        <span className="express-dropdown-icon"></span>
                        {/* <label htmlFor="page" tabIndex={0}>
                                                    <span className="label-input-name">Categories: {categories?.length || 0}</span>
                                                    <span className="icon icon-arrow-down"></span>
                                                    <input disabled value={getOfferCategoriesName(categories)} type="text" className="label-input" />
                                                </label> */}
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>

            </div>
          </div>

          <div className="express-row">
            <div className="express-label">
              Timezone: <InfoCircleOutlined id="guide-details-timezone" color="#007a5c" />
            </div>
            <div className="express-input express-dropdown">

              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return <div className="dropdown">
                      <div className="dropdown-search">
                        <SearchOutlined className="search-icon" />
                        <input ref={timezoneRef} type="text" placeholder="Search" className="default-input" value={searchTimezones} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTimezones(event.target.value)} />
                      </div>
                      <div className="dropdown-title">Timezones found:</div>
                      {selectedTimezone &&

                        <>

                          {filteredTimezones.length > 0 ?
                            <>
                              <ul>
                                {filteredTimezones?.map((t: Timezone) => {
                                  return <li key={`details-cat-${t.id}`}>
                                    <span onClick={() => { handleChangeTimezone(t); }} className={`font-hover ${t.id === selectedTimezone?.id ? 'font-active' : ''}`}>{t.displayName}</span>
                                  </li>
                                })}
                              </ul>
                            </>
                            :
                            <>
                              <span className="font-xsmall">No item found</span>
                            </>}
                        </>
                      }
                    </div>
                  }}
                  trigger={['click']}>
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input disabled={true} value={selectedTimezone?.displayName} className="express-disabled" placeholder="Select countries" />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>

          {/* <div className="express-row">
            <div className="express-label">
              Country: {selectedCountries.length} <InfoCircleOutlined id="guide-details-country" color="#007a5c" />
            </div>
            <div className="express-input express-dropdown">

              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return <div className="dropdown">
                      <div className="dropdown-search">
                        <SearchOutlined className="search-icon" />
                        <input ref={countriesRef} type="text" placeholder="Search" className="default-input" value={searchCountries} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCountries(event.target.value)} />

                      </div>

                      {selectedCountries.length > 0 && <>
                        <div className="dropdown-title">Selected Countries:</div>
                        <ul className="list-tags">
                          {selectedCountries?.map((country: Country) => {
                            return <li key={`selected-cat-${country.id}`}>
                              {country.name}
                              <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCountry(country.id)}></span>

                            </li>
                          })}
                          <span className="font-xsmall font-hover clear-categories" onClick={handleClearCountries}>Clear all</span>
                        </ul>
                      </>}
                      <div className="dropdown-title">Countries found:</div>

                      {filteredCountries.length > 0 ?
                        <>
                          <ul>
                            {filteredCountries?.map((country: Country) => {
                              return <li key={`details-cat-${country.id}`}>
                                <Checkbox checked={handleCheckCountry(country.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCountries(e.target.checked, country)} className="checkbox-express">{country.name}</Checkbox>
                              </li>
                            })}
                          </ul>
                        </>
                        :
                        <>
                          <span className="font-xsmall">No item found</span>
                        </>}
                    </div>
                  }}
                  trigger={['click']}>
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input disabled={true} value={getCountriesName(selectedCountries)} className="express-disabled" placeholder="Select countries" />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>

            </div>
          </div> */}

          <div className="express-row">
            <div className="express-label">
              Registered country: <InfoCircleOutlined id="guide-details-registered-country" color="#007a5c" />
            </div>
            <div className="express-input express-dropdown">

              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return <div className="dropdown">
                      <div className="dropdown-search">
                        <SearchOutlined className="search-icon" />
                        <input ref={registeredRef} type="text" placeholder="Search" className="default-input" value={searchRegistered} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchRegistered(event.target.value)} />
                      </div>

                      {/* {registeredCountry.length > 0 && <>
                                            <div className="dropdown-title">Selected Countries:</div>
                                            <ul className="list-tags">
                                                {registeredCountry?.map((country: Country) => {
                                                    return <li key={`selected-cat-${country.id}`}>
                                                        {country.name}
                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCountry(country.id)}></span>
                                                    </li>
                                                })}
                                                <span className="font-xsmall font-hover clear-categories" onClick={handleClearCountries}>Clear all</span>
                                            </ul>
                                        </>} */}
                      <div className="dropdown-title">Countries found:</div>

                      {filteredRegistered.length > 0 ?
                        <>
                          <ul>
                            {filteredRegistered?.map((country: Country) => {
                              return <li key={`details-cat-${country.id}`}>
                                <span onClick={() => { setSelectedRegisteredCountry(country); }} className={`font-hover ${country.id === selectedRegisteredCountry?.id ? 'font-active' : ''}`}>{country.name}</span>
                              </li>
                            })}
                          </ul>
                        </>
                        :
                        <>
                          <span className="font-xsmall">No item found</span>
                        </>}
                    </div>
                  }}
                  trigger={['click']}>
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input disabled={true} value={selectedRegisteredCountry?.name || ''} className="express-disabled" placeholder="Select company country" />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>

            </div>
          </div>


          <div className="express-button">
            <Button type="primary" loading={loading} disabled={!isValid()} className="express-button-success" onClick={handleSave}>Save</Button>
          </div>

        </form>

      </div>
      <div className="widget widget-express">
        <form className="express-form">

          <div className="express-title">
            Security
          </div>
          <div className="express-descrpiton">
          </div>

          <div className="express-row">
            <div className="express-label">
              Email
            </div>
            <div className="express-input-line">
              {userEmail} <span className="font-hover mleft10" onClick={() => setEmailOpen(true)}>Change email</span>
              {/* <span className="express-input-line-description">You have a valid and verified email</span> */}
            </div>
          </div>

          <div className="express-row">
            <div className="express-label">
              Password
            </div>
            <div className="express-input-line">
              <svg width="48" height="8" viewBox="0 0 48 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4Z" fill="#959AAB" />
                <path d="M10 4C10 1.79086 11.7909 0 14 0C16.2091 0 18 1.79086 18 4C18 6.20914 16.2091 8 14 8C11.7909 8 10 6.20914 10 4Z" fill="#959AAB" />
                <path d="M20 4C20 1.79086 21.7909 0 24 0C26.2091 0 28 1.79086 28 4C28 6.20914 26.2091 8 24 8C21.7909 8 20 6.20914 20 4Z" fill="#959AAB" />
                <path d="M30 4C30 1.79086 31.7909 0 34 0C36.2091 0 38 1.79086 38 4C38 6.20914 36.2091 8 34 8C31.7909 8 30 6.20914 30 4Z" fill="#959AAB" />
                <path d="M40 4C40 1.79086 41.7909 0 44 0C46.2091 0 48 1.79086 48 4C48 6.20914 46.2091 8 44 8C41.7909 8 40 6.20914 40 4Z" fill="#959AAB" />
              </svg>
              <span className="font-hover mleft10" onClick={() => setPasswordOpen(true)}>Change password</span>
              {/* <span className="express-input-line-description">You have a valid password and you can change it</span> */}
            </div>
          </div>

          <div className="express-row">
            {/* <div className="express-label">
              Session
            </div> */}

            <div className="express-button">
              <Button className="express-button-back" onClick={handleLogout} type="default">Log out</Button>
            </div>
          </div>

        </form>

      </div>
    </div>

    <Modal
      className="modal-default modal-express"
      title={<></>}
      footer={<></>}
      maskClosable={true}
      open={isPasswordOpen}
      width={450}
      closable={true}
      afterClose={() => setPassword('')}
      onCancel={() => setPasswordOpen(false)}
      closeIcon={<span className="icon icon-remove"></span>}
    >
      <div className="modal-title medium">
        Change your password
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <div className="modal-content-space">

          <div className="express-row">
            <div className="express-label">
              Current password
            </div>
            <div className="express-input">
              <Input.Password
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentPassword(e.target.value)
                }
                autoComplete="off"
                placeholder="Type your current password..."
                visibilityToggle={false} />
            </div>
          </div>

          <div className="express-row">
            <div className="express-label">
              Password

              {password && !validPassword && (
                <span className="unauth-label-error">
                  <span>
                    <img
                      src={failIcon}
                      alt={"fail"}
                    />
                  </span>
                </span>
              )}
            </div>
            <div className="express-input">
              <Input.Password
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                autoComplete="off"
                placeholder="Any characters, but required: letters and numbers"
                visibilityToggle={false} />

              <p
                id="eide"
                className={`error-line ${password && !validPassword
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.passwordValid}
              </p>
            </div>
          </div>
          <div className="express-row">
            <div className="express-label">
              Repeat password

              {repeatPassword && !validRepeatPassword && (
                <span className="unauth-label-error">
                  <span>
                    <img
                      src={failIcon}
                      alt={"fail"}
                    />
                  </span>
                </span>
              )}
            </div>
            <div className="express-input">
              <Input.Password

                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setRepeatPassword(e.target.value)
                }
                autoComplete="off"
                placeholder="Please enter your password again"
                visibilityToggle={false} />

              <p
                id="eide"
                className={`error-line ${repeatPassword && !validRepeatPassword
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.sameAsPrevious}
              </p>
            </div>
          </div>

        </div>

        <div className="modal-buttons flex-center express-button">
          <Button type="primary" className="button-default" onClick={() => setPasswordOpen(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={password.length === 0} className="express-button-success mleft10" onClick={handleChangePassword}>Save</Button>
        </div>

      </form>
    </Modal>

    <Modal
      className="modal-default modal-express"
      title={<></>}
      footer={<></>}
      maskClosable={true}
      open={isEmailOpen}
      width={400}
      closable={true}
      afterClose={() => setEmail('')}
      onCancel={() => setEmailOpen(false)}
      closeIcon={<span className="icon icon-remove"></span>}
    >
      <div className="modal-title medium">
        Change your email address
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <div className="modal-content-space">

          <div className="express-row">
            <div className="express-label">
              New email

              {email && !validEmail && (
                <span className="unauth-label-error">
                  <span>
                    <img
                      src={failIcon}
                      alt={"fail"}
                    />
                  </span>
                </span>
              )}
            </div>
            <div className="express-input">
              <Input
                type="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                placeholder="example@company.com" />

              <p
                id="eide"
                className={`error-line ${email && !validEmail
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.emailValid}
              </p>
            </div>
          </div>

          <div className="express-row">
            <div className="express-label">
              Repeat email

              {repeatEmail && !validRepeatEmail && (
                <span className="unauth-label-error">
                  <span>
                    <img
                      src={failIcon}
                      alt={"fail"}
                    />
                  </span>
                </span>
              )}
            </div>
            <div className="express-input">
              <Input
                type="email"
                value={repeatEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setRepeatEmail(e.target.value)
                }
                placeholder="example@company.com" />

              <p
                id="eide"
                className={`error-line ${repeatEmail && !validRepeatEmail
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.sameAsPrevious}
              </p>
            </div>
          </div>

        </div>

        <div className="modal-buttons flex-center express-button">
          <Button type="primary" className="button-default" onClick={() => setEmailOpen(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={false} className="express-button-success mleft10" onClick={handleChangeEmail}>Save</Button>
        </div>

      </form>
    </Modal>
  </>;
}

export default ExpressSettings;

