import { Layouts } from "react-grid-layout";

export enum HOME_WIDGET {
    // reminders = "reminders",
    insight = "insight",
    // settings = "settings",
    company = "company",
    // users = "users",
}

export const dashboardLayout: Layouts = {
    desktop: [
        // { i: HOME_WIDGET.reminders, x: 0, y: 0, w: 4, h: 5, minW: 3, maxW: 12, minH: 4, maxH: 10 },
        // { i: HOME_WIDGET.insight, x: 6, y: 0, w: 4, h: 5, minW: 3, maxW: 12, minH: 4, maxH: 10 },
        // { i: HOME_WIDGET.settings, x: 8, y: 0, w: 4, h: 5, minW: 3, maxW: 12, minH: 4, maxH: 10 },
        { i: HOME_WIDGET.company, x: 0, y: 0, w: 6, h: 8, minW: 6, maxW: 12, minH: 8, maxH: 15 },
        // { i: HOME_WIDGET.users, x: 4, y: 4, w: 8, h: 4, minW: 4, maxW: 12, minH: 3, maxH: 10 },
    ],
    mobile: [
        // { i: HOME_WIDGET.reminders, x: 0, y: 0, w: 6, h: 5, minW: 6, maxW: 6, minH: 4, maxH: 12 },
        // { i: HOME_WIDGET.insight, x: 6, y: 0, w: 6, h: 5, minW: 6, maxW: 6, minH: 4, maxH: 12 },
        // { i: HOME_WIDGET.settings, x: 0, y: 1, w: 6, h: 5, minW: 6, maxW: 6, minH: 4, maxH: 12 },
        { i: HOME_WIDGET.company, x: 0, y: 4, w: 6, h: 8, minW: 6, maxW: 12, minH: 8, maxH: 15 },
        // { i: HOME_WIDGET.users, x: 0, y: 3, w: 6, h: 6, minW: 6, maxW: 6, minH: 4, maxH: 12 },
    ],
}
