/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState, MouseEvent, Dispatch } from "react";
import advertiserImage from "../../../assets/images/box-advertiser.png";
import publisherImage from "../../../assets/images/box-publisher.png";
import checkIconSquare from "../../../assets/images/icons/check-white-square.svg";
import DeleteIcon from "../../../assets/images/icons/delete.svg";
import "./css/company-settings.scss";
import {
  Button,
  Checkbox,
  Dropdown,
  Modal,
  Switch,
  Typography,
  message,
} from "antd";
import useAxios from "../../../hooks/useAxios.hook";
import { NO_SPACE_EMPTY, URL_REGEX } from "../../../utility/regexp";
import failIcon from "../../../assets/images/icons/fail.svg";
import successIcon from "../../../assets/images/icons/success.svg";
import infoIcon from "../../../assets/images/icons/info-icon.svg";

import { SearchOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  CompanySettingsInterface,
  CompanyCategory,
  WeekDays,
} from "../../../types/company.interfaces";
import { OfferCategory } from "../../../types/offer.interfaces";
import { Country, Timezone } from "../../../types/global.interfaces";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import useLogout from "../../../hooks/useLogout.hook";
interface Props {
  company: CompanySettingsInterface;
  setCompany: Dispatch<React.SetStateAction<CompanySettingsInterface>>;
  isSuperAdmin: boolean | undefined;
}

const weekdays: WeekDays[] = [
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
  { name: "Sunday", value: 7 },
];

const CompanySettings = ({ company, setCompany, isSuperAdmin }: Props) => {
  const { myAxios } = useAxios();

  const logout = useLogout();

  const deleteAccess = JSON.parse(
    localStorage.getItem("company") || ""
  ).userAccess.access.includes("companydelete");

  const [isPublisher, setPublisher] = useState<boolean>(
    company.companyType === COMPANY_TYPES.publisher ||
      company.companyType === COMPANY_TYPES.both
  );
  const [isAdvertiser, setAdvertiser] = useState<boolean>(
    company.companyType === COMPANY_TYPES.advertiser ||
      company.companyType === COMPANY_TYPES.both
  );

  const companyNameRef = useRef<HTMLInputElement>(null);

  const [validCompanyName, setValidCompanyName] = useState<boolean>(false);
  const [companyNameBlur, setCompanyNameBlur] = useState<boolean>(false);

  const [validWebsite, setValidWebsite] = useState<boolean>(false);
  const [websiteBlur, setWebsiteBlur] = useState<boolean>(false);

  const categoriesRef = useRef<HTMLInputElement>(null);
  const [categories, setCategories] = useState<CompanyCategory[]>();
  const [filteredCategories, setFilteredCategories] = useState<
    CompanyCategory[]
  >([]);
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<OfferCategory[]>(
    company.categories.map((c: any) => {
      return {
        category_id: c.categoryId,
        name: c.name,
      };
    })
  );

  const [countries, setCountries] = useState<Country[]>();
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [searchCountries, setSearchCountries] = useState<string>("");
  const countryRef = useRef<HTMLInputElement>(null);
  const [countryBlur, setCountryBlur] = useState<boolean>(false);

  const [timezones, setTimezones] = useState<Timezone[]>();
  const [filteredTimezones, setFilteredTimezones] = useState<Timezone[]>([]);
  const [searchTimezones, setSearchTimezones] = useState<string>("");
  const timezoneRef = useRef<HTMLInputElement>(null);
  const [timezoneBlur, setTimezoneBlur] = useState<boolean>(false);

  const [weekDayBlur, setWeekDayBlur] = useState<boolean>(false);

  // // Set focus to company name
  // useEffect(() => {
  //   if (companyNameRef.current) companyNameRef.current.focus();
  // }, []);

  useEffect(() => {
    let companyType = "";
    if (isAdvertiser && isPublisher) {
      companyType = COMPANY_TYPES.both;
    } else if (isAdvertiser) {
      companyType = COMPANY_TYPES.advertiser;
    } else if (isPublisher) {
      companyType = COMPANY_TYPES.publisher;
    }
    setCompany((prevState) => prevState && { ...prevState, companyType });
  }, [isAdvertiser, isPublisher]);

  useEffect(() => {
    const isValid = NO_SPACE_EMPTY.test(company.name);
    setValidCompanyName(isValid && company.name.length >= 2);
  }, [company.name]);

  useEffect(() => {
    const isValid =
      URL_REGEX.test(company?.url || "") && (company?.url || "").length > 0;
    setValidWebsite(isValid);
  }, [company?.url]);

  const getOfferCategoriesName = (categories?: OfferCategory[]): string => {
    const catName: string[] = categories
      ? categories.map((value: OfferCategory) => value.name)
      : [];
    return catName.join(", ");
  };

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setFilteredCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchCategories.toLowerCase())
          ) || []
        );
      } else {
        setFilteredCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);

  useEffect(() => {
    if (categories) {
      setFilteredCategories(categories);
    }
  }, [categories]);

  const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSearchCategories("");
    categoriesRef?.current?.focus();
  };

  const handleChangeCategory = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: OfferCategory[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedCategories,
        { category_id: category.id, name: category.name },
      ];
    } else {
      selectedCategories?.forEach((value: OfferCategory) => {
        if (value.category_id !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          categories: _selectedCategories.map((c: OfferCategory) => {
            return {
              categoryId: c.category_id,
              name: c.name,
            };
          }),
        }
    );
  };

  const handleRemoveCategory = (categoryId: string) => {
    const _selectedCategories: OfferCategory[] = structuredClone(
      selectedCategories
    ).filter((f: OfferCategory) => f.category_id !== categoryId);

    setSelectedCategories(_selectedCategories);
    setCompany(
      (prevState) =>
        prevState && { ...prevState, categories: _selectedCategories }
    );
  };

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedCategories.find(
      (value: OfferCategory) => value.category_id === id
    );
    return isChecked;
  };

  useEffect(() => {
    if (countries) {
      if (searchCountries) {
        const newCountries: Country[] = structuredClone(countries);
        setFilteredCountries(
          newCountries.filter((c) =>
            c.name.toLowerCase().includes(searchCountries.toLowerCase())
          ) || []
        );
      } else {
        setFilteredCountries(countries);
      }
    }
  }, [searchCountries]);

  useEffect(() => {
    if (timezones) {
      if (searchTimezones) {
        const newTimezones: Timezone[] = structuredClone(timezones);
        setFilteredTimezones(
          newTimezones.filter((f) =>
            f.displayName.toLowerCase().includes(searchTimezones.toLowerCase())
          ) || []
        );
      } else {
        setFilteredTimezones(timezones);
      }
    }
  }, [searchTimezones]);

  useEffect(() => {
    getCompanyCategories();
    getCountries();
    getTimezones();
  }, []);

  useEffect(() => {
    setAdvertiser(
      company.companyType === COMPANY_TYPES.advertiser ||
        company.companyType === COMPANY_TYPES.both
    );
    setPublisher(
      company.companyType === COMPANY_TYPES.publisher ||
        company.companyType === COMPANY_TYPES.both
    );
    setSelectedCategories(
      company.categories.map((c: any) => {
        return {
          category_id: c.categoryId,
          name: c.name,
        };
      })
    );
  }, [company.id]);

  const getCompanyCategories = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "CompanyCategory",
    });
    if (response?.data?.status) {
      setCategories(response?.data?.result);
      setFilteredCategories(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  const getCountries = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "support/countries",
    });

    if (response?.data?.status) {
      setCountries(response?.data?.result);
      setFilteredCountries(response?.data?.result);
    } else {
      message.error("Failed to get regions", 1);
    }
  };

  const getTimezones = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "support/timezones",
    });

    if (response?.data?.status) {
      setTimezones(response?.data?.result);
      setFilteredTimezones(response?.data?.result);
    } else {
      message.error("Failed to get timezones", 1);
    }
  };

  const openConfirmModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete company ${company.name}?`,
      content: "This action is irreversible",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleDelete(),
    });
  };

  const handleDelete = async () => {
    const { response } = await myAxios({
      method: "DELETE",
      url: `/company/${company.id}`,
    });

    if (response?.data?.status) {
      message.success("Company deleted succesfully.", 1);
      logout();
    } else {
      message.error("Company has active campaigns and can't be deleted!", 1);
    }
  };

  return (
    <div>
      <div className="card">
        <h3 className="title">Account Type</h3>
        <div className="desc">
          <span>
            Let us know how you will be using BrandSwap. You are a retailer, an
            advertiser or{" "}
          </span>
          <span className="font-warning">you can select both</span>
        </div>
        <div className="options">
          <div
            onClick={() => setPublisher((prevState) => !prevState)}
            className={`option ${isPublisher && "active"}`}
          >
            {isPublisher && (
              <div className="option-active">
                <img src={checkIconSquare} alt="active" />
              </div>
            )}
            <div className="option-image">
              <img src={advertiserImage} alt="Advertiser" />
            </div>
            <div className="option-content">
              I want to host other companies’ ads on my website
            </div>
          </div>
          <div
            onClick={() => setAdvertiser((prevState) => !prevState)}
            className={`option ${isAdvertiser && "active"}`}
          >
            {isAdvertiser && (
              <div className="option-active">
                <img src={checkIconSquare} alt="active" />
              </div>
            )}
            <div className="option-image">
              <img src={publisherImage} alt="Publisher" />
            </div>
            <div className="option-content">
              I want my products advertised on other companies’ websites
            </div>
          </div>
        </div>
        {!isAdvertiser && !isPublisher && (
          <div id="cnide" className="font-error font-small mtop5">
            Select at least one option, or both.
          </div>
        )}
      </div>
      <div className="card">
        <h3 className="title">Company details</h3>
        <form className="widget-form large-form user-details">
          <div className="widget-multiple-form-row">
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Company name*</span>
                <span className="unauth-label-error">
                  <span>
                    {validCompanyName ? (
                      <img src={successIcon} alt="success" />
                    ) : companyNameBlur ? (
                      <img src={failIcon} alt="fail" />
                    ) : (
                      <></>
                    )}
                  </span>
                  {!validCompanyName && companyNameBlur && (
                    <span id="nide">Must be 2 to 24 characters.</span>
                  )}
                </span>
              </div>
              <div
                className={`
                widget-input ${
                  company.name && !validCompanyName
                    ? "widget-input-invalid"
                    : ""
                }`}
              >
                <input
                  type="text"
                  id="company-name"
                  ref={companyNameRef}
                  name="company-name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCompany(
                      (prevState) =>
                        prevState && { ...prevState, name: e.target.value }
                    )
                  }
                  value={company.name}
                  onBlur={() => setCompanyNameBlur(true)}
                  aria-invalid={validCompanyName ? "true" : "false"}
                  aria-describedby="cnide"
                  autoComplete="off"
                  placeholder="e.g. ABC Retail"
                />
              </div>
            </div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Company Website*</span>

                <span className="unauth-label-error">
                  <span>
                    {validWebsite ? (
                      <img src={successIcon} alt="success" />
                    ) : websiteBlur ? (
                      <img src={failIcon} alt="fail" />
                    ) : (
                      <></>
                    )}
                  </span>

                  {!validWebsite && websiteBlur && (
                    <span id="nide">Is required.</span>
                  )}
                </span>
              </div>
              <div
                className={`widget-input ${
                  company?.url && !validWebsite ? "widget-input-invalid" : ""
                }`}
              >
                <input
                  type="text"
                  id="website"
                  name="website"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCompany(
                      (prevState) =>
                        prevState && { ...prevState, url: e.target.value }
                    )
                  }
                  value={company?.url || ""}
                  onBlur={() => setWebsiteBlur(true)}
                  aria-invalid={validWebsite ? "true" : "false"}
                  aria-describedby="wide"
                  autoComplete="off"
                  placeholder="e.g. www.abc.com"
                />

                <p
                  id="wide"
                  className={`error-line ${
                    company?.url && !validWebsite ? "error-show" : "error-hide"
                  }`}
                >
                  Must be a valid url!
                </p>
              </div>
            </div>
          </div>

          <div className="widget-multiple-form-row">
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Ad partner match categories</span>
                {selectedCategories.length > 0 && (
                  <span className="unauth-label-error">
                    <span>
                      <img
                        src={selectedCategories.length ? successIcon : failIcon}
                        alt={selectedCategories.length ? "success" : "fail"}
                      />
                    </span>
                  </span>
                )}
              </div>
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={categoriesRef}
                            type="text"
                            className="default-input"
                            value={searchCategories}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchCategories(event.target.value)}
                          />
                          <span
                            className="close-icon"
                            onClick={handleClearCategories}
                          >
                            <span className="icon icon-remove"></span>
                          </span>
                        </div>

                        {selectedCategories.length > 0 && (
                          <>
                            <div className="dropdown-title">
                              Selected Categories:
                            </div>
                            <ul className="list-tags">
                              {selectedCategories?.map(
                                (category: OfferCategory) => {
                                  return (
                                    <li
                                      key={`selected-cat-${category.category_id}`}
                                    >
                                      {category.name}
                                      <span
                                        className="icon icon-remove mleft10 font-hover"
                                        onClick={() =>
                                          handleRemoveCategory(
                                            category.category_id
                                          )
                                        }
                                      ></span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        )}
                        <div className="dropdown-title">Categories found:</div>
                        <ul>
                          {filteredCategories?.map(
                            (category: CompanyCategory) => {
                              return (
                                <li key={`details-cat-${category.id}`}>
                                  <Checkbox
                                    checked={handleCheckedCategories(
                                      category.id
                                    )}
                                    onChange={(e: CheckboxChangeEvent) =>
                                      handleChangeCategory(
                                        e.target.checked,
                                        category
                                      )
                                    }
                                    className="checkbox-active"
                                  >
                                    {category.name}
                                  </Checkbox>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    );
                  }}
                  autoFocus={true}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="page" tabIndex={0}>
                        <span className="label-input-name">
                          Categories: {selectedCategories.length || 0}
                        </span>
                        <span className="icon icon-arrow-down"></span>
                        <input
                          disabled
                          value={getOfferCategoriesName(selectedCategories)}
                          type="text"
                          className="label-input"
                        />
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Timezone*</span>
                <span className="unauth-label-error">
                  <span className="error-span">
                    <span>
                      {company.timezone.id ? (
                        <img src={successIcon} alt="success" />
                      ) : timezoneBlur ? (
                        <img src={failIcon} alt="fail" />
                      ) : (
                        <></>
                      )}
                    </span>

                    {!company.timezone.id && timezoneBlur && (
                      <span id="nide">Is required.</span>
                    )}
                  </span>
                </span>
              </div>
              <div
                className="label-input-dropdown"
                onClick={() => {
                  setTimezoneBlur(true);
                }}
              >
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={timezoneRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchTimezones}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchTimezones(event.target.value)}
                          />
                        </div>
                        <div className="dropdown-title">Timezones found:</div>

                        <>
                          {filteredTimezones.length > 0 ? (
                            <>
                              <ul>
                                {filteredTimezones?.map((t: Timezone) => {
                                  return (
                                    <li key={`details-cat-${t.id}`}>
                                      <span
                                        onClick={() => {
                                          setCompany(
                                            (prevState) =>
                                              prevState && {
                                                ...prevState,
                                                timezone: t,
                                              }
                                          );
                                        }}
                                        className={`font-hover ${
                                          t.id === company.timezone?.id
                                            ? "font-active"
                                            : ""
                                        }`}
                                      >
                                        {t.displayName}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <>
                              <span className="font-xsmall">No item found</span>
                            </>
                          )}
                        </>
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="page">
                        <span className="icon icon-arrow-down top-center"></span>
                        <input
                          disabled={true}
                          type="text"
                          value={company.timezone?.displayName}
                          className="label-input label-input-padding"
                        />
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="widget-multiple-form-row">
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Registered country*</span>
                <span className="unauth-label-error">
                  <span className="error-span">
                    <span>
                      {company.registrationCountry.id ? (
                        <img src={successIcon} alt="success" />
                      ) : countryBlur ? (
                        <img src={failIcon} alt="fail" />
                      ) : (
                        <></>
                      )}
                    </span>

                    {!company.registrationCountry.id && countryBlur && (
                      <span id="nide">Is required.</span>
                    )}
                  </span>
                </span>
              </div>

              <div
                className="label-input-dropdown"
                onBlur={() => setCountryBlur(true)}
              >
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={countryRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchCountries}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchCountries(event.target.value)}
                          />
                        </div>
                        <div className="dropdown-title">Countries found:</div>

                        <>
                          {filteredCountries.length > 0 ? (
                            <>
                              <ul>
                                {filteredCountries?.map((c: Country) => {
                                  return (
                                    <li key={`details-cat-${c.id}`}>
                                      <span
                                        onClick={() => {
                                          setCompany(
                                            (prevState) =>
                                              prevState && {
                                                ...prevState,
                                                registrationCountry: c,
                                              }
                                          );
                                        }}
                                        className={`font-hover ${
                                          c.id ===
                                          company.registrationCountry?.id
                                            ? "font-active"
                                            : ""
                                        }`}
                                      >
                                        {c.name}
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <>
                              <span className="font-xsmall">No item found</span>
                            </>
                          )}
                        </>
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="page" tabIndex={0}>
                        <span className="icon icon-arrow-down top-center"></span>
                        <input
                          disabled
                          type="text"
                          value={company.registrationCountry?.name}
                          className="label-input label-input-padding"
                        />
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Week’s starting day*</span>
                <span className="unauth-label-error">
                  <span className="error-span">
                    <span>
                      {company.weekDayStart ? (
                        <img src={successIcon} alt="success" />
                      ) : weekDayBlur ? (
                        <img src={failIcon} alt="fail" />
                      ) : (
                        <></>
                      )}
                    </span>

                    {!company.weekDayStart && weekDayBlur && (
                      <span id="nide">Is required.</span>
                    )}
                  </span>
                </span>
              </div>
              <div
                className="label-input-dropdown"
                onClick={() => {
                  setWeekDayBlur(true);
                }}
              >
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <>
                          <ul>
                            {weekdays?.map((item: WeekDays) => {
                              return (
                                <li key={`details-cat-${item.value}`}>
                                  <span
                                    onClick={() => {
                                      setCompany(
                                        (prevState) =>
                                          prevState && {
                                            ...prevState,
                                            weekDayStart: item.value,
                                          }
                                      );
                                    }}
                                    className={`font-hover ${
                                      item.value === company.weekDayStart
                                        ? "font-active"
                                        : ""
                                    }`}
                                  >
                                    {item.name}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="page">
                        <span className="icon icon-arrow-down top-center"></span>
                        <input
                          disabled={true}
                          type="text"
                          value={
                            weekdays.find(
                              (item) => item.value === company.weekDayStart
                            )?.name
                          }
                          className="label-input label-input-padding"
                        />
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isSuperAdmin && (
        <div className="card admin-actions">
          <h3 className="title">Administrational actions</h3>
          <div className="widget-multiple-form-row admin-actions mbot20">
            <div className="widget-form-row">
              <div className="action">
                <div className="info">
                  <span>Company activated</span>
                  <img id="company-activated" src={infoIcon} alt="Info Icon" />
                </div>
                <Switch
                  size="default"
                  checked={company.isActive}
                  onChange={(value: boolean) => {
                    setCompany(
                      (prevState) =>
                        prevState && { ...prevState, isActive: value }
                    );
                  }}
                />
              </div>
            </div>
            <div className="widget-form-row">
              {deleteAccess && company.id && (
                <Button
                  type="default"
                  onClick={openConfirmModal}
                  className="action action--danger"
                  icon={
                    <img
                      src={DeleteIcon}
                      alt="Delete"
                      className="delete-icon"
                    />
                  }
                >
                  Permanently delete company
                </Button>
              )}
            </div>
          </div>

          <div className="widget-multiple-form-row admin-actions mbot20">
            <div className="widget-form-row">
              <div className="action">
                <div className="info">
                  <span>Closed Group</span>
                  <img id="company-activated" src={infoIcon} alt="Info Icon" />
                </div>
                <Switch
                  size="default"
                  checked={company.groupSettings.isInGroup}
                  onChange={(value: boolean) => {
                    setCompany(
                      (prevState) =>
                        prevState && {
                          ...prevState,
                          groupSettings: {
                            ...prevState.groupSettings,
                            isInGroup: value,
                          },
                        }
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="widget-multiple-form-row admin-actions mbot20">
            <div className="widget-form-row">
              <div className="action">
                <div className="info">
                  <span>Allow order extraction information</span>
                  <img id="company-activated" src={infoIcon} alt="Info Icon" />
                </div>
                <Switch
                  size="default"
                  checked={company.settings.allowOrderCollection}
                  onChange={(value: boolean) => {
                    setCompany(
                      (prevState) =>
                        prevState && {
                          ...prevState,
                          settings: {
                            ...prevState.settings,
                            allowOrderCollection: value,
                          },
                        }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanySettings;
