import { Empty } from "antd";
import { useEffect, useRef } from "react";
import usePage from "../../hooks/usePage.hook";
import "./css/insight.scss";
import {
  InsightsCampaignDaily,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import PartnersOffers from "./widgets/partnersOffers/partnersOffers.component";
import CampaignDaily from "./widgets/campaignDaily/campaingDaily.component";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import DailySalesChart from "./widgets/dailySalesChart/dailySalesChart.component";
import PartnersSalesChart from "./widgets/partnersSalesChart/partnersSalesChart.component";
import PartnersClicksChart from "./widgets/partnersClicksChart/partnersClicksChart.component";
import DailyViewsChart from "./widgets/dailyViewsChart/dailyViewsChart.component";
import { PlacementStage } from "../../types/templates.interfaces";
import CampaignDailyPre from "./widgets/campaignDaily/campaingDaily.component-pre";
import PartnersOffersPre from "./widgets/partnersOffers/partnersOffers.component-pre";

interface Props {
  loading: boolean;
  partnersOffers: InsightsHostPartners | undefined;
  campaignDaily: InsightsCampaignDaily | undefined;
  placementType: PlacementStage | "all";
  campaignId?: string;
  companyId?: string;
  filters: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
}

const InsightWidgets = ({
  placementType,
  loading,
  partnersOffers,
  campaignDaily,
  campaignId,
  companyId,
  filters,
}: Props) => {
  const { setPage } = usePage();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="layout-wrapper" ref={wrapperRef}>
        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Daily Sales <InfoCircleOutlined id="guide-insights-dailysales" />
            </div>
            {campaignDaily ? (
              <DailySalesChart
                loading={loading}
                campaignDaily={campaignDaily}
              ></DailySalesChart>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Partner Breakdown{" "}
              <InfoCircleOutlined id="guide-insights-partnerbreakdown" />
            </div>
            {partnersOffers && partnersOffers.data?.length > 0 ? (
              placementType === PlacementStage.pre ? (
                <PartnersOffersPre
                  loading={loading}
                  placementStage={placementType}
                  partnersData={partnersOffers}
                  campaignId={campaignId}
                  companyId={companyId}
                  filters={filters}
                ></PartnersOffersPre>
              ) : (
                <PartnersOffers
                  loading={loading}
                  placementStage={placementType}
                  partnersData={partnersOffers}
                  campaignId={campaignId}
                  companyId={companyId}
                  filters={filters}
                ></PartnersOffers>
              )
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget-double">
          <div className="widget mbot20 mright20">
            <div className="home-widget">
              <div className="widget-title">
                Partner By Clicks{" "}
                <InfoCircleOutlined id="guide-insights-partnersbyclicks" />
              </div>
              {partnersOffers && partnersOffers.data?.length > 0 ? (
                <PartnersClicksChart
                  loading={loading}
                  placementStage={placementType}
                  partnersData={partnersOffers.data}
                ></PartnersClicksChart>
              ) : (
                <>
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No data"
                    />
                  )}
                </>
              )}
            </div>
          </div>

          <div className="widget mbot20">
            <div className="home-widget">
              <div className="widget-title">
                Partner By Sales{" "}
                <InfoCircleOutlined id="guide-insights-partnersbysales" />
              </div>
              {partnersOffers && partnersOffers.data?.length > 0 ? (
                <PartnersSalesChart
                  loading={loading}
                  placementStage={placementType}
                  partnersData={partnersOffers.data}
                ></PartnersSalesChart>
              ) : (
                <>
                  {loading ? (
                    <LoadingOutlined />
                  ) : (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No data"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Daily Totals{" "}
              <InfoCircleOutlined id="guide-insights-dailytotals" />
            </div>
            {campaignDaily ? (
              placementType === PlacementStage.pre ? (
                <CampaignDailyPre
                  loading={loading}
                  campaignDaily={campaignDaily}
                ></CampaignDailyPre>
              ) : (
                <CampaignDaily
                  loading={loading}
                  campaignDaily={campaignDaily}
                ></CampaignDaily>
              )
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className="widget mbot20">
          <div className="home-widget">
            <div className="widget-title">
              Daily Totals <InfoCircleOutlined id="guide-insights-dailyviews" />
            </div>
            {campaignDaily ? (
              <DailyViewsChart
                loading={loading}
                placementStage={placementType}
                campaignDaily={campaignDaily}
              ></DailyViewsChart>
            ) : (
              <>
                {loading ? (
                  <LoadingOutlined />
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No data"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightWidgets;
