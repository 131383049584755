import { useEffect, useState } from "react"

const useDebounce = (input: any, time: number) => {
    const [debounceValue, setDebounceValue] = useState<any | undefined>(input)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebounceValue(input);
        }, time);

        return () => clearTimeout(timeout)
    }, [input, time])


    return debounceValue
}

export default useDebounce