import { Dropdown, Typography } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings, PlacementSettings } from "../../../../../../types/campaign.interfaces";
import { TextSettings, TextTransform } from "../../../../../templates-previews/types/params.interfaces";
import { getNumberFromFontWeight, itemsFontFamily, itemsFontSize, itemsFontWeight, uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings, message?: string) => void,
    layoutDevice: LayoutDevices,
    placement: PlacementSettings,
    defaultTitle: string,
}

const PopupHeaderSettings = ({ updateSettings, layoutDevice, placement, defaultTitle }: Props) => {
    const [headerText, setHeaderText] = useState<string>(placement.promoMessage);
    const debounceValue = useDebounce(headerText, 500);

    const setNewSettings = (key: keyof TextSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...placement.templateSettings, [layoutDevice]: {
                ...placement.templateSettings[layoutDevice],
                popup: {
                    ...placement.templateSettings[layoutDevice].popup,
                    header: {
                        ...placement.templateSettings[layoutDevice].popup?.header,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }
    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        setNewSettings('fontFamily', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewSettings('fontSize', props.key);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewSettings('fontWeight', props.key);
    }

    const handleClickTextTransform = (textTransform: TextTransform) => {
        setNewSettings('textTransform', textTransform);
    }

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setHeaderText(value);
    }

    useEffect(() => {
        if (typeof debounceValue !== "undefined" ) {
            updateSettings(placement.templateSettings, debounceValue)
        }
        // eslint-disable-next-line
    }, [debounceValue])

    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-single-col">
                    <input
                        type="text"
                        className="default-input"
                        value={headerText}
                        placeholder={defaultTitle}
                        onChange={handleChangeText}
                    />
                </div>
            </div>
            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`popupHeaderText${uniqueKey}`}
                            value={placement.templateSettings[layoutDevice]?.popup.header.textColor || ''}
                            onChange={handleTextChange}
                        />
                        <label htmlFor={`popupHeaderText${uniqueKey}`}>
                            Text: {placement.templateSettings[layoutDevice]?.popup.header.textColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            defaultSelectedKeys: [placement.templateSettings[layoutDevice].popup.header.fontFamily],
                            onClick: handleChangeFontFamily
                        }} trigger={['click']}>
                            <Typography.Link>
                                {placement.templateSettings[layoutDevice]?.popup?.header.fontFamily}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            defaultSelectedKeys: [placement.templateSettings[layoutDevice]?.popup.header.fontSize],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                {placement.templateSettings[layoutDevice]?.popup.header.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontWeight,
                            selectable: true,
                            defaultSelectedKeys: [placement.templateSettings[layoutDevice]?.popup.header.fontWeight.toString()],
                            onClick: handleChangeFontWeight
                        }} trigger={['click']}>
                            <Typography.Link>
                                {getNumberFromFontWeight(placement.templateSettings[layoutDevice]?.popup.header.fontWeight.toString())}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <span onClick={() => handleClickTextTransform(TextTransform.uppercase)} className={`text-select ${placement.templateSettings[layoutDevice]?.popup.header.textTransform === TextTransform.uppercase ? 'active' : ''}`}>AB</span>
                        <span onClick={() => handleClickTextTransform(TextTransform.capitalize)} className={`text-select ${placement.templateSettings[layoutDevice]?.popup.header.textTransform === TextTransform.capitalize ? 'active' : ''}`}>Ab</span>
                    </div>
                </div>
            </div>
        </div >
    </>
}

export default PopupHeaderSettings