import { Slider } from "antd";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ButtonLocationSettingsT, LocationPosition } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutDevices } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ExpressButtonLocationSettings = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {


    const setNewSettings = (key: keyof ButtonLocationSettingsT, value: string | LocationPosition | number, reset: boolean) => {

        const location = {
            ...currentPlacement.templateSettings[layoutView].button?.location,
            [key]: value,
        }

        if (reset) {
            location.x = 0;
            location.y = 0;
        }

        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                button: {
                    ...currentPlacement.templateSettings[layoutView].button,
                    location
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        location
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    button: {
                        ...currentPlacement.templateSettings[layoutView].button,
                        location
                    }
                }
            }
        }


        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleChangeLocation = (location: LocationPosition) => {
        setNewSettings('location', location, true);
    }

    const handleChangeLocationX = (x: number) => {
        setNewSettings('x', x, false);
    }

    const handleChangeLocationY = (y: number) => {
        setNewSettings('y', y, false);
    }

    const getLimitsByLocation = (location?: LocationPosition): { minY: number, maxY: number, minX: number, maxX: number } => {
        let minY = 0;
        let maxY = 0;

        let minX = 0;
        let maxX = 0;

        switch (location) {
            case LocationPosition.topLeft:
                minY = 0;
                minX = 0;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.top:
                minY = 0;
                minX = -500;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.topRight:
                minY = 0;
                minX = -500;
                maxY = 500;
                maxX = 0;
                break;
            case LocationPosition.left:
                minY = -500;
                minX = 0;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.right:
                minY = -500;
                minX = -500;
                maxY = 500;
                maxX = 0;
                break;
            case LocationPosition.bottomLeft:
                minY = -500;
                minX = 0;
                maxY = 0;
                maxX = 500;
                break;
            case LocationPosition.bottom:
                minY = -500;
                minX = -500;
                maxY = 0;
                maxX = 500;
                break;
            case LocationPosition.bottomRight:
                minY = -500;
                minX = -500;
                maxY = 0;
                maxX = 0;
                break;
            default:
                break;
        }


        return { minY, maxY, minX, maxX };
    }

    return <>
        <div className="option-box">
            {/* <div className="option-label">
                Location
            </div> */}
            <div className="option-content">
                <div className="vertical-tabs">
                    <div className="vertical-tab-list">

                        <div>
                            <div className="font-xsmall font-color">X: {currentPlacement.templateSettings[layoutView].button.location?.x || 0} </div>
                            <Slider
                                min={getLimitsByLocation(currentPlacement.templateSettings[layoutView].button.location?.location).minX}
                                max={getLimitsByLocation(currentPlacement.templateSettings[layoutView].button.location?.location).maxX}
                                step={1}
                                className="min50"
                                onChange={handleChangeLocationX}
                                value={currentPlacement.templateSettings[layoutView].button.location?.x || 0}
                            />
                        </div>
                        <div>
                            <div className="font-xsmall font-color">Y: {currentPlacement.templateSettings[layoutView].button.location?.y || 0} </div>
                            <Slider
                                min={getLimitsByLocation(currentPlacement.templateSettings[layoutView].button.location?.location).minY}
                                max={getLimitsByLocation(currentPlacement.templateSettings[layoutView].button.location?.location).maxY}
                                step={1}
                                className="min50"
                                onChange={handleChangeLocationY}
                                value={currentPlacement.templateSettings[layoutView].button.location?.y || 0}
                            />
                        </div>

                    </div>
                    <div className="vertical-tab-content">

                        <div className="top-content">
                            <div className="desktop-screen">
                                <div className="desktop-placement">
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                        className={`placement-topLeft ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.topLeft ? 'active' : ''}`}></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.top)}
                                        className={`placement-top ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.top ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.left)}
                                        className={`placement-left ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.left ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                        className={`placement-topRight ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.topRight ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.right)}
                                        className={`placement-right ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.right ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                        className={`placement-bottomLeft ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                        className={`placement-bottom ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.bottom ? 'active' : ''}`}
                                    ></span>
                                    <span
                                        onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                        className={`placement-bottomRight ${currentPlacement.templateSettings[layoutView].button.location?.location === LocationPosition.bottomRight ? 'active' : ''}`}
                                    ></span>
                                </div>
                            </div>
                            <div className="desktop-tail">

                            </div>
                            <div className="desktop-legs">

                            </div>
                        </div>
                        {/* <div className="bottom-content">
                                    <div className="mobile-screen">
                                        <div className="mobile-placement">
                                            <span
                                                className={`placement-topLeft ${location === LocationPosition.topLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                            ></span>
                                            <span
                                                className={`placement-left ${location === LocationPosition.left ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.left)}
                                            ></span>
                                            <span
                                                className={`placement-top ${location === LocationPosition.top ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.top)}
                                            ></span>
                                            <span
                                                className={`placement-topRight ${location === LocationPosition.topRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                            ></span>
                                            <span
                                                className={`placement-right ${location === LocationPosition.right ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.right)}
                                            ></span>
                                            <span
                                                className={`placement-bottomLeft ${location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                            ></span>
                                            <span
                                                className={`placement-bottom ${location === LocationPosition.bottom ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                            ></span>
                                            <span
                                                className={`placement-bottomRight ${location === LocationPosition.bottomRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                            ></span>
                                        </div>
                                    </div>
                                </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ExpressButtonLocationSettings
