export interface Badge {
  name: string;
  backgroundColor: string;
  fontColor: string;
  isDefault: boolean;
  id: string;
}

export const emptyBadge: Badge = {
  name: "",
  backgroundColor: "",
  fontColor: "",
  isDefault: false,
  id: "",
};

export interface OfferBadge {
  id: string;
  name: string;
  useBorder: boolean;
  backgroundColor: string;
  fontColor: string;
  isDefault: boolean;
}

export const emptyOfferBadge: OfferBadge = {
  id: "",
  name: "",
  useBorder: false,
  backgroundColor: "",
  fontColor: "",
  isDefault: false,
};
