/* eslint-disable react-hooks/exhaustive-deps */
import "./Badges.scss";
import plusIconBlack from "./../../../assets/images/icons/plus-icon-black.svg";
import useAxios from "../../../hooks/useAxios.hook";
import { useEffect, useRef, useState } from "react";
import { Badge, emptyBadge } from "../../../types/customizations.interfaces";
import { useParams } from "react-router-dom";
import { message, Drawer, Button, Modal } from "antd";
import { NO_SPACE_EMPTY } from "../../../utility/regexp";
import failIcon from "../../../assets/images/icons/fail.svg";
import successIcon from "../../../assets/images/icons/success.svg";
import { uniqueKey } from "../../campaigns/const/layout-options.enum";
import { LoadingOutlined } from "@ant-design/icons";

const Badges = () => {
  const { myAxios } = useAxios();
  const { companyId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultBadges, setDefaultBadges] = useState<Badge[]>([]);
  const [companyBadges, setCompanyBadges] = useState<Badge[]>([]);
  const [badge, setBadge] = useState<Badge>(emptyBadge);
  const [open, setOpen] = useState(false);
  const [initialName, setIntialName] = useState<string>("");

  const badgeNameRef = useRef<HTMLInputElement>(null);

  const [validBadgeName, setValidBadgeName] = useState<boolean>(false);
  const [badgeNameBlur, setBadgeNameBlur] = useState<boolean>(false);

  useEffect(() => {
    getBages();
  }, []);

  useEffect(() => {
    const isValid = NO_SPACE_EMPTY.test(badge?.name);
    setValidBadgeName(isValid && badge.name.length > 0 && !isNameInArray());
  }, [badge?.name]);

  const isNameInArray = (): boolean => {
    const isEqualToInitial = companyBadges.some((item) =>
      item.name === initialName ? false : item.name === badge.name
    );

    return isEqualToInitial;
  };

  const onClose = () => {
    setOpen(false);
    setBadge(emptyBadge);
  };

  const onAdd = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "POST",
      url: `company/badge/${companyId}`,
      data: {
        name: badge.name,
        backgroundColor: badge.backgroundColor
          ? badge.backgroundColor
          : "#3ab78f",
        fontColor: badge.fontColor ? badge.fontColor : "#000000",
      },
    });

    if (response?.status === 200) {
      message.success("Badge was added successfully!", 1);
      setCompanyBadges((prevState) => [...prevState, response.data.result]);
    } else {
      message.error("Failed to create Badge!");
    }

    onClose();
    setLoading(false);
  };

  const onEdit = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "PUT",
      url: `company/badge/${badge.id}/${companyId}`,
      data: {
        name: badge.name,
        backgroundColor: badge.backgroundColor,
        fontColor: badge.fontColor,
      },
    });

    if (response?.status === 200) {
      const badges = companyBadges.map((item) =>
        item.id === badge.id ? { ...item, ...badge } : item
      );

      setCompanyBadges(badges);
      message.success("Badge was edited successfully!", 1);
    } else {
      message.error("Failed to edit Badge!");
    }

    onClose();
    setLoading(false);
  };

  const handleRemove = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "DELETE",
      url: `company/badge/${badge.id}/${companyId}`,
    });

    if (response?.status === 200) {
      const companyBadgesClone: Badge[] = structuredClone(companyBadges).filter(
        (i: Badge) => i.id !== badge.id
      );
      setCompanyBadges(companyBadgesClone);
      message.success("Badge deleted successfully!", 1);
    } else {
      message.error("Failed to delete Badge!");
    }

    onClose();
    setLoading(false);
  };

  const openConfirmModal = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this badge?",
      content: "The badge will automatically be removed from all your offers",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(),
    });
  };

  const getBages = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/badges/${companyId}?merge=true`,
    });

    if (response?.status) {
      setDefaultBadges(
        response?.data?.result?.filter((badge: Badge) => badge.isDefault)
      );
      setCompanyBadges(
        response?.data?.result.filter((badge: Badge) => !badge.isDefault)
      );
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  return (
    <>
      <div className="frame">
        <div className="text-wrapper">Preset badges</div>
        <p className="title">
          Preset badges are the default badges that you can use on an offer.
        </p>
        <div className="rectangle" />
        <div className="div-2">
          {defaultBadges.map((badge: Badge) => {
            return (
              <div
                key={badge.id}
                className="group"
                onClick={() => {
                  setOpen(true);
                  setBadge(badge);
                }}
              >
                <div
                  className="line"
                  style={{
                    backgroundColor: badge.backgroundColor,
                    color: badge.fontColor,
                  }}
                >
                  <div className="text-wrapper-2">{badge.name}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="rectangle-2" />
        <div className="rectangle-3" />
        <div className="rectangle-2" />
        <div className="text-wrapper-4">Custom badges</div>
        <p className="title">
          If you need more than the preset badges, you can create your own fully
          customisable badges.
        </p>
        <div className="rectangle" />
        <div className="div-2">
          {JSON.parse(
            localStorage.getItem("company") || ""
          ).userAccess.access.includes("brandwrite") && (
            <div className="line-wrapper">
              <div className="line-6">
                <img className="plus" alt="Plus" src={plusIconBlack} />
                <div className="text-wrapper-5" onClick={() => setOpen(true)}>
                  Create badge
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <LoadingOutlined />
          ) : (
            companyBadges.map((badge: Badge) => {
              return (
                <div
                  key={badge.id}
                  className="group"
                  onClick={() => {
                    setOpen(true);
                    setBadge(badge);
                    setIntialName(badge.name);
                  }}
                >
                  <div
                    className="line"
                    style={{
                      backgroundColor: badge.backgroundColor,
                      color: badge.fontColor,
                    }}
                  >
                    <div className="text-wrapper-2">{badge.name}</div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <Drawer
        title="Badge configurations"
        placement="right"
        onClose={onClose}
        open={open}
        width="520"
      >
        <div className="drawer-content">
          <p className="drawer-paragraph">
            {badge.isDefault
              ? "Preset is not editable."
              : "You can create your own custom badges, which you can enable on any of your offers."}
          </p>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Text*</span>
              <span className="unauth-label-error">
                <span>
                  {validBadgeName ? (
                    <img src={successIcon} alt="success" />
                  ) : badgeNameBlur ? (
                    <img src={failIcon} alt="fail" />
                  ) : (
                    <></>
                  )}
                </span>
                {!validBadgeName && badgeNameBlur && (
                  <span id="nide">
                    {!isNameInArray()
                      ? "Is Required."
                      : "Badge already exists!"}
                  </span>
                )}
              </span>
            </div>
            <div
              className={`
            widget-input ${
              badge.name && !validBadgeName ? "widget-input-invalid" : ""
            }`}
            >
              <input
                type="text"
                id="company-name"
                ref={badgeNameRef}
                name="company-name"
                disabled={badge.isDefault}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setBadge(
                    (prevState) =>
                      prevState && { ...prevState, name: e.target.value }
                  )
                }
                value={badge.name}
                onBlur={() => setBadgeNameBlur(true)}
                aria-invalid={validBadgeName ? "true" : "false"}
                aria-describedby="cnide"
                autoComplete="off"
                placeholder="e.g. Super deal"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="drawer-action">
              <div className="info">
                <span>Default background color</span>
              </div>
              <div className="drawer-action-color">
                <label className="hex" htmlFor={`backgroundColor${uniqueKey}`}>
                  {badge.backgroundColor || "#3ab78f"}
                </label>
                <input
                  disabled={badge.isDefault}
                  type="color"
                  className="box"
                  id={`backgroundColor${uniqueKey}`}
                  value={badge.backgroundColor || "#3ab78f"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBadge(
                      (prevState) =>
                        prevState && {
                          ...prevState,
                          backgroundColor: e.target.value,
                        }
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="widget-form-row">
            <div className="drawer-action">
              <div className="info">
                <span>Default text color</span>
              </div>
              <div className="drawer-action-color">
                <label className="hex" htmlFor={`backgroundColor${uniqueKey}`}>
                  {badge.fontColor || "#000000"}
                </label>
                <input
                  disabled={badge.isDefault}
                  type="color"
                  className="box"
                  id={`backgroundColor${uniqueKey}`}
                  value={badge.fontColor || "#000000"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBadge(
                      (prevState) =>
                        prevState && {
                          ...prevState,
                          fontColor: e.target.value,
                        }
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {!badge.isDefault && (
            <>
              {badge.id && (
                <div className="drawer-delete" onClick={openConfirmModal}>
                  Delete badge
                </div>
              )}
              <div className="widget-button mtop40">
                <Button className="cancel-btn mright10" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  type="primary"
                  disabled={!validBadgeName}
                  className="button-success success-btn"
                  onClick={() => (badge.id ? onEdit() : onAdd())}
                >
                  {loading && <LoadingOutlined />}Save
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Badges;
