import { InfoCircleOutlined } from "@ant-design/icons";
import { Empty, message } from "antd";
import { useEffect, useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import useExpress from "../../../hooks/useExpress.hook";
import { AggregatedDailyEvent, AggregatedEvent } from "../../../types/events.interfaces";
import { Pagination } from "../../../types/global.interfaces";
import { ExpressPage } from "../const/views";
import "../css/express.scss";
import { ExpressCommisionResponse } from "../types/express.types";
import OffersEventsWidget from "../../insight/widgets/retailerOffersEvents/retailerOffersEvents.component";
import RetailerChartEvents from "../../insight/widgets/retailerChartEvents/retailerChartEvents.component";

const ExpressInsights = () => {
  const { company, setExpressPage } = useExpress();

  const { myAxios } = useAxios();

  const [commision, setCommision] = useState<ExpressCommisionResponse[]>([]);

  const [loadingRetailerChart, setLoadingRetailerChart] = useState<boolean>(false);
  const [loadingRetailerOffers, setLoadingRetailerOffers] = useState<boolean>(false);
  const [retailerOffersEvent, setRetailerOffersEvent] = useState<AggregatedEvent>();
  const [retailerDailyOffersEvent, setRetailerDailyOffersEvent] = useState<AggregatedDailyEvent>();

  const [pagination] = useState<Pagination>({
    page: 1,
    pageSize: 20
  });

  const [filters] = useState({
    dateFilterType: "thisweek",
    fromDate: "",
    endDate: ""
  });


  const fetchAdvertiserOffers = async (companyId: string) => {
    setLoadingRetailerOffers(true);
    const { response } = await myAxios({ method: 'post', url: `insights/retailer/offers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setRetailerOffersEvent(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingRetailerOffers(false);
  }

  const fetchAdvertiserOffersDaily = async (companyId: string) => {
    setLoadingRetailerChart(true);
    const { response } = await myAxios({ method: 'post', url: `insights/retailer/campaignstotaldaily/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setRetailerDailyOffersEvent(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingRetailerChart(false);
  }

  // Set campaigns and offers toggle array
  useEffect(() => {
    if (company?.id) {
      fetchAdvertiserOffers(company.id);
      fetchAdvertiserOffersDaily(company.id);
    }
    // eslint-disable-next-line
  }, [pagination, company, filters]);

  const getCommisionPayDate = () => {

    // today's date
    const today = new Date();

    // Get next month's index(0 based)
    const nextMonth = today.getMonth() + 1;

    // Get year
    const year = today.getFullYear() + (nextMonth === 12 ? 1 : 0);

    // Get first day of the next month
    const firstDayOfNextMonth = new Date(year, nextMonth % 12, 1);

    return firstDayOfNextMonth.toDateString();;
  }


  const getInsightsRetailerCommisionById = async (id: string) => {
    const { response } = await myAxios({ method: 'POST', url: `insights/retailer/commision/${id}` });
    if (response?.data.status) {
      setCommision(response.data.result);
    } else {
      message.error('Getting commision data failed');
    }
  }

  useEffect(() => {
    document.title = "BrandSwap Express - Insights";
    setExpressPage(ExpressPage.insights);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    company?.id && getInsightsRetailerCommisionById(company.id);
    // eslint-disable-next-line
  }, [company?.id]);

  return <>
    <div className="d-grid-box mbot15">
      <div className="d-box widget widget-express">
        <div className="d-box-content">
          <div className="d-box-title">
            My earnings <InfoCircleOutlined id="guide-express-earnings-box" />
          </div>
          <div className="d-box-subtitle">
            Detailing of sources of income and dynamics of changes
          </div>
          <div className="d-box-table">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>
                      Deal Partner
                    </th>
                    <th>
                      Pending sales
                    </th>
                    <th>
                      Confirmed sales
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {commision?.map((item: ExpressCommisionResponse) => {
                    return <tr key={item._id}>
                      <td>
                        {item.advertiser || '-'}
                      </td>
                      <td>
                        {item.totalAmountPending} {item.currency}
                      </td>
                      <td>
                        {item.totalAmountApproved} {item.currency}
                      </td>
                    </tr>
                  })}
                  {(!commision || commision?.length === 0) &&
                    <tr>
                      <td className="text-center" colSpan={6}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                      </td>
                    </tr>
                  }

                </tbody>

              </table>
            </div>

            <ul className="d-box-list">
              <li className="success">
                Next payout date: <span>{getCommisionPayDate()}</span>
              </li>
              <li className="warning">
                You need to reach the <span>50 {company?.registrationCountry.currencySymbol || '$'}  threshold</span> to get paid
              </li>
            </ul>
            {/* Your earnings for the last month
                        <span className="d-box-big">$15,568</span> */}
          </div>
        </div>
        <div className="d-box-icon">
          <div className="d-icon-holder">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="14" width="5" height="26" rx="2" fill="#B2B7C3" />
              <rect x="7" y="8" width="5" height="18" rx="2" fill="#DCDCDC" />
              <rect x="21" y="11" width="5" height="15" rx="2" fill="#DCDCDC" />
              <rect y="15" width="5" height="11" rx="2" fill="#DCDCDC" />
            </svg>

          </div>
        </div>
      </div>

      <div className="d-box widget widget-express">
        <OffersEventsWidget loading={loadingRetailerOffers} events={retailerOffersEvent} />
      </div>

    </div>

    <div className="d-box widget widget-express">
      <RetailerChartEvents loading={loadingRetailerChart} events={retailerDailyOffersEvent} />
    </div>
  </>;
}

export default ExpressInsights;
