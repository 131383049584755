import { CopyOutlined } from "@ant-design/icons";
import { Dropdown, InputNumber, message, Slider, Typography } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ElementSettings } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
    campaignId: string,
}

const InlineElementSettings = ({ updateSettings, layoutDevice, settings, campaignId }: Props) => {
    const inlineHtml = `<div id="brandswap__${campaignId}"></div>`;

    const setNewSettings = (key: keyof ElementSettings, value: string | number | boolean) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                inline: {
                    ...settings[layoutDevice].inline,
                    element: {
                        ...settings[layoutDevice].inline?.element,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewSettings('attribute', value);
    }

    const handleChangeElementTypes = (props: DropdownMenuProps) => {
        setNewSettings('elementType', props.key);
    }

    const handleChangeUseExisting = (e: CheckboxChangeEvent) => {
        setNewSettings('existingElement', e.target.checked);
    }

    const handleChangeFullWidth = (e: CheckboxChangeEvent) => {
        setNewSettings('fullWidth', e.target.checked);
    }

    const handleChangeWidth = (value: number | null) => {
        setNewSettings('width', value || 0);
    }

    const handleChangeHeight = (value: number | null) => {
        setNewSettings('height', value || 0);
    }

    const elementTypes: ItemType[] = [
        {
            label: 'id',
            key: 'id'
        },
        {
            label: 'class',
            key: 'class'
        },
    ];


    const handleCopyHtml = () => {
        navigator.clipboard.writeText(inlineHtml);
        message.success('Html element is copied')
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="option-label">
                    <Checkbox className="checkbox-active" defaultChecked={settings[layoutDevice].inline.element.existingElement} onChange={handleChangeUseExisting}> <span className="font-xsmall font-color">   Use existing html element </span></Checkbox>
                </div>
            </div>

            {!settings[layoutDevice].inline.element.existingElement ? <>
                <div className="option-content mbot20">
                    <div className="option-two-col">
                        <div className="option-col option-full">
                            <textarea
                                disabled={true}
                                className="default-input grow minh100"
                                value={inlineHtml}
                            >

                            </textarea>
                        </div>
                        <div className="option-col option-fix">
                            <span className="font-xsmall font-hover copy-icon " onClick={() => handleCopyHtml()}>
                                Copy <CopyOutlined />
                            </span>
                        </div>
                    </div>
                </div>
            </>
                :
                <>

                    <div className="option-content">
                        <div className="option-two-col">
                            <div className="option-col option-fix">
                                <Dropdown menu={{
                                    items: elementTypes,
                                    selectable: true,
                                    defaultSelectedKeys: [settings[layoutDevice]?.inline.element.elementType],
                                    onClick: handleChangeElementTypes
                                }} trigger={['click']}>
                                    <Typography.Link>
                                        Element : {settings[layoutDevice]?.inline.element.elementType}
                                    </Typography.Link>
                                </Dropdown>
                            </div>
                            <div className="option-col option-full mleft10">
                                <input
                                    type="text"
                                    className="default-input"
                                    placeholder={`type ${settings[layoutDevice]?.inline.element.elementType}...`}
                                    value={settings[layoutDevice]?.inline.element.attribute || ''}
                                    onChange={handleChangeId}
                                />
                            </div>
                        </div>
                    </div>

                </>

            }
            {/* <div className="option-label">
                Html element ID
            </div>
            <div className="option-content">
                <input
                    type="text"
                    className="default-input"
                    value={settings[layoutDevice]?.buttonOptions?.inlineId || ''}
                    onChange={handleChangeId}
                />
            </div>

            <div className="option-col">
                <Dropdown menu={{
                    items: itemsOffers,
                    selectable: true,
                    defaultSelectedKeys: [settings[layoutDevice]?.inline.general.offers],
                    onClick: handleChangeOffers
                }} trigger={['click']}>
                    <Typography.Link>
                        Offers: {settings[layoutDevice]?.inline.general.offers}
                    </Typography.Link>
                </Dropdown>
            </div> */}

            <div className="option-content">
                <div className="option-two-col">

                    <div className="option-col">
                        <Dropdown trigger={['click']} dropdownRender={
                            () =>
                                <div className="dropdown min150">
                                    <div className="dropdown-title option-label">Set Width</div>
                                    <Checkbox className="checkbox-active" defaultChecked={settings[layoutDevice].inline.element.fullWidth} onChange={handleChangeFullWidth}>Full width</Checkbox>

                                    <div className="flex-center-space-between">
                                        <Slider
                                            min={250}
                                            max={2000}
                                            step={10}
                                            disabled={settings[layoutDevice]?.inline.element.fullWidth}
                                            className="min100"
                                            onChange={handleChangeWidth}
                                            value={typeof settings[layoutDevice]?.inline.element.width === 'number' ? settings[layoutDevice]?.inline.element.width : 250}
                                        />
                                        <InputNumber
                                            min={250}
                                            max={2000}
                                            disabled={settings[layoutDevice].inline.element.fullWidth}
                                            style={{ marginLeft: '16px' }}
                                            onChange={handleChangeWidth}
                                            value={typeof settings[layoutDevice]?.inline.element.width === 'number' ? settings[layoutDevice]?.inline.element.width : 250}
                                        />
                                    </div>
                                </div>
                        }>
                            <Typography.Link>
                                Width: {settings[layoutDevice].inline.element.fullWidth ? '100%' : settings[layoutDevice]?.inline.element.width || 250}
                            </Typography.Link>
                        </Dropdown>
                    </div>

                    <div className="option-col">
                        <Dropdown trigger={['click']} dropdownRender={
                            () =>
                                <div className="dropdown min150">
                                    <div className="dropdown-title">Set Height</div>
                                    <div className="flex-center-space-between">
                                        <Slider
                                            min={settings[layoutDevice]?.inline?.minimized ? 160 : 220}
                                            max={2000}
                                            step={10}
                                            className="min100"
                                            onChange={handleChangeHeight}
                                            value={typeof settings[layoutDevice].inline.element.height === 'number' ? settings[layoutDevice].inline.element.height : 220}
                                        />
                                        <InputNumber
                                            min={settings[layoutDevice]?.inline?.minimized ? 160 : 220}
                                            max={1000}
                                            step={10}
                                            style={{ marginLeft: '16px' }}
                                            onChange={handleChangeHeight}
                                            value={typeof settings[layoutDevice].inline.element.height === 'number' ? settings[layoutDevice].inline.element.height : 220}
                                        />
                                    </div>
                                </div>
                        }>
                            <Typography.Link>
                                Height: {settings[layoutDevice].inline.element.height || 250}
                            </Typography.Link>
                        </Dropdown>
                    </div>

                </div>
            </div>
        </div>
    </>
}

export default InlineElementSettings