import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { LayoutSettings, PlacementSettings } from "../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../const/views";
import ActionGeneralSettings from "../options/action/ActionGeneralSettings.component";
import ActionHeaderSettings from "../options/action/ActionHeaderSettings.component";
import ActionLocationSettings from "../options/action/ActionLocationSettings.component";
import ActionReadMoreSettings from "../options/action/ActionGetButtonSettings.component";
import ActionTextImagesSettings from "../options/action/ActionTextImagesSettings.component";
import ActionBehaviourSettings from "../options/action/ActionBehaviourSettings.component";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    currentPlacement: PlacementSettings,
    defaultTitle: string,
}

const ActionView = ({ updateSettings, currentPlacement, layoutDevice, defaultTitle }: Props) => {

    return <>
        <div className="settings-options-list">
            <Collapse defaultActiveKey={['']} accordion>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Location <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="location">
                    <div className="settings-option">
                        <ActionLocationSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Behaviour <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="behaviour">
                    <div className="settings-option">
                        <ActionBehaviourSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="general">
                    <div className="settings-option">
                        <ActionGeneralSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Header <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="header">
                    <div className="settings-option">
                        <ActionHeaderSettings placement={currentPlacement} defaultTitle={defaultTitle} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Text & images <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="text">
                    <div className="settings-option">
                        <ActionTextImagesSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Image representation <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="image">
                    <div className="settings-option">
                        <ActionImageRepresentationSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}

                {!currentPlacement.displayOnly &&
                    <CollapsePanel
                        showArrow={false}
                        header={
                            <div className="option-title">
                                Get offer <span className="icon icon-collapse-mix"></span>
                            </div>
                        }
                        key="readmore">
                        <div className="settings-option">
                            <ActionReadMoreSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                        </div>
                    </CollapsePanel>
                }
            </Collapse>
        </div>
    </>
}

export default ActionView