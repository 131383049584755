import { Dropdown, Slider, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { GetButtonSettings, TextTransform } from "../../../../../templates-previews/types/params.interfaces";
import { getNumberFromFontWeight, itemsFontFamily, itemsFontSize, itemsFontWeight, uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
}

const ActionGetButtonSettings = ({ updateSettings, layoutDevice, settings }: Props) => {

    const setNewSettings = (key: keyof GetButtonSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                action: {
                    ...settings[layoutDevice].action,
                    getButton: {
                        ...settings[layoutDevice].action?.getButton,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleClickTextTransform = (textTransform: TextTransform) => {
        setNewSettings('textTransform', textTransform);
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('borderColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }

    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        setNewSettings('fontFamily', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewSettings('fontSize', props.key);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewSettings('fontWeight', props.key);
    }

    const handleChangeRounding = (value: number) => {
        setNewSettings('rounding', value);
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`actionGetBg${uniqueKey}`}
                            value={settings[layoutDevice]?.action.getButton.backgroundColor || '#ffffff'}
                            onChange={handleBgChange}
                        />
                        <label htmlFor={`actionGetBg${uniqueKey}`}>
                            Background: {settings[layoutDevice]?.action.getButton.backgroundColor || '#ffffff'}
                        </label>
                    </div>

                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonBorder${uniqueKey}`}
                            value={settings[layoutDevice]?.action?.getButton?.borderColor || settings[layoutDevice]?.action?.getButton?.backgroundColor}
                            onChange={handleBorderChange}
                        />
                        <label htmlFor={`buttonBorder${uniqueKey}`}>
                            Border: {settings[layoutDevice]?.action?.getButton?.borderColor || settings[layoutDevice]?.action?.getButton?.backgroundColor}
                        </label>
                    </div>

                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`popupGetText${uniqueKey}`}
                            value={settings[layoutDevice]?.popup.getButton.textColor || ''}
                            onChange={handleTextChange}
                        />
                        <label htmlFor={`popupGetText${uniqueKey}`}>
                            Text: {settings[layoutDevice]?.popup.getButton.textColor}
                        </label>
                    </div>
                    <div className="option-col">
                        <span onClick={() => handleClickTextTransform(TextTransform.uppercase)} className={`text-select ${settings[layoutDevice]?.action.getButton?.textTransform === TextTransform.uppercase ? 'active' : ''}`}>AB</span>
                        <span onClick={() => handleClickTextTransform(TextTransform.capitalize)} className={`text-select ${settings[layoutDevice]?.action.getButton?.textTransform === TextTransform.capitalize ? 'active' : ''}`}>Ab</span>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice].action.getButton.fontFamily || 'Poppins'],
                            onClick: handleChangeFontFamily
                        }} trigger={['click']}>
                            <Typography.Link>
                                {settings[layoutDevice]?.action.getButton.fontFamily || 'Poppins'}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            disabled: settings[layoutDevice]?.action?.minimized || false,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.action.getButton.fontSize],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                Size: {settings[layoutDevice]?.action.getButton.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontWeight,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.action.getButton.fontWeight?.toString()],
                            onClick: handleChangeFontWeight
                        }} trigger={['click']}>
                            <Typography.Link>
                                {getNumberFromFontWeight(settings[layoutDevice]?.action.getButton.fontWeight?.toString())}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown trigger={['click']} dropdownRender={
                            () =>
                                <div className="dropdown min150">
                                    <div className="dropdown-title option-label">Set Rounding</div>
                                    <div className="flex-center-space-between">
                                        <Slider
                                            min={0}
                                            max={100}
                                            className="min100"
                                            onChange={handleChangeRounding}
                                            value={settings[layoutDevice]?.action.getButton.rounding || 0}
                                        />
                                    </div>
                                </div>
                        }>
                            <Typography.Link>
                                Rounding: {settings[layoutDevice]?.action.getButton.rounding || 0}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </div >
    </>
}

export default ActionGetButtonSettings