import { Dropdown, InputNumber, Slider, Typography } from "antd";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ButtonSettings, ButtonShapes, ButtonSizesSettings } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
    displayOnly: boolean,
}

const ButtonShapeSizeSettings = ({ updateSettings, layoutDevice, settings, displayOnly }: Props) => {

    const setNewSettings = (buttonSettings: ButtonSettings) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                button: buttonSettings
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeButtonShape = (shape: ButtonShapes) => {
        var newSettings: ButtonSettings = {
            ...settings[layoutDevice].button,
        }
        switch (shape) {
            case ButtonShapes.roundingAll:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 12,
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                    },
                    sizes: {
                        ...newSettings.sizes,
                        w: 200,
                        h: 40
                    },
                    shape
                }
                break;

            case ButtonShapes.roundingBottom:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                    },
                    sizes: {
                        ...newSettings.sizes,
                        w: 200,
                        h: 40
                    },
                    shape
                }
                break;

            case ButtonShapes.roundingTop:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 12,
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                    sizes: {
                        ...newSettings.sizes,
                        w: 200,
                        h: 40
                    },
                    shape
                }
                break;
            case ButtonShapes.square:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                    sizes: {
                        ...newSettings.sizes,
                        w: 200,
                        h: 40
                    },
                    shape
                }
                break;
            case ButtonShapes.circle:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        circleSize: 100,
                    },
                    shape
                }
                break;
            case ButtonShapes.squareImage:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 6,
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                    },
                    shape,
                    sizes: {
                        ...newSettings.sizes,
                        w: 100,
                        h: 200
                    }
                }

                break;
            case ButtonShapes.squareImageLock:
                newSettings = {
                    ...newSettings,
                    style: {
                        ...newSettings.style,
                        borderTopRightRadius: 6,
                        borderTopLeftRadius: 6,
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                    },
                    shape,
                    sizes: {
                        ...newSettings.sizes,
                        w: 100,
                        h: 200
                    }
                }

                break;
            default:
                break;
        }

        setNewSettings(newSettings);
    }

    const setNewSizeSettings = (key: keyof ButtonSizesSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                button: {
                    ...settings[layoutDevice].button,
                    sizes: {
                        ...settings[layoutDevice].button?.sizes,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeSizeWidth = (value: number | null) => {
        setNewSizeSettings('w', value || 0);
    }

    const handleChangeSizeHeight = (value: number | null) => {
        setNewSizeSettings('h', value || 0);
    }


    const handleChangeCircleSize = (value: number | null) => {
        value && setNewSizeSettings('circle', value);
    }

    return <>
        <div className="option-box">
            <div className="option-two-col">

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.roundingAll ? 'active' : ''}`}>
                        <div className="button-shape roundingAll" onClick={() => handleChangeButtonShape(ButtonShapes.roundingAll)}>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.square ? 'active' : ''}`}>
                        <div className="button-shape square active" onClick={() => handleChangeButtonShape(ButtonShapes.square)}>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="option-two-col">

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.roundingBottom ? 'active' : ''}`}>
                        <div className="button-shape roundingBottom" onClick={() => handleChangeButtonShape(ButtonShapes.roundingBottom)}>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.roundingTop ? 'active' : ''}`}>
                        <div className="button-shape roundingTop" onClick={() => handleChangeButtonShape(ButtonShapes.roundingTop)}>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div className="option-two-col">

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.squareImage ? 'active' : ''}`}>
                        <div className="button-shape squareImage" onClick={() => handleChangeButtonShape(ButtonShapes.squareImage)}>
                            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41.5 23.5V46H5.5V23.5M23.5 46V12.25M23.5 12.25H13.375C11.8832 12.25 10.4524 11.6574 9.39752 10.6025C8.34263 9.54758 7.75 8.11684 7.75 6.625C7.75 5.13316 8.34263 3.70242 9.39752 2.64752C10.4524 1.59263 11.8832 1 13.375 1C21.25 1 23.5 12.25 23.5 12.25ZM23.5 12.25H33.625C35.1168 12.25 36.5476 11.6574 37.6025 10.6025C38.6574 9.54758 39.25 8.11684 39.25 6.625C39.25 5.13316 38.6574 3.70242 37.6025 2.64752C36.5476 1.59263 35.1168 1 33.625 1C25.75 1 23.5 12.25 23.5 12.25ZM1 12.25H46V23.5H1V12.25Z" stroke="#292426" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.squareImageLock ? 'active' : ''}`}>
                        <div className="button-shape squareImageLock" onClick={() => handleChangeButtonShape(ButtonShapes.squareImageLock)}>
                            {displayOnly ?
                                <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z" stroke="#292426" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                :
                                <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z" stroke="#292426" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            }
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>

            </div>



            <div className="option-two-col">

                <div className="option-col">
                    <div className={`button-shape-wrapper ${settings[layoutDevice]?.button?.shape === ButtonShapes.circle ? 'active' : ''}`}>
                        <div className="button-shape circle" onClick={() => handleChangeButtonShape(ButtonShapes.circle)}>
                            <span className="max-2lines">{settings[layoutDevice]?.button?.text}</span>
                        </div>
                    </div>
                </div>
            </div>


            {settings[layoutDevice]?.button?.shape === ButtonShapes.circle ?

                <div className="option-box">
                    <div className="option-content">

                        <div className="option-two-col">
                            <div className="option-col">
                                <Dropdown trigger={['click']} dropdownRender={
                                    () =>
                                        <div className="dropdown min150">
                                            <div className="dropdown-title option-label">Set Size</div>
                                            <div className="flex-center-space-between">
                                                <Slider
                                                    min={50}
                                                    max={500}
                                                    step={10}
                                                    className="min100"
                                                    onChange={handleChangeCircleSize}
                                                    value={settings[layoutDevice]?.button.sizes.circle || 50}
                                                />
                                                <InputNumber
                                                    min={50}
                                                    max={500}
                                                    style={{ marginLeft: '16px' }}
                                                    onChange={handleChangeCircleSize}
                                                    value={settings[layoutDevice]?.button.sizes.circle || 50}
                                                />
                                            </div>
                                        </div>
                                }>
                                    <Typography.Link>
                                        <div className="option-label">
                                            Size: {settings[layoutDevice]?.button.sizes.circle || 50}
                                        </div>
                                    </Typography.Link>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                :

                <div className="option-box">

                    <div className="option-content">
                        <div className="option-two-col">

                            <div className="option-col">
                                <Dropdown trigger={['click']} dropdownRender={
                                    () =>
                                        <div className="dropdown min150">
                                            <div className="dropdown-title option-label">Set Width</div>

                                            <div className="flex-center-space-between">
                                                <Slider
                                                    min={50}
                                                    max={500}
                                                    step={5}
                                                    className="min100"
                                                    onChange={handleChangeSizeWidth}
                                                    value={settings[layoutDevice].button?.sizes.w || 0}
                                                />
                                            </div>
                                        </div>
                                }>
                                    <Typography.Link>
                                        <div className="option-label">
                                            Width: {settings[layoutDevice].button?.sizes.w || 0}
                                        </div>
                                    </Typography.Link>
                                </Dropdown>
                            </div>

                            <div className="option-col">
                                <Dropdown trigger={['click']} dropdownRender={
                                    () =>
                                        <div className="dropdown min150">
                                            <div className="dropdown-title option-label">Set Height</div>

                                            <div className="flex-center-space-between">
                                                <Slider
                                                    min={20}
                                                    max={500}
                                                    step={5}
                                                    className="min100"
                                                    onChange={handleChangeSizeHeight}
                                                    value={settings[layoutDevice].button?.sizes.h || 0}
                                                />
                                            </div>
                                        </div>
                                }>
                                    <Typography.Link>
                                        <div className="option-label">
                                            Height: {settings[layoutDevice].button?.sizes.h || 0}
                                        </div>
                                    </Typography.Link>
                                </Dropdown>
                            </div>

                        </div>
                    </div>
                </div>
            }


        </div>

    </>
}

export default ButtonShapeSizeSettings
