import { Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { PWD_REGEX } from "../../utility/regexp";
import failIcon from "./../../assets/images/icons/fail.svg";
import successIcon from "./../../assets/images/icons/success.svg";
import { VALIDATION } from "../../utility/enums/validation.enums";
import { useNavigate, useParams } from "react-router-dom";
import { UserChangePassword } from "../../types/user.interfaces";
import { ApiErrorMessages } from "../../types/response.interfaces";
import useAxios from "../../hooks/useAxios.hook";
import Footer from "../footer/footer.component";

const ChangePassword = () => {
  const navigate = useNavigate();
  const { response, axiosFetch } = useAxios();
  const userRef = useRef<HTMLInputElement>(null);
  const { paramToken } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<undefined | string>();
  const [password, setPassword] = useState<string>("");
  const [validPassword, setValidPassword] = useState<boolean>(false);

  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [validRepeatPassword, setValidRepeatPassword] =
    useState<boolean>(false);

  // Focus user to the first input
  useEffect(() => {
    userRef?.current?.focus();
  }, [])

  // Validate password and repeat password
  useEffect(() => {
    const isValid = PWD_REGEX.test(password);
    setValidPassword(isValid);
    const isRepeatPassword = password === repeatPassword;
    setValidRepeatPassword(isRepeatPassword);
  }, [password, repeatPassword]);


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true)
    if (validPassword && validRepeatPassword && paramToken) {

      const data: UserChangePassword = {
        password: password
      }
      await axiosFetch({ method: 'post', url: `/user/changepassword/${paramToken}`, data });

    } else {
      setErrorMessage("Form is not valid!");
    }

    setLoading(false);
  }

  // Handel response of forgot password
  useEffect(() => {
    if (response?.status) {
      message.success({ content: 'Password has been changed successfully' });
      navigate('/login');
    } else {
      response?.result?.length > 0 && response?.result.forEach((value: ApiErrorMessages) => {
        setErrorMessage(value.message);
      });
    }
    // eslint-disable-next-line 
  }, [response])

  return (
    <div className="unauth-container-wrapper scroll-dark  unauth-login">
      <div className="unauth-container">
        <div className="unauth-content">

          <div className="widget unauth-widget">
            <form className="widget-form small-form forgot-password" onSubmit={handleSubmit}>

              <h3 className="widget-form-title">Change your password</h3>
              <div className="widget-form-description">
                <span>Enter your secured password</span>
              </div>

              <div className="widget-form-row">
                <div className="unauth-label">
                  <span>Password</span>
                  {password.length > 0 && (
                    <span className="unauth-label-error">
                      <span>
                        <img
                          src={validPassword ? successIcon : failIcon}
                          alt={validPassword ? "success" : "fail"}
                        />
                      </span>
                    </span>
                  )}
                </div>
                <div
                  className={`
            widget-input unauth-password-input ${password && !validPassword ? "widget-input-invalid" : ""
                    }`}
                >
                  <input
                    type="password"
                    id="pwd"
                    name="unauth-pwd"
                    ref={userRef}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    aria-invalid={validPassword ? "true" : "false"}
                    aria-describedby="pide"
                    autoComplete="off"
                  />
                  <p
                    id="pide"
                    className={`error-line ${password && !validPassword
                      ? "error-show"
                      : "error-hide"
                      }`}
                  >
                    {VALIDATION.passwordValid}
                  </p>
                </div>
              </div>

              <div className="widget-form-row">
                <div className="unauth-label">
                  <span>Repeat password</span>
                  {repeatPassword.length > 0 && (
                    <span className="unauth-label-error">
                      <span>
                        <img
                          src={validRepeatPassword ? successIcon : failIcon}
                          alt={validRepeatPassword ? "success" : "fail"}
                        />
                      </span>
                    </span>
                  )}
                </div>
                <div
                  className={`
            widget-input ${repeatPassword && !validRepeatPassword ? "widget-input-invalid" : ""
                    }`}
                >
                  <input
                    type="password"
                    id="repeatpwd"
                    name="unauth-repeat-pwd"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRepeatPassword(e.target.value)
                    }
                    aria-invalid={validRepeatPassword ? "true" : "false"}
                    aria-describedby="rpide"
                  />
                  <p
                    id="rpide"
                    className={`error-line ${repeatPassword && !validRepeatPassword
                      ? "error-show"
                      : "error-hide"
                      }`}
                  >
                    {VALIDATION.sameAsPrevious}
                  </p>
                </div>
              </div>


              {errorMessage &&
                <div className="widget-form-error">
                  <p className="error-show error-line">
                    {errorMessage}
                  </p>
                </div>
              }

              <div className="widget-form-actions unauth-button">
                <div className="widget-form-button">
                  <Button
                    type="default"
                    htmlType="submit"
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={!validPassword || !validRepeatPassword}
                    className="button-success">Change password</Button>
                </div>
              </div>
            </form>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ChangePassword;
