import { Select } from "antd"
import { Dispatch, useMemo } from "react"
import { CompanyEmailTemplate } from "../../../types/company.interfaces"
import { SelectOption } from "../../../types/global.interfaces"
import { OfferCreate } from "../../../types/offer.interfaces"
import { DelayType, RedeemBy } from "../../../utility/enums/offer.enums"

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>
    emailTemplates: CompanyEmailTemplate[],
}

const CreateOfferRedemtion = ({ offer, setOffer, emailTemplates }: Props) => {

    const reedemByTypes: SelectOption[] = [
        {
            label: 'Web & Email',
            value: RedeemBy.webemail
        },
        {
            label: 'Email Only',
            value: RedeemBy.email
        },
    ];

    const delayTypes: SelectOption[] = [
        {
            label: 'None',
            value: DelayType.none
        },
        {
            label: 'Minutes',
            value: DelayType.minutes
        },
        {
            label: 'Days',
            value: DelayType.days
        },
        {
            label: 'Months',
            value: DelayType.months
        }
    ];

    const emailTemplateList: SelectOption[] = useMemo(() => {
        const _list = emailTemplates.map((value: CompanyEmailTemplate) => {
            return {
                label: value.emailTemplateName,
                value: value.emailTemplateId
            }
        });
        _list.unshift({ label: 'Brandswap email', value: '' });
        return _list;
    }, [emailTemplates]);

    const handleChangeRedeemBy = (value: RedeemBy) => {
        setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, redeemBy: value } }))
    }

    const handleChangeDelayType = (value: DelayType) => {
        setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, delayType: value } } }))
    }

    return <>
        <div className="widget-header">

            <div className="widget-icon-box mtop5">
                <span className="icon icon-settings"></span>
            </div>
            <div className="grow mleft10">
                <h3 className="widget-s-title">
                    Offer redemption
                </h3>

                <div className="widget-form-description">
                    Settings fields for offer redemtion
                </div>
            </div>
        </div>

        <div className="widget-content">
            <div className="widget-icon-content">

                <div className="label-input-wrapper">
                    <label htmlFor="page">
                        <span className="label-input-name">Redeem by</span>
                        <Select
                            suffixIcon={<span className="icon icon-arrow-down"></span>}
                            className="label-select"
                            value={offer?.offerSettings?.redeemBy || 'Webmail'}
                            onChange={handleChangeRedeemBy}
                            options={reedemByTypes}
                        >
                        </Select>
                    </label>
                </div>

                {offer.offerSettings?.redeemBy === RedeemBy.email &&
                    <>
                        <div className="label-input-wrapper">
                            <label htmlFor="page">
                                <span className="label-input-name">Delay type</span>
                                <Select
                                    suffixIcon={<span className="icon icon-arrow-down"></span>}
                                    className="label-select"
                                    value={offer?.offerSettings?.delaySetting?.delayType || 'None'}
                                    onChange={handleChangeDelayType}
                                    options={delayTypes}
                                >
                                </Select>
                            </label>
                        </div>

                        {offer.offerSettings?.delaySetting?.delayType !== DelayType.none &&
                            <div className="label-input-wrapper">
                                <label htmlFor="Name">
                                    <span className="label-input-name">Delay value</span>
                                    <input type="number" min="0" className="label-input" step="1" value={offer.offerSettings?.delaySetting?.delayValue || 0} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => prevState && ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, delayValue: (e.target.value).toString() } } }))} />
                                    {offer.offerSettings?.redeemBy === RedeemBy.email && offer.offerSettings?.delaySetting?.delayValue === '0' &&
                                        <span className="label-error">Must be greater than 0</span>
                                    }
                                </label>
                            </div>
                        }
                    </>
                }

                <div className="label-input-wrapper">
                    <label htmlFor="page">
                        <span className="label-input-name">Email template</span>
                        <Select
                            suffixIcon={<span className="icon icon-arrow-down"></span>}
                            className="label-select"
                            value={offer?.offerSettings?.delaySetting?.emailTemplateId || ''}
                            onChange={(id: string) => {
                                setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, emailTemplateId: id } } }))
                            }}
                            options={emailTemplateList}
                        >
                        </Select>
                    </label>
                </div>
            </div>
        </div>
    </>
}

export default CreateOfferRedemtion