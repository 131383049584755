import { Button, Empty, Input, Select, Switch } from "antd";
import { BaseOptionType } from "antd/lib/select";
import React, { useEffect, useMemo, useState } from "react";
import useAuth from "../../../../../hooks/useAuth.hook";
import useAxios from "../../../../../hooks/useAxios.hook";
import { CompanyDataLayerKey } from "../../../../../types/company.interfaces";
import { Offer, OfferRelevance, OfferRule, OfferRules } from "../../../../../types/offer.interfaces";
import { conditionOperatorsAmountToLabel, conditionOperatorsTextToLabel, conditionSearchTypeToLabel, CONDITION_LOGICAL_OPERATORS, CONDITION_OPERATORS_AMOUNT, CONDITION_OPERATORS_TEXT, CONDITION_SEARCH_LOCATION, CONDITION_SEARCH_TYPE } from "../../../../../utility/enums/offer.enums";

interface Props {
    setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    offer: Offer,
    selectedOffers: Offer[],
    setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>,
}

const emptyRule: OfferRule = {
    searchType: CONDITION_SEARCH_TYPE.amount,
    operator: CONDITION_OPERATORS_AMOUNT.greaterThanorEqual,
    targetValue: '',
    location: CONDITION_SEARCH_LOCATION.none,
    locationValue: ''
}

const emptyRulesList: OfferRules = {
    logicalOperator: CONDITION_LOGICAL_OPERATORS.and,
    rules: [emptyRule]
}

const emptyAppearance: OfferRelevance = {
    showIfFalse: false,
    slot: 'current',
    rules: []
}

const OfferConditions = ({ setConditionModalOpen, offer, setSelectedOffers, selectedOffers }: Props) => {
    const { response, axiosFetch, myAxios } = useAxios();
    const { company } = useAuth();
    const [relevance, setRelevance] = useState<OfferRelevance>(offer?.appearanceRules?.relevance ? offer.appearanceRules.relevance : emptyAppearance);
    const [companyDataLayerKeys, setCompanyDataLayer] = useState<CompanyDataLayerKey[]>();

    useEffect(() => {
        const getCompanyById = async (id: string) => {
            const { response } = await myAxios({ method: 'GET', url: `company/${id}` });
            setCompanyDataLayer(response?.data.settings.dataLayer.keys);
        }

        company?.company_Id && getCompanyById(company.company_Id);
        // eslint-disable-next-line
    }, [company])

    const handleChange = (index: number, value: CONDITION_SEARCH_LOCATION | CONDITION_SEARCH_TYPE | CONDITION_OPERATORS_AMOUNT | CONDITION_OPERATORS_TEXT | CONDITION_LOGICAL_OPERATORS | string, type: keyof OfferRule) => {
        const ruleValue = relevance.rules[0].rules[index];
        var _conditionRules: OfferRule = {
            ...ruleValue,
            operator: type === "searchType" ? value.toLowerCase() === CONDITION_SEARCH_TYPE.amount.toLowerCase() ? CONDITION_OPERATORS_AMOUNT.equal : CONDITION_OPERATORS_TEXT.contains : ruleValue.operator,
            [type]: value
        };

        const newRules = relevance.rules[0].rules.map((value: OfferRule, i: number) => i === index ? _conditionRules : value);

        const _newConditions = relevance.rules.map((_val: OfferRules) => {
            return {
                logicalOperator: _val.logicalOperator,
                rules: newRules
            }
        });

        setRelevance(prevSatet => ({ ...prevSatet, rules: _newConditions }));
    }

    const handleAddCondition = () => {
        if (relevance.rules.length > 0 && relevance.rules[0]?.rules?.length > 0) {
            const _conditions: OfferRules[] = relevance.rules.map((_val: OfferRules) => {
                const _newRules = [..._val.rules, emptyRule];
                return {
                    logicalOperator: _val.logicalOperator,
                    rules: _newRules
                }
            })
            setRelevance(prevSatet => ({ ...prevSatet, rules: _conditions }));
        } else {
            setRelevance({ ...emptyAppearance, rules: [emptyRulesList] });
        }
    }

    const hanldeRemoveCondition = (index: number) => {
        if (relevance.rules[0].rules.length > 1) {
            const _rules = relevance.rules[0].rules.filter((val, i) => i !== index);
            const _conditions: OfferRules[] = relevance.rules.map((_val: OfferRules) => {
                return {
                    logicalOperator: _val.logicalOperator,
                    rules: relevance.rules[0].rules.length > 1 ? _rules : [emptyRule]
                }
            });

            setRelevance(prevSatet => ({ ...prevSatet, rules: _conditions }));
        } else {
            setRelevance(prevSatet => ({ ...prevSatet, rules: [] }));
        }
    }

    const getObjectKeysToArrayList = (_data: {}) => {
        let arr = [];
        for (const [key, value] of Object.entries(_data)) {
            arr.push({ key: value, value: key })
        }
        return arr;
    }

    const operatorsAmountList = useMemo(() => {
        const _data = response?.result?.amountOperators;
        return _data ? getObjectKeysToArrayList(_data) : []
    }, [response]);

    const operatorsTextList = useMemo(() => {
        const _data = response?.result?.textOperators;
        return _data ? getObjectKeysToArrayList(_data) : []
    }, [response]);

    const ruleSearchLocationsList = useMemo(() => {
        const _data = response?.result?.ruleSearchLocations;
        const _dataArray = _data ? getObjectKeysToArrayList(_data) : [];
        const dataLayerOptions = companyDataLayerKeys?.filter((value: CompanyDataLayerKey) => value.allowFilters).map((value: CompanyDataLayerKey) => ({ key: value.key, value: value.name })) || [];
        const mergedData = [..._dataArray, ...dataLayerOptions];
        return mergedData
    }, [response, companyDataLayerKeys]);

    const ruleSearchTypeList = useMemo(() => {
        const _data = response?.result?.ruleSearchType;
        return _data ? getObjectKeysToArrayList(_data) : []
    }, [response]);

    const getRuleLocationValue = (key: string) => {
        const value = ruleSearchLocationsList.find(value => value.key === key)?.value;
        return value;
    }

    const offerSlotConditions = useMemo(() => {
        const items = selectedOffers.map((_o: Offer, index: number) => {
            return {
                value: (index + 1).toString(),
                label: (index + 1).toString()
            }
        }
        );

        // offer.media?.featured?.images?.length > 0 &&
        //     items.unshift({
        //         value: 'featured',
        //         label: 'Featured'
        //     });

        items.unshift({
            value: 'current',
            label: 'Current'
        });

        return items;

    }, [selectedOffers, offer]);

    const offerShowConditions = [
        {
            value: false,
            label: 'Don`t show the offer',
        },
        {
            value: true,
            label: 'Show the offer in its current slot',
        }
    ]

    const handleLogicalCondition = (checked: boolean) => {
        const _conditions: OfferRules[] = relevance.rules.map((value: OfferRules) => {
            return {
                logicalOperator: !checked ? CONDITION_LOGICAL_OPERATORS.and : CONDITION_LOGICAL_OPERATORS.or,
                rules: value.rules
            }
        });

        setRelevance(prevSatet => ({ ...prevSatet, rules: _conditions }));

    }

    const handleUpdate = () => {
        setSelectedOffers((prevState: Offer[]) => prevState.map((o: Offer) => o.id === offer.id ? { ...offer, appearanceRules: { ...offer.appearanceRules, relevance: relevance } } : o));
        setConditionModalOpen(false);
    }

    const fetchRulesOptions = async () => {
        await axiosFetch({ method: 'get', url: `support/ruleoperators` });
    }

    useEffect(() => {
        fetchRulesOptions();
        // eslint-disable-next-line
    }, []);

    const hanldeValidateRules = (): boolean => {
        var disabled: boolean = false;
        relevance.rules.forEach((value: OfferRules) => {
            value.rules.forEach((offerRule: OfferRule) => {
                if (
                    !offerRule.targetValue ||
                    (offerRule.location.toLowerCase() === CONDITION_SEARCH_LOCATION.other.toLowerCase() && !offerRule.locationValue)
                ) {
                    disabled = true;

                }
            });
        });

        return disabled;
    }

    return <>
        <div className="condition-header">
            <div className="condition-rule">
                <label htmlFor="condition-switch" className="font-hover mleft5 ">
                    <span className={`${relevance.rules?.length > 0 && relevance.rules[0].logicalOperator === CONDITION_LOGICAL_OPERATORS.and ? 'active' : ''}`}>And</span>

                    <Switch id="condition-switch" size="small" defaultChecked={relevance.rules?.length > 0 && relevance.rules[0].logicalOperator !== CONDITION_LOGICAL_OPERATORS.and} onChange={(checked: boolean) => handleLogicalCondition(checked)} />

                    <span className={`${relevance.rules?.length > 0 && relevance.rules[0].logicalOperator !== CONDITION_LOGICAL_OPERATORS.and ? 'active' : ''}`}>Or</span>
                </label>
            </div>
            <span className="condition-title">
                Offer appearance rules
            </span>
            <span></span>
        </div>
        <div className="condition-rules-inputs mbot20">
            <div className="condition-rules-header x4">
                <div className="condition-column">
                    Text/Amount
                </div>
                <div className="condition-column">
                    Operator
                </div>
                <div className="condition-column">
                    Value
                </div>
                <div className="condition-column">
                    Location
                </div>
            </div>
            {relevance.rules?.length > 0 && relevance.rules?.map((value: OfferRules, index: number) => {
                return <div className="conditions" key={`rules${index}`}>
                    {value.rules.map((rule: OfferRule, ruleIndex: number) => {
                        return <div key={`rule-${ruleIndex}`} className="condition-rule-row x4">
                            <div className="condition-column">

                                <div className="express-input">
                                    <Select
                                        placeholder="Select operator"
                                        suffixIcon={<span className="icon icon-arrow-down"></span>}
                                        options={ruleSearchTypeList}
                                        value={conditionSearchTypeToLabel(rule.searchType)}
                                        onChange={(value: CONDITION_SEARCH_TYPE, option: BaseOptionType) => {
                                            handleChange(ruleIndex, option.key, 'searchType');
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="condition-column">

                                <div className="express-input">
                                    {rule.searchType.toLowerCase() === CONDITION_SEARCH_TYPE.amount.toLowerCase() ?
                                        <Select
                                            placeholder="Select operator"
                                            suffixIcon={<span className="icon icon-arrow-down"></span>}
                                            options={operatorsAmountList}
                                            value={conditionOperatorsAmountToLabel(rule.operator)}
                                            onChange={(value: CONDITION_OPERATORS_AMOUNT | CONDITION_OPERATORS_TEXT, option: BaseOptionType) => {
                                                console.log(option);
                                                handleChange(ruleIndex, option.key, 'operator');
                                            }}
                                        />
                                        :
                                        <Select
                                            placeholder="Select operator"
                                            suffixIcon={<span className="icon icon-arrow-down"></span>}
                                            options={operatorsTextList}
                                            value={conditionOperatorsTextToLabel(rule.operator)}
                                            onChange={(value: CONDITION_OPERATORS_AMOUNT | CONDITION_OPERATORS_TEXT, option: BaseOptionType) => {
                                                console.log(option);
                                                handleChange(ruleIndex, option.key, 'operator');
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="condition-column">
                                <div className="express-input">
                                    <Input
                                        value={rule.targetValue}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            handleChange(ruleIndex, e.target.value, 'targetValue');
                                        }}
                                        autoComplete="off"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            <div className="condition-column">
                                <div className="express-input">
                                    <Select
                                        placeholder="Select page"
                                        suffixIcon={<span className="icon icon-arrow-down"></span>}
                                        options={ruleSearchLocationsList}
                                        value={getRuleLocationValue(rule.location)}
                                        onChange={(value: string, option: BaseOptionType) => {
                                            handleChange(ruleIndex, option.key, 'location');
                                        }}
                                    />
                                </div>
                                {rule.location.toLowerCase() === CONDITION_SEARCH_LOCATION.other.toLowerCase() &&
                                    <div className="express-input mtop10">
                                        <Input
                                            placeholder="Html selector"
                                            value={rule.locationValue}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleChange(ruleIndex, e.target.value, 'locationValue');
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                }
                            </div>
                            <div onClick={() => hanldeRemoveCondition(ruleIndex)} className="condition-rule-action">
                                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 3.35742H2.22225H12.0003" stroke="#A3A8B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.4286 3.3572V11.6074C10.4286 11.92 10.3104 12.2198 10.0999 12.4408C9.88935 12.6618 9.60384 12.786 9.30614 12.786H3.69376C3.39607 12.786 3.11056 12.6618 2.90005 12.4408C2.68955 12.2198 2.57129 11.92 2.57129 11.6074V3.3572M4.255 3.3572V2.1786C4.255 1.86602 4.37326 1.56623 4.58377 1.3452C4.79427 1.12417 5.07978 1 5.37748 1H7.62243C7.92013 1 8.20563 1.12417 8.41614 1.3452C8.62664 1.56623 8.7449 1.86602 8.7449 2.1786V3.3572" stroke="#A3A8B9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="condition-float-label">
                                {relevance.rules.length > 0 &&
                                    <>
                                        <span>{relevance.rules[0].logicalOperator}</span>
                                    </>
                                }
                            </div>
                        </div>
                    })}
                </div>
            })}

            {relevance.rules?.length === 0 &&
                <>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </>
            }

            <div className="condition-footer">
                <span onClick={handleAddCondition} className="font-active font-hover">Add condition</span>
            </div>
        </div>

        {relevance.rules?.length > 0 &&
            <>
                <div className="modal-color-row flex-align-center">
                    <div className="modal-label mright10">
                        When the above conditions are met, show the offer in the
                    </div>

                    <div className="relative">
                        <div className="express-input">
                            <Select
                                placeholder="Select page"
                                className="min100"
                                suffixIcon={<span className="icon icon-arrow-down"></span>}
                                options={offerSlotConditions}
                                value={relevance.slot || 'Current'}
                                onChange={(value: string, option: BaseOptionType) => {
                                    setRelevance(prevState => ({ ...prevState, slot: value }))
                                }}
                            />
                        </div>
                    </div>
                    <span className="modal-label mleft5">
                        slot
                    </span>
                </div>

                <div className="modal-color-row flex-align-center">
                    <div className="modal-label mright10">
                        When the above conditions are not met
                    </div>

                    <div className="relative">
                        <div className="express-input">
                            <Select
                                placeholder="Select appearance"
                                suffixIcon={<span className="icon icon-arrow-down"></span>}
                                options={offerShowConditions}
                                value={relevance.showIfFalse}
                                onChange={(value: boolean, option: BaseOptionType) => {
                                    setRelevance(prevState => ({ ...prevState, showIfFalse: value }))
                                }}
                            />
                        </div>
                    </div>
                </div>

            </>}

        <div className="ant-modal-footer">
            <Button onClick={handleUpdate} disabled={hanldeValidateRules()} type="primary" className="success-button mtop20">Update</Button>
        </div>

    </>
}

export default OfferConditions