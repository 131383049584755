import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { AggregatedEvent, AggregatedEventData } from "../../../../types/events.interfaces";

interface Props {
    loading: boolean,
    events?: AggregatedEvent
}

const AdvertiserRetailersEvents = ({ loading, events }: Props) => {
    return <>
        <div className="home-widget">
            <div className="widget-title">
                Retailers  <InfoCircleOutlined id="guide-insights-retailers" />
            </div>
            <div className="widget-description">
            </div>
            <div className="widget-content widget-table">
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Retailer
                                </th>
                                <th>
                                    Impressions
                                </th>
                                <th>
                                    Read more
                                </th>
                                <th>
                                    Interested
                                </th>
                                <th>
                                    Get offer
                                </th>
                                <th>
                                    Sale amount pending
                                </th>
                                <th>
                                    Sale amount approved
                                </th>
                                <th>
                                    Paid amount pending
                                </th>
                                <th>
                                    Paid amount approved
                                </th>
                                <th>
                                    Sales pending count
                                </th>
                                <th>
                                    Sales approved count
                                </th>
                                <th>
                                    Paid pending count
                                </th>
                                <th>
                                    Paid approved count
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {events && events?.data?.map((event: AggregatedEventData, index: number) => {
                                return <tr key={`retailerevent-${index}`}>
                                    <td>
                                        {event.item.name}
                                    </td>
                                    <td>
                                        {event.metrics.onofferview}
                                    </td>
                                    <td>
                                        {event.metrics.onreadmoreclick}
                                    </td>
                                    <td>
                                        {event.metrics.onselectoffer}
                                    </td>
                                    <td>
                                        {event.metrics.totalOfferClicks}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalSaleAmountPending || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalSaleAmountApproved || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalPaidAmountPending || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalPaidAmountApproved || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.totalSalesPendingCount || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.totalSalesApprovedCount || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.totalPaidPendingCount || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.totalPaidApprovedCount || 0}
                                    </td>
                                </tr>
                            })}
                            {loading ?
                                <tr className={events && events?.count > 0 ? 'loader-absolute' : ''}>
                                    <td className="text-center" colSpan={13}>
                                        <LoadingOutlined />
                                    </td>
                                </tr>
                                :
                                <>
                                    {events && events?.count === 0 &&
                                        <tr>
                                            <td className="text-center" colSpan={13}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="widget-footer">
            </div>
        </div>
    </>
}

export default AdvertiserRetailersEvents;