import { Empty, message } from "antd";
import { useState, useEffect } from "react";
import useAxios from "../../../../../hooks/useAxios.hook";
import { SalesFunnelDataItem } from "../../../../../types/graphs.interfaces";
import { PlacementStage } from "../../../../../types/templates.interfaces";
import Click from "./../../../../../assets/images/icons/click.svg";
import Eye from "./../../../../../assets/images/icons/eye.svg";
import Cart from "./../../../../../assets/images/icons/cart.svg";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  companyId?: string;
  campaignId?: string;
  offerId: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  placementType?: PlacementStage;
  salesNumber: number;
  CTR: number;
  CR: number;
}

const SalesFunnelData = ({
  companyId,
  campaignId,
  offerId,
  filters,
  placementType,
  salesNumber,
  CTR,
  CR,
}: Props) => {
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [salesFunnelData, setSalesFunnelData] = useState<SalesFunnelDataItem>();

  useEffect(() => {
    if (salesNumber) {
      fetchSalesFunnelData();
    }
    // eslint-disable-next-line
  }, []);

  const fetchSalesFunnelData = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/offer/funnel/${companyId}/${campaignId}/${offerId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setSalesFunnelData(response.data.result.data?.[0]);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  return (
    <>
      <h1 className="title">Sales metrics</h1>
      {!salesNumber ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <div className="three-boxes mtop10">
          <div className="three-boxes-item">
            <div className="three-boxes-item-header">
              <img src={Eye} alt="" className="three-boxes-item-header-image" />
              <div className="three-boxes-item-header-title">Impressions</div>
            </div>
            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <div className="three-boxes-item-content-number">
                  {salesFunnelData?.distinctViews}
                </div>
              )}
            </div>
          </div>
          <div className="three-boxes-item">
            <div className="three-boxes-item-header">
              <img
                src={Click}
                alt=""
                className="three-boxes-item-header-image"
              />
              <div className="three-boxes-item-header-title">Clicks</div>
            </div>

            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <div className="three-boxes-item-content-number">
                    {placementType === PlacementStage.pre
                      ? salesFunnelData?.distinctSelected
                      : salesFunnelData?.totalDistinctClicks}
                  </div>
                  <div className="three-boxes-item-content-percentage">
                    {CTR}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="three-boxes-item">
            <div className="three-boxes-item-header">
              <img
                src={Cart}
                alt=""
                className="three-boxes-item-header-image"
              />
              <div className="three-boxes-item-header-title">Orders</div>
            </div>
            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <div className="three-boxes-item-content-number">
                    {salesFunnelData?.totalSalesCount}
                  </div>
                  <div className="three-boxes-item-content-percentage">
                    {CR}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesFunnelData;
