import { createContext, useState } from "react";
import { AuthContextType } from "../types/auth.interfaces";
import { UserCompany } from "../types/company.interfaces";
import { UserAuth } from "../types/user.interfaces";

const localAuth = localStorage.getItem('user');
const localCompany = localStorage.getItem('company');

export const AuthContext = createContext<AuthContextType>(
    {
        auth: localAuth ? JSON.parse(localAuth) : undefined,
        setAuth: () => { },
        company: localCompany ? JSON.parse(localCompany) : undefined,
        setCompany: () => { }
    });

export const AuthProvider = ({ children }: any) => {
    const _localAuth = localStorage.getItem('user');
    const _localCompany = localStorage.getItem('company');
    const [auth, setAuth] = useState<UserAuth | undefined>(_localAuth ? JSON.parse(_localAuth) : undefined);
    const [company, setCompany] = useState<UserCompany | undefined>(_localCompany ? JSON.parse(_localCompany) : undefined);


    return <AuthContext.Provider value={{ auth, setAuth, company, setCompany }}>
        {children}
    </AuthContext.Provider >
}

export default AuthContext;