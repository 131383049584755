import { Button, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth.hook";
import useAxios from "../../../hooks/useAxios.hook";
import usePage from "../../../hooks/usePage.hook";
import { CampaignType } from "../../../types/campaign.interfaces";
import { ACCESS_TYPES } from "../../../utility/enums/user.enums";
import { CAMPAIGNSTEPS, CAMPAIGN_INIT, CAMPAIGN_STEP_ARRAY, getCampaignStepIndex } from "../const/campaigns.enum";
import CampaignDetails from "./details/CampaignDetail.component";
import CampaignOffers from "./offers/CampaignOffers.component";
import CampaigLayout from "./layout/CampaignLayout.component";

const Campaign = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const { response: getResponse, axiosFetch: getAxios } = useAxios();
    const { myAxios } = useAxios();
    const { auth, company } = useAuth();
    const { setPage } = usePage();
    const [currentStep, setCurrentStep] = useState<CAMPAIGNSTEPS>(CAMPAIGNSTEPS.details);
    const [createdId, setCreatedId] = useState<string | undefined>(undefined);
    const [campaign, setCampaign] = useState<CampaignType>(CAMPAIGN_INIT);
    const [savedCampaign, setSavedCampaign] = useState<CampaignType>(CAMPAIGN_INIT);

    const [isDisabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        setPage({
            title: <>Campaign name</>
        });
        // eslint-disable-next-line
    }, []);

    const fetchCampaignById = async (id: string) => {
        await getAxios({ method: 'get', url: `campaign/id/${id}` });
    }

    useEffect(() => {
        if (id) {
            fetchCampaignById(id);
            setCreatedId(id);
        }
        // eslint-disable-next-line
    }, [id]);

    // Handle response of GET campaign by id
    useEffect(() => {
        if (getResponse?.status && company) {
            const _campaign: CampaignType = { ...getResponse.result, company_id: company.company_Id };
            setCampaign(_campaign);
            setSavedCampaign(_campaign);
        }
        // eslint-disable-next-line
    }, [getResponse, auth]);

    const handlePutCampaign = async (newCampaign: CampaignType, move: boolean) => {
        const { response } = await myAxios({ method: 'put', url: `/campaign/${createdId}`, data: newCampaign });
        if (response?.data?.status && response?.data?.result) {
            message.success(`Updated campaign: ${newCampaign.name}`, 1);
            move && setCurrentStep(prevState => getNextStep(prevState));
            setSavedCampaign(newCampaign);
            setCampaign(newCampaign);
        } else {
            if (response?.data?.result) {
                message.error(response?.data?.result[0]?.message, 1);
            } else {
                message.error(`Something went wrong`, 1);
            }
        }
        setLoading(false);
    }

    const getCurrentComponent = useMemo((): JSX.Element => {
        let jsxElm = <></>;
        switch (currentStep) {
            case CAMPAIGNSTEPS.details:
                jsxElm = <CampaignDetails campaign={campaign} setCampaign={setCampaign} setDisabled={setDisabled} />
                break;
            case CAMPAIGNSTEPS.offers:
                jsxElm = <CampaignOffers campaign={campaign} setCampaign={setCampaign} setDisabled={setDisabled} />

                break;
            case CAMPAIGNSTEPS.layout:
                jsxElm = <CampaigLayout campaign={campaign} handlePut={handlePutCampaign} setCampaign={setCampaign} setSavedCampaign={setSavedCampaign} setDisabled={setDisabled} />

                break;
            // case CAMPAIGNSTEPS.placement:
            //     jsxElm = <CampaignPlacements campaign={campaign} setCampaign={setCampaign} setDisabled={setDisabled} />

            //     break;
            default:
                break;
        }
        return jsxElm
        // eslint-disable-next-line
    }, [currentStep, campaign]);

    const handleSaveContinue = async () => {
        if (company) {
            setLoading(true);
            const nextStep = getNextStep(currentStep);
            const _campaign: CampaignType = { ...campaign, company_id: company.company_Id, completionStatus: { next: nextStep, status: currentStep === CAMPAIGNSTEPS.layout } }
            delete _campaign.id;
            setCampaign(_campaign);
            createdId ? handlePutCampaign(_campaign, true) : handlePostCampaign(_campaign);
        }
    }

    const handlePostCampaign = async (newCampaign: CampaignType) => {
        const { response } = await myAxios({ method: 'post', url: '/campaign', data: newCampaign });

        if (response?.data?.status === true) {
            setCreatedId(response?.data?.result?.id);
            message.success(`Created campaign: ${newCampaign.name}`, 1);
            setCurrentStep(prevState => getNextStep(prevState));
            setSavedCampaign(newCampaign);
            setCampaign(newCampaign);
        } else {
            if (response?.data?.result) {
                message.error(response?.data?.result[0]?.message, 1);
            } else {
                message.error('Something went wrong', 1);
            }
        }
        setLoading(false);
    }

    const handleSave = async () => {
        setLoading(true);
        if (company) {
            const _campaign: CampaignType = { ...campaign, company_id: company.company_Id };
            handlePutCampaign(_campaign, false);
        }
    }

    const getAllowedStep = (step: number, current: CAMPAIGNSTEPS): boolean => {
        const stepIndex = getCampaignStepIndex(current as CAMPAIGNSTEPS);
        return stepIndex >= step
    }

    const getNextStep = (step: CAMPAIGNSTEPS): CAMPAIGNSTEPS => {
        const currentIndex = getCampaignStepIndex(step);
        const nextStep: CAMPAIGNSTEPS = currentIndex !== CAMPAIGN_STEP_ARRAY.length - 1 ? CAMPAIGN_STEP_ARRAY[currentIndex + 1] : CAMPAIGN_STEP_ARRAY[currentIndex]
        return nextStep
    }

    const isOnCreation = (_campaign: CampaignType): boolean => {
        return getCampaignStepIndex(_campaign.completionStatus.next) <= getCampaignStepIndex(currentStep)
    }

    const handleStartCampaign = async () => {
        const _campaign: CampaignType = { ...campaign, isActive: true, completionStatus: { ...campaign.completionStatus, status: true } };

        if (currentStep === CAMPAIGNSTEPS.layout && !campaign.completionStatus.status) {
            const { response } = await myAxios({ method: 'put', url: `/campaign/${createdId}`, data: _campaign });

            if (!response?.status) {
                console.log(response);
                message.error(`Failed to start campaign`, 1);
                return;
            }
        }

        const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${createdId}/true` });

        if (response?.status) {
            setCampaign(_campaign);
            setSavedCampaign(_campaign);
            message.success(`Campaign has started`, 1);
        } else {
            console.log(error);
        }
    }

    const handleStopCampaign = async () => {
        const _campaign: CampaignType = { ...campaign, isActive: false };

        const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${createdId}/false` });

        if (response?.status) {
            setCampaign(_campaign);
            setSavedCampaign(_campaign);
            message.success(`Campaign has stopped`, 1);
        } else {
            console.log(error);
        }
    }

    return <>
        <div className="content campaign-content">
            <div className="content-header">
                <div className="content-steps">
                    <ul>
                        <li className="step-details" onClick={() => getAllowedStep(0, campaign.completionStatus.next) && setCurrentStep(CAMPAIGNSTEPS.details)}>
                            <span className={`campaign-step ${getAllowedStep(0, campaign.completionStatus.next) ? '' : 'step-disabled'} ${currentStep === CAMPAIGNSTEPS.details ? 'active' : ''}`}>
                                <span className="step-icon"></span>
                                <span className="step-label">1. Details</span>
                            </span>
                        </li>
                        <li className="step-offers" onClick={() => getAllowedStep(1, campaign.completionStatus.next) && setCurrentStep(CAMPAIGNSTEPS.offers)}>
                            <span className={`campaign-step ${getAllowedStep(1, campaign.completionStatus.next) ? '' : 'step-disabled'} ${currentStep === CAMPAIGNSTEPS.offers ? 'active' : ''}`}>
                                <span className="step-icon"></span>
                                <span className="step-label">2. Offers</span>
                            </span>
                        </li>
                        <li className="step-layout" onClick={() => getAllowedStep(2, campaign.completionStatus.next) && setCurrentStep(CAMPAIGNSTEPS.layout)}>
                            <span className={`campaign-step ${getAllowedStep(2, campaign.completionStatus.next) ? '' : 'step-disabled'} ${currentStep === CAMPAIGNSTEPS.layout ? 'active' : ''}`}>
                                <span className="step-icon"></span>
                                <span className="step-label">3. Layout</span>
                            </span>
                        </li>
                        {/* <li className="step-placements" onClick={() => getAllowedStep(2, campaign.completionStatus.next) && setCurrentStep(CAMPAIGNSTEPS.layout)}>
                            <span className={`campaign-step ${getAllowedStep(2, campaign.completionStatus.next) ? '' : 'step-disabled'} ${currentStep === CAMPAIGNSTEPS.layout ? 'active' : ''}`}>
                                <span className="step-icon"></span>
                                <span className="step-label">4. Placement</span>
                            </span>
                        </li> */}
                    </ul>
                </div>
                <div className="content-action">
                    {company?.userAccess?.access.includes(ACCESS_TYPES.campaignwrite) &&
                        <>
                            <>
                                {campaign?.isActive &&
                                    <Button type="primary" className="button-default thin button-icon" icon={<span className="icon-stop icon"></span>} onClick={handleStopCampaign}>
                                        Stop
                                    </Button>
                                }

                                {(!campaign?.isActive && campaign.completionStatus.status && !isDisabled) &&
                                    <Button type="primary" className="button-default thin button-icon" icon={<span className="icon-start icon"></span>} onClick={handleStartCampaign}>
                                        Start
                                    </Button>
                                }
                            </>
                            {isOnCreation(savedCampaign) && !savedCampaign.completionStatus.status ?
                                <Button type="primary" loading={loading} disabled={isDisabled} onClick={handleSaveContinue}>
                                    {currentStep !== CAMPAIGNSTEPS.layout ? 'Save and Continue' : 'Save and Complete'}
                                </Button>
                                :
                                <Button type="primary" className="button-active" loading={loading} disabled={isDisabled} onClick={handleSave}>
                                    Save
                                </Button>
                            }
                        </>
                    }
                </div>
            </div>

            {getCurrentComponent}

        </div>
    </>
}

export default Campaign