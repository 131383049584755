export const enum COMPANY_TYPES {
  publisher = "publisher",
  advertiser = "advertiser",
  both = "both",
  agency = "agency",
}

export const enum ACCESS_TYPES {
  campaignread = "campaignread",
  campaignwrite = "campaignwrite",
  campaigndelete = "campaigndelete",
  offerread = "offerread",
  offerwrite = "offerwrite",
  insightsread = "insightsread",
  companyread = "companyread",
  companywrite = "companywrite",
  companydelete = "companydelete",
  companyplacementwrite = "companyplacementwrite",
  companyplacementread = "companyplacementread",
  brandread = "brandread",
  brandwrite = "brandwrite",
  financewrite = "financewrite",
  paymentsread = "paymentsread",
}

export const accessArray: ACCESS_TYPES[] = [
  ACCESS_TYPES.campaignread,
  ACCESS_TYPES.campaignwrite,
  ACCESS_TYPES.campaigndelete,
  ACCESS_TYPES.offerread,
  ACCESS_TYPES.offerwrite,
  ACCESS_TYPES.insightsread,
  ACCESS_TYPES.companyread,
  ACCESS_TYPES.companywrite,
  ACCESS_TYPES.companydelete,
  ACCESS_TYPES.companyplacementwrite,
  ACCESS_TYPES.companyplacementread,
  ACCESS_TYPES.brandread,
  ACCESS_TYPES.brandwrite,
  ACCESS_TYPES.financewrite,
  ACCESS_TYPES.paymentsread,
];
