import './css/style.scss';
import { ButtonShapes } from '../../../../../../templates-previews/types/params.interfaces';
import { CampaignType, TemplateOptionSettings } from '../../../../../../../types/campaign.interfaces';
import SwiperCore from 'swiper';
import { Navigation, Controller } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperComponent from '../swiper/Swiper.component';
import phoneSrc from './../../../../../../../assets/images/phone.jpg';
import { CSSProperties, useRef, useState } from 'react';

SwiperCore.use([Navigation, Controller]);

interface Props {
  description: string,
  settings: TemplateOptionSettings | undefined,
  campaign: CampaignType,
  displayOnly: boolean,
  offersCount: string,
  buttonShape: ButtonShapes,
}

const PreviewNewLook = ({ offersCount, settings, campaign, displayOnly, buttonShape }: Props) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const tagElmRef = useRef<HTMLInputElement>(null);

  const handleScrollTo = () => {
    if (tagElmRef.current) {
      tagElmRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return <>

    <div className="adv-body overflow">
      <div className="adv-page">

        <div className="adv-content">

          <div className="extra-element" onClick={handleScrollTo} style={{ backgroundColor: settings?.embeddedElement?.extraElementBackground || '#CBEFE8', borderColor: settings?.embeddedElement?.extraElementColor || '#009D7E', color: settings?.embeddedElement?.extraElementColor || '#009D7E', fontFamily: settings?.mainLayout.fontFamily }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8" cy="8" r="8" fill={settings?.embeddedElement?.extraElementColor || '#009D7E'} />
              <path fillRule="evenodd" clipRule="evenodd" d="M11.8156 5.15494C11.9879 5.33394 11.9879 5.62415 11.8156 5.80315L6.96038 10.8451C6.78801 11.0241 6.50854 11.0241 6.33617 10.8451L4.12928 8.55329C3.95691 8.37429 3.95691 8.08407 4.12928 7.90507C4.30165 7.72607 4.58111 7.72607 4.75348 7.90507L6.64828 9.87274L11.1913 5.15494C11.3637 4.97594 11.6432 4.97594 11.8156 5.15494Z" fill={settings?.embeddedElement?.extraElementBackground || 'white'} stroke={settings?.embeddedElement?.extraElementBackground || '#CBEFE8'} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="extra-element-content">
              <span className="extra-element-text">
                {settings?.embeddedElement?.extraElementText || 'You have qualified for free gifts!'}&nbsp;
              </span>
              <span className="extra-element-text-bold">
                {settings?.embeddedElement?.extraElementTextBold || 'Don’t forget to make your selection below'}
              </span>
            </div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="20" height="20" rx="4" fill={settings?.embeddedElement?.extraElementColor || '#009D7E'} />
              <path d="M10 15L14 10.7143M10 15L6 10.7143M10 15L10 5" stroke={settings?.embeddedElement?.extraElementBackground || '#CBEFE8'} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          <div className="adv-embedd-wrapper">

            <div className={`inline-new-look-wrapper ${settings?.mainLayout?.align}`} style={{
              padding: `0 ${settings?.embeddedElement?.margin || '0'}${settings?.embeddedElement?.marginType || '%'}`,
            }}>

              <div className={`new-look`} ref={tagElmRef} style={{
                width: settings?.embeddedElement?.fullWidth ? '100%' : settings?.embeddedElement?.width,
                backgroundColor: settings?.mainLayout.backgroundColor,
                borderColor: settings?.mainLayout?.borderColor || settings?.mainLayout.backgroundColor,
                borderRadius: 0,
                transform: `translate(${settings?.location.x || 0}px, ${settings?.location.y || 0}px)`,
                fontFamily: settings?.mainLayout.fontFamily,
                color: settings?.mainLayout.textColor,
                height: `${settings?.embeddedElement?.height}px`,
                paddingTop: `${settings?.embeddedElement?.paddingTop || 0}px`,
                paddingBottom: `${settings?.embeddedElement?.paddingBottom || 0}px`
              }}>

                <div className={`new-look-title`}>
                  {buttonShape === ButtonShapes.squareImage &&
                    <>
                      <div className="preview-title-icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z" stroke={settings?.mainLayout.headerTextColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </>
                  }

                  {buttonShape === ButtonShapes.squareImageLock &&
                    <>
                      <div className="preview-title-icon">
                        {displayOnly ?
                          <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z" stroke={settings?.mainLayout.headerTextColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          :
                          <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z" stroke={settings?.mainLayout.headerTextColor} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        }
                      </div>
                    </>
                  }

                  <span className={`new-look-title-label`} style={{ fontSize: settings?.mainLayout.headerFontSize + 'px', fontWeight: settings?.mainLayout.headerFontWeight, color: settings?.mainLayout.headerTextColor }}>
                    {settings?.mainLayout.headerText || ''}
                  </span>

                </div>
                <div className={`new-look-description`} style={{ fontSize: settings?.mainLayout.subHeaderFontSize + 'px', fontWeight: settings?.mainLayout.subHeaderFontWeight, color: settings?.mainLayout.subHeaderTextColor }} >
                  {settings?.mainLayout.subHeaderText || ''}
                </div>

                {displayOnly && <div className="new-look-selected">
                  <div className="new-look-selected-count">
                    <span>0 of {campaign?.offers.length} </span>
                    {settings?.mainLayout?.countText || 'Free gifts'}
                  </div>
                  <div className="new-look-select" onClick={() => setSelectAll(!selectAll)}>
                    <span>{settings?.mainLayout?.selectText || 'Select all'}</span>

                    <div className={`new-look-checkbox ${selectAll ? 'checked' : ''}`} style={{
                      backgroundColor: selectAll ? settings?.mainLayout.highlightColor : 'transparent',
                      borderColor: selectAll ? settings?.mainLayout?.highlightColor : settings?.dealLayout?.borderColor || '#ddd'
                    }}>
                      {selectAll &&
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 1L4 8L1 5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      }
                    </div>

                  </div>
                </div>
                }

                <div className={`new-look-swiper`} style={{ "--swiper-pagination-color": settings?.mainLayout.textColor, "--swiper-pagination-bullet-inactive-color": `${settings?.mainLayout.textColor}e0` } as CSSProperties}>
                  {campaign?.offers.length > 0 && settings &&
                    <SwiperComponent offersCount={offersCount} campaign={campaign} settings={settings} displayOnly={displayOnly} ></SwiperComponent>
                  }
                </div>

                <div className="new-look-footer">
                  {displayOnly ?
                    <>
                      {campaign?.generalOptions.emailCaptureOnOffers &&
                        <>
                          <span>Pick your gifts and receive by e-mail after purchase</span>
                        </>
                      }
                    </>
                    :
                    <>
                      <span>By selecting the option above you will be directed to a third party website. <span className="preview-tooltip">By selecting the option above you will be directed to a third party website. Any use of third party websites are not subject to our Privacy Policy, and we encourage you to also read their privacy statements</span></span>
                    </>
                  }
                </div>
              </div>
            </div>

            {/* <TemplateInline layoutView={layoutView} description={description} settings={settings} campaign={campaign} displayOnly={displayOnly || false} /> */}
          </div>

          <div className="adv-product-row">
            <div className="adv-product-image">
              <img src={phoneSrc} alt="Phone" />
            </div>
            <div className="adv-product-content">
              <span className="adv-pre-content large"></span>
              <span className="adv-pre-content"></span>
              <span className="adv-pre-content"></span>
            </div>
            <div className="adv-product-actions">
              <span className="adv-pre-action"></span>
              <span className="adv-pre-action"></span>
            </div>
          </div>

          <div className="adv-shopping-buttons">
            <div className="adv-buttons">
              <span className="adv-success-button">Next</span>
              <span className="adv-default-button">Back to shop</span>
            </div>
            <div className="adv-shopping-actions">
              <span className="adv-pre-action"></span>
              <span className="adv-pre-action"></span>
            </div>
          </div>

        </div>
      </div>
    </div>


  </>
}

export default PreviewNewLook;