import { Button, Collapse, message, Switch } from "antd";
import { Dispatch, useEffect, useRef, useState } from "react"
import { ImageUpload, OfferCreate, OfferResolutionMedia } from "../../../../types/offer.interfaces"
import { RcFile, } from "antd/lib/upload"
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { postOfferImage } from "../../../../services/upload/upload.service"
import Dragger from "antd/lib/upload/Dragger";
import { Editor } from "@tinymce/tinymce-react";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import failIcon from "../../../../assets/images/icons/fail.svg";
import successIcon from "../../../../assets/images/icons/success.svg";
import { HTTPS_URL_REGEX } from "../../../../utility/regexp";

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/GiF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isLt2M;
};

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>
}

const CreateOfferContent = ({ offer, setOffer }: Props) => {

    const editorRef = useRef<any>();

    const [imageUrl, setImageUrl] = useState<string>();

    const [loadingMainImage, setLoadingMainImage] = useState<boolean>(false);

    const [compactAccordion, setCompactAccordion] = useState<boolean>(false);
    const [featureAccordion, setFeatureAccordion] = useState<boolean>(false);

    const [loadingFeatureImage, setLoadingFeatureImage] = useState<boolean>(false);

    const [loadingCompactImage, setLoadingCompactImage] = useState<boolean>(false);

    const emptyResolutionMedia: OfferResolutionMedia = {
        type: 'compact',
        size: '74x74',
        images: [],
        title: '',
        description: ''
    }

    useEffect(() => {
        setImageUrl(offer.imageUrl);
    }, [offer.imageUrl]);

    const handleUploadRequest = async (option: any) => {
        setLoadingMainImage(true);
        if (option?.file) {
            const response = await postOfferImage(option.file);

            if (response?.data?.status) {
                const image = response.data.result;
                setImageUrl(image);
                // setOffer(prevState => ({ ...prevState, imageUrl: response.data.result.filepath, media: { ...prevState.media, mainImage: image } }))
                setOffer(prevState => ({ ...prevState, imageUrl: image }))
            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoadingMainImage(false)
        return option
    }

    const handleCompactUploadRequest = async (option: any) => {
        setLoadingCompactImage(true);
        if (option?.file) {
            const response = await postOfferImage(option.file);

            if (response?.data?.status) {
                const image = response.data.result;
                const images: ImageUpload[] = offer.media.resolution[0]?.images ? [...offer.media.resolution[0]?.images, image] : [image];
                setOffer(prevState => ({ ...prevState, imageUrl: response.data.result.filepath, media: { ...prevState.media, resolution: [{ ...prevState.media.resolution[0], images, type: 'compact' }] } }))

            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoadingCompactImage(false)
        return option
    }

    const handleUploadFeatureRequest = async (option: any) => {
        setLoadingFeatureImage(true);
        if (option?.file) {
            const response = await postOfferImage(option.file);

            if (response?.data?.status) {
                const image = response.data.result;
                setOffer(prevState => ({ ...prevState, media: { ...prevState.media, featured: { ...prevState.media.featured, images: [image], } } }))
            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoadingFeatureImage(false)
        return option
    }

    const isValidUrl = (url: string): Boolean => {
        return url.length > 0 ? HTTPS_URL_REGEX.test(url) : true;
    }

    return <>
        <div className="widget widget-icon mbot20">
            <div className="widget-header">
                <div className="grow">
                    <h3 className="widget-s-title">
                        Default info and media
                    </h3>
                </div>
            </div>
            <div className="widget-content-two">
                <div className="offer-preview">
                    <span className="offer-preview-toptext">PREVIEW</span>
                    <div className="offer-preview-box popup">
                        <div className="offer-preview-image popup">
                            {imageUrl && <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />}
                        </div>
                        <div className="offer-preview-title">
                            {offer.title || 'My offer title'}
                        </div>
                        <div className="offer-preview-description">
                            {offer.description || 'my offer description'}
                        </div>
                    </div>
                    <span className="offer-preview-bottomtext">Texts may differ in the end result, depending on your typography settings.</span>
                </div>
                <div className="offer-form">
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Image</span>
                        </div>
                        <div className={`voucher-uploader ${imageUrl ? 'voucher-uploaded' : ''} mbot20`}>
                            <Dragger
                                customRequest={handleUploadRequest}
                                name="avatar"
                                listType="picture-card"
                                showUploadList={false}
                                multiple={false}
                                disabled={false}
                                beforeUpload={beforeUpload}
                                maxCount={1}>
                                {loadingMainImage ?
                                    <div>
                                        <LoadingOutlined />
                                        <div style={{ marginTop: 8 }}>Uploading</div>
                                    </div>
                                    :
                                    <>
                                        {imageUrl && <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />}

                                        <div className="grow">
                                            <Button type="link" className="mbot20" style={{ backgroundColor: "#D8F1E9" }} loading={false} disabled={false}>
                                                <CloudUploadOutlined style={{ fontSize: 16 }} />
                                                Upload</Button>
                                        </div>
                                        {!imageUrl && <>
                                            <p className="font-color">Drag & drop or click to upload any image file.</p>
                                            <p className="font-color">You can upload multiple images to be played as a slideshow.</p>
                                        </>
                                        }

                                    </>}

                            </Dragger>
                        </div>
                    </div>
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Offer title *</span>
                        </div>
                        <div className={`widget-input`}>
                            <input
                                type="text"
                                id="offer-title"
                                name="offer-title"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => prevState && ({ ...prevState, title: e.target.value }))}
                                value={offer.title}
                                aria-describedby="cnide"
                                autoComplete="off"
                                placeholder="My offer title"
                            />
                        </div>
                    </div>

                    {/* <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Video Url (optional)</span>
                            <span className="unauth-label-error">
                                {offer.media.videoURL.length > 0 && (
                                    <span className="unauth-label-error">
                                        <span>
                                            <img
                                                src={isValidUrl(offer.media.videoURL) ? successIcon : failIcon}
                                                alt={isValidUrl(offer.media.videoURL) ? "success" : "fail"}
                                            />
                                        </span>
                                    </span>
                                )}
                                {offer.media.videoURL.length > 0 && !isValidUrl(offer.media.videoURL) && (
                                    <span>Invalid url</span>
                                )}
                            </span>
                        </div>
                        <div className={`widget-input`}>
                            <input
                                type="text"
                                id="video-url"
                                // ref={companyNameRef}
                                name="video-url"
                                value={offer.media.videoURL}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, media: { ...prevState.media, videoURL: e.target.value } }))}
                                // onBlur={() => setCompanyNameBlur(true)}
                                // aria-invalid={validCompanyName ? "true" : "false"}
                                aria-describedby="cnide"
                                autoComplete="off"
                                placeholder="Video url"
                            />
                        </div>
                    </div> */}

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Offer description * </span>
                            <span className="unauth-label-error">
                                {/* <span>
                                    {validCompanyName ? (
                                        <img src={successIcon} alt="success" />
                                    ) : companyNameBlur ? (
                                        <img src={failIcon} alt="fail" />
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                {!validCompanyName && companyNameBlur && (
                                    <span id="nide">Must be 2 to 24 characters.</span>
                                )} */}
                            </span>
                        </div>
                        {/* <div
                            className={`
                widget-input ${company.name && !validCompanyName
                                    ? "widget-input-invalid"
                                    : ""
                                }`}
                        > */}
                        <div className={`widget-input`}>
                            <textarea
                                id="offer-description"
                                // ref={companyNameRef}
                                name="offer-description"
                                value={offer.description}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setOffer(prevState => ({ ...prevState, description: e.target.value }))}
                                // onBlur={() => setCompanyNameBlur(true)}
                                // aria-invalid={validCompanyName ? "true" : "false"}
                                aria-describedby="cnide"
                                autoComplete="off"
                                placeholder="e.g. ABC Retail"
                            />
                        </div>
                    </div>

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Offer url *</span>
                        </div>
                        <div className={`widget-input`}>
                            <input
                                type="text"
                                id="offer-url"
                                // ref={companyNameRef}
                                name="offer-url"
                                value={offer.destinationUrl}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, destinationUrl: e.target.value }))}
                                // onBlur={() => setCompanyNameBlur(true)}
                                // aria-invalid={validCompanyName ? "true" : "false"}
                                aria-describedby="cnide"
                                autoComplete="off"
                                placeholder="Offer url"
                            />
                        </div>
                    </div>

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Offer CTA text</span>
                        </div>
                        <div className={`widget-input`}>
                            <input type="text" tabIndex={0} className="label-input" value={offer.offerCTAText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, offerCTAText: e.target.value }))} />
                        </div>
                    </div>

                    <div className="label-wrapper">
                        <div className="label-wrapper-title">Long description *</div>
                        <Editor
                            apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={offer.longDescription}
                            onEditorChange={(text, editor) => {
                                setOffer(prevState => ({ ...prevState, longDescription: text }));
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'autolink', 'lists', 'preview',
                                    'anchor',
                                    'table', 'wordcount'
                                ],
                                toolbar: 'blocks | ' +
                                    'bold italic | bullist numlist',
                                content_style: 'body{ background-color: #f2f2f2} ::selection{background: #3ab78f;color:#fff;}',
                                content_css_cors: true
                            }
                            }
                        />
                    </div>

                    <div className="label-wrapper">
                        <div className="label-wrapper-title">Frequently asked questions</div>
                        <Editor
                            apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={offer.faqs}
                            onEditorChange={(text, editor) => {
                                setOffer(prevState => ({ ...prevState, faqs: text }));
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'autolink', 'lists', 'preview',
                                    'anchor',
                                    'table', 'wordcount'
                                ],
                                toolbar: 'blocks | ' +
                                    'bold italic | bullist numlist',
                                content_style: 'body{ background-color: #f2f2f2}',
                                content_css_cors: true,
                            }
                            }
                        />
                    </div>

                    <div className="label-wrapper">
                        <div className="label-wrapper-title">Terms and conditions</div>
                        <Editor
                            apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={offer.terms}
                            onEditorChange={(text, editor) => {
                                setOffer(prevState => ({ ...prevState, terms: text }));
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'autolink', 'lists', 'preview',
                                    'anchor',
                                    'table', 'wordcount'
                                ],
                                toolbar: 'blocks | ' +
                                    'bold italic | bullist numlist',
                                content_style: 'body{ background-color: #f2f2f2}',
                                content_css_cors: true,
                            }
                            }
                        />
                    </div>

                </div>
            </div>
        </div>

        {/* <div className="widget widget-icon mbot20">
            <Collapse activeKey={[compactAccordion ? 'compact' : '']} ghost>
                <CollapsePanel
                    showArrow={false}
                    forceRender={compactAccordion}
                    header={
                        <div className="widget-header widget-header-switch">
                            <label htmlFor="switch-compact" className="flex-center-space-between font-hover grow">
                                <h3 className="widget-s-title">
                                    Compact version of info and media
                                </h3>
                                <div>
                                    <span className="mright10 font-small font-color">
                                        Optimize for compact views
                                    </span>
                                    <Switch id="switch-compact" className="widget-header-switch-checked" checked={compactAccordion} onChange={(checked: boolean) => setCompactAccordion(checked)}></Switch>
                                </div>
                            </label>
                        </div>
                    }
                    key="compact">
                    <div className="label-normal font-color mbot20 mtop10">
                        Here you can set an extended version of the offer’s basic info. This info will override the default main info when the offer is shown on featured position.
                    </div>
                    <div className="widget-content-two">
                        <div className="offer-preview">
                            <span className="offer-preview-toptext">PREVIEW</span>
                            <div className="offer-preview-box compact">
                                <div className="offer-preview-image compact">
                                    {offer.media.resolution[0]?.images.length && <img src={offer.media.resolution[0]?.images[0].filePath} alt="compact" />}
                                </div>
                                <div className="offer-preview-content">
                                    <div className="offer-preview-title">
                                        {offer.media.resolution[0]?.title || 'My offer title'}
                                    </div>
                                    <div className="offer-preview-description">
                                        {offer.media.resolution[0]?.description || 'my offer description'}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="offer-form">
                            <div className="widget-form-row">
                                <div className="unauth-label">
                                    <span>Image</span>
                                </div>
                                <div className={`voucher-uploader ${offer.media.resolution.length ? 'voucher-uploaded' : ''} mbot20`}>
                                    <Dragger
                                        customRequest={handleCompactUploadRequest}
                                        name="avatar"
                                        listType="picture-card"
                                        showUploadList={false}
                                        multiple={false}
                                        disabled={false}
                                        beforeUpload={beforeUpload}
                                        maxCount={1}>
                                        {loadingCompactImage ?
                                            <div>
                                                <LoadingOutlined />
                                                <div style={{ marginTop: 8 }}>Uploading</div>
                                            </div>
                                            :
                                            <>
                                                <>
                                                    {offer.media.resolution[0]?.images?.map(i => <img src={i.filePath} alt="avatar" />)}
                                                </>

                                                <div className="grow">
                                                    <Button type="link" className="mbot20" style={{ backgroundColor: "#D8F1E9" }} loading={false} disabled={false}>
                                                        <CloudUploadOutlined style={{ fontSize: 16 }} />
                                                        Upload</Button>
                                                </div>
                                                {!offer.media.resolution[0]?.images?.length && <>
                                                    <p className="font-color">Drag & drop or click to upload any image file.</p>
                                                    <p className="font-color">You can upload multiple images to be played as a slideshow.</p>
                                                </>
                                                }

                                            </>}
                                    </Dragger>
                                </div>
                            </div>
                            <div className="widget-form-row">
                                <div className="unauth-label">
                                    <span>Title</span>
                                </div>
                                <div className={`widget-input`}>
                                    <input
                                        type="text"
                                        id="compact-title"
                                        name="compact-title"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setOffer(prevState => prevState && ({ ...prevState, media: { ...prevState.media, resolution: [{ ...emptyResolutionMedia, ...prevState.media.resolution[0], images: prevState.media.resolution[0].images || [], title: e.target.value }] } }))
                                        }
                                        value={offer.media.resolution[0]?.title}
                                        autoComplete="off"
                                        placeholder="My offer title"
                                    />
                                </div>
                            </div>
                            <div className="widget-form-row">
                                <div className="unauth-label">
                                    <span>
                                        Description
                                    </span>
                                </div>
                                <div className={`widget-input`}>
                                    <textarea
                                        id="compact-description"
                                        name="compact-description"
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setOffer(prevState => prevState && ({ ...prevState, media: { ...prevState.media, resolution: [{ ...emptyResolutionMedia, ...prevState.media.resolution[0], images: prevState.media.resolution[0].images || [], description: e.target.value }] } }))}
                                        value={offer.media.resolution[0]?.description}
                                        aria-describedby="cnide"
                                        autoComplete="off"
                                        placeholder="My offer description"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </CollapsePanel>
            </Collapse>
        </div>
        */}
        {/* <div className="widget widget-icon mbot20">
            <Collapse activeKey={[featureAccordion ? 'feature' : '']} ghost>
                <CollapsePanel
                    showArrow={false}
                    forceRender={featureAccordion}
                    header={
                        <div className="widget-header widget-header-switch">
                            <label htmlFor="switch-feature" className="flex-center-space-between font-hover grow">
                                <h3 className="widget-s-title">
                                    Feature version of info and media
                                </h3>
                                <div>
                                    <span className="mright10 font-small font-color">
                                        Optimize for feature
                                    </span>
                                    <Switch id="switch-feature" className="widget-header-switch-checked" checked={featureAccordion} onChange={(checked: boolean) => setFeatureAccordion(checked)}></Switch>
                                </div>
                            </label>
                        </div>
                    }
                    key="feature">
                    <div className="label-normal font-color mbot20 mtop10">
                        Here you can set an extended version of the offer’s basic info. This info will override the default main info when the offer is shown on featured position.
                    </div>
                    <div className="offer-preview mbot20">
                        <span className="offer-preview-toptext">PREVIEW</span>
                        <div className="offer-preview-box feature">
                            <div className="offer-preview-image feature">
                                {offer.media?.featured?.images.length && <img src={offer.media?.featured.images[0].filePath} alt="feature" />}
                            </div>
                            <div className="offer-preview-content">
                                <div className="offer-preview-title">
                                    {offer.title || 'My offer title'}
                                </div>
                                <div className="offer-preview-description">
                                    {offer.description || 'my offer description'}
                                </div>
                            </div>
                        </div>

                        <span className="offer-preview-bottomtext">Texts may differ in the end result, depending on your typography settings.</span>
                    </div>
                    <div className="offer-form">
                        <div className="widget-form-row">
                            <div className="unauth-label">
                                <span>Image</span>
                            </div>
                            <div className={`voucher-uploader  ${offer.media?.featured?.images.length ? 'voucher-uploaded' : ''}  mbot20`}>
                                <Dragger
                                    customRequest={handleUploadFeatureRequest}
                                    name="avatar"
                                    listType="picture-card"
                                    showUploadList={false}
                                    multiple={false}
                                    disabled={false}
                                    beforeUpload={beforeUpload}
                                    maxCount={5}>
                                    {loadingFeatureImage ?
                                        <div>
                                            <LoadingOutlined />
                                            <div style={{ marginTop: 8 }}>Uploading</div>
                                        </div>
                                        :
                                        <>
                                            <>
                                                {offer.media?.featured?.images.map(i => <img src={i.filePath} alt="avatar" />)}
                                            </>

                                            <div className="grow">
                                                <Button type="link" className="mbot20" style={{ backgroundColor: "#D8F1E9" }} loading={false} disabled={false}>
                                                    <CloudUploadOutlined style={{ fontSize: 16 }} />
                                                    Upload</Button>
                                            </div>
                                            {!offer.media?.featured?.images.length && <>
                                                <p className="font-color">Drag & drop or click to upload any image file.</p>
                                                <p className="font-color">You can upload multiple images to be played as a slideshow.</p>
                                            </>
                                            }
                                        </>}
                                </Dragger>
                            </div>
                        </div>
                        <div className="widget-form-row">
                            <div className="unauth-label">
                                <span>Video url (optional)</span>
                                {offer.media.featured.featuredVideoURL.length > 0 && (
                                    <span className="unauth-label-error">
                                        <span>
                                            <img
                                                src={isValidUrl(offer.media.featured.featuredVideoURL) ? successIcon : failIcon}
                                                alt={isValidUrl(offer.media.featured.featuredVideoURL) ? "success" : "fail"}
                                            />
                                        </span>
                                    </span>
                                )}
                                {offer.media.featured.featuredVideoURL.length > 0 && !isValidUrl(offer.media.featured.featuredVideoURL) && (
                                    <span>Invalid url</span>
                                )}
                            </div>
                            <div className={`widget-input ${offer.media.featured.featuredVideoURL.length && !isValidUrl(offer.media.featured.featuredVideoURL) ? "widget-input-invalid" : ""}`}>
                                <input
                                    type="text"
                                    id="feature-video-url"
                                    name="feature-video-url"
                                    value={offer.media.featured.featuredVideoURL}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => prevState && ({ ...prevState, media: { ...prevState.media, featured: { ...prevState.media.featured, featuredVideoURL: e.target.value } } }))}
                                    aria-describedby="cnide"
                                    autoComplete="off"
                                    placeholder="Video url"
                                />
                            </div>
                        </div>
                    </div>
                </CollapsePanel>
            </Collapse>
        </div> */}
    </>
}

export default CreateOfferContent