import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, message } from "antd";
import Tooltip from "antd/es/tooltip";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import usePage from "../../hooks/usePage.hook";
import { Country, Pagination } from "../../types/global.interfaces";
import { Offer } from "../../types/offer.interfaces";
import { stringDateToMoment } from "../../utility/date";
import Footer from "../footer/footer.component";
import defaultOfferImg from "../../assets/images/offer-image.jpg";
import "./css/offers.scss";
import { useNavigate } from "react-router-dom";
import { ACCESS_TYPES } from "../../utility/enums/user.enums";
import { ApiErrorMessages } from "../../types/response.interfaces";
import { OFFER_TYPES } from "../../utility/enums/offer.enums";

const Offers = () => {
  const { setPage } = usePage();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [triggers, setTriggers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalOffers, setTotalOffers] = useState<number>(0);
  const navigate = useNavigate();

  const { company } = useAuth();
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 10
  });
  const [filters] = useState({
    advertiserIds: [],
    categories: [],
    exclusiveToCategories: [],
    exclusiveToRegions: [],
    brandswopExclusive: "all",
    status: "all",
    type: OFFER_TYPES.advertiser
  });

  const { myAxios } = useAxios();

  const fetchOffers = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({ method: 'post', url: `offers/getoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.data?.status) {
      setOffers(response.data.result.data);
      setTotalPages(response.data.result.totalPages);
      setTotalOffers(response.data.result.count);
      setTriggersFromOffers(response.data.result.data);
    } else {
      response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
        if (value.errorCode !== 12000) {
          message.error(value.message || `Failed to get offers`, 1);
        }
      });
    }

    setLoading(false);
  }

  // Functions to toggle offers description 
  const setTriggersFromOffers = (_offer: Offer[]) => {
    _offer.forEach((value: Offer) => {
      !triggers.includes(value.id) && setTriggers(prevState => ([...prevState, value.id]));
    });
  }

  const removeTriggerById = (id: string) => {
    setTriggers(prevState => prevState.filter(f => f !== id));
  }

  const sliceText = (text: string): string => {
    return text.length > 50 ? `${text.slice(0, 15)}` : text
  }

  useEffect(() => {
    if (company?.company_Id) {
      setPage({
        title: <>Offers</>
      });

      fetchOffers(company?.company_Id);
    }
    // eslint-disable-next-line
  }, [company?.company_Id, filters, pagination]);

  const handleStartOffer = async (id: string) => {
    const { response } = await myAxios({ method: 'POST', url: `offers/active/${id}/true` });
    if (response?.data?.status) {
      message.success("Offer is started", 1);
      company?.company_Id && fetchOffers(company?.company_Id);
    } else {
      response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
        if (value.errorCode !== 12000) {
          message.error(value.message || `Failed to start offer`, 1);
        }
      });
    }
  }

  const handleStopOffer = async (id: string) => {
    const { response } = await myAxios({ method: 'POST', url: `offers/active/${id}/false` });
    if (response?.data?.status) {
      message.success("Offer is stopped", 1);
      company?.company_Id && fetchOffers(company?.company_Id);
    } else {
      response?.data?.result?.length > 0 && response?.data?.result.forEach((value: ApiErrorMessages) => {
        if (value.errorCode !== 12000) {
          message.error(value.message || `Failed to stop offer`, 1);
        }
      });
    }
  }

  return <>
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          <li className="active">
            My brandswap offers
          </li>
          {/* 
          <li>
            My affiliate offers
          </li> */}
        </ul>
        <div className="content-action">
          {company?.userAccess?.access.includes(ACCESS_TYPES.offerwrite) &&
            <Button type="primary" onClick={() => navigate('/offers/create')} className="button-success">
              Create offer
            </Button>
          }
        </div>
      </div>

      <div className="content-layout">
        <div className="layout-options">
          <div className="filters">
            <div className="filter-dropdown">
              {/* <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                return <div className="dropdown">
                  <div className="dropdown-title">TOP 5 categories</div>
                  <ul>
                    <li>
                      <Checkbox className="checkbox-active">Clothing<span className="checkbox-value">6</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox defaultChecked={true} className="checkbox-active">Food<span className="checkbox-value">12</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox className="checkbox-active">Artificial intelligence<span className="checkbox-value">7</span></Checkbox>
                    </li>
                  </ul>
                </div>
              }}>
                <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                  Cateogries:
                  <span className="label-count">
                    2
                  </span>
                </span>
              </Dropdown>

              <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                return <div className="dropdown">
                  <div className="dropdown-title">Offer type</div>
                  <ul>
                    <li>
                      <Checkbox defaultChecked={true} className="checkbox-active">Promotions<span className="checkbox-value">20</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox className="checkbox-active">Vouchers<span className="checkbox-value">12</span></Checkbox>
                    </li>
                  </ul>
                </div>
              }}>
                <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                  Types
                </span>
              </Dropdown> */}
            </div>
          </div>
          <div className="layout-actions">

            {/* <Button type="primary">Test</Button> */}

            {/* <Dropdown menu={{ items: byRelevant }} trigger={['click']} >
              <div className="button-dropdown button-icon">
                By relevance <span className="icon small icon-arrow-down"></span>
              </div>
            </Dropdown> */}
          </div>

        </div>
        <div className="table-wrapper table-scrollable-wrapper">
          <div className="table-title">
            Offers available: {totalOffers || 0}
          </div>
          <div className="table-scrollable">
            <table>
              <thead>
                <tr>
                  <th>
                    Image
                  </th>
                  <th>
                    Title
                  </th>
                  <th>
                    Start-end
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    Regions
                  </th>
                  <th>
                    Description
                  </th>
                  <th>
                    Status
                  </th>
                </tr>
              </thead>

              <tbody>
                {company?.userAccess?.access.includes(ACCESS_TYPES.offerread) ?
                  <>
                    {offers.map((offer: Offer, index: number) => {
                      return <tr key={`offer-${index}`}>
                        <td>
                          <img className="table-image mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />
                        </td>
                        <td className="td-min-100">
                          {offer.title}
                        </td>
                        <td className="td-min-100">
                          {stringDateToMoment(offer.startDate).format("DD-MM-YYYY")}  {stringDateToMoment(offer.endDate).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {offer.type}
                        </td>
                        <td>
                          {!offer?.exclusiveTo?.regions?.length ? 'All' : offer?.exclusiveTo?.regions?.map((region: Country, index: number, arr: Country[]) => {
                            return index !== arr.length - 1 ? `${region.name}, ` : region.name
                          })}
                        </td>
                        <td className="break-word">
                          <Tooltip title={offer.description.length > 50 && triggers.includes(offer.id) ? offer.description : ''} >
                            <span className="font-color">{triggers.includes(offer.id) ? sliceText(offer.description) : offer.description}</span>
                            {offer.description.length > 50 && triggers.includes(offer.id) &&
                              <span className="font-active font-hover" onClick={() => removeTriggerById(offer.id)}>...</span>
                            }
                          </Tooltip>
                        </td>
                        <td>
                          <span className="table-actions">
                            {offer.status === "active" ?
                              <>
                                <span className={`font-label-${offer.status} min80 capitalize`}>{offer.displayStatus}</span>
                                {company?.userAccess?.access.includes(ACCESS_TYPES.offerwrite) &&
                                  <>
                                    <span className="label label-select table-label" onClick={() => navigate(`/offers/edit/${offer.id}`)}>Edit</span>
                                    <span className="table-icon icon-stop mleft20" onClick={() => handleStopOffer(offer.id)}></span>
                                  </>
                                }
                              </>
                              :
                              <>
                                <span className={`font-label-${offer.status} min80 capitalize`}>{offer.displayStatus}</span>
                                {company?.userAccess?.access.includes(ACCESS_TYPES.offerwrite) &&
                                  <>
                                    <span className="label label-select table-label" onClick={() => navigate(`/offers/edit/${offer.id}`)}>Edit</span>
                                    <span className="table-icon icon-start mleft20" onClick={() => handleStartOffer(offer.id)}></span>
                                  </>
                                }
                              </>
                            }
                          </span>
                        </td>
                      </tr>
                    })}

                    {loading ?
                      <tr className={offers.length > 0 ? 'loader-absolute' : ''}>
                        <td className="text-center" colSpan={7}>
                          <LoadingOutlined />
                        </td>
                      </tr>
                      :
                      <>
                        {offers.length === 0 &&
                          <tr>
                            <td className="text-center" colSpan={7}>
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No offers" />
                            </td>
                          </tr>
                        }
                      </>
                    }
                  </> :
                  <>
                    <tr>
                      <td className="text-center" colSpan={7}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You are not authorized to access this resourse" />
                      </td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="pagination">

          <div className="pagination-prev" onClick={() => pagination.page > 1 && setPagination(prevState => ({ ...prevState, page: prevState.page - 1 }))}>
            <span className="pagination-box"></span>
            <span className="pagination-label">Preview</span>
          </div>

          <ul>
            <>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page: number) => {
                if ((page < pagination.page + 3 && page > pagination.page - 3) || page === 1 || page === totalPages) {
                  return <React.Fragment key={page}> {page > 2 && page + 2 <= pagination.page && <span className="icon icon-pagination"></span>}
                    <li onClick={() => page !== pagination.page && setPagination(prevState => ({ ...prevState, page }))} className={page === pagination.page ? 'active' : ''}>
                      <span className="pagination-box">{page}</span>
                    </li>
                    {page + 1 < totalPages && page - 2 >= pagination.page && <span className="icon icon-pagination"></span>}
                  </React.Fragment>
                }
                return <React.Fragment key={`rf${page}`}></React.Fragment>
              })}
            </>
          </ul>

          <div className="pagination-next" onClick={() => pagination.page < totalPages && setPagination(prevState => ({ ...prevState, page: prevState.page + 1 }))}>
            <span className="pagination-label">Next</span>
            <span className="pagination-box"></span>
          </div>

        </div> */}
        <div className="pagination">

          <div className="pagination-prev" onClick={() => pagination.page > 1 && setPagination(prevState => ({ ...prevState, page: prevState.page - 1 }))}>
            {pagination.page > 1 &&
              <>
                <span className="pagination-box"></span>
                <span className="pagination-label">Preview</span>
              </>
            }
          </div>

          <ul>
            <>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page: number) => {
                return <li key={page} onClick={() => page !== pagination.page && setPagination(prevState => ({ ...prevState, page }))} className={page === pagination.page ? 'active' : ''}>
                  <span className="pagination-box">{page}</span>
                </li>
              })}
            </>
          </ul>

          <div className="pagination-next" onClick={() => pagination.page < totalPages && setPagination(prevState => ({ ...prevState, page: prevState.page + 1 }))}>
            {pagination.page < totalPages &&
              <>
                <span className="pagination-label">Next</span>
                <span className="pagination-box"></span>
              </>
            }
          </div>

        </div>

      </div>

      <Footer />
    </div>
  </>;
}

export default Offers;
