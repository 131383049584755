import { useEffect } from "react";
import { LayoutClass, LayoutDevices } from "../../../../const/views";
import { CampaignType, DealLayoutSettings, MainLayoutSettings, PlacementSettings } from "../../../../../../types/campaign.interfaces";
import { Template } from "../../../../../../types/templates.interfaces";
import ViewEmbeddedS from "./settings/SettingsEs.component";
import { ButtonShapes, ElementSettings, LocationSettings } from "../../../../../templates-previews/types/params.interfaces";
import PreviewEmbeddedCompact from "./preview/PreviewEmbeddedCompact.component";

interface Props {
    currentPlacement: PlacementSettings,
    setCurrentPlacement: React.Dispatch<React.SetStateAction<PlacementSettings | undefined>>,
    campaign: CampaignType,
    layoutView: LayoutDevices,
    offersCount: string,
    displayOnly: boolean,
    setTemplate: React.Dispatch<React.SetStateAction<Template | undefined>>,
}

const TemplateEmbeddedCompact = ({ campaign, layoutView, currentPlacement, offersCount, setCurrentPlacement, displayOnly }: Props) => {

    const getPreviewTitle = () => {
        return currentPlacement?.promoMessage && currentPlacement.promoMessage !== ' ' ? currentPlacement.promoMessage : campaign.description
    }

    const setMainButtonShapeSettings = (shape: ButtonShapes): void => {
        if (currentPlacement) {
            var newPlacement = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        button:
                        {
                            ...currentPlacement.templateSettings[layoutView].button,
                            shape
                        }
                    }

                }
            };
            setCurrentPlacement(newPlacement);
        }
    }

    const setMainLayoutSettings = (mainLayout: MainLayoutSettings): void => {
        if (currentPlacement) {
            var newPlacement = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        settings:
                        {
                            ...currentPlacement.templateSettings[layoutView].settings,
                            mainLayout
                        }
                    }

                }
            };
            setCurrentPlacement(newPlacement);
        }
    }

    const setDealLayoutSettings = (dealLayout: DealLayoutSettings): void => {
        if (currentPlacement) {
            var newPlacement = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        settings:
                        {
                            ...currentPlacement.templateSettings[layoutView].settings,
                            dealLayout
                        }
                    }

                }
            };
            setCurrentPlacement(newPlacement);
        }
    }

    const setLocationSettings = (location: LocationSettings): void => {
        if (currentPlacement) {
            var newPlacement = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        settings:
                        {
                            ...currentPlacement.templateSettings[layoutView].settings,
                            location
                        }
                    }

                }
            };
            setCurrentPlacement(newPlacement);
        }
    }

    const setElementSettings = (settings: ElementSettings): void => {
        if (currentPlacement) {
            var newPlacement = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        settings:
                        {
                            ...currentPlacement.templateSettings[layoutView].settings,
                            embeddedElement: settings
                        }
                    }

                }
            };
            setCurrentPlacement(newPlacement);
        }
    }

    useEffect(() => {
        if (currentPlacement) {
            var newPlacement: PlacementSettings = {
                ...currentPlacement,
                templateSettings:
                {
                    ...currentPlacement.templateSettings,
                    [layoutView]: {
                        ...currentPlacement.templateSettings[layoutView],
                        type: LayoutClass.inline
                    }
                }
            };
            setCurrentPlacement(newPlacement);
        }
        // eslint-disable-next-line 
    }, [])

    return <>

        <div className="widget-layout">

            <div className="interaction-pages">
                <div className="interaction-pages-overlay">

                    <div className="interaction-section">
                        <ViewEmbeddedS updateMainButtonShapeSettings={setMainButtonShapeSettings} currentPlacement={currentPlacement} defaultTitle={campaign.description} updateLocationSettings={setLocationSettings} updateMainLayoutSettings={setMainLayoutSettings} updateDealLayout={setDealLayoutSettings} updateElementSettings={setElementSettings} layoutDevice={layoutView} campaignId={campaign.id || 'campaignNoId'} />
                    </div>
                </div>
            </div>

            <div id="interaction-view" className="interaction-view">

                <div className={`view-content view-embedded ${layoutView === 'desktop' ? 'desktop' : 'mobile'}`}>
                    <PreviewEmbeddedCompact
                        description={getPreviewTitle()}
                        campaign={campaign}
                        displayOnly={displayOnly}
                        settings={currentPlacement?.templateSettings[layoutView].settings}
                        buttonShape={currentPlacement?.templateSettings[layoutView]?.button.shape}
                        offersCount={offersCount}
                    />
                </div>
            </div>
        </div>
    </>;
}

export default TemplateEmbeddedCompact;
