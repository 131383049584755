import axios, { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse } from "../../types/response.interfaces";

const apiConfig: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

export async function getOnboardingExpress(id: string): Promise<void | AxiosResponse<ApiResponse, any>> {
    return await apiConfig
        .get<ApiResponse>(`/onboarding/express/${id}`)
        .catch((error) => {
            console.log(error);
        });
}
