import { CampaignType } from "../../../types/campaign.interfaces";
import TemplatePopup from "../templates/template-default/TemplatePopup.component";
import { ButtonShapes, PopupSettings } from "../types/params.interfaces";


interface Props {
  description: string,
  settings: PopupSettings,
  campaign: CampaignType,
  displayOnly: boolean,
  buttonShape: ButtonShapes,
}

const PopupLayout = ({ description, settings, campaign, displayOnly, buttonShape }: Props) => {
    // const urlParamsQuery = new URLSearchParams(window.location.search);
    // const position = urlParamsQuery.get("position");
    // const isIncluded = Object.values(urlParamsPosition).includes(position as urlParamsPosition || '');
    // console.log(position);

    return (
      <>
         <TemplatePopup displayOnly={displayOnly} buttonShape={buttonShape} description={description} campaign={campaign} settings={settings} />
      </>
    );
  }
  
  export default PopupLayout;