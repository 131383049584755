export interface urlParams {
    position?: LocationPosition
}

export interface ButtonOptions {
    location: LocationPosition,
    text: string,
    backgroundColor: string,
    textColor: string,
    fontFamily: string,
    fontWeight: number,
    fontSize: string,
    borderRadius: number,
    isInline: boolean,
    inlineId: string,
    inlineFullWidth: boolean,
    inlineWidth: number,
    inlineHeight: number,
}

export interface ButtonSettings {
    location: ButtonLocationSettingsT,
    text: string,
    sizes: ButtonSizesSettings,
    shape: ButtonShapes,
    style: ButtonStyleSettingsT,
    number: ButtonNumberSettingsT
}

export interface PopupSettings {
    minimized: boolean,
    horizontal: string,
    general: GeneralSettings,
    location: LocationSettings,
    header: TextSettings,
    text: TextSettings,
    image: ImageSettings,
    getButton: GetButtonSettings,
    imageRepresentation: ImageRepresentationSettings
}

export interface ActionSettings {
    minimized: boolean,
    horizontal: string,
    general: GeneralSettings,
    location: LocationSettings,
    behaviour: BehaviourSettings,
    header: TextSettings,
    text: TextSettings,
    image: ImageSettings,
    getButton: GetButtonSettings,
    imageRepresentation: ImageRepresentationSettings
}

export interface InlineSettings {
    minimized: boolean,
    element: ElementSettings,
    general: GeneralSettings,
    header: TextSettings,
    text: TextSettings,
    image: ImageSettings,
    getButton: GetButtonSettings,
    imageRepresentation: ImageRepresentationSettings
}

export interface ElementSettings {
    attribute: string,
    existingElement: boolean,
    elementType: 'id' | 'class',
    fullWidth: boolean,
    width: number,
    height: number,
    marginType: '%' | 'px',
    margin: number,
    paddingTop: number,
    paddingBottom: number,
    extraElementSelector: string,
    extraElementType: 'id' | 'class',
    extraElementBackground: string,
    extraElementColor: string,
    extraElementText: string,
    extraElementTextBold: string,
}

export interface GeneralSettings {
    backgroundColor: string,
    offers: string,
    align: TextAlign,
}

export interface TextSettings {
    textColor: string,
    fontFamily: string,
    fontWeight: number,
    fontSize: string,
    textTransform: TextTransform,
}

export interface GetButtonSettings {
    backgroundColor: string,
    borderColor?: string,
    textTransform?: TextTransform,
    textColor: string,
    fontFamily: string,
    fontSize: string,
    fontWeight: number,
    rounding: number,
}

export interface BehaviourSettings {
    displayBy: DisplayBy,
    afterTime: number,
}

export interface LocationSettings {
    location: LocationPosition,
    transition: number,
    x?: number,
    y?: number,
}

export interface ButtonLocationSettingsT {
    location: LocationPosition,
    x?: number,
    y?: number,
}

export interface ButtonSizesSettings {
    type?: 'flex' | 'fixed',
    w: number,
    h: number,
    circle?: number,
}

export interface ButtonStyleSettingsT {
    textColor: string,
    fontFamily: string,
    fontWeight: number,
    fontSize: string,
    textTransform: TextTransform,
    backgroundColor: string,
    borderColor?: string,
    borderTopLeftRadius?: number,
    borderTopRightRadius?: number,
    borderBottomLeftRadius?: number,
    borderBottomRightRadius?: number,
    circleSize?: number,
}

export interface ButtonNumberSettingsT {
    display: boolean,
    backgroundColor: string,
    textColor: string,
}

export interface ImageSettings {
    size: ImageSizes,
    rounding: number,
}

export interface ImageRepresentationSettings {
    position: ImageRepresentation
}

export enum ImageSizes {
    xsmall = "xsmall",
    small = "small",
    medium = "medium",
    large = "large",
}

export enum DisplayBy {
    time = "time",
    scroll = "scroll"
}


export enum TextTransform {
    uppercase = "uppercase",
    capitalize = "none",
}

export enum ButtonShapes {
    roundingAll = "roundingAll",
    roundingTop = "roundingTop",
    roundingBottom = "roundingBottom",
    square = "square",
    circle = "circle",
    squareImage = "squareImage",
    squareImageLock = "squareImageLock"
}

export enum LocationPosition {
    bottom = "bottom",
    left = "left",
    center = "center",
    right = "right",
    top = "top",
    topLeft = "topLeft",
    topRight = "topRight",
    bottomLeft = "bottomLeft",
    bottomRight = "bottomRight"
}

export enum TextAlign {
    start = "start",
    left = "left",
    right = "right",
    center = "center",
    justify = "justify"
}

export enum ImageRepresentation {
    top = "top",
    left = "left",
    right = "right",
}