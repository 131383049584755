import "../css/express.scss";
import logoIcon from "../../../assets/images/brandswap-logo-checkout-marketing-black.svg";
import { LoadingOutlined } from "@ant-design/icons";
import useAuth from "../../../hooks/useAuth.hook";
import useExpress from "../../../hooks/useExpress.hook";
import ExpressOnboarding from "../onboarding/expressOnboarding.component";
import { Navigate } from "react-router-dom";

const ExpressUnAuth = () => {
  const { auth } = useAuth();
  const { campaign, company, onboardingStatus, loading } = useExpress();

  return <>
    {auth && auth.onboardingStatus.completed ?
      <>
        <Navigate to="/express/dashboard" />
      </>
      :
      <>
        <div className="express">

          <div className="express-header">
            <img className="express-logo" src={logoIcon} alt="Brandswap logo" />
          </div>
          <div className="express-content">
            {loading ? <>
              <div className="flex-center">
                <LoadingOutlined />
              </div>
            </>
              :
              <>
                {campaign && company && onboardingStatus ?
                  <>
                    <ExpressOnboarding />
                  </>
                  :
                  <div className="flex-center">

                  </div>
                }
              </>
            }
          </div>


        </div>
      </>
    }
  </>;
}

export default ExpressUnAuth;
