import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import failIcon from "../../../../assets/images/icons/fail.svg";
import successIcon from "../../../../assets/images/icons/success.svg";
import { OnboardingSteps } from "../../interfaces";
import { ONBOARDING } from "../../const/onboarding.enum";
import { EMAIL_REGEX, NAME_REGEX, PWD_REGEX } from "../../../../utility/regexp";
import { OnboardingRegister } from "../../../../types/onboarding.interfaces";
import { ApiErrorMessages } from "../../../../types/response.interfaces";
import { VALIDATION } from "../../../../utility/enums/validation.enums";
import { UserLoginResponse } from "../../../../types/user.interfaces";
import { Button } from "antd";
import useAxios from "../../../../hooks/useAxios.hook";


export interface Props {
    changeStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>,
    setUser: React.Dispatch<React.SetStateAction<Partial<UserLoginResponse> | undefined>>
}

const StepUserDetails = ({ changeStep, setUser }: Props) => {
    const navigate = useNavigate();
    const { response, loading, axiosFetch } = useAxios();
    const userRef = useRef<HTMLInputElement>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<undefined | string>();

    const handleSignInClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        navigate("/login");
    };

    const [firstName, setFirstName] = useState<string>("");
    const [validFirstName, setValidFirstName] = useState<boolean>(false);
    const [firstNameFocus, setFirstNameFocus] = useState<boolean>(false);

    const [lastName, setLastName] = useState<string>("");
    const [validLastName, setValidLastName] = useState<boolean>(false);
    const [lastNameFocus, setLastNameFocus] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");
    const [validEmail, setValidEmail] = useState<boolean>(false);

    const [repeatEmail, setRepeatEmail] = useState<string>("");
    const [validRepeatEmail, setValidRepeatEmail] = useState<boolean>(false);

    const [password, setPassword] = useState<string>("");
    const [validPassword, setValidPassword] = useState<boolean>(false);

    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [validRepeatPassword, setValidRepeatPassword] =
        useState<boolean>(false);

    // Set focus to first name
    useEffect(() => {
        if (userRef.current) userRef.current.focus();
    }, []);

    // Validate first name
    useEffect(() => {
        const isValid = NAME_REGEX.test(firstName);
        setValidFirstName(isValid);
    }, [firstName]);

    // Validate last name
    useEffect(() => {
        const isValid = NAME_REGEX.test(lastName);
        setValidLastName(isValid);
    }, [lastName]);

    // Validate email on change
    useEffect(() => {
        const isValid = EMAIL_REGEX.test(email);
        setValidEmail(isValid);
    }, [email]);

    // Validate repeat email
    useEffect(() => {
        const isRepeatEmail = email.toLowerCase() === repeatEmail.toLowerCase();
        setValidRepeatEmail(isRepeatEmail);
    }, [email, repeatEmail]);

    // Validate password and repeat password
    useEffect(() => {
        const isValid = PWD_REGEX.test(password);
        setValidPassword(isValid);
        const isRepeatPassword = password === repeatPassword;
        setValidRepeatPassword(isRepeatPassword);
    }, [password, repeatPassword]);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);
        if (firstName && lastName && email && password) {
            const userRegister: OnboardingRegister = {
                firstname: firstName,
                lastname: lastName,
                email,
                password
            }
            await axiosFetch({ method: 'post', url: '/onboarding/register', data: userRegister });
        } else {
            setErrorMessage("Form is not valid!");
        }
        setLoading(loading);
    }

    // Handle submit response 
    useEffect(() => {
        if (response && response?.status) {
            setUser(response.result);
            changeStep({ currentStep: ONBOARDING.verification });
        } else {
            response?.result?.length > 0 && response?.result.forEach((value: ApiErrorMessages) => {
                setErrorMessage(value.message);
                value.errorCode === 1200 && setValidEmail(false);
            });
        }

        // eslint-disable-next-line 
    }, [response])

    return (
        <div className="widget unauth-widget">
            <form className="widget-form large-form user-details">
                <h3 className="widget-form-title">Enter your details, please</h3>
                <div className="widget-form-description">
                    <span>Already have an account? </span>
                    <span className="font-active font-hover semibold" onClick={handleSignInClick}>
                        Sign In
                    </span>
                </div>

                <div className="widget-multiple-form-row">
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>First name</span>
                            {firstName.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validFirstName ? successIcon : failIcon}
                                            alt={validFirstName ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validFirstName && firstNameFocus && (
                                        <span id="nide">Must be 2 to 24 characters.</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${firstName && !validFirstName ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="text"
                                id="first-name"
                                ref={userRef}
                                name="unauth-first-name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFirstName(e.target.value)
                                }
                                onFocus={() => setFirstNameFocus(true)}
                                onBlur={() => setFirstNameFocus(false)}
                                aria-invalid={validFirstName ? "true" : "false"}
                                aria-describedby="fide"
                                autoComplete="off"
                            />
                            <p
                                id="fide"
                                className={`error-line ${firstName && firstNameFocus && !validFirstName
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.nameValid}
                            </p>
                        </div>
                    </div>
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Last name</span>
                            {lastName.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validLastName ? successIcon : failIcon}
                                            alt={validLastName ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validLastName && lastNameFocus && (
                                        <span id="lide">Must be 3 to 24 characters.</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${lastName && !validLastName ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="text"
                                id="last-name"
                                name="unauth-last-name"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setLastName(e.target.value)
                                }
                                onFocus={() => setLastNameFocus(true)}
                                onBlur={() => setLastNameFocus(false)}

                                aria-invalid={validLastName ? "true" : "false"}
                                aria-describedby="lide"
                                autoComplete="off"
                            />
                            <p
                                id="lide"
                                className={`error-line ${lastName && lastNameFocus && !validLastName
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.nameValid}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="widget-multiple-form-row">
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Email</span>
                            {email && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validEmail ? successIcon : failIcon}
                                            alt={validEmail ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validEmail && emailFocus && (
                                        <span id="eide">Must be valid email!</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${email && !validEmail ? "widget-input-invalid" : ""}`}
                        >
                            <input
                                type="email"
                                id="email"
                                name="unauth-email"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }
                                aria-invalid={validEmail ? "true" : "false"}
                                aria-describedby="eide"
                                autoComplete="off"
                            />
                            <p
                                id="eide"
                                className={`error-line ${email && !validEmail && !errorMessage
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.emailValid}
                            </p>
                        </div>
                    </div>
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Email repeat</span>
                            {repeatEmail && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validRepeatEmail ? successIcon : failIcon}
                                            alt={validRepeatEmail ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validRepeatEmail && repeatEmailFocus && (
                                        <span id="eide">Must be the same email!</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${repeatEmail && !validRepeatEmail ? "widget-input-invalid" : ""}`}
                        >
                            <input
                                type="repeatemail"
                                id="repeat-email"
                                name="unauth-repeat-email"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setRepeatEmail(e.target.value)
                                }
                                aria-invalid={validRepeatEmail ? "true" : "false"}
                                aria-describedby="reid"
                                autoComplete="off"
                            />
                            <p
                                id="reid"
                                className={`error-line ${repeatEmail && !validRepeatEmail
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.sameAsPrevious}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="widget-multiple-form-row">
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Password</span>
                            {password.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validPassword ? successIcon : failIcon}
                                            alt={validPassword ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validPassword && passwordFocus && (
                                        <span id="pide">
                                            Must have at least 6 characters, include uppercase, lowercase
                                            letters and a number.
                                        </span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
            widget-input ${password && !validPassword ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="password"
                                id="pwd"
                                name="unauth-pwd"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPassword(e.target.value)
                                }
                                aria-invalid={validPassword ? "true" : "false"}
                                aria-describedby="pide"
                                autoComplete="off"
                            />
                            <p
                                id="pide"
                                className={`error-line ${password && !validPassword
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.passwordValid}
                            </p>
                        </div>
                    </div>

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Repeat password</span>
                            {repeatPassword.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validRepeatPassword ? successIcon : failIcon}
                                            alt={validRepeatPassword ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validRepeatPassword && repeatPasswordFocus && (
                                        <span id="pide">Password must match the previous</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
            widget-input ${repeatPassword && !validRepeatPassword ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="password"
                                id="repeatpwd"
                                name="unauth-repeat-pwd"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setRepeatPassword(e.target.value)
                                }
                                aria-invalid={validRepeatPassword ? "true" : "false"}
                                aria-describedby="rpide"
                            />
                            <p
                                id="rpide"
                                className={`error-line ${repeatPassword && !validRepeatPassword
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.sameAsPrevious}
                            </p>
                        </div>
                    </div>
                </div>
                {errorMessage &&
                    <div className="widget-form-error">
                        <p className="error-show error-line">
                            {errorMessage}
                        </p>
                    </div>
                }

                <div className="widget-form-actions unauth-button">
                    <div className="widget-form-button">
                        <Button
                            type="default"
                            className="button-success"
                            onClick={handleSubmit}
                            loading={isLoading}
                            htmlType="submit"
                            disabled={
                                !validFirstName ||
                                !validLastName ||
                                !validEmail ||
                                !validRepeatEmail ||
                                !validPassword ||
                                !validRepeatPassword
                            }
                        >
                            Next
                        </Button>
                    </div>
                </div>


            </form>
        </div>
    );
}

export default StepUserDetails;
