import { useEffect, useRef, useState } from "react"
import { Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react"
import { CampaignType, TemplateOptionSettings } from "../../../../../../../types/campaign.interfaces"
import { Offer } from "../../../../../../../types/offer.interfaces"
import OfferItem from "../preview/offer-item/OfferItem.component"

interface Props {
    displayOnly: boolean,
    settings: TemplateOptionSettings,
    campaign: CampaignType,
    width: number,
}

const SwiperComponent = ({ displayOnly, settings, campaign, width }: Props) => {
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const refNavPrev = useRef<HTMLDivElement | null>(null);
    const refNavNext = useRef<HTMLDivElement | null>(null);

    // swiper item that are fully displayed with margin 20px
    const swiperItemDisplayed = Math.floor(width / 370);

    const [noNavigation, setNoNav] = useState<boolean>(refNavNext.current?.classList.contains('swiper-button-lock') || false);

    useEffect(() => {
        if (refNavNext.current) {
            setNoNav(refNavNext.current.classList.contains('swiper-button-lock'));
        }
    }, [swiper]);

    return <>
        {campaign?.offers.length > 0 &&
            <div className={`inline-nlh-swiper-wrapper ${noNavigation ? 'no-navigation' : ''}`}>
                <Swiper
                    className={`swiper-container swiper-inline`}
                    modules={[Navigation, Pagination]}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination'
                    }}
                    navigation={{
                        prevEl: refNavPrev.current,
                        nextEl: refNavNext.current,
                    }}
                    onSwiper={(s: SwiperClass) => setSwiper(s)}
                    onBeforeInit={(swiper: any) => {
                        swiper.params.navigation.prevEl = refNavPrev.current;
                        swiper.params.navigation.nextEl = refNavNext.current;
                    }}
                    slidesPerView={'auto'}
                    spaceBetween={10}
                    loop={swiperItemDisplayed < campaign.offers.length}
                    loopAddBlankSlides={true}
                >
                    {campaign?.offers.map((o: Offer, i: number) => {
                        return <SwiperSlide key={`slide${i}`} style={{ color: settings?.dealLayout.textColor }}>
                            <OfferItem offer={o} campaign={campaign} settings={settings?.dealLayout} displayOnly={displayOnly} />
                        </SwiperSlide>
                    })}
                    <div className="swiper-navigation">
                        <div className="swiper-button-prev" ref={refNavPrev}>
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="17" transform="rotate(-180 17 17)" fill={settings.dealLayout.backgroundColor} />
                                <rect x="18.6094" y="23" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(-135 18.6094 23)" fill={settings.dealLayout.textColor} />
                                <rect x="20.3135" y="12.2188" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(135 20.3135 12.2188)" fill={settings.dealLayout.textColor} />
                            </svg>
                        </div>

                        <div className="swiper-pagination"></div>
                        <div className="swiper-button-next" ref={refNavNext}>
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="17" cy="17" r="17" fill={settings.dealLayout.backgroundColor} />
                                <rect x="15.3906" y="11" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(45 15.3906 11)" fill={settings.dealLayout.textColor} />
                                <rect x="14.1719" y="22.2656" width="8.6192" height="1.72384" rx="0.86192" transform="rotate(-45 14.1719 22.2656)" fill={settings.dealLayout.textColor} />
                            </svg>
                        </div>
                    </div>
                </Swiper>
            </div>
        }
    </>
}


export default SwiperComponent