import { Button, message, Modal, Switch, Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import { LayoutChildClass, LayoutClass, LayoutDevices } from "../../../../campaigns/const/views";
import { offer } from "../../../const/campaigns.enum";
import { ExpressCampaign, ExpressCompany, ExpressPlacementSettings } from "../../../types/express.types";
import ExpressPrePreview from "../../expressPreview/ExpressPrePreview.component";
import ExpressTemplateButton from "../../expressPreview/ExpressTemplateButton.component";
import ExpressTemplatePopup from "../../expressPreview/ExpressTemplatePopup.component";
import ExpressPopupView from "./Views/PopupView.component";
import ExpressButtonView from "./Views/ButtonView.component";
import useExpress from "../../../../../hooks/useExpress.hook";
import { EyeOutlined, InfoCircleOutlined, LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { ExpressPage } from "../../../const/views";
import useAxios from "../../../../../hooks/useAxios.hook";

interface Props {
    isSingle: boolean,
    currentPlacement: ExpressPlacementSettings,
    setCurrentPlacement: (placement: ExpressPlacementSettings | undefined) => void;
}

const ExpressLayoutSettings = ({ setCurrentPlacement, currentPlacement }: Props) => {
    const { campaign, company, setExpressPage, setCampaign, onboardingStatus } = useExpress();
    const [layoutView, setLayoutView] = useState<LayoutDevices>(LayoutDevices.desktop);
    const [optionsView, setOptionsView] = useState<LayoutChildClass>(currentPlacement.templateSettings[layoutView].type === LayoutClass.action ? LayoutChildClass.popup : LayoutChildClass.button);
    const [lock, setLock] = useState<boolean>(true);
    const { myAxios } = useAxios();
    const [loading, setLoading] = useState<boolean>(false);

    const handleStartAsExpand = (value: boolean) => {
        setOptionsView(value ? LayoutChildClass.popup : LayoutChildClass.button);
        if (currentPlacement) {
            lock ?
                setCurrentPlacement(
                    {
                        ...currentPlacement,
                        templateSettings: {
                            desktop: {
                                ...currentPlacement.templateSettings.desktop,
                                type: value ? LayoutClass.action : LayoutClass.button
                            },
                            mobile: {
                                ...currentPlacement.templateSettings.mobile,
                                type: value ? LayoutClass.action : LayoutClass.button
                            }
                        }
                    })
                :
                setCurrentPlacement(
                    {
                        ...currentPlacement,
                        templateSettings: {
                            ...currentPlacement?.templateSettings,
                            [layoutView]: {
                                ...currentPlacement.templateSettings[layoutView],
                                type: value ? LayoutClass.action : LayoutClass.button
                            }
                        }
                    });
        }
    }

    const getTabOrderItems = useMemo(() => {
        const items = [
            {
                label: <>Button</>,
                key: LayoutChildClass.button,
                children: <>
                    <ExpressButtonView key={`expbtnview-${layoutView}`} sync={lock} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
                </>,
            },
            {
                label: <>Pop up</>,
                key: LayoutChildClass.popup,
                children: <>
                    <ExpressPopupView key={`exppview-${layoutView}`} sync={lock} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
                </>
            }
        ];
        return currentPlacement?.templateSettings[layoutView].type === LayoutClass.action ? items.reverse() : items;

    }, [currentPlacement, layoutView, setCurrentPlacement, lock])


    const type = useMemo(() => {
        if (currentPlacement) {
            var _type: LayoutClass.popup | LayoutClass.action = currentPlacement.templateSettings[layoutView].type === LayoutClass.action ? LayoutClass.action : LayoutClass.popup;
            return _type
        }
        return LayoutClass.popup
    }, [currentPlacement, layoutView])

    useEffect(() => {
        setExpressPage(ExpressPage.layout);
        // eslint-disable-next-line
    }, []);

    const handleSave = () => {
        if (currentPlacement && campaign && company) {
            const newPlacements: ExpressPlacementSettings[] = campaign.placementSettings.map((p: ExpressPlacementSettings) => {
                return p.id === currentPlacement.id ? currentPlacement : p;
            });

            updateExpressById(company, { ...campaign, placementSettings: newPlacements })
        }
    }

    const updateExpressById = async (_company: ExpressCompany, _campaign: ExpressCampaign) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'POST', url: `express/${_company.id}`, data: { company: _company, campaign: _campaign } });
        if (response?.data?.status) {
            setCampaign(_campaign);
            setCurrentPlacement(undefined);
            message.success({
                content: "Your campaign is updated",
                className: 'express-message',
                duration: 1,
            });
        } else {
            message.error("Failed to save campaign!", 1);
        }
        setLoading(false);
    }



    const handlePreview = (page?: string) => {
        page && window.open(`${page}?bsw_preview=1`, '_blank');
    }


    const openConfirmModal = (url: string) => {
        Modal.confirm({
            title: 'This is not a preview of your order confirmation page but it’s a demonstration of how deals are presented on the order confirmation page in general.',
            content: 'On your actual page, we will use the deals you have selected and also the design you have selected.',
            okText: 'Continue',
            icon: <></>,
            maskClosable: true,
            width: 520,
            closable: true,
            closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-express-success'
            },
            onOk: () => handlePreview(url)
        })
    }

    return <>
        <div className="express-layout-header">
            <div className="express-layout-info">
                <div className="layout-name">
                    {currentPlacement.name}
                </div>
                <div className="layout-url">
                    <span className="layout-globe-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 8C15 11.866 11.866 15 8 15M15 8C15 4.13401 11.866 1 8 1M15 8H1M8 15C4.13401 15 1 11.866 1 8M8 15C9.7509 13.0832 10.7459 10.5956 10.8 8C10.7459 5.40442 9.7509 2.91685 8 1M8 15C6.2491 13.0832 5.25407 10.5956 5.2 8C5.25407 5.40442 6.2491 2.91685 8 1M1 8C1 4.13401 4.13401 1 8 1" stroke="#C9CFD6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                    {currentPlacement?.page}
                </div>
            </div>
            {onboardingStatus?.completed &&
                <div className="express-layout-actions">
                    <Button className="express-button-dark mright10 min100" onClick={() => currentPlacement.displayOnly ? handlePreview(currentPlacement.page) : openConfirmModal('https://brandswap.myshopify.com/pages/order-confirmation-page')} type="default"><EyeOutlined /> Preview</Button>
                    <Button className="express-button-success min100" loading={loading} onClick={handleSave}>Save</Button>
                </div>
            }
        </div>

        <div className="widget-express widget-express-layout">
            <div className="express-layout-sidebar">

                <div className="express-sidebar-row">

                    <div className="option-content">
                        <div className="option-single-col">
                            {currentPlacement.displayOnly &&
                                <>
                                    <Switch id="minimizedAction" size="small" defaultChecked={currentPlacement.templateSettings[layoutView].type === LayoutClass.action} onChange={handleStartAsExpand} />
                                    <label htmlFor="minimizedAction" className="font-hover mleft5">
                                        Start as expanded <InfoCircleOutlined id="guide-start-as-expanded" />
                                    </label>
                                </>}
                        </div>
                    </div>

                </div>
                <Tabs
                    centered
                    onChange={(key: string) => setOptionsView(key as LayoutChildClass)}
                    activeKey={optionsView}
                    items={getTabOrderItems} />
            </div>
            <div className="express-layout-preview">
                <div className="flex-center">
                    <div className="item-body-device">
                        <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.desktop ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.desktop)}>
                            <svg className="desktop" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5693 1H3.12229C2.33203 1 1.69141 1.59695 1.69141 2.33333V9C1.69141 9.73638 2.33203 10.3333 3.12229 10.3333H14.5693C15.3596 10.3333 16.0002 9.73638 16.0002 9V2.33333C16.0002 1.59695 15.3596 1 14.5693 1Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.98242 13H11.7059" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8.8457 10.3335V13.0002" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.mobile ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.mobile)}>
                            <svg className="mobile" width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.74358 1.43604H2.4615C1.7676 1.43604 1.20508 1.99855 1.20508 2.69245V12.7438C1.20508 13.4377 1.7676 14.0002 2.4615 14.0002H8.74358C9.43748 14.0002 10 13.4377 10 12.7438V2.69245C10 1.99855 9.43748 1.43604 8.74358 1.43604Z" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.60156 11.4868H5.60806" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="layout-lock">
                    <span className="lock-left-border"></span>
                    {lock ?
                        <LockOutlined onClick={() => setLock(false)} />
                        :
                        <UnlockOutlined onClick={() => setLock(true)} />
                    }
                    <span className="lock-right-border"></span>
                </div>
                <div className={`express-template-layout  ${layoutView === 'desktop' ? 'desktop' : 'mobile'}`} >
                    <div className="template-layout">
                        <ExpressPrePreview confirmation={false} title={currentPlacement.name} />
                    </div>

                    {optionsView === LayoutChildClass.popup &&
                        <>
                            {currentPlacement?.templateSettings[layoutView][type] &&
                                <ExpressTemplatePopup buttonShape={currentPlacement.templateSettings[layoutView].button.shape} settings={currentPlacement.templateSettings[layoutView][type]} offers={campaign && campaign?.offers.length > 0 ? campaign?.offers : [offer]} displayOnly={currentPlacement.displayOnly} description={currentPlacement.promptMessage || 'See offers below'} />
                            }
                        </>
                    }

                    {optionsView === LayoutChildClass.button &&
                        <>
                            {currentPlacement?.templateSettings[layoutView]?.button &&
                                <ExpressTemplateButton settings={currentPlacement.templateSettings[layoutView].button} displayOnly={currentPlacement.displayOnly} />
                            }
                        </>
                    }

                </div>
            </div>
        </div>
    </>;
}

export default ExpressLayoutSettings;
