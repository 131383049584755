import { LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import {
  InsightsCampaignDaily,
  InsightsCampaignDailyData,
} from "../../../../types/events.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";

interface Props {
  loading: boolean;
  campaignDaily: InsightsCampaignDaily;
}

const CampaignDailyPre = ({ loading, campaignDaily }: Props) => {
  const viewsSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click += value.distinctViews;
    });
    return click;
  };

  const optinSum = (): number => {
    let optin = 0;
    campaignDaily.dates?.forEach((value) => {
      optin += value.distinctSelected;
    });
    return optin;
  };

  const emailSendSum = (): number => {
    let emailSend = 0;
    campaignDaily.dates?.forEach((value) => {
      emailSend += value.totalDistinctAutoEmailSends;
    });
    return emailSend;
  };

  const emailClickSum = (): number => {
    let emailClick = 0;
    campaignDaily.dates?.forEach((value) => {
      emailClick += value.distinctEmailClicks;
    });
    return emailClick;
  };

  const emailCtrAvg = (): number => {
    let ctr = 0;
    campaignDaily.dates?.forEach((value) => {
      ctr += checkForZero(
        value.distinctEmailClicks,
        value.totalDistinctAutoEmailSends
      )
        ? (value.distinctEmailClicks / value.totalDistinctAutoEmailSends) * 100
        : 0;
    });
    return ctr > 0 ? ctr / campaignDaily.dates.length : ctr;
  };

  const emailCrAvg = (): number => {
    let cr = 0;
    campaignDaily.dates?.forEach((value) => {
      cr += checkForZero(
        value.totalAutoEmailSalesCount,
        value.distinctEmailClicks
      )
        ? (value.totalAutoEmailSalesCount / value.distinctEmailClicks) * 100
        : 0;
    });
    return cr > 0 ? cr / campaignDaily.dates.length : cr;
  };

  const clickSum = (): number => {
    let click = 0;
    campaignDaily.dates?.forEach((value) => {
      click += value.totalDistinctClicks;
    });
    return click;
  };

  const salesSum = (): number => {
    let sales = 0;
    campaignDaily.dates?.forEach((value) => {
      sales += value.totalAutoEmailSalesCount;
    });
    return sales;
  };

  const commisionSum = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    campaignDaily.dates?.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / campaignDaily.dates.length : commision;
  };

  const epcAvg = (): number => {
    let epc = 0;
    campaignDaily.dates?.forEach((value) => {
      epc += checkForZero(value.totalCommission, value.distinctEmailClicks)
        ? value.totalCommission / value.totalDistinctClicks
        : 0;
    });
    return epc > 0 ? epc / campaignDaily.dates.length : epc;
  };

  const epiAvg = (): number => {
    let epi = 0;
    campaignDaily.dates?.forEach((value) => {
      epi += checkForZero(value.distinctViews, value.totalCommission)
        ? value.totalCommission / value.distinctViews
        : 0;
    });
    return epi > 0 ? epi / campaignDaily.dates.length : epi;
  };

  const ecpmAvg = (): number => {
    let ecpm = 0;
    campaignDaily.dates?.forEach((value) => {
      ecpm += checkForZero(value.distinctViews, value.totalCommission)
        ? (value.totalCommission / value.distinctViews) * 1000
        : 0;
    });
    return ecpm > 0 ? ecpm / campaignDaily.dates.length : ecpm;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  interface DataType {
    key: React.Key;
    date: string;
    impressions: number;
    optins: number;
    emailsends: number;
    clicksfromemail: number;
    emailctr: string;
    sales: number;
    emailcr: string;
    commision: string;
    epc: string;
    epi: string;
    ecpm: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      fixed: "left",
      width: 170,
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
    },
    {
      title: "Opt ins",
      dataIndex: "optins",
      sorter: (a: DataType, b: DataType) => a.optins - b.optins,
      align: "center",
      width: 150,
    },
    {
      title: "Email sends",
      dataIndex: "emailsends",
      sorter: (a: DataType, b: DataType) => a.emailsends - b.emailsends,
      align: "center",
      width: 180,
    },
    {
      title: "Clicks from email",
      dataIndex: "clicksfromemail",
      sorter: (a: DataType, b: DataType) =>
        a.clicksfromemail - b.clicksfromemail,
      align: "center",
      width: 180,
    },
    {
      title: "Email CTR",
      dataIndex: "emailctr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.emailctr) - stripAndReturnNumber(b.emailctr),
      align: "center",
      width: 120,
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 100,
    },
    {
      title: "Email CR",
      dataIndex: "emailcr",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.emailcr) - stripAndReturnNumber(b.emailcr),
      align: "center",
      width: 150,
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 180,
    },
    {
      title: "EPC",
      dataIndex: "epc",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epc) - stripAndReturnNumber(b.epc),
      align: "center",
      width: 120,
    },
    {
      title: "EPI",
      dataIndex: "epi",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.epi) - stripAndReturnNumber(b.epi),
      align: "center",
      width: 120,
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ecpm) - stripAndReturnNumber(b.ecpm),
      align: "center",
      width: 120,
    },
  ];

  const data = campaignDaily?.dates?.map(
    (data: InsightsCampaignDailyData, index: number) => {
      return {
        key: index.toString(),
        date: moment(data.date).format("LL"),
        impressions: data.distinctViews,
        optins: data.distinctSelected,
        emailsends: data.totalDistinctAutoEmailSends,
        clicksfromemail: data.distinctEmailClicks,
        emailctr: `${
          checkForZero(
            data.totalDistinctAutoEmailSends,
            data.distinctEmailClicks
          )
            ? (
                (data.distinctEmailClicks / data.totalDistinctAutoEmailSends) *
                100
              ).toFixed(2)
            : "0.00"
        }%`,
        sales: data.totalAutoEmailSalesCount,
        emailcr: `${
          checkForZero(data.totalAutoEmailSalesCount, data.distinctEmailClicks)
            ? (
                (data.totalAutoEmailSalesCount / data.distinctEmailClicks) *
                100
              ).toFixed(2)
            : "0.00"
        }%`,
        commision: `${
          campaignDaily?.campaign?.currencySymbol
        }${data.totalCommission.toFixed(2)}`,
        epc: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.totalDistinctClicks, data.totalCommission)
            ? (data.totalCommission / data.totalDistinctClicks).toFixed(2)
            : "0.00"
        }`,
        epi: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? (data.totalCommission / data.distinctViews).toFixed(2)
            : "0.00"
        }`,
        ecpm: `${campaignDaily?.campaign?.currencySymbol}${
          checkForZero(data.distinctViews, data.totalCommission)
            ? ((data.totalCommission / data.distinctViews) * 1000).toFixed(2)
            : "0.00"
        }`,
      };
    }
  );

  return (
    <>
      <div className="widget-description"></div>
      <div className="widget-table">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            tableLayout="fixed"
            scroll={{ x: 1200, y: 380 }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">{viewsSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">{optinSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <div className="bold">{emailSendSum()}</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="center">
                    <div className="bold">{emailClickSum()}</div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={5} align="center">
                    <div className="bold">
                      {checkForZero(emailClickSum(), emailSendSum())
                        ? ((emailClickSum() / emailSendSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">
                      {" "}
                      {emailCtrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="center">
                    {/* Sales */}
                    <span className="bold">{salesSum()}</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="center">
                    <div className="bold">
                      {checkForZero(salesSum(), emailClickSum())
                        ? ((salesSum() / emailClickSum()) * 100).toFixed(2)
                        : "0.00"}
                      %
                    </div>
                    <div className="font-xsmall">
                      {emailCrAvg().toFixed(2)}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} align="center">
                    {/* Commision */}
                    <div className="bold">
                      {campaignDaily?.campaign?.currencySymbol}
                      {commisionSum().toFixed(2)}
                    </div>
                    <div className="font-xsmall">
                      {campaignDaily?.campaign?.currencySymbol}
                      {commisionAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} align="center">
                    {/* EPC */}
                    <div className="bold">
                      {campaignDaily?.campaign?.currencySymbol}
                      {checkForZero(commisionSum(), clickSum())
                        ? (commisionSum() / clickSum()).toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {campaignDaily?.campaign?.currencySymbol}
                      {epcAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10} align="center">
                    {/* EPI */}
                    <div className="bold">
                      {campaignDaily?.campaign?.currencySymbol}
                      {checkForZero(commisionSum(), viewsSum())
                        ? (commisionSum() / viewsSum()).toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {campaignDaily?.campaign?.currencySymbol}
                      {epiAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11} align="center">
                    {/* ECPM */}
                    <div className="bold">
                      {campaignDaily?.campaign?.currencySymbol}
                      {checkForZero(commisionSum(), viewsSum())
                        ? ((commisionSum() / viewsSum()) * 1000).toFixed(2)
                        : "0.00"}
                    </div>
                    <div className="font-xsmall">
                      {campaignDaily?.campaign?.currencySymbol}
                      {ecpmAvg().toFixed(2)} avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
      <div className="widget-footer"></div>
    </>
  );
};

export default CampaignDailyPre;
