import { Dropdown, Slider, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ImageSettings, ImageSizes, TextSettings, TextTransform } from "../../../../../templates-previews/types/params.interfaces";
import { getNumberFromFontWeight, itemsFontFamily, itemsFontSize, itemsFontWeight, itemsSizes, uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
}

const InlineTextImagesSettings = ({ updateSettings, layoutDevice, settings }: Props) => {

    const setNewSettings = (key: keyof TextSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                inline: {
                    ...settings[layoutDevice].inline,
                    text: {
                        ...settings[layoutDevice].inline?.text,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }
    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        setNewSettings('fontFamily', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewSettings('fontSize', props.key);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewSettings('fontWeight', props.key);
    }

    const handleClickTextTransform = (textTransform: TextTransform) => {
        setNewSettings('textTransform', textTransform);
    }

    const setImageNewSettings = (key: keyof ImageSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                inline: {
                    ...settings[layoutDevice].inline,
                    image: {
                        ...settings[layoutDevice].inline?.image,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeRounding = (value: number) => {
        setImageNewSettings('rounding', value);
    }

    const handleChangeSize = (size: ImageSizes) => {
        setImageNewSettings('size', size);
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`inlineTextColor${uniqueKey}`}
                            value={settings[layoutDevice]?.inline.text.textColor || ''}
                            onChange={handleTextChange}
                        />
                        <label htmlFor={`inlineTextColor${uniqueKey}`}>
                            Text: {settings[layoutDevice]?.inline.text.textColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice].inline.text.fontFamily],
                            onClick: handleChangeFontFamily
                        }} trigger={['click']}>
                            <Typography.Link>
                                {settings[layoutDevice]?.inline?.text.fontFamily}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            disabled: settings[layoutDevice]?.inline?.minimized,
                            defaultSelectedKeys: [settings[layoutDevice]?.inline.text.fontSize],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                {settings[layoutDevice]?.inline.text.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontWeight,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.inline.text.fontWeight.toString()],
                            onClick: handleChangeFontWeight
                        }} trigger={['click']}>
                            <Typography.Link>
                                {getNumberFromFontWeight(settings[layoutDevice]?.inline.text.fontWeight.toString())}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <span onClick={() => handleClickTextTransform(TextTransform.uppercase)} className={`text-select ${settings[layoutDevice]?.inline.text.textTransform === TextTransform.uppercase ? 'active' : ''}`}>AB</span>
                        <span onClick={() => handleClickTextTransform(TextTransform.capitalize)} className={`text-select ${settings[layoutDevice]?.inline.text.textTransform === TextTransform.capitalize ? 'active' : ''}`}>Ab</span>
                    </div>
                </div>
            </div>

            <div className="option-content">
                <div className="option-label">
                    Images
                </div>
                <div className="option-two-col">
                    {!settings[layoutDevice]?.inline?.minimized &&
                        <div className="option-col">
                            <Dropdown menu={{
                                items: itemsSizes,
                                selectable: true,
                                disabled: settings[layoutDevice]?.inline?.minimized,
                                defaultSelectedKeys: [settings[layoutDevice]?.inline.image.size],
                                onClick: (prop) => handleChangeSize(prop.key as ImageSizes)
                            }} trigger={['click']}>
                                <Typography.Link className="start-capital">
                                    Size: {settings[layoutDevice]?.inline.image.size}
                                </Typography.Link>
                            </Dropdown>
                        </div>
                    }
                    <div className="option-col">
                        <Dropdown trigger={['click']} dropdownRender={
                            () =>
                                <div className="dropdown min150">
                                    <div className="dropdown-title option-label">Set Rounding</div>
                                    <div className="flex-center-space-between">
                                        <Slider
                                            min={0}
                                            max={100}
                                            className="min100"
                                            onChange={handleChangeRounding}
                                            value={settings[layoutDevice]?.inline.image.rounding}
                                        />
                                    </div>
                                </div>
                        }>
                            <Typography.Link>
                                Rounding: {settings[layoutDevice]?.inline.image.rounding}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

            </div>
        </div >
    </>
}

export default InlineTextImagesSettings