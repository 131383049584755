import { Dispatch } from "react";
import { CompanySettingsInterface } from "../../../types/company.interfaces";
import "./css/financial.scss";
import { Switch } from "antd";

interface Props {
  company: CompanySettingsInterface;
  setCompany: Dispatch<React.SetStateAction<CompanySettingsInterface>>;
  isSuperAdmin: boolean | undefined;
}

const FinancialSettings = ({ company, setCompany, isSuperAdmin }: Props) => {
  const handleChangeVat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === "" || /^[0-9]*$/.test(inputValue)) {
      setCompany(
        (prevState) =>
          prevState && {
            ...prevState,
            finance: {
              ...prevState.finance,
              paymentDetails: {
                ...prevState.finance.paymentDetails,
                vat: +inputValue,
              },
            },
          }
      );
    }
  };

  return (
    <div>
      <div className="card">
        <h3 className="title">Billing details</h3>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Billing name (if different to company name)</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          name: e.target.value,
                        },
                      }
                  );
                }}
                value={company.finance.name}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Address 1</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          address: e.target.value,
                        },
                      }
                  )
                }
                value={company.finance.address}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Address 2</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          addressTwo: e.target.value,
                        },
                      }
                  )
                }
                value={company.finance.addressTwo}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>City</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: { ...prevState.finance, city: e.target.value },
                      }
                  )
                }
                value={company.finance.city}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>County / State</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          state: e.target.value,
                        },
                      }
                  )
                }
                value={company.finance.state}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Post code</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          postCode: e.target.value,
                        },
                      }
                  )
                }
                value={company.finance.postCode}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>VAT / Tax number</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: { ...prevState.finance, vat: e.target.value },
                      }
                  )
                }
                value={company.finance.vat}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <h3 className="title">Payments Details</h3>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Account name</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          paymentDetails: {
                            ...prevState.finance.paymentDetails,
                            accountName: e.target.value,
                          },
                        },
                      }
                  )
                }
                value={company.finance.paymentDetails.accountName}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Sort Code</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          paymentDetails: {
                            ...prevState.finance.paymentDetails,
                            sortCode: e.target.value,
                          },
                        },
                      }
                  )
                }
                value={company.finance.paymentDetails.sortCode}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>Account number</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          paymentDetails: {
                            ...prevState.finance.paymentDetails,
                            accountNumber: e.target.value,
                          },
                        },
                      }
                  )
                }
                value={company.finance.paymentDetails.accountNumber}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>SWIFT</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          paymentDetails: {
                            ...prevState.finance.paymentDetails,
                            swift: e.target.value,
                          },
                        },
                      }
                  )
                }
                value={company.finance.paymentDetails.swift}
                aria-describedby="cnide"
                placeholder="Enter your details"
              />
            </div>
          </div>
        </div>
        <div className="widget-multiple-form-row">
          <div className="widget-form-row">
            <div className="unauth-label">
              <span>IBAN</span>
            </div>
            <div className="widget-input">
              <input
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCompany(
                    (prevState) =>
                      prevState && {
                        ...prevState,
                        finance: {
                          ...prevState.finance,
                          paymentDetails: {
                            ...prevState.finance.paymentDetails,
                            iban: e.target.value,
                          },
                        },
                      }
                  )
                }
                value={company.finance.paymentDetails.iban}
                aria-describedby="cnide"
                placeholder="e.g. 0000 0000 0000 0000 0000"
              />
            </div>
          </div>
        </div>
        {isSuperAdmin && (
          <>
            <div className="widget-multiple-form-row admin-actions">
              <div className="widget-form-row mtop30 mbot20">
                <div className="action">
                  <div className="info">
                    <span>Add VAT</span>
                  </div>
                  <Switch
                    size="default"
                    checked={company.finance.paymentDetails.allowVAT}
                    onChange={(value: boolean) => {
                      setCompany(
                        (prevState) =>
                          prevState && {
                            ...prevState,
                            finance: {
                              ...prevState.finance,
                              paymentDetails: {
                                ...prevState.finance.paymentDetails,
                                allowVAT: value,
                              },
                            },
                          }
                      );
                    }}
                  />
                </div>
              </div>
              {company.finance.paymentDetails.allowVAT && (
                <div className="widget-form-row">
                  <div className="widget-form-row">
                    <div className="unauth-label">
                      <span>VAT</span>
                    </div>
                    <div className="flex">
                      <div className="widget-input">
                        <input
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChangeVat(e)
                          }
                          value={company.finance.paymentDetails.vat}
                          aria-describedby="cnide"
                          placeholder="Enter your details"
                        />
                      </div>
                      <span className="financial-percentage">%</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="widget-multiple-form-row">
              <div className="widget-form-row">
                <div className="unauth-label">
                  <span>Payment terms in days</span>
                </div>
                <div className="widget-input">
                  <input
                    type="text"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setCompany(
                        (prevState) =>
                          prevState && {
                            ...prevState,
                            finance: {
                              ...prevState.finance,
                              paymentDetails: {
                                ...prevState.finance.paymentDetails,
                                paymentDays: e.target.value,
                              },
                            },
                          }
                      )
                    }
                    value={company.finance.paymentDetails.paymentDays}
                    aria-describedby="cnide"
                    placeholder="Enter your details"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FinancialSettings;
