import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { DealLayoutSettings,  elementSettings,  MainLayoutSettings, PlacementSettings } from "../../../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../../../const/views";
import MainLayoutSettingsComponent from "../../../options/templateOptions/MainLayoutSettings.component";
import { ButtonShapes, ElementSettings, LocationSettings } from "../../../../../../templates-previews/types/params.interfaces";
import DealLayoutSettingsComponent from "../../../options/templateOptions/DealLayoutSettings.component";
import EmbeddedElementSettings from "../../../options/templateOptions/ElementSettings.component";

interface Props {
    updateMainButtonShapeSettings: (value: ButtonShapes) => void,
    updateDealLayout: (value: DealLayoutSettings) => void,
    updateMainLayoutSettings: (value: MainLayoutSettings) => void,
    updateLocationSettings: (value: LocationSettings) => void,
    updateElementSettings: (value: ElementSettings) => void,
    layoutDevice: LayoutDevices,
    currentPlacement: PlacementSettings,
    defaultTitle: string,
    campaignId: string,
}

const SettingsEsTop = ({ updateMainButtonShapeSettings, updateMainLayoutSettings, updateDealLayout, updateElementSettings, currentPlacement, layoutDevice, campaignId }: Props) => {
    return <>
        <div className="settings-options-list">
            <Collapse defaultActiveKey={['']} accordion>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Element <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="element">
                    <div className="settings-option">
                        <EmbeddedElementSettings settings={currentPlacement.templateSettings[layoutDevice].settings?.embeddedElement || elementSettings} updateElementSettings={updateElementSettings} layoutDevice={layoutDevice} campaignId={campaignId} />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Main Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="main-layout">
                    <div className="settings-option">
                        <MainLayoutSettingsComponent updateMainButtonShape={updateMainButtonShapeSettings} buttonShape={currentPlacement.templateSettings[layoutDevice].button.shape} mainLayout={currentPlacement.templateSettings[layoutDevice].settings?.mainLayout} updateMainLayoutSettings={updateMainLayoutSettings} template={currentPlacement.templateSettings[layoutDevice].template}  />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Deal Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="deal-layout">
                    <div className="settings-option">
                        <DealLayoutSettingsComponent template={currentPlacement.templateSettings[layoutDevice].template} displayOnly={currentPlacement.displayOnly} dealLayout={currentPlacement.templateSettings[layoutDevice].settings?.dealLayout} updateDealLayoutSettings={updateDealLayout} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>
            </Collapse>
        </div>
    </>
}

export default SettingsEsTop