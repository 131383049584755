import { Dropdown, Switch, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { GeneralSettings, ImageRepresentation, ImageRepresentationSettings, ImageSizes, LocationPosition } from "../../../../../templates-previews/types/params.interfaces";
import { itemsAlign, itemsOffers, uniqueKey } from "../../../../const/layout-options.enum";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
}

const ActionGeneralSettings = ({ updateSettings, layoutDevice, settings }: Props) => {

    const setNewSettings = (key: keyof GeneralSettings, value: string | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                action: {
                    ...settings[layoutDevice].action,
                    general: {
                        ...settings[layoutDevice].action?.general,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleChangeOffers = (props: DropdownMenuProps) => {
        setNewSettings('offers', props.key);
    }

    const handleChangeAlign = (props: DropdownMenuProps) => {
        setNewSettings('align', props.key);
    }


    const setNewImageSettings = (key: keyof ImageRepresentationSettings, value: ImageRepresentation) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                action: {
                    ...settings[layoutDevice].action,
                    imageRepresentation: {
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeImageRepresentation = (position: ImageRepresentation) => {
        setNewImageSettings('position', position);
    }


    const handleChangeMinimize = (checked: boolean) => {
        updateSettings(
            {
                ...settings,
                [layoutDevice]: {
                    ...settings[layoutDevice],
                    action: {
                        ...settings[layoutDevice].action,
                        location: {
                            ...settings[layoutDevice].action.location,
                            location: LocationPosition.left,
                            x: 0,
                            y: 0,
                        },
                        minimized: checked,
                        image: {
                            size: checked ? ImageSizes.xsmall : ImageSizes.small
                        },
                        getButton: {
                            ...settings[layoutDevice].action.getButton,
                            fontSize: 12,
                        },
                        imageRepresentation: checked ? { position: ImageRepresentation.left } : settings[layoutDevice].action.imageRepresentation,
                    }
                }
            })
    }

    return <>
        <div className="option-box">
            <div className="option-content">
                <div className="option-single-col">
                    <Switch id="minimizedAction" size="small" className="displayswitch" checked={settings[layoutDevice].action?.minimized || false} onChange={(checked: boolean) => { handleChangeMinimize(checked) }} checkedChildren="On" unCheckedChildren="Off" />
                    <label htmlFor="minimizedAction" className="font-hover mleft5">
                        Compact view
                    </label>
                </div>
            </div>
            <div className="option-content">


                <div className="option-single-col">

                    <div className="option-col">
                        <input type="color" className="color-box" id={`actionBgColor${uniqueKey}`} value={settings[layoutDevice]?.action.general.backgroundColor} onChange={handleBgChange} />
                        <label htmlFor={`actionBgColor${uniqueKey}`}>
                            Background: {settings[layoutDevice]?.action.general.backgroundColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">

                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsAlign,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.action.general.align],
                            onClick: handleChangeAlign
                        }} trigger={['click']}>
                            <Typography.Link className="start-capital">
                                Align: {settings[layoutDevice]?.action.general.align}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsOffers,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.action.general.offers],
                            onClick: handleChangeOffers
                        }} trigger={['click']}>
                            <Typography.Link>
                                Offers: {settings[layoutDevice]?.action.general.offers}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

            </div>
            <div className="option-content">
                <div className="option-list-col">
                    {!settings[layoutDevice]?.action?.minimized &&
                        <div className={`option-item ${settings[layoutDevice].action?.imageRepresentation ? '' : 'active'} ${settings[layoutDevice].action?.imageRepresentation?.position === ImageRepresentation.top ? 'active' : ''}`} onClick={() => handleChangeImageRepresentation(ImageRepresentation.top)}>
                            <div className="option-item-icon">
                                <svg width="38" height="29" viewBox="0 0 38 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.5403 9.22896C26.8676 9.48285 27.3387 9.42335 27.5926 9.09607C27.8465 8.76879 27.787 8.29766 27.4597 8.04377L26.5403 9.22896ZM22.3125 5L22.7722 4.40741C22.5017 4.19753 22.1233 4.19753 21.8528 4.40741L22.3125 5ZM11.5403 12.4074C11.213 12.6613 11.1535 13.1324 11.4074 13.4597C11.6613 13.787 12.1324 13.8465 12.4597 13.5926L11.5403 12.4074ZM12.7778 1.75H25.2222V0.25H12.7778V1.75ZM25.2222 1.75C25.9429 1.75 26.25 2.19893 26.25 2.44444H27.75C27.75 1.09447 26.4652 0.25 25.2222 0.25V1.75ZM26.25 2.44444V12.5556H27.75V2.44444H26.25ZM26.25 12.5556C26.25 12.8011 25.9429 13.25 25.2222 13.25V14.75C26.4652 14.75 27.75 13.9055 27.75 12.5556H26.25ZM25.2222 13.25H12.7778V14.75H25.2222V13.25ZM12.7778 13.25C12.0571 13.25 11.75 12.8011 11.75 12.5556H10.25C10.25 13.9055 11.5348 14.75 12.7778 14.75V13.25ZM11.75 12.5556V2.44444H10.25V12.5556H11.75ZM11.75 2.44444C11.75 2.19893 12.0571 1.75 12.7778 1.75V0.25C11.5348 0.25 10.25 1.09447 10.25 2.44444H11.75ZM16.25 5.5C16.25 5.91421 15.9142 6.25 15.5 6.25V7.75C16.7426 7.75 17.75 6.74264 17.75 5.5H16.25ZM15.5 6.25C15.0858 6.25 14.75 5.91421 14.75 5.5H13.25C13.25 6.74264 14.2574 7.75 15.5 7.75V6.25ZM14.75 5.5C14.75 5.08579 15.0858 4.75 15.5 4.75V3.25C14.2574 3.25 13.25 4.25736 13.25 5.5H14.75ZM15.5 4.75C15.9142 4.75 16.25 5.08579 16.25 5.5H17.75C17.75 4.25736 16.7426 3.25 15.5 3.25V4.75ZM27.4597 8.04377L22.7722 4.40741L21.8528 5.59259L26.5403 9.22896L27.4597 8.04377ZM21.8528 4.40741L11.5403 12.4074L12.4597 13.5926L22.7722 5.59259L21.8528 4.40741Z" />
                                    <rect y="21" width="19" height="1.5" rx="0.75" />
                                    <rect y="24" width="38" height="1.5" rx="0.75" />
                                    <rect y="27" width="30" height="1.5" rx="0.75" />
                                </svg>
                            </div>
                            <span>Top</span>
                        </div>
                    }
                    <div className={`option-item ${settings[layoutDevice].action?.imageRepresentation?.position === ImageRepresentation.left ? 'active' : ''}`} onClick={() => handleChangeImageRepresentation(ImageRepresentation.left)}>
                        <div className="option-item-icon">
                            <svg width="43" height="15" viewBox="0 0 43 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5403 9.22896C16.8676 9.48285 17.3387 9.42335 17.5926 9.09607C17.8465 8.76879 17.787 8.29766 17.4597 8.04377L16.5403 9.22896ZM12.3125 5L12.7722 4.40741C12.5017 4.19753 12.1233 4.19753 11.8528 4.40741L12.3125 5ZM1.54029 12.4074C1.21301 12.6613 1.15352 13.1324 1.40741 13.4597C1.6613 13.787 2.13243 13.8465 2.45971 13.5926L1.54029 12.4074ZM2.77778 1.75H15.2222V0.25H2.77778V1.75ZM15.2222 1.75C15.9429 1.75 16.25 2.19893 16.25 2.44444H17.75C17.75 1.09447 16.4652 0.25 15.2222 0.25V1.75ZM16.25 2.44444V12.5556H17.75V2.44444H16.25ZM16.25 12.5556C16.25 12.8011 15.9429 13.25 15.2222 13.25V14.75C16.4652 14.75 17.75 13.9055 17.75 12.5556H16.25ZM15.2222 13.25H2.77778V14.75H15.2222V13.25ZM2.77778 13.25C2.05712 13.25 1.75 12.8011 1.75 12.5556H0.25C0.25 13.9055 1.53475 14.75 2.77778 14.75V13.25ZM1.75 12.5556V2.44444H0.25V12.5556H1.75ZM1.75 2.44444C1.75 2.19893 2.05712 1.75 2.77778 1.75V0.25C1.53475 0.25 0.25 1.09447 0.25 2.44444H1.75ZM6.25 5.5C6.25 5.91421 5.91421 6.25 5.5 6.25V7.75C6.74264 7.75 7.75 6.74264 7.75 5.5H6.25ZM5.5 6.25C5.08579 6.25 4.75 5.91421 4.75 5.5H3.25C3.25 6.74264 4.25736 7.75 5.5 7.75V6.25ZM4.75 5.5C4.75 5.08579 5.08579 4.75 5.5 4.75V3.25C4.25736 3.25 3.25 4.25736 3.25 5.5H4.75ZM5.5 4.75C5.91421 4.75 6.25 5.08579 6.25 5.5H7.75C7.75 4.25736 6.74264 3.25 5.5 3.25V4.75ZM17.4597 8.04377L12.7722 4.40741L11.8528 5.59259L16.5403 9.22896L17.4597 8.04377ZM11.8528 4.40741L1.54029 12.4074L2.45971 13.5926L12.7722 5.59259L11.8528 4.40741Z" />
                                <rect x="22" y="4" width="12" height="1.5" rx="0.75" />
                                <rect x="22" y="7" width="21" height="1.5" rx="0.75" />
                                <rect x="22" y="10" width="18" height="1.5" rx="0.75" />
                            </svg>
                        </div>
                        <span>Left</span>
                    </div>
                    <div className={`option-item ${settings[layoutDevice].action?.imageRepresentation?.position === ImageRepresentation.right ? 'active' : ''}`} onClick={() => handleChangeImageRepresentation(ImageRepresentation.right)}>
                        <div className="option-item-icon">
                            <svg width="44" height="15" viewBox="0 0 44 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M42.5403 9.22896C42.8676 9.48285 43.3387 9.42335 43.5926 9.09607C43.8465 8.76879 43.787 8.29766 43.4597 8.04377L42.5403 9.22896ZM38.3125 5L38.7722 4.40741C38.5017 4.19753 38.1233 4.19753 37.8528 4.40741L38.3125 5ZM27.5403 12.4074C27.213 12.6613 27.1535 13.1324 27.4074 13.4597C27.6613 13.787 28.1324 13.8465 28.4597 13.5926L27.5403 12.4074ZM28.7778 1.75H41.2222V0.25H28.7778V1.75ZM41.2222 1.75C41.9429 1.75 42.25 2.19893 42.25 2.44444H43.75C43.75 1.09447 42.4652 0.25 41.2222 0.25V1.75ZM42.25 2.44444V12.5556H43.75V2.44444H42.25ZM42.25 12.5556C42.25 12.8011 41.9429 13.25 41.2222 13.25V14.75C42.4652 14.75 43.75 13.9055 43.75 12.5556H42.25ZM41.2222 13.25H28.7778V14.75H41.2222V13.25ZM28.7778 13.25C28.0571 13.25 27.75 12.8011 27.75 12.5556H26.25C26.25 13.9055 27.5348 14.75 28.7778 14.75V13.25ZM27.75 12.5556V2.44444H26.25V12.5556H27.75ZM27.75 2.44444C27.75 2.19893 28.0571 1.75 28.7778 1.75V0.25C27.5348 0.25 26.25 1.09447 26.25 2.44444H27.75ZM32.25 5.5C32.25 5.91421 31.9142 6.25 31.5 6.25V7.75C32.7426 7.75 33.75 6.74264 33.75 5.5H32.25ZM31.5 6.25C31.0858 6.25 30.75 5.91421 30.75 5.5H29.25C29.25 6.74264 30.2574 7.75 31.5 7.75V6.25ZM30.75 5.5C30.75 5.08579 31.0858 4.75 31.5 4.75V3.25C30.2574 3.25 29.25 4.25736 29.25 5.5H30.75ZM31.5 4.75C31.9142 4.75 32.25 5.08579 32.25 5.5H33.75C33.75 4.25736 32.7426 3.25 31.5 3.25V4.75ZM43.4597 8.04377L38.7722 4.40741L37.8528 5.59259L42.5403 9.22896L43.4597 8.04377ZM37.8528 4.40741L27.5403 12.4074L28.4597 13.5926L38.7722 5.59259L37.8528 4.40741Z" />
                                <rect y="4" width="12" height="1.5" rx="0.75" />
                                <rect y="7" width="21" height="1.5" rx="0.75" />
                                <rect y="10" width="18" height="1.5" rx="0.75" />
                            </svg>
                        </div>
                        <span>Right</span>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default ActionGeneralSettings