import { Offer } from "../../../../../types/offer.interfaces";
import { PopupSettings } from "../../../types/params.interfaces";
import offerImageSrc from "./../../../../../assets/images/offer-listening.png";

interface Props {
  offer: Offer,
  settings: PopupSettings,
  displayOnly: boolean,
}

const OfferItem = ({ offer, settings, displayOnly }: Props) => {
  return (
    <>
      <li className={`opl-list-item opl-item-${settings.image.size} ${displayOnly ? 'opl-item-display' : 'opl-item-getbutton'}`}>
        <input className="opl-input" id={offer.id} type="checkbox" />
        <label htmlFor={offer.id} className="opl-item-layout">

          <div className="opl-item-image">
            <img className={`image-${settings.image.size}`} style={{ borderRadius: `${settings.image.rounding}%` }} src={offer.imageUrl ? offer.imageUrl : offerImageSrc} alt={offer.title} />
          </div>

          <div className={`opl-item-content opl-content-${settings.image.size}`}>
            <div className="opl-item-title">
              <span className={`opl-item-title-text ${settings?.minimized ? 'opl-max-2lines' : 'opl-max-1lines'}`} style={{
                color: settings.text.textColor,
                fontSize: `${settings.text.fontSize}px`,
                fontFamily: settings.text.fontFamily,
                fontWeight: settings.text.fontWeight,
                textTransform: settings.text.textTransform
              }}>
                {offer.title}
              </span>
              <svg onClick={(e) => e.preventDefault()} className="icon-circle-info" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z" fill={settings.text.textColor} />
              </svg>
              {/* <svg onClick={(e) => e.preventDefault()} className="icon-circle-info" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 10.1V7.5M7.5 4.9H7.5065M14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5Z" stroke={settings.text.textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg> */}
            </div>
            <div className="opl-item-description opl-max-2lines" style={{
              color: settings.text.textColor,
              fontSize: `${settings.text.fontSize}px`,
              fontFamily: settings.text.fontFamily,
              fontWeight: settings.text.fontWeight,
              textTransform: settings.text.textTransform
            }}>{offer.description}</div>

            {!settings?.minimized &&
              <div className={`opl-item-get ${!displayOnly ? 'space-between' : ''}`}>

                {/* <div className="opl-item-button" style={{ color: settings.text.textColor }} >Read more</div> */}

                {!displayOnly &&
                  <div className="action-save" style={{ color: settings.text.textColor }} >
                    <svg width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Save offer
                  </div>
                }
              </div>
            }

            <div className="opl-item-get">
              {displayOnly ?
                <div className="opl-switch-wrapper">
                  <label htmlFor={offer.id}>
                    <span className="opl-switch-toggle">
                      <span className="opl-switch-toggle-before"></span>
                    </span>

                    <span className="opl-switch-text" style={{
                      fontSize: `${settings.text.fontSize}px`,
                      fontFamily: settings.text.fontFamily,
                      fontWeight: settings.text.fontWeight,
                      textTransform: settings.text.textTransform
                    }}>
                      <span className="checked-text">
                        Selected
                      </span>
                      <span className="unchecked-text">
                        Select
                      </span>
                    </span>
                  </label>
                </div>
                :
                <>
                  <div className="action-get button" style={{
                    color: settings.getButton.textColor,
                    fontSize: `${settings.getButton.fontSize}px`,
                    fontFamily: settings.getButton.fontFamily,
                    fontWeight: settings.getButton.fontWeight,
                    backgroundColor: settings.getButton.backgroundColor,
                    borderRadius: settings.getButton.rounding,
                    border: `2px solid ${settings.getButton?.borderColor || settings.getButton.backgroundColor}`,
                    textTransform: settings.getButton?.textTransform || 'initial'
                  }}>
                    Get offer now
                  </div>
                  {settings?.minimized &&
                    <div className="action-save" style={{ color: settings.text.textColor }} >
                      <svg width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  }
                </>}
            </div>

          </div>

          <div className="opl-item-actions">
            <div className="icon-circle-info" onClick={(e) => e.preventDefault()} >
              {/* <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 10.1V7.5M7.5 4.9H7.5065M14 7.5C14 11.0899 11.0899 14 7.5 14C3.91015 14 1 11.0899 1 7.5C1 3.91015 3.91015 1 7.5 1C11.0899 1 14 3.91015 14 7.5Z" stroke={settings.text.textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg> */}

              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z" fill={settings.text.textColor} />
              </svg>

            </div>
            {!displayOnly &&
              <svg className="save-icon" width="12" height="14" viewBox="0 0 12 14" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                <path d="M11 13L6 9.66667L1 13V2.33333C1 1.97971 1.15051 1.64057 1.41842 1.39052C1.68633 1.14048 2.04969 1 2.42857 1H9.57143C9.95031 1 10.3137 1.14048 10.5816 1.39052C10.8495 1.64057 11 1.97971 11 2.33333V13Z" stroke={settings.text.textColor} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            }
          </div>
        </label>
      </li>
    </>
  );
}

export default OfferItem;
