import { CheckCircleOutlined, CloseCircleOutlined, CloudUploadOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons"
import { Button, message, Modal, Switch, Upload } from "antd"
import Table, { ColumnsType } from "antd/lib/table"
import { RcFile } from "antd/lib/upload"
import moment from "moment"
import { Dispatch, useEffect, useMemo, useState } from "react"
import useAuth from "../../../hooks/useAuth.hook"
import useAxios from "../../../hooks/useAxios.hook"
import { postOfferVoucherFile } from "../../../services/upload/upload.service"
import { OfferCreate, Voucher } from "../../../types/offer.interfaces"
import { VoucherType } from "../../../utility/enums/offer.enums"

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>
}

const { Dragger } = Upload;

const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'text/csv';
    if (!isJpgOrPng) {
        message.error('You can only upload CSV file!');
    }

    return isJpgOrPng;
}

const CreateOfferVouchers = ({ offer, setOffer }: Props) => {
    const { company } = useAuth();
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const [isHover, setHover] = useState<number>(0);
    const { myAxios } = useAxios();

    const handleUploadRequest = async (option: any) => {
        message.info("Upload started, please do not close this window", 2);
        setLoadingUpload(true);
        if (option?.file && company && offer.id) {
            const response = await postOfferVoucherFile(option.file, company.company_Id, offer.id);

            if (response?.data?.status) {
                message.success("File uploaded successfully", 1);
                getOfferVouchers();

            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoadingUpload(false)
        return option
    }

    const getOfferVouchers = async () => {
        setLoading(true);
        const { response } = await myAxios({ method: 'GET', url: `/offers/vouchers/${company?.company_Id}/${offer.id}` });

        if (response?.data?.status) {
            setVouchers(response.data.result);
        } else {
            message.error("Failed to get vouchers", 1);
        }
        setLoading(false);
    }

    useEffect(() => {
        company && getOfferVouchers();
        // eslint-disable-next-line
    }, [company]);

    interface DataType {
        key: React.Key,
        updatedOn: string,
        count: number,
        actions: any
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Updated on',
            dataIndex: 'updatedOn'
        },
        {
            title: 'Count',
            dataIndex: 'count'
        },
        {
            title: 'Actions',
            dataIndex: 'actions'
        }
    ];

    const handleRemove = async (id: string) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'DELETE', url: `/offers/vouchers/${company?.company_Id}/${id}` });

        if (response?.data?.status) {
            getOfferVouchers();
        } else {
            message.error("Failed to delete voucher", 1);
        }
        setLoading(false);
    }

    const handleActivate = async (id: string, active: boolean) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'POST', url: `/offers/vouchers/activation/${company?.company_Id}/${id}/${active}` });

        if (response?.data?.status) {
            getOfferVouchers();
        } else {
            message.error("Failed to delete voucher", 1);
            setLoading(false);
        }
    }

    const openConfirmModal = (id: string) => {
        Modal.confirm({
            title: 'Do you want to remove this offer voucher',
            content: 'You can add it again from the input forms',
            okText: 'Remove',
            icon: <></>,
            maskClosable: true,
            width: 520,
            closable: true,
            closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-danger'
            },
            onOk: () => handleRemove(id)
        })
    }

    const data = useMemo(() => {
        const dataColumns = vouchers.map((data: Voucher, index: number) => {
            return {
                key: index.toString(),
                updatedOn: moment(data.createdAt).format('LL'),
                count: data.totalCodes,
                actions: <span className="table-actions">

                    <span className="table-icon-bordered mright20" onClick={() => openConfirmModal(data.id)}>
                        <DeleteOutlined />
                    </span>

                    {data.isActive ?
                        <span className="font-success font-xsmall semibold flex-center font-hover"
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => handleActivate(data.id, false)}>
                            {isHover !== index ? <>
                                <CheckCircleOutlined style={{ fontSize: 16 }} className="mright5" /> Activated</>
                                :
                                <><CloseCircleOutlined style={{ fontSize: 16 }} className="mright5" /> Deactivate</>
                            }
                        </span>
                        :
                        <span className="label label-select table-label" onClick={() => handleActivate(data.id, true)}>Activate</span>
                    }
                </span>,
            }
        });
        return dataColumns;
        // eslint-disable-next-line
    }, [vouchers, isHover])

    return <>
        <div className="widget widget-icon widget-centered alignself-baseline mbot20">
            <div className="flex-space-between mbot20">
                <span className="widget-semititle">Single voucher</span>
                <div>
                    <span className="font-color mright10">Enable single voucher</span>
                    <Switch checked={offer.voucher.voucherType === VoucherType.single} onChange={(checked: boolean) => setOffer(prevState => ({ ...prevState, voucher: { ...prevState.voucher, voucherType: checked ? VoucherType.single : offer.voucher.voucherType !== VoucherType.custom ? VoucherType.none : VoucherType.custom } }))}></Switch>
                </div>
            </div>

            <div className="label-input-wrapper max300 mbot0">
                <label htmlFor="Name">
                    <span className="label-input-name">Use code</span>
                    <input tabIndex={0} className="label-input" value={offer.voucher.code} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, voucher: { ...prevState.voucher, code: e.target.value } }))} />
                    {/* {offer.voucher.voucherType === VoucherType.single && !offer.voucher.code &&
                        <div className="label-error">
                            required
                        </div>
                    } */}
                </label>

            </div>
        </div>

        <div className="widget widget-icon widget-centered alignself-baseline ">
            <div className="flex-space-between mbot20">
                <span className="widget-semititle">Multiple vouchers</span>
                <div>
                    <span className="font-color mright10">Enable multiple vouchers</span>
                    <Switch checked={offer.voucher.voucherType === VoucherType.custom} onChange={(checked: boolean) => setOffer(prevState => ({ ...prevState, voucher: { ...prevState.voucher, voucherType: checked ? VoucherType.custom : offer.voucher.voucherType !== VoucherType.single ? VoucherType.none : VoucherType.single } }))}></Switch>
                </div>
            </div>

            <div className="voucher-uploader mbot20">
                <Dragger
                    customRequest={handleUploadRequest}
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    multiple={false}
                    disabled={loadingUpload}
                    beforeUpload={beforeUpload}
                    maxCount={1}>
                    <Button type="link" className="mbot20" style={{ backgroundColor: "#D8F1E9" }} loading={loadingUpload} disabled={loadingUpload}>
                        <CloudUploadOutlined style={{ fontSize: 16 }} />
                        Upload</Button>
                    <p className="font-color">Drag & drop or click to upload a CSV file</p>
                </Dragger>
            </div>

            <Table
                columns={columns}
                dataSource={data}
                pagination={{ pageSize: 5 }}
                tableLayout="fixed"
                loading={{ indicator: <LoadingOutlined ></LoadingOutlined>, spinning: loading }}
            ></Table>
        </div>
    </>
}

export default CreateOfferVouchers