import "../css/express.scss";
import logoIcon from "../../../assets/images/icons/logo-icon.svg";
import useAuth from "../../../hooks/useAuth.hook";
import { Navigate, NavLink, Outlet } from "react-router-dom";
import { Button, message } from "antd";
import useExpress from "../../../hooks/useExpress.hook";
import { useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import { ExpressCampaign } from "../types/express.types";
const ExpressAuth = () => {
  const { auth } = useAuth();
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>();
  const { campaign, setCampaign } = useExpress();


  const handleStart = () => {
    handleStartCampaign(campaign?.id);
    setCampaign((prevState: ExpressCampaign | undefined) => (prevState && { ...prevState, isActive: true }))
  }

  const handleStop = () => {
    handleStopCampaign(campaign?.id);
    setCampaign((prevState: ExpressCampaign | undefined) => (prevState && { ...prevState, isActive: false }))
  }

  const handleStartCampaign = async (id: string | undefined) => {
    if (id) {
      setLoading(true);
      const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${id}/true` });

      if (response?.status) {
        message.success({
          content: "Campaign is started",
          className: 'express-message',
          duration: 1,
        });
      } else {
        message.error('Campaign failed to start', 1);
        console.log(error);
      }
    }
    setLoading(false);
  }

  const handleStopCampaign = async (id: string | undefined) => {
    if (id) {
      setLoading(true);
      const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${id}/false` });
      if (response?.status) {
        message.success({
          content: "Campaign is stopped",
          className: 'express-message',
          duration: 10,
        });
      } else {
        message.error('Campaign failed to stop', 1);
        console.log(error);
      }
    }
    setLoading(false);
  }

  // useEffect(() => {
  //   company?.email && useExternalScripts({ id: 'hs-script-loader', url: '//js-eu1.hs-scripts.com/26993522.js', email: company.email });
  // }, [company?.email])

  return <>
    {auth ?
      <div className="express">

        <div className="express-auth-header">

          <div className="express-logo-wrapper">
            <img className="express-logo" src={logoIcon} alt="Brandswap icon" />
          </div>

          <ul className="express-navlist">
            <li>
              <NavLink to="/express/dashboard">Home</NavLink>
            </li>
            <li>
              <NavLink to="/express/insights">Insights</NavLink>
            </li>
            <li>
              <NavLink to="/express/settings" >Company settings</NavLink>
            </li>
            <li>
              <NavLink to="/express/deals" >Partners and Deals</NavLink>
            </li>
            <li>
              <NavLink to="/express/campaign" >Deal Pages and Presentation</NavLink>
            </li>
          </ul>

          {!campaign?.isActive ?
            <>
              <Button type="primary" loading={loading} onClick={handleStart} className="start button-express-status">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.5 7.13397C14.1667 7.51887 14.1667 8.48113 13.5 8.86603L1.5 15.7942C0.833333 16.1791 -7.73604e-07 15.698 -7.39955e-07 14.9282L-1.34273e-07 1.0718C-1.00623e-07 0.301996 0.833333 -0.17913 1.5 0.20577L13.5 7.13397Z" fill="white" />
                </svg>
                Start campaign
              </Button>
            </>
            :
            <Button type="primary" loading={loading} onClick={handleStop} className="stop button-express-status">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="4" fill="white" />
              </svg>

              Stop campaign
            </Button>
          }
        </div>


        <div className="express-page">
          <Outlet />
        </div>

      </div>
      :
      <Navigate to="/login" />
    }
  </>;
}

export default ExpressAuth;
