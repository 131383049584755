
interface Props {
    id: string,
    url: string,
    email: string,
    enable: boolean
}

declare global {
    interface Window { _hsq: any; }
}

export const setExternalScript = ({ id, url, email, enable }: Props) => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    const _hasElement = document.getElementById(id);

    script.setAttribute("src", url);
    script.setAttribute("id", id);
    const hasElm = document.getElementById(id);
    !hasElm && !_hasElement && enable && head?.appendChild(script);

    var _hsq = window._hsq = window._hsq || [];

    _hsq.push(
        ["identify", {
            email: email
        }]
    );

    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['trackPageView']);
}