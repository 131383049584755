import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { UserAuth } from "../../types/user.interfaces";

// default axios config
export const apiConfig: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

apiConfig.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const user = localStorage.getItem("user");
    if (user) {
        const userAuth: UserAuth = JSON.parse(user);
        if (userAuth?.tokens) {
            config.headers.setAuthorization(`bearer ${userAuth.tokens.token}`);
        }
    }
    return config;
});

apiConfig.interceptors.response.use(
    (config: AxiosResponse) => {
        return config;
    },
    async (error: any) => {
        if (error?.response) {
            return error.response;
        }
        return Promise.reject(error);
    }
);

export default apiConfig;
