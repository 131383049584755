import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { DealLayoutSettings, MainLayoutSettings, PlacementSettings } from "../../../../../../../types/campaign.interfaces";
import { LayoutClass, LayoutDevices } from "../../../../../const/views";
import MainLayoutSettingsComponent from "../../../options/templateOptions/MainLayoutSettings.component";
import LocationSettingsComponent from "../../../options/templateOptions/LocationSettings.component";
import { BehaviourSettings, LocationSettings } from "../../../../../../templates-previews/types/params.interfaces";
import DealLayoutSettingsComponent from "../../../options/templateOptions/DealLayoutSettings.component";
import DelaySettingsComponent from "../../../options/templateOptions/DelaySettings.component";

interface Props {
    updateDealLayout: (value: DealLayoutSettings) => void,
    updateMainLayoutSettings: (value: MainLayoutSettings) => void,
    updateLocationSettings: (value: LocationSettings) => void,
    updateDelaySettings: (value: BehaviourSettings) => void,
    layoutDevice: LayoutDevices,
    currentPlacement: PlacementSettings,
    defaultTitle: string,
}

const ViewPopupN13 = ({ updateMainLayoutSettings, updateLocationSettings, updateDealLayout, updateDelaySettings, currentPlacement, layoutDevice }: Props) => {
    return <>
        <div className="settings-options-list">
            <Collapse defaultActiveKey={['']} accordion>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Location <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="location">
                    <div className="settings-option">
                        <LocationSettingsComponent locationSettings={currentPlacement.templateSettings[layoutDevice].settings?.location} updateLocationSettings={updateLocationSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Main Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="main-layout">
                    <div className="settings-option">
                        <MainLayoutSettingsComponent mainLayout={currentPlacement.templateSettings[layoutDevice].settings?.mainLayout} updateMainLayoutSettings={updateMainLayoutSettings} template={currentPlacement.templateSettings[layoutDevice].template} />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Deal Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="deal-layout">
                    <div className="settings-option">
                        <DealLayoutSettingsComponent template={currentPlacement.templateSettings[layoutDevice].template} displayOnly={currentPlacement.displayOnly} dealLayout={currentPlacement.templateSettings[layoutDevice].settings?.dealLayout} updateDealLayoutSettings={updateDealLayout} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                {currentPlacement.templateSettings[layoutDevice].type === LayoutClass.action &&
                    <CollapsePanel
                        showArrow={false}
                        header={
                            <div className="option-title">
                                Delay <span className="icon icon-collapse-mix"></span>
                            </div>
                        }
                        key="delay">
                        <div className="settings-option">
                            <DelaySettingsComponent behaviourSettings={currentPlacement.templateSettings[layoutDevice].settings?.delay} updateDelaySettings={updateDelaySettings} layoutDevice={layoutDevice} />
                        </div>
                    </CollapsePanel>
                }
                {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="general">
                    <div className="settings-option">
                        <ActionGeneralSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Header <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="header">
                    <div className="settings-option">
                        <ActionHeaderSettings placement={currentPlacement} defaultTitle={defaultTitle} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Text & images <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="text">
                    <div className="settings-option">
                        <ActionTextImagesSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}

                {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Image representation <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="image">
                    <div className="settings-option">
                        <PopupImageRepresentationSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}

                {/* {!currentPlacement.displayOnly &&
                    <CollapsePanel
                        showArrow={false}
                        header={
                            <div className="option-title">
                                Get offer <span className="icon icon-collapse-mix"></span>
                            </div>
                        }
                        key="readmore">
                        <div className="settings-option">
                            <ActionGetButtonSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                        </div>
                    </CollapsePanel>
                } */}
            </Collapse>
        </div>
    </>
}

export default ViewPopupN13