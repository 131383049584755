export enum ONBOARDING {
    userdetails = "userdetails",
    verification = "verification",
    usertype = "usertype",
    companydetails = "companydetails",
    affiliate = "affiliate"
}

export const ONBOARDING_STEPS_ARRAY: ONBOARDING[] = [ONBOARDING.userdetails, ONBOARDING.verification, ONBOARDING.usertype, ONBOARDING.companydetails, ONBOARDING.affiliate];

export const getOnboardingStepIndex = (step: ONBOARDING): number => {
    return ONBOARDING_STEPS_ARRAY.findIndex(s => s === step);
}

export enum ONBOARDING_STEPS_NR {
    "step1" = "userdetails",
    "step2" = "verification",
    "step3" = "usertype",
    "step4" = "companydetails",
    "step5" = "affiliate"
}

export const getOnboardingStepByNr = (string: any): ONBOARDING => {
    let _step: ONBOARDING = ONBOARDING.userdetails;
    if (string?.length > 0) {
        let _nr = string.replace("step", "");

        if (_nr.length === 1) {
            _step = ONBOARDING_STEPS_ARRAY[Number(_nr - 1)]
        }
    }
    return _step;
}