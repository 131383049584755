import { SearchOutlined } from "@ant-design/icons"
import { Checkbox, Dropdown, Empty, message, Select, Switch, Typography } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import Modal from "antd/lib/modal/Modal"
import React, { Dispatch, MouseEvent, useEffect, useRef, useState } from "react"
import useAuth from "../../../hooks/useAuth.hook"
import useAxios from "../../../hooks/useAxios.hook"
import { CompanyCategory } from "../../../types/company.interfaces"
import { Country, Pagination, Retailer } from "../../../types/global.interfaces"
import { OfferCreate } from "../../../types/offer.interfaces"

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>,
    categories: CompanyCategory[] | undefined,
    countries: Country[] | undefined,
}

const CreateOfferExclusive = ({ offer, categories, countries, setOffer }: Props) => {
    const [filteredCountries, setCountries] = useState<Country[]>(countries || []);
    const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
    const [searchCountries, setSearchCountries] = useState<string>('');
    const countriesRef = useRef<HTMLInputElement>(null);

    const [isSpecificRetailer, setSpecificRetailer] = useState<boolean>(offer.exclusiveTo.retailers.length > 0);
    const [isSpecificRetailerModal, setSpecificRetailerModal] = useState<boolean>(false);

    const [filteredCategories, setCategories] = useState<CompanyCategory[]>(categories || []);
    const [searchCategories, setSearchCategories] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<CompanyCategory[]>([]);
    const categoriesRef = useRef<HTMLInputElement>(null);

    const [retailers, setRetailers] = useState<Retailer[]>();
    const [selectedRetailers, setSelectedRetailers] = useState<Retailer[]>([]);
    const [searchRetailers, setSearchRetailers] = useState<string>('');
    const [filteredRetailers, setFilteredRetailers] = useState<Retailer[]>([]);
    const retailersRef = useRef<HTMLInputElement>(null);

    const { myAxios } = useAxios();
    const { company } = useAuth();
    const [totalPages, setTotalPages] = useState<number>(1);
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: 100
    });

    const getOfferCategoriesName = (categories?: CompanyCategory[]): string => {
        const catName: string[] = categories ? categories.map((value: CompanyCategory) => value.name) : [];
        return catName.join(', ')
    }

    const fetchRetailersById = async (id: string) => {
        const { response } = await myAxios({ method: 'GET', url: `company/all?companyid=${id}&page=${pagination.page}&pageSize=${pagination.pageSize}` });
        if (response?.data?.status) {
            setRetailers(response?.data?.result?.data);
            setTotalPages(response?.data?.result?.totalPages);
            setFilteredRetailers(response?.data?.result?.data);
            // setTotalRetailers(response?.data?.result?.count);
        } else {
            message.error("Failed to get company", 1);
        }
    }

    useEffect(() => {
        if (company?.company_Id) {
            fetchRetailersById(company?.company_Id);
            setSearchRetailers('');
        }
        // eslint-disable-next-line
    }, [company?.company_Id, pagination]);

    useEffect(() => {
        if (categories) {
            setCategories(categories);
        }
        setSelectedCategories(offer.exclusiveTo.categories);
        setSelectedRetailers(offer.exclusiveTo.retailers);
        setSelectedCountries(offer.exclusiveTo.regions);
        setSpecificRetailer(offer.exclusiveTo.retailers.length > 0)
    }, [offer.exclusiveTo, categories]);

    useEffect(() => {
        if (categories) {
            if (searchCategories) {
                const newCategories: CompanyCategory[] = structuredClone(categories);
                setCategories(newCategories.filter(f => f.name.toLowerCase().includes(searchCategories.toLowerCase())) || []);
            } else {
                setCategories(categories);
            }
        }
        // eslint-disable-next-line
    }, [searchCategories]);

    useEffect(() => {
        if (retailers) {
            if (searchRetailers) {
                const newRetailers: Retailer[] = structuredClone(retailers);
                setFilteredRetailers(newRetailers.filter(f => f.name.toLowerCase().includes(searchRetailers.toLowerCase())) || []);
            } else {
                setFilteredRetailers(retailers);
            }
        }
        // eslint-disable-next-line
    }, [searchRetailers]);

    const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSearchCategories('');
        categoriesRef?.current?.focus();
    }

    const handleChangeCategory = (checked: boolean, category: CompanyCategory) => {
        let _selectedCategories: CompanyCategory[] = [];

        if (checked) {
            _selectedCategories = [...selectedCategories, { id: category.id, name: category.name }];
        } else {
            selectedCategories?.forEach((value: CompanyCategory) => {
                if (value.id !== category.id) {
                    _selectedCategories.push(value);
                }
            });
        }

        setSelectedCategories(_selectedCategories);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, categories: _selectedCategories } }));
    }

    const handleRemoveCategory = (categoryId: string) => {
        const _selectedCategories: CompanyCategory[] = structuredClone(selectedCategories).filter((f: CompanyCategory) => f.id !== categoryId);
        setSelectedCategories(_selectedCategories);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, categories: _selectedCategories } }));
    }

    const handleCheckedCategories = (id: string): boolean => {
        const isChecked: boolean = !!offer?.exclusiveTo.categories.find((value: CompanyCategory) => value.id === id);
        return isChecked
    }

    const handleClearRetailers = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSearchRetailers('');
        retailersRef?.current?.focus();
    }

    useEffect(() => {
        if (countries) {
            setCountries(countries);
        }
    }, [countries]);

    useEffect(() => {
        if (countries) {
            if (searchCountries) {
                const newCountries: Country[] = structuredClone(countries);
                setCountries(newCountries.filter(f => f.name.toLowerCase().includes(searchCountries.toLowerCase())) || []);
            } else {
                setCountries(countries);
            }
        }
        // eslint-disable-next-line
    }, [searchCountries]);

    const handleClearCountries = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSearchCountries('');
        countriesRef?.current?.focus();
    }

    const handleChangeCountry = (checked: boolean, country: Country) => {
        let _selectedCountries: Country[] = [];

        if (checked) {
            _selectedCountries = [...selectedCountries, country];
        } else {
            selectedCountries?.forEach((value: Country) => {
                if (value.id !== country.id) {
                    _selectedCountries.push(value);
                }
            });
        }

        setSelectedCountries(_selectedCountries);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, regions: _selectedCountries } }));
    }

    const handleRemoveCountry = (countryId: string) => {
        const _selectedCountries: Country[] = structuredClone(selectedCountries).filter((f: Country) => f.id !== countryId);
        setSelectedCountries(_selectedCountries);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, regions: _selectedCountries } }));
    }

    const handleCheckedCountries = (id: string): boolean => {
        const isChecked: boolean = !!offer?.exclusiveTo.regions.find((value: Country) => value.id === id);
        return isChecked
    }

    const getOfferCountriesName = (countries: Country[]): string => {
        const catName: string[] = countries ? countries.map((value: Country) => value.name) : [];
        return catName.join(', ')
    }

    const handleSwitchRetailer = (checked: boolean) => {
        if (!checked) {
            setSelectedRetailers([]);
            setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, retailers: [] } }));
        }
        setSpecificRetailer(checked);
    }

    const handleAddRetailer = (retailer: Retailer) => {
        const _selectedRetailers: Retailer[] = structuredClone([...selectedRetailers, retailer]);
        setSelectedRetailers(_selectedRetailers);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, retailers: _selectedRetailers } }));
    }

    const handleRemoveRetailer = (retailerId: string) => {
        const _selectedRetailers: Retailer[] = structuredClone(selectedRetailers).filter((f: CompanyCategory) => f.id !== retailerId);
        setSelectedRetailers(_selectedRetailers);
        setOffer(prevState => prevState && ({ ...prevState, exclusiveTo: { ...prevState.exclusiveTo, retailers: _selectedRetailers } }));
    }

    return <>
        <div className="widget-header">
            <div className="widget-icon-box mtop5">
                <span className="icon icon-settings"></span>
            </div>

            <div className="grow mleft10">
                <h3 className="widget-s-title">
                    Exclusive to
                </h3>

                <div className="widget-form-description">
                    Offer filters to host
                </div>
            </div>

        </div>
        <div className="widget-content">
            <div className="widget-icon-content">

                <div className="label-wrapper">
                    <Switch id="chooseRetailer" checked={isSpecificRetailer} onChange={(checked: boolean) => { handleSwitchRetailer(checked) }} checkedChildren="Specific" unCheckedChildren="All" defaultChecked />
                    <label htmlFor="chooseRetailer" className="font-hover mleft5">
                        Hosts
                    </label>
                </div>

                {isSpecificRetailer ? <>
                    <div className="label-wrapper">
                        <span className="font-active font-hover" onClick={() => setSpecificRetailerModal(true)}>
                            Add hosts: {selectedRetailers.length}
                        </span>
                        <ul className="list-tags mtop10">
                            {selectedRetailers?.map((retailer: Retailer) => {
                                return <li key={`selected-retailer-${retailer.id}`}>
                                    {retailer.name}
                                    <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveRetailer(retailer.id)}></span>
                                </li>
                            })}
                        </ul>
                    </div>
                </>
                    :
                    <>
                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={categoriesRef} type="text" className="default-input" value={searchCategories} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCategories(event.target.value)} />
                                            <span className="close-icon" onClick={handleClearCategories}>
                                                <span className="icon icon-remove"></span>
                                            </span>
                                        </div>

                                        {selectedCategories.length > 0 && <>
                                            <div className="dropdown-title">Selected Categories:</div>
                                            <ul className="list-tags">
                                                {selectedCategories?.map((category: CompanyCategory) => {
                                                    return <li key={`selected-cat-${category.id}`}>
                                                        {category.name}
                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCategory(category.id)}></span>
                                                    </li>
                                                })}
                                            </ul>
                                        </>}
                                        <div className="dropdown-title">Categories found:</div>
                                        <ul>
                                            {filteredCategories?.map((category: CompanyCategory) => {
                                                return <li key={`details-cat-${category.id}`}>
                                                    <Checkbox checked={handleCheckedCategories(category.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCategory(e.target.checked, category)} className="checkbox-active">{category.name}</Checkbox>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                }}
                                autoFocus={true}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="page" tabIndex={0}>
                                            <span className="label-input-name">Categories: {offer?.exclusiveTo.categories.length || 0}</span>
                                            <span className="icon icon-arrow-down"></span>
                                            <input disabled value={getOfferCategoriesName(offer?.exclusiveTo.categories)} type="text" className="label-input" />
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>

                        <div className="label-input-dropdown">
                            <Dropdown
                                dropdownRender={(menu) => {
                                    return <div className="dropdown">
                                        <div className="dropdown-search">
                                            <SearchOutlined className="search-icon" />
                                            <input ref={countriesRef} type="text" className="default-input" value={searchCountries} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCountries(event.target.value)} />
                                            <span className="close-icon" onClick={handleClearCountries}>
                                                <span className="icon icon-remove"></span>
                                            </span>
                                        </div>

                                        {selectedCountries.length > 0 && <>
                                            <div className="dropdown-title">Selected Regions:</div>
                                            <ul className="list-tags">
                                                {selectedCountries?.map((country: Country) => {
                                                    return <li key={`selected-excat-${country.id}`}>
                                                        {country.name}
                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCountry(country.id)}></span>

                                                    </li>
                                                })}

                                                {selectedCountries.length === 0 &&
                                                    <li>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No categories" />
                                                    </li>
                                                }
                                            </ul>
                                        </>}
                                        <div className="dropdown-title">Regions found:</div>
                                        <ul>
                                            {filteredCountries?.map((country: Country) => {
                                                return <li key={`details-country-${country.id}`}>
                                                    <Checkbox checked={handleCheckedCountries(country.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCountry(e.target.checked, country)} className="checkbox-active">{country.name}</Checkbox>
                                                </li>
                                            })}

                                            {filteredCountries.length === 0 &&
                                                <li>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No regions" />
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                }}
                                autoFocus={true}
                                trigger={['click']}>
                                <Typography.Link>
                                    <div className="label-input-wrapper label-input-select">
                                        <label htmlFor="page" tabIndex={0}>
                                            <span className="label-input-name">Regions: {offer?.exclusiveTo?.regions?.length || 0}</span>
                                            <span className="icon icon-arrow-down"></span>
                                            <input disabled type="text" value={getOfferCountriesName(offer?.exclusiveTo?.regions)} className="label-input" />
                                        </label>
                                    </div>
                                </Typography.Link>
                            </Dropdown>
                        </div>

                        <div className="multiple-label-input">
                            <div className="label-input-wrapper disabled">
                                <label htmlFor="page">
                                    <span className="label-input-name">Gender</span>
                                    <Select
                                        disabled={true}
                                        suffixIcon={<span className="icon icon-arrow-down"></span>}
                                        className="label-select"
                                        options={[
                                            {
                                                label: 'Type 1',
                                                value: 'type1'
                                            },

                                            {
                                                label: 'Type 2',
                                                value: 'type2'
                                            }
                                        ]}
                                    >
                                    </Select>
                                </label>
                            </div>
                            <div className="label-input-wrapper disabled">
                                <label htmlFor="page">
                                    <span className="label-input-name">Age</span>
                                    <Select
                                        disabled={true}
                                        suffixIcon={<span className="icon icon-arrow-down"></span>}
                                        className="label-select"
                                        options={[
                                            {
                                                label: '15-20 years old',
                                                value: '15-20'
                                            },
                                            {
                                                label: '20-30 years old',
                                                value: '20-30'
                                            },
                                            {
                                                label: '30-40 years old',
                                                value: '30-40'
                                            },
                                            {
                                                label: '40-50 years old',
                                                value: '40-50'
                                            },
                                            {
                                                label: '50+ years old',
                                                value: '50'
                                            },
                                        ]}
                                    >
                                    </Select>
                                </label>
                            </div>
                        </div>
                    </>
                }

                <div className="label-wrapper">
                    <div className="label-wrapper-title">
                        Min monthly visits
                    </div>

                    <div className="label-box-wrapper disabled">
                        <div className="label-box">
                            10k
                        </div>
                        <div className="label-box">
                            100k
                        </div>
                        <div className="label-box">
                            500k
                        </div>
                        <div className="label-box">
                            1m
                        </div>
                        <div className="label-box">
                            5m
                        </div>
                        <div className="label-box">
                            10m
                        </div>
                    </div>
                </div>

                <div className="label-wrapper">
                    <div className="label-wrapper-title">
                        Minimum Average Order Value (AOV)
                    </div>
                    <div className="label-box-wrapper disabled">
                        <div className="label-box">
                            10
                        </div>
                        <div className="label-box">
                            25
                        </div>
                        <div className="label-box">
                            50
                        </div>
                        <div className="label-box">
                            100
                        </div>
                        <div className="label-box">
                            500
                        </div>
                        <div className="label-box">
                            1k
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Show all placement pages */}
        <Modal
            title={<></>}
            closable={true}
            width='50vw'
            closeIcon={<span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>}
            maskClosable={true}
            open={isSpecificRetailerModal}
            // onOk={onAddPages}
            onCancel={() => setSpecificRetailerModal(false)}
            footer={<></>}
        >
            <div className="modal-title">
                BrandSwap hosts
            </div>
            <div className="modal-description">
                Please select the hosts to whom offer will be available
            </div>

            <div className="modal-content">

                <div className="dropdown-search">
                    <SearchOutlined className="search-icon" />
                    <input ref={retailersRef} type="text" className="default-input" value={searchRetailers} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchRetailers(event.target.value)} />
                    {searchRetailers.length > 0 &&
                        <span className="close-icon" onClick={handleClearRetailers}>
                            <span className="icon icon-remove"></span>
                        </span>
                    }
                </div>

                <div className="table-wrapper table-scrollable-wrapper">
                    <div className="table-scrollable">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Host
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredRetailers?.map((retailer: Retailer, index: number) => {
                                    return <tr key={`offer-${index}`}>
                                        <td>
                                            <span className="flex">
                                                {retailer.logoUrl && <img className="table-image mright10" src={retailer.logoUrl} alt={retailer.name} />}
                                                {retailer.name}
                                            </span>
                                        </td>
                                        <td width="100">
                                            <span className="table-actions">
                                                {selectedRetailers?.map(r => r.id).includes(retailer.id) ?
                                                    <span className="label label-select active table-label" onClick={() => { }}>Selected</span>
                                                    :
                                                    <span className="label label-select table-label" onClick={() => handleAddRetailer(retailer)}>Select</span>
                                                }
                                            </span>
                                        </td>
                                    </tr>
                                })}
                                {filteredRetailers?.length === 0 &&
                                    <tr>
                                        <td className="text-center" colSpan={6}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No hosts" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="pagination mtop20">

                <div className="pagination-prev" onClick={() => pagination.page > 1 && setPagination(prevState => ({ ...prevState, page: prevState.page - 1 }))}>
                    <span className="pagination-box"></span>
                    <span className="pagination-label">Preview</span>
                </div>

                <ul>
                    <>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page: number) => {
                            if ((page < pagination.page + 3 && page > pagination.page - 3) || page === 1 || page === totalPages) {
                                return <React.Fragment key={page}> {page > 2 && page + 2 <= pagination.page && <span className="icon icon-pagination"></span>}
                                    <li onClick={() => page !== pagination.page && setPagination(prevState => ({ ...prevState, page }))} className={page === pagination.page ? 'active' : ''}>
                                        <span className="pagination-box">{page}</span>
                                    </li>
                                    {page + 1 < totalPages && page - 2 >= pagination.page && <span className="icon icon-pagination"></span>}
                                </React.Fragment>
                            }
                            return <React.Fragment key={`rf${page}`}></React.Fragment>
                        })}
                    </>
                </ul>

                <div className="pagination-next" onClick={() => pagination.page < totalPages && setPagination(prevState => ({ ...prevState, page: prevState.page + 1 }))}>
                    <span className="pagination-label">Next</span>
                    <span className="pagination-box"></span>
                </div>

            </div>
        </Modal>
    </>
}

export default CreateOfferExclusive