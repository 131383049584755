import React, { useEffect, useRef, useState } from "react";
import { OnboardingResendcode, OnboardingValidatecode } from "../../../../types/onboarding.interfaces";
import { ApiErrorMessages } from "../../../../types/response.interfaces";
import { ONBOARDING } from "../../const/onboarding.enum";
import checkIcon from "../../../../assets/images/icons/check-green.svg";
import { OnboardingSteps } from "../../interfaces";
import { UserLoginResponse } from "../../../../types/user.interfaces";
import { Button } from "antd";
import useAxios from "../../../../hooks/useAxios.hook";

export interface Props {
    changeStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>,
    user: Partial<UserLoginResponse>,
}

const StepVerification = ({ changeStep, user }: Props) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const { response: responseValidate, axiosFetch: fetchValidate } = useAxios();
    const { response: responseResend, axiosFetch: fetchResend } = useAxios();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const userRef1 = useRef<HTMLInputElement>(null);
    const userRef2 = useRef<HTMLInputElement>(null);
    const userRef3 = useRef<HTMLInputElement>(null);
    const userRef4 = useRef<HTMLInputElement>(null);
    const userRef5 = useRef<HTMLInputElement>(null);
    const userRef6 = useRef<HTMLInputElement>(null);

    const [code1, setCode1] = useState<string>("");
    const [code2, setCode2] = useState<string>("");
    const [code3, setCode3] = useState<string>("");
    const [code4, setCode4] = useState<string>("");
    const [code5, setCode5] = useState<string>("");
    const [code6, setCode6] = useState<string>("");

    const getCode = (): string => {
        return code1 + code2 + code3 + code4 + code5 + code6
    }
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);
        if (user?.id && getCode().length > 5) {
            const data: OnboardingValidatecode = {
                code: getCode(),
                userid: user?.id
            }
            await fetchValidate({ method: 'post', url: '/onboarding/validatecode', data: data });
        }

        setLoading(false);
    }

    // Handling submit responseValidate
    useEffect(() => {
        if (responseValidate && responseValidate?.status) {
            changeStep({ currentStep: ONBOARDING.usertype })
        } else {
            responseValidate?.result?.length > 0 && responseValidate?.result.forEach((value: ApiErrorMessages) => {
                setErrorMessage(value.message);
            });
        }
        // eslint-disable-next-line 
    }, [responseValidate])


    useEffect(() => {
        userRef1?.current?.focus();
    }, [])

    useEffect(() => {
        setErrorMessage("");
    }, [code1, code2, code3, code4, code5, code6])

    const handleInput1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace("-", "");
        setCode1(value.charAt(0));
        value.charAt(1) !== "" && setCode2(value.charAt(1))
        value.charAt(2) !== "" && setCode3(value.charAt(2))
        value.charAt(3) !== "" && setCode4(value.charAt(3))
        value.charAt(4) !== "" && setCode5(value.charAt(4))
        value.charAt(5) !== "" && setCode6(value.charAt(5))
        value.length > 1 && userRef1?.current?.blur();
        value.length === 1 && userRef2?.current?.focus();
    }

    const handleInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCode2(value);
        e.target.value !== "" && userRef3?.current?.focus();
    }

    const handleInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCode3(value);

        e.target.value !== "" && userRef4?.current?.focus();
    }

    const handleInput4 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCode4(value);

        e.target.value !== "" && userRef5?.current?.focus();
    }

    const handleInput5 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCode5(value);
        e.target.value !== "" && userRef6?.current?.focus();
    }

    const handleInput6 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCode6(value);
    }

    const handleResendCode = async (e: React.MouseEvent<HTMLSpanElement>) => {
        setCodeSent(false);
        if (user.email) {
            const resendCode: OnboardingResendcode = {
                email: user.email,
                firstName: user.firstname || ''
            }
            await fetchResend({ method: 'post', url: '/onboarding/resendvalcode', data: resendCode });
        }
    }

    // Handling resend response
    useEffect(() => {
        if (responseResend && responseResend?.status) {
            setCode1(""); setCode2(""); setCode3(""); setCode4(""); setCode5(""); setCode6("");
            setCodeSent(true);
        } else {
            responseResend?.result?.length > 0 && responseResend?.result.forEach((value: ApiErrorMessages) => {
                setErrorMessage(value.message);
            });
        }
        // eslint-disable-next-line 
    }, [responseResend])

    return (
        <div className="widget unauth-widget">
            <form className="widget-form verification">
                <h3 className="widget-form-title">Confirm your Email, please</h3>
                <div className="widget-form-description">
                    <span>Please enter the 6 digit code we sent to </span>
                    <span className="font-warning font-small semibold">
                        {user.email}
                    </span>
                    <div>
                        or
                        <span className="font-active font-small font-hover" onClick={handleResendCode}> click to resend </span>
                    </div>

                </div>

                {codeSent && <>
                    <div className="widget-form-row unauth-link">
                        <span className="font-warning font-small"><img src={checkIcon} alt="completed" />  New code has been sent to your email.</span>
                    </div>
                </>
                }

                <div className="widget-form-row widget-form-row-inline">
                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            ref={userRef1}
                            value={code1}
                            onChange={handleInput1}
                            aria-describedby="nide"
                            autoComplete="off"
                        />
                    </div>
                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            maxLength={1}
                            ref={userRef2}
                            value={code2}
                            onChange={handleInput2}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (code2 === "") {
                                    event.keyCode === 8 && userRef1?.current?.focus();
                                }
                            }}
                            aria-describedby="nide"
                            autoComplete="off"
                        />
                    </div>
                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            maxLength={1}
                            ref={userRef3}
                            value={code3}
                            onChange={handleInput3}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (code3 === "") {
                                    event.keyCode === 8 && userRef2?.current?.focus();
                                }
                            }}
                            aria-describedby="nide"
                            autoComplete="off"
                        />
                    </div>
                    <div className="widget-input-devider">
                        <span></span>
                    </div>
                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            maxLength={1}
                            ref={userRef4}
                            value={code4}
                            onChange={handleInput4}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (code4 === "") {
                                    event.keyCode === 8 && userRef3?.current?.focus();
                                }
                            }}
                            autoComplete="off"
                        />
                    </div>

                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            maxLength={1}
                            ref={userRef5}
                            value={code5}
                            onChange={handleInput5}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (code5 === "") {
                                    event.keyCode === 8 && userRef4?.current?.focus();
                                }
                            }}
                            autoComplete="off"
                        />
                    </div>

                    <div className="widget-input email-verification-input">
                        <input
                            type="text"
                            id="code0"
                            maxLength={1}
                            ref={userRef6}
                            value={code6}
                            onChange={handleInput6}
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (code6 === "") {
                                    event.keyCode === 8 && userRef5?.current?.focus();
                                }
                            }}
                            autoComplete="off"
                        />
                    </div>
                </div>

                {errorMessage &&
                    <div className="widget-form-error">
                        <p className="error-show error-line">
                            {errorMessage}
                        </p>
                    </div>
                }

                <div className="widget-form-actions unauth-button">
                    <div className="widget-form-button">
                        <Button
                            type="default"
                            loading={isLoading}
                            onClick={handleSubmit}
                            className="button-success"
                            htmlType="submit"
                            disabled={getCode().length < 6}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default StepVerification