import { Button } from "antd";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin.hook";
import useLogout from "../../hooks/useLogout.hook";
import { VALIDATION } from "../../utility/enums/validation.enums";
import { EMAIL_REGEX } from "../../utility/regexp";
import Footer from "../footer/footer.component";
import failIcon from "./../../assets/images/icons/fail.svg";
import successIcon from "./../../assets/images/icons/success.svg";

const Login = () => {
  const { login, error, } = useLogin();
  const logout = useLogout();
  const navigate = useNavigate();
  const userRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState<string>('');
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    logout();
    userRef?.current && userRef.current.focus();
    // eslint-disable-next-line
  }, [])

  // Validate email on change
  useEffect(() => {
    const isValid = EMAIL_REGEX.test(email);
    setValidEmail(isValid);
  }, [email]);

  // Validate password and repeat password
  useEffect(() => {
    setErrorMessage('');
  }, [email, password])

  const handleForgotPasswordClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    navigate("/forgot-password");
  };

  // const handleCreateAccountClick = (e: React.MouseEvent<HTMLSpanElement>) => {
  //   e.preventDefault();
  //   navigate("/register");
  // };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (email && password) {
      await login({ email: email, password: password });
    }

    setLoading(false);
  }

  // Handle login error message
  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error]);

  return (
    <>
      <div className="unauth-container-wrapper scroll-dark unauth-login">
        <div className="unauth-container">
          <div className="unauth-content">

            <div className="widget unauth-widget">
              <form className="widget-form small-form login">
                <h3 className="widget-form-title">Welcome to BrandSwap</h3>
                <div className="widget-form-description">
                  {/* <span>New here? </span>
                  <span className="font-active font-hover semibold" onClick={handleCreateAccountClick}>Create an account</span> */}
                </div>

                <div className="widget-form-row">
                  <div className="unauth-label">
                    <span>Email</span>
                    {email.length > 0 && (
                      <span className="unauth-label-error">
                        <span>
                          <img
                            src={validEmail ? successIcon : failIcon}
                            alt={validEmail ? "success" : "fail"}
                          />
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="widget-input">
                    <input
                      type="text"
                      id="unauth-email"
                      value={email}
                      ref={userRef}
                      name="unauth-email"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                      }
                      required aria-describedby="eide"
                    />
                    <p
                      id="eide"
                      className={`error-line ${email && !validEmail
                        ? "error-show"
                        : "error-hide"
                        }`}
                    >
                      {VALIDATION.emailValid}
                    </p>
                  </div>
                </div>
                <div className="widget-form-row">
                  <div className="unauth-label">
                    <span>Password</span>
                  </div>
                  <div className="widget-input">
                    <input
                      type="password"
                      name="unauth-password"
                      value={password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                      required
                    />
                  </div>
                </div>

                <div className="widget-form-error">
                  {errorMessage &&
                    <p className="error-show error-line">
                      {errorMessage}
                    </p>
                  }
                </div>

                <div className="widget-form-actions">
                  <div className="widget-form-button">
                    {/* <button className="primary">Sign in</button> */}

                    <Button
                      type="default"
                      htmlType="submit"
                      onClick={handleSubmit}
                      loading={isLoading}
                      disabled={!validEmail || !password}
                      className="button-success">Sign in</Button>

                    {/* <button type="button" className="extra button-icon">
                      <img src={googleIcon} alt="google" />
                      <span>Sign in with Google</span>
                    </button> */}
                  </div>
                  <div className="widget-form-forgot">
                    <span className="font-color font-small font-hover" onClick={handleForgotPasswordClick}>
                      Forgot password?
                    </span>
                  </div>
                </div>

              </form>
            </div>
          </div>

          <Footer />
        </div>

      </div>
    </>
  );
}

export default Login;
