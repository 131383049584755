import { Button, InputNumber, Radio } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import { CampaignType, FrequncyCappingOffer } from "../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../types/offer.interfaces";
import { UseFrom } from "../../../../../utility/enums/offer.enums";

interface Props {
    setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    offer: Offer,
    campaign: CampaignType,
    setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>,
}

const OfferFrequencyCapping = ({ campaign, setConditionModalOpen, offer, setSelectedOffers }: Props) => {

    const [offerFrequencyCapping, setOfferFrequencyCapping] = useState<FrequncyCappingOffer>(offer.appearanceRules.frequencyCapping);

    const handleUpdateKey = (key: keyof FrequncyCappingOffer, value: number | null | UseFrom) => {
        setOfferFrequencyCapping(prevState => ({ ...prevState, [key]: value }));
    }
    const handleUpdate = () => {
        setSelectedOffers((prevState: Offer[]) => prevState.map((o: Offer) => o.id === offer.id ? { ...offer, appearanceRules: { ...offer.appearanceRules, frequencyCapping: offerFrequencyCapping } } : o));
        setConditionModalOpen(false);
    }

    return <>
        <Radio.Group style={{ display: 'block' }} onChange={(e: CheckboxChangeEvent) => handleUpdateKey('useFrom', e.target.value)} value={offerFrequencyCapping.useFrom}>
            <div className="modal-color-row">
                <div className="modal-row-checkbox">
                    <label className="font-hover"><Radio name="campaign" value={UseFrom.campaign}></Radio> <span className="font-color"> Follow the frequency capping rules of the whole campaign</span></label>
                </div>
            </div>

            <div className="modal-color-row">
                <div className="modal-row-checkbox">
                    <label className="font-hover"><Radio name="none" value={UseFrom.none}></Radio> <span className="font-color"> Deactivate frequency capping for this offer</span></label>
                </div>
            </div>


            <div className="modal-color-row">
                <div className="modal-row-checkbox mbot10">
                    <label className="font-hover"><Radio name="offer" value={UseFrom.offer} ></Radio> <span className="font-color"> Set specific frequency capping rules for this offer</span></label>
                </div>

                <div className={`modal-inputrow mbot20 ${offerFrequencyCapping?.useFrom !== UseFrom.offer ? 'modal-inputrow-disabled' : ''}`}>

                    <div className="modal-label grow mright10">
                        Max times this offer appears to each user
                    </div>

                    <div className="relative pbot15 ptop15">
                        <InputNumber status={offerFrequencyCapping?.useFrom === UseFrom.offer && typeof offerFrequencyCapping.frequency !== 'number' ? 'error' : ''} defaultValue={offer.appearanceRules.frequencyCapping.frequency} onChange={(value: number | null) => handleUpdateKey('frequency', value)} placeholder="eg 5.0" disabled={offerFrequencyCapping?.useFrom !== UseFrom.offer} style={{ width: 150 }}></InputNumber>
                        {offerFrequencyCapping?.useFrom === UseFrom.offer && typeof offerFrequencyCapping.frequency !== 'number' &&
                            <div className="label-error">
                                Required
                            </div>
                        }
                    </div>
                </div>

                <div className={`modal-inputrow mbot20 ${offerFrequencyCapping?.useFrom !== UseFrom.offer ? 'modal-inputrow-disabled' : ''}`}>

                    <div className="modal-label grow mright10">
                        Number of days that the offer will not appear to the user after reaching the above limit
                    </div>
                    <div className="relative pbot15 ptop15">
                        <InputNumber status={offerFrequencyCapping?.useFrom === UseFrom.offer && typeof offerFrequencyCapping.reset !== 'number' ? 'error' : ''} defaultValue={offer.appearanceRules.frequencyCapping.reset} onChange={(value: number | null) => handleUpdateKey('reset', value)} placeholder="eg 5.0" disabled={offerFrequencyCapping?.useFrom !== UseFrom.offer} style={{ width: 150 }}></InputNumber>
                        {offerFrequencyCapping?.useFrom === UseFrom.offer && typeof offerFrequencyCapping.reset !== 'number' &&
                            <div className="label-error">
                                Required
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Radio.Group>

        <div className="ant-modal-footer">
            <Button type="primary" disabled={offerFrequencyCapping.useFrom === UseFrom.offer && (typeof offerFrequencyCapping.frequency !== 'number' || typeof offerFrequencyCapping.reset !== 'number')} onClick={handleUpdate} className="success-button mtop20">Update</Button>
        </div>

    </>
}

export default OfferFrequencyCapping