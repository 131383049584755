import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth.hook";

const UnAuthGuard = () => {
  const { auth } = useAuth();
  return (
    <>
      {auth && auth?.onboardingStatus?.completed ?
        <Navigate to="/dashboard" />
        :
        <Outlet />
      }
    </>
  );
}

export default UnAuthGuard;
