import { Editor } from "@tinymce/tinymce-react"
import { message } from "antd";
import { Dispatch, useEffect, useRef, useState } from "react"
import { OfferCreate } from "../../../types/offer.interfaces"
import Upload, { RcFile, } from "antd/lib/upload"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { postOfferImage } from "../../../services/upload/upload.service"
import { HTTPS_URL_REGEX } from "../../../utility/regexp"


const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/GiF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isLt2M;
};

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>
}

const CreateOfferContent = ({ offer, setOffer }: Props) => {
    const editorRef = useRef<any>();

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();


    useEffect(() => {
        setImageUrl(offer.imageUrl);
    }, [offer.imageUrl])

    // const onPreview = async (file: UploadFile) => {
    //     let src = file.url as string;
    //     if (!src) {
    //         src = await new Promise(resolve => {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.originFileObj as RcFile);
    //             reader.onload = () => resolve(reader.result as string);
    //         });
    //     }
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow?.document.write(image.outerHTML);
    // };

    const handleUploadRequest = async (option: any) => {
        setLoading(true);
        if (option?.file) {
            const response = await postOfferImage(option.file);

            if (response?.data?.status) {
                setImageUrl(response.data?.result);
                setOffer(prevState => ({ ...prevState, imageUrl: response.data.result }))
            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoading(false)
        return option
    }


    const isValidUrl = (): Boolean => {
        return offer.destinationUrl.length > 0 ? HTTPS_URL_REGEX.test(offer.destinationUrl) : true;
    }

    return <>
        <div className="widget-header">
            <div className="widget-icon-box mtop5">
                <span className="icon icon-settings"></span>
            </div>

            <div className="grow mleft10">
                <h3 className="widget-s-title">
                    Content
                </h3>

                <div className="widget-form-description">
                    Required fields for offer content
                </div>
            </div>

        </div>

        <div className="widget-content">
            <div className="widget-icon-content">

                <div className="label-wrapper">
                    <Upload
                        customRequest={handleUploadRequest}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        multiple={false}
                        beforeUpload={beforeUpload}
                        maxCount={1}
                    >
                        {loading ?
                            <div>
                                <LoadingOutlined />
                                <div style={{ marginTop: 8 }}>Uploading</div>
                            </div>
                            :
                            <>
                                {imageUrl ?
                                    <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> :
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                }
                            </>}
                    </Upload>
                </div>

                <div className="label-input-wrapper">
                    <label htmlFor="Name">
                        <span className="label-input-name">Title *</span>
                        <input type="text" tabIndex={0} className="label-input" value={offer.title} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => prevState && ({ ...prevState, title: e.target.value }))} />
                    </label>
                </div>

                <div className="label-input-wrapper">
                    <label htmlFor="Name">
                        <span className="label-input-name">Description *</span>
                        <input type="text" tabIndex={0} className="label-input" value={offer.description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, description: e.target.value }))} />
                    </label>
                </div>

                <div className={`label-input-wrapper ${!isValidUrl() ? 'invalid' : ''}`}>
                    <label htmlFor="Name">
                        <span className="label-input-name">Destination URL *</span>
                        <input type="text" tabIndex={0} className="label-input" value={offer.destinationUrl} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, destinationUrl: e.target.value }))} />
                        {!isValidUrl() && <span className="error-line">Is not valid URL</span>}
                    </label>
                </div>

                <div className="label-input-wrapper">
                    <label htmlFor="Name">
                        <span className="label-input-name">Offer CTA text</span>
                        <input type="text" tabIndex={0} className="label-input" value={offer.offerCTAText} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => ({ ...prevState, offerCTAText: e.target.value }))} />
                    </label>
                </div>

                <div className="label-wrapper">
                    <div className="label-wrapper-title">Long description *</div>
                    <Editor
                        apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={offer.longDescription}
                        onEditorChange={(text, editor) => {
                            setOffer(prevState => ({ ...prevState, longDescription: text }));
                        }}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'autolink', 'lists', 'preview',
                                'anchor',
                                'table', 'wordcount'
                            ],
                            toolbar: 'blocks | ' +
                                'bold italic | bullist numlist',
                            content_style: 'body{ background-color: #f2f2f2} ::selection{background: #3ab78f;color:#fff;}',
                            content_css_cors: true
                        }
                        }
                    />
                </div>

                <div className="label-wrapper">
                    <div className="label-wrapper-title">Frequently asked questions</div>
                    <Editor
                        apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={offer.faqs}
                        onEditorChange={(text, editor) => {
                            setOffer(prevState => ({ ...prevState, faqs: text }));
                        }}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'autolink', 'lists', 'preview',
                                'anchor',
                                'table', 'wordcount'
                            ],
                            toolbar: 'blocks | ' +
                                'bold italic | bullist numlist',
                            content_style: 'body{ background-color: #f2f2f2}',
                            content_css_cors: true,
                        }
                        }
                    />
                </div>

                <div className="label-wrapper">
                    <div className="label-wrapper-title">Terms and conditions</div>
                    <Editor
                        apiKey='efle5cgwsys06uvxk314h48et3wqin9zpaq1qss9hf9nicgp'
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={offer.terms}
                        onEditorChange={(text, editor) => {
                            setOffer(prevState => ({ ...prevState, terms: text }));
                        }}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'autolink', 'lists', 'preview',
                                'anchor',
                                'table', 'wordcount'
                            ],
                            toolbar: 'blocks | ' +
                                'bold italic | bullist numlist',
                            content_style: 'body{ background-color: #f2f2f2}',
                            content_css_cors: true,
                        }
                        }
                    />
                </div>
            </div>
        </div>
    </>
}

export default CreateOfferContent