import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { LayoutSettings } from "../../../../../types/campaign.interfaces";
import { LayoutClass, LayoutDevices } from "../../../const/views";
import ButtonLocationSettings from "../options/button/ButtonLocationSettings.component";
import ButtonTextStyleSettings from "../options/button/ButtonTextStyleSettings.component";
import ButtonShapeSizeSettings from "../options/button/ButtonShapeSizeSettings.component";
import ButtonNumberSettings from "../options/button/ButtonNumberSettings.component";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
    displayOnly: boolean,
}

const ButtonView = ({ updateSettings, settings, layoutDevice, displayOnly }: Props) => {

    return <>
        <div className="settings-options-list">
            <Collapse defaultActiveKey={['']} accordion>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Location <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="location">
                    <div className="settings-option">
                        <ButtonLocationSettings settings={settings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Shapes & size <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="shapes">
                    <div className="settings-option">
                        <ButtonShapeSizeSettings settings={settings} updateSettings={updateSettings} layoutDevice={layoutDevice} displayOnly={displayOnly} />
                    </div>
                </CollapsePanel>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Text & style <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="text">
                    <div className="settings-option">
                        <ButtonTextStyleSettings settings={settings} updateSettings={updateSettings} layoutDevice={layoutDevice} layoutClass={LayoutClass.button} />
                    </div>
                </CollapsePanel>

                
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Number settings <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="number">
                    <div className="settings-option">
                        <ButtonNumberSettings settings={settings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>
                {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Style <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="style">
                    <div className="settings-option">
                        <ButtonStyleSettings settings={settings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}
            </Collapse>
            {/* <Collapse defaultActiveKey={['button']} ghost>
                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Button options <span className="icon icon-arrow-down"></span>
                        </div>
                    }
                    key="button">
                    <div className="settings-option">
                        <LayoutOptionButton settings={settings} updateSettings={updateSettings} setLayoutView={setLayoutView} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>
            </Collapse> */}
        </div>
    </>
}

export default ButtonView