import {  Switch, Tabs } from "antd";
import { useState } from "react";
import { LayoutChildClass, LayoutClass, LayoutDevices } from "../../../../const/views";
import { CampaignType, LayoutSettings, PlacementSettings } from "../../../../../../types/campaign.interfaces";
import { Template } from "../../../../../../types/templates.interfaces";
import PopupLayout from "../../../../../templates-previews/layout-views/PopupLayout.component";
import ButtonLayout from "../../../../../templates-previews/layout-views/ButtonLayout.component";
import PreviewTemplate from "../../../../../templates-previews/PreviewTemplate.component";
import InlineView from "../../views/InlineView.component";
import ButtonView from "../../views/ButtonView.component";
import PopupView from "../../views/PopupView.component";
import ActionView from "../../views/ActionView.component";

interface Props {
    currentPlacement: PlacementSettings,
    setCurrentPlacement: React.Dispatch<React.SetStateAction<PlacementSettings | undefined>>,
    campaign: CampaignType,
    layoutView: LayoutDevices,
    setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>,
    setTemplate: React.Dispatch<React.SetStateAction<Template | undefined>>,
}

const TemplateUltimate = ({ campaign, layoutView, setCampaign, currentPlacement, setCurrentPlacement, setTemplate }: Props) => {
    // const [layoutView, setLayoutView] = useState<LayoutDevices>(LayoutDevices.desktop);
    const [optionsView, setOptionsView] = useState<LayoutChildClass>(currentPlacement?.templateSettings.desktop.type === LayoutClass.action ? LayoutChildClass.popup : LayoutChildClass.button);
 

    const updatePlacementSettings = (allPlacements: PlacementSettings[], current: PlacementSettings): PlacementSettings[] => {
        const newPlacements: PlacementSettings[] = allPlacements.map((placement => {
            return placement.id === current.id ? { ...placement, templateSettings: current.templateSettings, promoMessage: current.promoMessage } : placement
        }));

        return newPlacements;
    }

    const setLayoutSettings = (settings: LayoutSettings, promoMessage?: string) => {
        if (currentPlacement) {
            var newPlacement = { ...currentPlacement, templateSettings: settings };
            (promoMessage || promoMessage === '') && (newPlacement.promoMessage = promoMessage);
            setCurrentPlacement(newPlacement);
        }
    }

    const handleChangeType = (key: LayoutClass, placement: PlacementSettings) => {
        const newPlacement = { ...placement, templateSettings: { ...placement.templateSettings, [layoutView]: { ...placement.templateSettings[layoutView], type: key } } };
        const newPlacements: PlacementSettings[] = updatePlacementSettings(campaign.placementSettings, newPlacement)
        setCampaign(prevState => ({
            ...prevState, placementSettings: newPlacements,
        }));

        key === LayoutClass.action && setOptionsView(LayoutChildClass.popup);
        key === LayoutClass.button && setOptionsView(LayoutChildClass.button);

        setCurrentPlacement(newPlacement);
    }

    const isType = (type: LayoutClass) => {
        return currentPlacement?.templateSettings[layoutView].type === type
    }

    const getPreviewTitle = () => {
        return currentPlacement?.promoMessage && currentPlacement.promoMessage !== ' ' ? currentPlacement.promoMessage : campaign.description
    }

    return <>
        <div className="widget-layout">
            <div className="interaction-wrapper">
                <div className="interaction-pages">
                    <div className="interaction-pages-overlay">

                        <div className="interaction-section">
                            {/* <LayoutPlacements campaign={campaign} setCampaign={setCampaign} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} setDisabled={setDisabled} /> */}
                        </div>

                        <div className="interaction-section">
                            {/* <div className="interaction-device">
                                <div className={`icon-holder flex-center ${layoutView === 'desktop' ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.desktop)}>
                                    <span className={`icon icon-desktop  ${layoutView === 'desktop' ? 'active' : ''}`}></span>
                                </div>
                                <div className={`icon-holder flex-center ${layoutView === 'mobile' ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.mobile)}>
                                    <span className={`icon icon-mobile ${layoutView === 'mobile' ? 'active' : ''}`}></span>
                                </div>
                            </div> */}

                            <Tabs
                                className="layout-tabs no-style"
                                centered
                                defaultActiveKey={currentPlacement.templateSettings[layoutView].type}
                                activeKey={currentPlacement.templateSettings[layoutView].type}
                                moreIcon={false}
                                onChange={(key: string) => handleChangeType(key as LayoutClass, currentPlacement)}
                                items={[
                                    {
                                        label: <><Switch id="switch-button" size="small" checked={currentPlacement.templateSettings[layoutView].type === LayoutClass.button} /><label htmlFor="switch-button">Overlay</label></>,
                                        key: LayoutClass.button,
                                        children: (
                                            <>
                                                <Tabs
                                                    centered
                                                    onChange={(key: string) => setOptionsView(key as LayoutChildClass)}
                                                    activeKey={optionsView}
                                                    items={[
                                                        {
                                                            label: <>Button</>,
                                                            key: LayoutChildClass.button,
                                                            children: <ButtonView settings={currentPlacement.templateSettings} updateSettings={setLayoutSettings} layoutDevice={layoutView} displayOnly={currentPlacement.displayOnly} />,
                                                        },
                                                        {
                                                            label: <>Pop up</>,
                                                            key: LayoutChildClass.popup,
                                                            children: <PopupView currentPlacement={currentPlacement} defaultTitle={campaign.description} updateSettings={setLayoutSettings} layoutDevice={layoutView} />
                                                        },
                                                    ]} />
                                            </>
                                        )
                                    },
                                    {
                                        label: <> <Switch id="switch-action" size="small" checked={currentPlacement.templateSettings[layoutView].type === LayoutClass.action} /> <label htmlFor="switch-action">Behaviour</label></>,
                                        key: LayoutClass.action,
                                        children: (
                                            <>
                                                <Tabs
                                                    centered
                                                    onChange={(key: string) => setOptionsView(key as LayoutChildClass)}
                                                    activeKey={optionsView}
                                                    items={[
                                                        {
                                                            label: <>Pop up</>,
                                                            key: LayoutChildClass.popup,
                                                            children: <ActionView currentPlacement={currentPlacement} defaultTitle={campaign.description} updateSettings={setLayoutSettings} layoutDevice={layoutView} />
                                                        },
                                                        {
                                                            label: <>Button</>,
                                                            key: LayoutChildClass.button,
                                                            children: <ButtonView settings={currentPlacement.templateSettings} updateSettings={setLayoutSettings} layoutDevice={layoutView} displayOnly={currentPlacement?.displayOnly} />,
                                                        },
                                                    ]} />
                                            </>
                                        )
                                    },
                                    {
                                        label: <> <Switch id="switch-inline" size="small" checked={currentPlacement.templateSettings[layoutView].type === LayoutClass.inline} />  <label htmlFor="switch-inline">Embedded</label></>,
                                        key: LayoutClass.inline,
                                        children: <InlineView campaignId={campaign.id || 'id'} defaultTitle={campaign.description} currentPlacement={currentPlacement} updateSettings={setLayoutSettings} layoutDevice={layoutView} />
                                    }
                                ]} />
                        </div>
                    </div>
                </div>
                <div className="interaction-view">

                    <div className={`view-content ${layoutView === 'desktop' ? 'desktop' : 'mobile'}`}>
                        <div className={`template-layout ${isType(LayoutClass.inline) ? '' : 'template-blur'}`} >
                            {/* <iframe title="advertisers-template" src={`${process.env.PUBLIC_URL}/templates`}></iframe> */}

                            {isType(LayoutClass.inline) ?
                                <PreviewTemplate
                                    isInline={true}
                                    description={getPreviewTitle()}
                                    campaign={campaign}
                                    settings={currentPlacement?.templateSettings[layoutView]?.inline}
                                    displayOnly={currentPlacement?.displayOnly}
                                    layoutView={layoutView}
                                />
                                :
                                <PreviewTemplate
                                    isInline={false}
                                    layoutView={layoutView}
                                />
                            }
                        </div>

                        {currentPlacement?.templateSettings && !isType(LayoutClass.inline) &&
                            <>
                                {optionsView === LayoutChildClass.popup &&
                                    <PopupLayout
                                        description={getPreviewTitle()}
                                        campaign={campaign}
                                        settings={currentPlacement?.templateSettings[layoutView].type === LayoutClass.action ? currentPlacement?.templateSettings[layoutView]?.action : currentPlacement?.templateSettings[layoutView]?.popup}
                                        displayOnly={currentPlacement?.displayOnly}
                                        buttonShape={currentPlacement?.templateSettings[layoutView]?.button.shape}
                                    />
                                }
                                {optionsView === LayoutChildClass.button &&
                                    <ButtonLayout settings={currentPlacement?.templateSettings[layoutView]?.button} displayOnly={currentPlacement.displayOnly} count={campaign.offers.length} />
                                }
                            </>
                        }
                        {/* {optionsView === LayoutChildClass.button && <></>} */}
                        {/* <iframe title="offer-page-layout" src={`${process.env.PUBLIC_URL}/offer-page-layout?position=${position}`} ></iframe> */}

                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default TemplateUltimate;
