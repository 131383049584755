/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, useEffect, useState } from "react";
import checkIconSquare from "../../../assets/images/icons/check-white-square.svg";
import "./css/affiliate-and-offers-settings.scss";
import { Switch, message } from "antd";
import infoIcon from "../../../assets/images/icons/info-icon.svg";
import {
  CompanySettingsInterface,
  Network,
  NetworkSettings,
} from "../../../types/company.interfaces";
import failIcon from "../../../assets/images/icons/fail.svg";
import successIcon from "../../../assets/images/icons/success.svg";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import useAxios from "../../../hooks/useAxios.hook";
interface Props {
  company: CompanySettingsInterface;
  setCompany: Dispatch<React.SetStateAction<CompanySettingsInterface>>;
  isSuperAdmin: boolean | undefined;
}

const emptyImageURL =
  "https://brswstorage.blob.core.windows.net/assets/images/empty_image.png";

const AffiliateAndOffersSettings = ({
  company,
  setCompany,
  isSuperAdmin,
}: Props) => {
  const { myAxios } = useAxios();
  const [networks, setNetworks] = useState<NetworkSettings[]>();
  const [selectedNetworks, setSelectedNetworks] = useState<Network[]>();

  const [validComission, setValidComission] = useState<boolean>(false);

  useEffect(() => {
    const isValid =
      company.commissionAmount.amount >= 0 &&
      company.commissionAmount.amount <= 100;

    setValidComission(isValid);
  }, [company.commissionAmount.amount]);

  const handleSwitch = (value: boolean) => {
    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          autoApproveOffers: value,
        }
    );
  };

  const getNetworks = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "affiliatenetwork",
    });

    if (response?.data?.status) {
      setNetworks(response?.data?.result);
      setSelectedNetworks(
        checkSelectedItems(company.networks, response?.data?.result)
      );
    } else {
      message.error("Failed to get networks", 1);
    }
  };

  const checkSelectedItems = (array1: any, array2: any) => {
    // Create a Set from the uniqueIdentifier values in the second array
    const uniqueIdentifiersSet = new Set(
      array2.map((item: any) => item.uniqueIdentifier)
    );

    // Filter the first array to include only items whose uniqueIdentifier is in the second array
    const calculatedItems = array1.filter((item: any) =>
      uniqueIdentifiersSet.has(item.uniqueIdentifier)
    );

    return calculatedItems;
  };

  useEffect(() => {
    getNetworks();
  }, []);

  const handleRemoveNetwork = (networkUniqueId: string) => {
    const _selectedNetworks: any[] = structuredClone(
      selectedNetworks || []
    ).filter((item: any) => item.uniqueIdentifier !== networkUniqueId);

    setSelectedNetworks(_selectedNetworks);
    setCompany(
      (prevState) => prevState && { ...prevState, networks: _selectedNetworks }
    );
  };

  const handleAddNetwork = (networkUniqueId: string) => {
    const network: NetworkSettings | undefined = structuredClone(
      networks || []
    ).find((item: any) => item.uniqueIdentifier === networkUniqueId);

    const newNetwork: Network = {
      id: network?.id || "",
      name: network?.name || "",
      uniqueIdentifier: network?.uniqueIdentifier || "",
      external_Pid: "",
      external_Aid: "",
    };

    setSelectedNetworks((prevState) => prevState && [...prevState, newNetwork]);

    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          networks: [...prevState.networks, newNetwork],
        }
    );
  };

  const checkIfSelected = (uniqueIdentifier: string) => {
    return selectedNetworks?.find(
      (value) => value.uniqueIdentifier === uniqueIdentifier
    );
  };

  const handlePidChange = (uniqueIdentifier: string, newPid: string) => {
    const net = (selectedNetworks || []).map((net) =>
      net.uniqueIdentifier === uniqueIdentifier
        ? { ...net, external_Pid: newPid }
        : net
    );

    setSelectedNetworks(net);

    setCompany((prevState) => prevState && { ...prevState, networks: net });
  };

  const handleAidChange = (uniqueIdentifier: string, newAid: string) => {
    const net = (selectedNetworks || []).map((net) =>
      net.uniqueIdentifier === uniqueIdentifier
        ? { ...net, external_Aid: newAid }
        : net
    );

    setSelectedNetworks(net);

    setCompany((prevState) => prevState && { ...prevState, networks: net });
  };

  const setPid = (uniqueIdentifier: string) => {
    return (
      selectedNetworks?.find((sn) => sn.uniqueIdentifier === uniqueIdentifier)
        ?.external_Pid || ""
    );
  };

  const setAid = (uniqueIdentifier: string) => {
    return (
      selectedNetworks?.find((sn) => sn.uniqueIdentifier === uniqueIdentifier)
        ?.external_Aid || ""
    );
  };

  return (
    <div className="card">
      <h3 className="title">Affiliate And Offers</h3>
      <div className="desc">
        <span>Select a relevant Affiliate Network below</span>
      </div>
      <div className="items">
        {networks?.map((network) => (
          <div
            className={`item ${
              checkIfSelected(network.uniqueIdentifier) && "active"
            }`}
            key={network.uniqueIdentifier}
          >
            {checkIfSelected(network.uniqueIdentifier) ? (
              <div className="check check--active">
                <img
                  src={checkIconSquare}
                  onClick={() => handleRemoveNetwork(network.uniqueIdentifier)}
                  alt="active"
                />
              </div>
            ) : (
              <span
                className="check check--inactive"
                onClick={() => handleAddNetwork(network.uniqueIdentifier)}
              ></span>
            )}

            <div className="affiliate-wrapper">
              <div className="affiliate-header">
                <div className="affiliate-image">
                  <img
                    src={network.logoUrl ? network.logoUrl : emptyImageURL}
                    alt="Network"
                  />
                </div>
                <div className="affiliate-desc">
                  <span>{network.name}</span>
                </div>
              </div>

              <div
                className={`widget-multiple-form-row ${
                  company.companyType !== COMPANY_TYPES.both
                    ? "widget-form-row-single"
                    : ""
                }`}
              >
                {(company.companyType === COMPANY_TYPES.both ||
                  company.companyType === COMPANY_TYPES.publisher) && (
                  <div className="widget-form-row">
                    <div className="unauth-label">
                      <span>Publisher ID</span>
                    </div>
                    <div className="widget-input affiliate-input">
                      <input
                        id="publisher-id"
                        name="publisher-id"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePidChange(
                            network.uniqueIdentifier,
                            e.target.value
                          )
                        }
                        value={setPid(network.uniqueIdentifier)}
                        aria-describedby="cnide"
                        autoComplete="off"
                        placeholder="e.g. 0009384020"
                      />
                    </div>
                  </div>
                )}

                {(company.companyType === COMPANY_TYPES.both ||
                  company.companyType === COMPANY_TYPES.advertiser) && (
                  <div className="widget-form-row">
                    <div className="unauth-label">
                      <span>Advertiser ID</span>
                    </div>
                    <div className="widget-input affiliate-input">
                      <input
                        id="advertiser-id"
                        name="advertiser-id"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleAidChange(
                            network.uniqueIdentifier,
                            e.target.value
                          )
                        }
                        value={setAid(network.uniqueIdentifier)}
                        aria-describedby="cnide"
                        autoComplete="off"
                        placeholder="e.g. 0009384020"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {(company.companyType === COMPANY_TYPES.advertiser ||
        company.companyType === COMPANY_TYPES.both ||
        isSuperAdmin) && (
        <>
          <div className="desc">
            <span>Set the financial and marketing details below</span>
          </div>
          <div className="actions">
            {isSuperAdmin && (
              <div className="comission">
                <div className="item">
                  <div className="unauth-label">
                    <span>Commission sharing ID</span>
                  </div>
                  <div className="widget-input comission--input">
                    <input
                      type="number"
                      id="commission-sharing-id"
                      name="company-name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCompany(
                          (prevState) =>
                            prevState && {
                              ...prevState,
                              commissionSharingId: e.target.value,
                            }
                        )
                      }
                      value={company.commissionSharingId}
                      aria-describedby="cnide"
                      autoComplete="off"
                      placeholder="e.g. 937502300"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="unauth-label">
                    <span>Commission amount*</span>
                    {!(
                      company.commissionAmount.amount >= 0 &&
                      company.commissionAmount.amount <= 100
                    ) && (
                      <span className="unauth-label-error">
                        <span>
                          <img
                            src={validComission ? successIcon : failIcon}
                            alt={validComission ? "success" : "fail"}
                          />
                        </span>
                      </span>
                    )}
                  </div>
                  <div
                    className={`widget-input comission--widget ${
                      company.commissionAmount &&
                      company.commissionAmount.amount &&
                      !validComission
                        ? "widget-input-invalid"
                        : ""
                    }`}
                  >
                    <input
                      type="number"
                      id="commissionAmount"
                      name="commissionAmount"
                      step="0"
                      min="0"
                      max="100"
                      value={company.commissionAmount.amount}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setCompany(
                          (prevState) =>
                            prevState && {
                              ...prevState,
                              commissionAmount: {
                                ...prevState.commissionAmount,
                                amount: Math.round(parseFloat(e.target.value)),
                              },
                            }
                        )
                      }
                      placeholder="e.g. 10"
                    />
                    <span className="percentage">%</span>
                    <p
                      id="wide"
                      className={`error-line ${
                        !validComission ? "error-show" : "error-hide"
                      }`}
                    >
                      Must be between 0 to 100%
                    </p>
                  </div>
                </div>
              </div>
            )}

            {(company.companyType === COMPANY_TYPES.advertiser ||
              company.companyType === COMPANY_TYPES.both) && (
              <div className="action action-auto-approve">
                <div className="info">
                  <span className="mright5">Auto approve offers</span>
                  <img src={infoIcon} alt="Info Icon" />
                </div>
                <Switch
                  size="default"
                  checked={company.autoApproveOffers}
                  onChange={(value: boolean) => {
                    handleSwitch(value);
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AffiliateAndOffersSettings;
