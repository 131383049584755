import { Button, message } from "antd";
import "./css/development-tools-settings.scss";
import Copy from "../../../assets/images/icons/copy.svg";

interface Props {
  authToken: string;
}

const DevelopmentToolsSettings = ({ authToken }: Props) => {
  const inlineHtml = `<script>
  (function (document) {
    var token = "${authToken}";
    var script = document.createElement("script");
    // Set source of script logic
    script.setAttribute("src", "https://assets.brandswap.com/assets/tag/production/brandswop.js");
    script.setAttribute("id", "brandswopinitscript");
    // company token
    script.setAttribute("token", token);
    (document.body || document.head).appendChild(script);
  })(document);
</script>
  `;

  const handleCopyHtml = () => {
    navigator.clipboard.writeText(inlineHtml);
    message.success("Script is copied");
  };

  return (
    <div className="card">
      <h3 className="title">Tracking code</h3>
      <div className="desc">
        <span>
          Copy and paste this code snippet into the{" "}
          <span className="bold-text">{"<head> "}</span>
          tag in the html of your website.
        </span>
      </div>
      <div className="option-content mbot20">
        <div className="option-two-col">
          <div className="copy-wrapper">
            <textarea
              disabled={true}
              className="copy-textarea"
              value={inlineHtml}
            ></textarea>
            <Button
              type="primary"
              icon={<img src={Copy} alt="copy" className="copy-image" />}
              onClick={() => handleCopyHtml()}
              className="copy-button"
            >
              Copy to clipboard
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentToolsSettings;
