import { createContext, useState } from "react";
import { PageContextType, PageType } from "../types/global.interfaces";

export const PageContext = createContext<PageContextType>({ page: { title: <></> }, setPage: () => { } });

export const PageProvider = ({ children }: any) => {
    const [page, setPage] = useState<PageType>({ title: <></> });

    return <PageContext.Provider value={{ page, setPage }}>
        {children}
    </PageContext.Provider>
}

export default PageContext;