import { LayoutDevices } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";
import ExpressButtonLocationSettings from "../Button/ButtonLocationSettings.component";
import ExpressButtonShapeSizeSettings from "../Button/ButtonShapeSizeSettings.component";
import ExpressButtonTextStyleSettings from "../Button/ButtonTextStyleSettings.component";

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ExpressButtonView = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {
    return <>

        <div className="express-sidebar-row">
            <div className="option-title">
                Location <span className="icon icon-collapse-mix"></span>
            </div>
            <ExpressButtonLocationSettings sync={sync} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
        </div>

        <div className="express-sidebar-row">
            <div className="option-title">
                Shapes & Sizes <span className="icon icon-collapse-mix"></span>
            </div>
            <ExpressButtonShapeSizeSettings sync={sync} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
        </div>


        <div className="express-sidebar-row">
            <div className="option-title">
                Text & Style <span className="icon icon-collapse-mix"></span>
            </div>
            <ExpressButtonTextStyleSettings sync={sync} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
        </div>
    </>
}

export default ExpressButtonView

