import { Button, Empty, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { Offer } from "../../../../types/offer.interfaces";
import defaultOfferImg from "../../../../assets/images/offer-image.jpg";
import { LoadingOutlined } from "@ant-design/icons";
import useAuth from "../../../../hooks/useAuth.hook";
import { ACCESS_TYPES } from "../../../../utility/enums/user.enums";

interface Props {
    isLoading: boolean,
    offers: Offer[]
}

const OffersWidget = ({ isLoading, offers }: Props) => {
    const navigate = useNavigate();
    const { company } = useAuth();

    const sliceText = (text: string): string => {
        return text.length > 50 ? `${text.slice(0, 15)}...` : text
    }

    return <>
        <div className="home-widget">
            <div className="widget-title">
                Offers
            </div>
            <div className="widget-description">
            </div>
            <div className="widget-content widget-table">
                <div className="table-wrapper table-scrollable-wrapper">
                    <div className="table-scrollable">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Advertiser
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                {company?.userAccess?.access.includes(ACCESS_TYPES.offerread) ?
                                    <>
                                        {offers.map((offer: Offer, index: number) => {
                                            return <tr key={`offer-${index}`}>
                                                <td className="min150">
                                                    <img className="table-image mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />

                                                    {offer.advertiser.name}
                                                </td>
                                                <td className="break-word">
                                                    <Tooltip title={offer.description.length > 50 ? offer.description : ''} >
                                                        <span className="font-color">{sliceText(offer.description)}</span>
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        })}
                                        {isLoading ?
                                            <tr className={offers.length > 0 ? 'loader-absolute' : ''}>
                                                <td className="text-center" colSpan={6}>
                                                    <LoadingOutlined />
                                                </td>
                                            </tr>
                                            :
                                            <>
                                                {offers.length === 0 &&
                                                    <tr>
                                                        <td className="text-center" colSpan={6}>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No offers" />
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        }

                                    </> :
                                    <>
                                        <tr>
                                            <td className="text-center" colSpan={6}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You are not authorized to access this resourse" />
                                            </td>
                                        </tr>
                                    </>

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="widget-footer">
                {company?.userAccess?.access.includes(ACCESS_TYPES.offerread) &&
                    <Button type="primary" onClick={() => navigate('/offers')}>Go to all offers</Button>
                }
            </div>
        </div>
    </>
}

export default OffersWidget;