import { Dropdown, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { itemsFontFamily, itemsFontSize } from "../../../../../campaigns/const/layout-options.enum";
import { GeneralSettings, LocationPosition } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutClass, LayoutDevices } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ExpressPopupHeaderSettings = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {
    const [headerText, setHeaderText] = useState<string>(currentPlacement?.promptMessage || '');
    const debounceValue = useDebounce(headerText, 500);

    const _type = useMemo(() => {
        if (currentPlacement) {
          var _type: LayoutClass.popup | LayoutClass.action = currentPlacement.templateSettings[layoutView].type === LayoutClass.action ? LayoutClass.action : LayoutClass.popup;
          return _type
        }
        return LayoutClass.popup
      }, [currentPlacement, layoutView])

    const uniqueKey = new Date().getTime();

    
    const updateButtonGeneralStyle = (key: keyof GeneralSettings, value: string | LocationPosition | number) => {

        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                [_type]: {
                    ...currentPlacement.templateSettings[layoutView][_type],
                    general: {
                        ...currentPlacement.templateSettings[layoutView][_type]?.general,
                        [key]: value
                    }
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        general: {
                            ...currentPlacement.templateSettings[layoutView][_type].general,
                            [key]: value
                        }
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        general: {
                            ...currentPlacement.templateSettings[layoutView][_type].general,
                            [key]: value
                        }
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        updateButtonGeneralStyle('backgroundColor', color);
    }

    const handleTextColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const color = event.target.value;
        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                [_type]: {
                    ...currentPlacement.templateSettings[layoutView][_type],
                    text: {
                        ...currentPlacement.templateSettings[layoutView][_type].text,
                        textColor: color
                    },
                    header: {
                        ...currentPlacement.templateSettings[layoutView][_type].header,
                        textColor: color
                    }
                }
            }
        }

        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        text: {
                            ...currentPlacement.templateSettings[layoutView][_type].text,
                            textColor: color
                        },
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            textColor: color
                        }
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        text: {
                            ...currentPlacement.templateSettings[layoutView][_type].text,
                            textColor: color
                        },
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            textColor: color
                        }
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                [_type]: {
                    ...currentPlacement.templateSettings[layoutView][_type],
                    header: {
                        ...currentPlacement.templateSettings[layoutView][_type].header,
                        fontFamily: props.key
                    },
                    text: {
                        ...currentPlacement.templateSettings[layoutView][_type].text,
                        fontFamily: props.key
                    }
                }
            }
        }


        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            fontFamily: props.key
                        },
                        text: {
                            ...currentPlacement.templateSettings[layoutView][_type].text,
                            fontFamily: props.key
                        }
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            fontFamily: props.key
                        },
                        text: {
                            ...currentPlacement.templateSettings[layoutView][_type].text,
                            fontFamily: props.key
                        }
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        var newSettings: LayoutSettings = {
            ...currentPlacement.templateSettings, [layoutView]: {
                ...currentPlacement.templateSettings[layoutView],
                [_type]: {
                    ...currentPlacement.templateSettings[layoutView][_type],
                    header: {
                        ...currentPlacement.templateSettings[layoutView][_type].header,
                        fontSize: props.key
                    }
                }
            }
        }
        if (sync) {
            newSettings = {
                ...currentPlacement.templateSettings, desktop: {
                    ...currentPlacement.templateSettings.desktop,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            fontSize: props.key
                        }
                    }
                }, mobile: {
                    ...currentPlacement.templateSettings.mobile,
                    [_type]: {
                        ...currentPlacement.templateSettings[layoutView][_type],
                        header: {
                            ...currentPlacement.templateSettings[layoutView][_type].header,
                            fontSize: props.key
                        }
                    }
                }
            }
        }

        setCurrentPlacement({ ...currentPlacement, templateSettings: newSettings });
    }

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setHeaderText(value);
    }

    useEffect(() => {
        if (typeof debounceValue !== "undefined") {
            setCurrentPlacement({ ...currentPlacement, promptMessage: debounceValue });
        }
        // eslint-disable-next-line
    }, [debounceValue])

    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-single-col">
                    <input
                        type="text"
                        className="default-input"
                        placeholder="Offers"
                        onChange={handleChangeText}
                        value={headerText || ''}
                    />
                </div>
            </div>

            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`popupBgColor${uniqueKey}${layoutView}`}
                            onChange={handleBgChange}
                            value={currentPlacement.templateSettings[layoutView][_type].general.backgroundColor || ''}
                        />
                        <label htmlFor={`popupBgColor${uniqueKey}${layoutView}`}>
                            Background: {currentPlacement.templateSettings[layoutView][_type].general.backgroundColor}
                        </label>
                    </div>
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`popupText${uniqueKey}${layoutView}`}
                            onChange={handleTextColorChange}
                            value={currentPlacement.templateSettings[layoutView][_type].text.textColor || ''}
                        />
                        <label htmlFor={`popupText${uniqueKey}${layoutView}`}>
                            Text: {currentPlacement.templateSettings[layoutView][_type].text.textColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            defaultSelectedKeys: [currentPlacement.templateSettings[layoutView][_type].text.fontFamily],
                            onClick: handleChangeFontFamily
                        }} trigger={['click']}>
                            <Typography.Link>
                                {currentPlacement.templateSettings[layoutView][_type].text.fontFamily}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            defaultSelectedKeys: [currentPlacement.templateSettings[layoutView][_type].text.fontSize],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                {currentPlacement.templateSettings[layoutView][_type].header.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

            </div>
        </div>
    </>
}

export default ExpressPopupHeaderSettings
