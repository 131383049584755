import React, { useState } from "react";
import { COMPANY_TYPES } from "../../../../utility/enums/user.enums";
import advertiserImage from '../../../../assets/images/box-advertiser.png';
import publisherImage from '../../../../assets/images/box-publisher.png';
import checkIcon from '../../../../assets/images/icons/check-white.svg';
import { OnboardingSteps } from "../../interfaces";
import { ONBOARDING } from "../../const/onboarding.enum";
import { OnboardingCompnay } from "../../../../types/onboarding.interfaces";
import { Button } from "antd";

export interface Props {
    changeStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>
    setCompany: React.Dispatch<React.SetStateAction<OnboardingCompnay | undefined>>
}

const StepType = ({ changeStep, setCompany }: Props) => {
    const [isPublisher, setPublisher] = useState<boolean>(true);
    const [isAdvertiser, setAdvertiser] = useState<boolean>(false);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isPublisher || isAdvertiser) {
            const type: COMPANY_TYPES = isPublisher && isAdvertiser ?
                COMPANY_TYPES.both :
                isAdvertiser && !isPublisher ?
                    COMPANY_TYPES.advertiser :
                    isPublisher && !isAdvertiser ?
                        COMPANY_TYPES.publisher :
                        COMPANY_TYPES.agency;
            setTypeChangeStep(type);
        }
    }

    const setTypeChangeStep = (_type: COMPANY_TYPES) => {
        setCompany(prevState => ({ ...prevState, companyType: _type }))
        changeStep({ currentStep: ONBOARDING.companydetails })
    }

    return <>
        <div className="widget unauth-widget">
            <form className="widget-form user-type">
                <h3 className="widget-form-title">Select user type, please</h3>
                <div className="widget-form-description">
                    <span>Let us know how you will be using BrandSwap <br />
                        You are a retailer, an advertiser or </span>
                    <span className="font-warning">
                        you can select both
                    </span>
                </div>
                <div className="unauth-boxes-wrapper">
                    <div onClick={() => setPublisher(prevState => !prevState)} className={`unauth-box ${isPublisher && 'active'}`}>
                        {isPublisher && <div className="unauth-box-active">
                            <img src={checkIcon} alt="active" />
                        </div>}
                        <div className="box-image">
                            <img src={advertiserImage} alt="Advertiser" />
                        </div>
                        <div className="box-content">
                            I have my own website
                            and I want to find brands
                            to advertise on my website
                        </div>
                    </div>
                    <div onClick={() => setAdvertiser(prevState => !prevState)} className={`unauth-box ${isAdvertiser && 'active'}`}>

                        {isAdvertiser && <div className="unauth-box-active">
                            <img src={checkIcon} alt="active" />
                        </div>}
                        <div className="box-image">
                            <img src={publisherImage} alt="Publisher" />
                        </div>
                        <div className="box-content">
                            I want to advertise
                            my brand and products
                            to other brands
                        </div>
                    </div>
                </div>
                {/* Hidden agency link for now, to be added later */}
                {/* <div className="widget-form-row unauth-link">
                    <span className="font-active font-hover" onClick={() => setTypeChangeStep(COMPANY_TYPES.agency)}>None of the above, I represent an agency</span>
                </div> */}

                <div className="widget-form-actions unauth-button">
                    <div className="widget-form-button">
                        <Button
                            type="default"
                            className="button-success"
                            onClick={handleSubmit}
                            disabled={!isAdvertiser && !isPublisher}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    </>
}

export default StepType