import { Button, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import usePage from "../../../hooks/usePage.hook";
import { Company, CompanyCategory } from "../../../types/company.interfaces";
import { OfferCreate } from "../../../types/offer.interfaces";
import CreateOfferExclusive from "./CreateOfferExclusive.component";
import {
  DelayType,
  emptyOffer,
  RedeemBy,
} from "../../../utility/enums/offer.enums";
import useAuth from "../../../hooks/useAuth.hook";
import { useNavigate, useParams } from "react-router-dom";
import { Country } from "../../../types/global.interfaces";
import { HTTPS_URL_REGEX } from "../../../utility/regexp";
import { ACCESS_TYPES } from "../../../utility/enums/user.enums";
import CreateOfferHost from "./CreateOfferHost.component";
import { LoadingOutlined } from "@ant-design/icons";
import CreateOfferVouchers from "./CreateOfferVouchers";
import CreateOfferContent from "./content/CreateOfferContent.component";
import CreateOfferSettings from "./settings/CreateOfferSettings.component";

const tabs = ["Settings", "Host", "Exclusive"];

const CreateOffer = () => {
  const { id } = useParams();
  const { setPage } = usePage();
  const [offer, setOffer] = useState<OfferCreate>(emptyOffer);
  const [categories, setCategories] = useState<CompanyCategory[]>();
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [countries, setCountries] = useState<Country[]>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [loading, setLoading] = useState<boolean>(false);

  const { company } = useAuth();
  const { myAxios } = useAxios();

  const getCompanyCategories = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "CompanyCategory",
    });

    if (response?.data?.status) {
      setCategories(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  const getOfferById = async (id: string) => {
    const { response } = await myAxios({ method: "GET", url: `offers/${id}` });

    if (response?.data?.status) {
      setOffer(response?.data?.result);
    } else {
      message.error("Failed to single offer", 1);
    }
  };

  const getCompanyById = async (companyId: string) => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/${companyId}`,
    });
    if (response?.data.status || response?.status === 200) {
      const _currentCompany: Company = response?.data;
      const _networkId =
        _currentCompany.networks?.length > 0
          ? _currentCompany.networks[0].id
          : "";

      setOffer((prevState) => ({
        ...prevState,
        advertiser: {
          ...prevState.advertiser,
          affNetwork_Id: prevState.advertiser.affNetwork_Id || _networkId,
          company_Id: _currentCompany?.id,
        },
      }));

      setCurrentCompany(_currentCompany);
    } else {
      message.error("Failed to get company", 1);
    }
  };

  const getRegions = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "support/countries",
    });

    if (response?.data?.status) {
      setCountries(response?.data?.result);
    } else {
      message.error("Failed to get regions", 1);
    }
  };

  const handleCreateOffer = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: "offers",
      data: offer,
    });
    if (response?.data?.status) {
      const _id = response.data?.result?.id;
      message.success("Offer is created successfully", 1);
      _id && navigate(`/offers/edit/${_id}`);
    } else {
      message.error("Failed to create offer", 1);
    }
    setLoading(false);
  };

  const handleEditOffer = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "PUT",
      url: `offers/${id}`,
      data: offer,
    });
    if (response?.data?.status) {
      message.success("Offer is updated successfully", 1);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
    setLoading(false);
  };

  const handleStartOffer = async (offerId: string) => {
    const { response } = await myAxios({
      method: "POST",
      url: `offers/active/${offerId}/true`,
    });
    if (response?.data?.status) {
      message.success("Offer is started", 1);
      getOfferById(offerId);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
  };

  const handleStopOffer = async (offerId: string) => {
    const { response } = await myAxios({
      method: "POST",
      url: `offers/active/${offerId}/false`,
    });
    if (response?.data?.status) {
      message.success("Offer is stopped", 1);
      getOfferById(offerId);
    } else {
      message.error(response?.data?.result[0].message, 1);
    }
  };

  const handleValidation = useMemo((): boolean => {
    let isValid =
      !offer.startDate ||
      !offer.endDate ||
      !offer.title ||
      !offer.description ||
      !offer.longDescription ||
      (offer.media.videoURL && !HTTPS_URL_REGEX.test(offer.media.videoURL)) ||
      !HTTPS_URL_REGEX.test(offer.destinationUrl) ||
      (offer.offerSettings?.redeemBy === RedeemBy.email &&
        offer.offerSettings?.delaySetting?.delayType !== DelayType.none &&
        offer.offerSettings?.delaySetting?.delayValue === "0") ||
      !offer.categories.length;

    return isValid;
  }, [offer]);

  useEffect(() => {
    setPage({
      title: <>Create Offer</>,
    });
    getCompanyCategories();
    getRegions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getOfferById(id);
      setPage({
        title: <>Save Offer</>,
      });
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (company?.company_Id) {
      getCompanyById(company.company_Id);
    }
    // eslint-disable-next-line
  }, [company]);

  const tabsContent = useMemo(() => {
    let elm = <></>;
    switch (activeTab) {
      case "Content":
        elm = (
          <>
            <CreateOfferContent offer={offer} setOffer={setOffer} />
          </>
        );
        break;

      case "Settings":
        elm = (
          <>
            <CreateOfferSettings
              networks={currentCompany?.networks || []}
              emailTemplates={currentCompany?.emailTemplates || []}
              offer={offer}
              setOffer={setOffer}
              categories={categories}
            />
          </>
        );
        break;

      // case "Settings":
      //   elm = (
      //     <>
      //       <div className="mright20 widget-wrapper grow">
      //         <div className="widget mbot20 widget-icon">
      //           <CreateOfferSettings
      //             networks={currentCompany?.networks || []}
      //             emailTemplates={currentCompany?.emailTemplates || []}
      //             offer={offer}
      //             setOffer={setOffer}
      //             categories={categories}
      //           />
      //         </div>

      //         <div className="widget mbot20 widget-icon">
      //           <CreateOfferRedemtion
      //             emailTemplates={currentCompany?.emailTemplates || []}
      //             offer={offer}
      //             setOffer={setOffer}
      //           ></CreateOfferRedemtion>
      //         </div>
      //       </div>
      //       <div className="widget mbot20 widget-icon">
      //         <CreateOfferContent offer={offer} setOffer={setOffer} />
      //       </div>
      //     </>
      //   );
      //   break;

      case "Host":
        elm = (
          <div className="widget widget-centered alignself-baseline ">
            <CreateOfferHost
              networks={currentCompany?.networks || []}
              emailTemplates={currentCompany?.emailTemplates || []}
              offer={offer}
              setOffer={setOffer}
              categories={categories}
            />
          </div>
        );

        break;
      case "Exclusive":
        elm = (
          <div className="widget widget-centered alignself-baseline ">
            <CreateOfferExclusive
              offer={offer}
              setOffer={setOffer}
              categories={categories}
              countries={countries}
            />
          </div>
        );

        break;

      case 'Vouchers':
        elm = <div className="mright20 widget-wrapper grow">
          <CreateOfferVouchers offer={offer} setOffer={setOffer} />
        </div>

        break;

      default:
        break;
    }

    return elm;
  }, [activeTab, currentCompany, offer, categories, countries]);

  return (
    <>
      <div className="content">
        <div className="content-header">
          <ul className="content-list">
            <li
              className={activeTab === "Content" ? "active" : ""}
              onClick={() => setActiveTab("Content")}
            >
              Content
            </li>
            <li
              className={activeTab === "Settings" ? "active" : ""}
              onClick={() => setActiveTab("Settings")}
            >
              Settings
            </li>
            <li
              className={activeTab === "Host" ? "active" : ""}
              onClick={() => setActiveTab("Host")}
            >
              Host Specific
            </li>
            <li
              className={activeTab === "Exclusive" ? "active" : ""}
              onClick={() => setActiveTab("Exclusive")}
            >
              Exclusive to
            </li>
            {offer?.id &&
              <li
                className={activeTab === "Vouchers" ? "active" : ""}
                onClick={() => setActiveTab("Vouchers")}
              >
                Vouchers
              </li>
            }
          </ul>
          <div className="content-action">
            {company?.userAccess?.access.includes(ACCESS_TYPES.offerwrite) && (
              <>
                {id && (
                  <>
                    {offer?.status === "active" ? (
                      <Button
                        type="primary"
                        className="button-default thin button-icon"
                        icon={<span className="icon-stop icon"></span>}
                        onClick={() => handleStopOffer(id)}
                      >
                        Stop
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        className="button-default thin button-icon"
                        icon={<span className="icon-start icon"></span>}
                        onClick={() => handleStartOffer(id)}
                      >
                        Start
                      </Button>
                    )}
                  </>
                )}

                <Button
                  type="primary"
                  disabled={handleValidation || loading}
                  onClick={id ? handleEditOffer : handleCreateOffer}
                  className={`${id ? "button-active" : "button-success"}`}
                >
                  {id ? <>Save</> : <>Create</>}{" "}
                  {loading && <LoadingOutlined />}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="content-layout content-offers">
          {tabsContent}

          {/* <div className="widget mbot20 widget-icon mright20 alignself-baseline">
                    <CreateOfferSettings networks={currentCompany?.networks || []} emailTemplates={currentCompany?.emailTemplates || []} offer={offer} setOffer={setOffer} categories={categories} />
                </div>

                <div className="settings-widgets">
                    <div className="widget mbot20 widget-icon">
                        <CreateOfferContent offer={offer} setOffer={setOffer} />
                    </div>
                    <div className="widget mbot20 widget-icon">
                        <CreateOfferExclusive offer={offer} setOffer={setOffer} categories={categories} countries={countries} />
                    </div>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default CreateOffer;
