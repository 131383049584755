import {  filtersDefaultSettings, FiltersSettings } from "../../../../../../types/campaign.interfaces";
import { uniqueKey } from "../../../../const/layout-options.enum";

interface Props {
    updateFiltersSettings: (value: FiltersSettings) => void,
    filtersSettings: FiltersSettings | undefined,
}

const FiltersSettingsComponent = ({ updateFiltersSettings, filtersSettings }: Props) => {

    const setNewSettings = (key: keyof FiltersSettings, value: string | number) => {
        const _filtersSettings: FiltersSettings = filtersSettings ? filtersSettings : filtersDefaultSettings;

        const newSettings: FiltersSettings = {
            ..._filtersSettings,
            [key]: value
        }

        updateFiltersSettings(newSettings);
    }


    const handleBackgroundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }

    const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('activeColor', color);
    }

    const handleBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('borderColor', color);
    }

    return <>
        <div className="option-box">

            <div className="option-two-col">
                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`filtersBg${uniqueKey}`}
                        value={filtersSettings?.backgroundColor || '#e5eef5'}
                        onChange={handleBackgroundChange}
                    />
                    <label htmlFor={`filtersBg${uniqueKey}`}>
                        Background: {filtersSettings?.backgroundColor || '#e5eef5'}
                    </label>
                </div>

                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`showMoreText${uniqueKey}`}
                        value={filtersSettings?.textColor || '#939393'}
                        onChange={handleTextChange}
                    />
                    <label htmlFor={`showMoreText${uniqueKey}`}>
                        Text: {filtersSettings?.textColor || '#939393'}
                    </label>
                </div>

            </div>

            <div className="option-two-col">
                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`filtersActive${uniqueKey}`}
                        value={filtersSettings?.activeColor || '#0053a0'}
                        onChange={handleActiveChange}
                    />
                    <label htmlFor={`filtersActive${uniqueKey}`}>
                        Active: {filtersSettings?.activeColor || '#0053a0'}
                    </label>
                </div>
                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`filtersBorder${uniqueKey}`}
                        value={filtersSettings?.borderColor || '#939393'}
                        onChange={handleBorderChange}
                    />
                    <label htmlFor={`filtersBorder${uniqueKey}`}>
                        Border: {filtersSettings?.borderColor || '#939393'}
                    </label>
                </div>
            </div>

        </div>
    </>
}

export default FiltersSettingsComponent
