import { LayoutDevices } from "../../../../const/views";
import { ExpressPlacementSettings } from "../../../../types/express.types";
import ExpressPopupHeaderSettings from "../Popup/PopupHeaderSettings.component"
import ExpressPopupLocationSettings from "../Popup/PopupLocationSettings.component"

interface Props {
    layoutView: LayoutDevices,
    currentPlacement: ExpressPlacementSettings,
    sync: boolean,
    setCurrentPlacement: (placement: ExpressPlacementSettings) => void;
}

const ExpressPopupView = ({ layoutView, currentPlacement, sync, setCurrentPlacement }: Props) => {
    return <>
        <div className="express-sidebar-row">
            <div className="option-title">
                <span className="icon icon-collapse-mix"></span>
            </div>
            <ExpressPopupLocationSettings sync={sync} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
        </div>
        <div className="express-sidebar-row">
            <div className="option-title">
                Text & style <span className="icon icon-collapse-mix"></span>
            </div>
            <ExpressPopupHeaderSettings sync={sync} currentPlacement={currentPlacement} setCurrentPlacement={setCurrentPlacement} layoutView={layoutView} />
        </div>
    </>
}

export default ExpressPopupView

