import { Button, Modal, Select, Slider } from "antd";
import React, { useEffect, useState } from "react";
import { Offer } from "../../../../../types/offer.interfaces";
import defaultOfferImg from "../../../../../assets/images/offer-image.jpg";
import { BaseOptionType } from "antd/lib/select";

interface Props {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectedOffers: Offer[],
    setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>,
}

const ModalOfferSlots = ({ isOpen, setOpen, selectedOffers, setSelectedOffers }: Props) => {
    const [offers, setOffers] = useState<Offer[]>(selectedOffers);
    const [isDisabled, setDisabled] = useState<boolean>(false);

    const slotOptions: BaseOptionType[] = selectedOffers.map((offer, index: number) => {
        return {
            key: index + 1,
            value: index + 1
        }
    });

    const handleChangeSlot = (offer: Offer, slot: number) => {
        const disabled: boolean = offers.filter(_o => _o.slot.slot === offer.slot.slot && _o.id !== offer.id).length > 0

        const _offers = offers.map((o) => o.id === offer.id ? { ...offer, slot: { slot: slot, percentage: disabled ? 100 : offer.slot.percentage } } : o);
        setOffers(_offers);
    }

    const handleChangePercentage = (offer: Offer, percentage: number) => {
        const _offers = offers.map((o) => o.id === offer.id ? { ...offer, slot: { slot: offer.slot.slot, percentage } } : o);
        setOffers(_offers);
    }

    const cancelModal = () => {
        setOpen(false);
        setOffers(selectedOffers);
    }

    useEffect(() => {
        setOffers(selectedOffers);
    }, [selectedOffers]);

    // const hasOfferWithSameSlot = (offer: Offer): boolean => {
    //     const disabled: boolean = offers.filter(_o => _o.slot.slot === offer.slot.slot && _o.id !== offer.id).length > 0
    //     return disabled;
    // }

    const handleUpdate = () => {
        setSelectedOffers(offers);
        setOpen(false);
    }

    const groupOffersBySlot = (arr: Offer[]) => {
        return arr.reduce((grouped: Offer[][], obj) => {
            const slot = obj?.slot?.slot;
            let groupIndex: number = 0;
            let slotsFound: number = 0;

            grouped.forEach((_offers, index) => {
                const sameSlotNr = _offers.filter(_o => _o?.slot?.slot === slot).length;

                if (sameSlotNr > 0) {
                    slotsFound++;
                    groupIndex = index;
                } else {
                    if (slotsFound === 0) {
                        groupIndex = index + 1;
                    }
                }
            });

            if (!grouped[groupIndex]) {
                grouped[groupIndex] = []
            }

            grouped[groupIndex].push(obj);

            return grouped;
        }, []);
    }

    const findInvalidOffersSlot = (groupedOffers: Offer[][]) => {
        setDisabled(false);
        groupedOffers.forEach((_offers, index) => {
            let percentage = 0;
            _offers.forEach(offer => {
                percentage += offer?.slot?.percentage;
            });

            if (percentage !== 100) {
                setDisabled(true);
            }
        });
    }

    useEffect(() => {
        const groupedOffers: Offer[][] = groupOffersBySlot(offers);
        findInvalidOffersSlot(groupedOffers);
    }, [offers]);

    return <>
        <Modal
            className="modal-default"
            title={<></>}
            footer={<></>}
            centered={true}
            maskClosable={true}
            open={isOpen}
            width={1200}
            destroyOnClose={true}
            closable={true}
            onCancel={cancelModal}
            closeIcon={<span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>}
        >
            <div className="condition-header">
                <div className="condition-rule">

                </div>
                <span className="condition-title">
                    Manage slot allocation
                </span>
                <span></span>
            </div>
            <div className="condition-rules-inputs">
                <div className="condition-rules-header x3">
                    <div className="condition-column">
                        Offer
                    </div>
                    <div className="condition-column">
                        Slot
                    </div>
                    <div className="condition-column">
                        Traffic allocation
                    </div>
                </div>

                {offers?.map((offer: Offer, index: number) => {
                    return <div key={`rule-${index}`} className="condition-rule-row x3">
                        <div className="condition-column">

                            <div className="express-input">
                                <img className="table-image mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />
                                {offer.title}
                            </div>
                        </div>
                        <div className="condition-column">

                            <div className="express-input">
                                <Select
                                    placeholder="Select operator"
                                    className="max200"
                                    suffixIcon={<span className="icon icon-arrow-down"></span>}
                                    options={slotOptions}
                                    value={offer?.slot?.slot || 1}
                                    onChange={(value: number, option: BaseOptionType) => {
                                        handleChangeSlot(offer, option.key);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="condition-column">
                            <div className="flex-center-space-between">
                                <span className="semibold">{offer?.slot?.percentage}%</span>
                                <Slider
                                    min={0}
                                    max={100}
                                    step={10}
                                    className="min100 grow"
                                    onChange={(value) => handleChangePercentage(offer, value)}
                                    value={offer?.slot?.percentage || 100}
                                />
                            </div>
                        </div>
                    </div>
                })}

                <div className="condition-footer">
                </div>
            </div>

            <div className="ant-modal-footer mtop20 flex-space-between">
                <Button type="primary" className="button-danger" onClick={cancelModal}>Cancel</Button>
                {isDisabled && <label className="error">There are slots not equal to 100%</label>}
                <Button disabled={isDisabled} type="primary" className="success-button" onClick={handleUpdate}>Update</Button>
            </div>
        </Modal>

    </>
}

export default ModalOfferSlots