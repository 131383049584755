import "../css/express.scss";
import { Alert, Button, Collapse, Input, message, Modal, Switch } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { DeleteOutlined, DownOutlined, InfoCircleOutlined, UpOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { ExpressPage, LayoutClass, LayoutDevices } from "../const/views";
import { expressSettings, offer, PLACEMENT_IDENTIFIER } from "../const/campaigns.enum";
import ExpressTemplatePopup from "../onboarding/expressPreview/ExpressTemplatePopup.component";
import ExpressPrePreview from "../onboarding/expressPreview/ExpressPrePreview.component";
import useExpress from "../../../hooks/useExpress.hook";
import { ExpressCompany, ExpressPlacement, ExpressPlacementSettings } from "../types/express.types";
import ExpressLayoutSettings from "../onboarding/expressLayout/settings/expressLayoutSettings.component";
import useAxios from "../../../hooks/useAxios.hook";
import Select, { BaseOptionType } from "antd/lib/select";
import { VALIDATION } from "../../../utility/enums/validation.enums";
import { LayoutSettings } from "../../../types/campaign.interfaces";
import { ActionSettings, PopupSettings } from "../../templates-previews/types/params.interfaces";

const ExpressCampaignLayout = () => {
  const { campaign, setCampaign, company, onboardingStatus, setExpressPage, refreshState } = useExpress();
  const { myAxios } = useAxios();
  const [currentPlacement, setCurrentPlacement] = useState<ExpressPlacementSettings | undefined>();
  const [layoutView, setLayoutView] = useState<LayoutDevices>(LayoutDevices.desktop);

  const handleChangeIsEnable = async (value: boolean, id: string) => {
    if (campaign && campaign?.placementSettings?.length > 0) {
      const newPlacements: ExpressPlacementSettings[] = campaign.placementSettings.map((p: ExpressPlacementSettings) => {
        return p.id === id ? { ...p, isEnabled: value } : p;
      });

      const _newCampaign = { ...campaign, placementSettings: newPlacements };

      setCampaign(_newCampaign);
      const { response } = await myAxios({ method: 'POST', url: `onboarding/express/campaign`, data: { company, campaign: _newCampaign, onboardingStatus, password: '' } });
      if (response?.data?.status) {
        refreshState();

        message.success({
          content: "Placement is updated",
          className: 'express-message',
          duration: 1,
        });
      } else {
        message.error("Failed to save placement!", 1);
      }
    }
  }

  const [isAddPagesOpen, setAddPagesOpen] = useState<boolean>(false);
  const [pagesList, setPages] = useState<ExpressPlacement[]>([]);
  const [isEditPageOpen, setEditPageOpen] = useState<boolean>(false);
  const { response: responsePageTypes, axiosFetch: fetchPageTypes } = useAxios();

  const emptyPage: ExpressPlacement = {
    id: '',
    name: '',
    identifier: PLACEMENT_IDENTIFIER.product,
    pageType_Id: '',
    url: ''
  }

  const [currentPage, setCurrentPage] = useState<ExpressPlacement>(emptyPage);

  const getSettingsByType = (settings: LayoutSettings, view: LayoutDevices): PopupSettings | ActionSettings => {
    return settings[view].type === LayoutClass.action ? settings[view].action : settings[view].popup;
  }

  const fetchPages = async () => {
    await fetchPageTypes({ method: 'get', url: `pagetype` });
  }

  // Init request for get pageTypes and company placement pages
  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line
  }, []);

  // Set all page types
  useEffect(() => {
    if (responsePageTypes?.status) {
      setPages(responsePageTypes.result);
    }
    // eslint-disable-next-line
  }, [responsePageTypes]);

  const openConfirmRemovePlacementByIndex = (id: string) => {
    setEditPageOpen(false);
    Modal.confirm({
      title: 'Do you want to remove this placement?',
      content: 'You can add it again by the “Add placement” button',
      okText: 'Remove',
      icon: <></>,
      maskClosable: true,
      width: 420,
      closable: true,
      className: 'express-modal-confirm',
      closeIcon: <span className="icon icon-remove"></span>,
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'button-default'
      },
      okButtonProps: {
        className: 'button-danger'
      },
      onOk: () => removePlacementById(id),
      onCancel: () => setCurrentPage(emptyPage),
    })
  }

  const removePlacementById = async (id: string) => {
    if (company && campaign) {

      const { response } = await myAxios({
        method: 'DELETE', url: `/company/pageplacementswcamp/${id}/${company.id}/${campaign.id}`
      });

      if (response?.data?.status) {
        refreshState();
        message.success({
          content: "Placements are updated",
          className: 'express-message',
          duration: 1,
        });
      } else {
        setAddPagesOpen(true);
        message.error("Failed to update placements", 1);
      }
    }
  }

  const isCurrentPageDisabled = (page: ExpressPlacement): boolean => {
    return !page.name || !page.url || !page.pageType_Id || !isUrl(page.url)
  }

  const isUrl = (str: string) => {
    var pattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!]))?/;
    return pattern.test(str);
  }

  const updatePlacementsCompanyCampaign = async (companyId: string, campaignId: string) => {
    const { response } = await myAxios({
      method: 'POST', url: `/company/pageplacementswcamp/${companyId}/${campaignId}`, data: { ...currentPage, templateSettings: expressSettings }
    });

    if (response?.data?.status) {
      refreshState();
      setAddPagesOpen(false);
      setCurrentPage(emptyPage);

      message.success({
        content: "Placements are updated",
        className: 'express-message',
        duration: 1,
      });
    } else {
      message.error(response?.data?.result[0]?.message || "Failed to update placements", 1);
    }
  }

  // Generate items for dropdown of page Types
  const pageTypes: BaseOptionType[] = useMemo(() => {
    if (pagesList.length > 0) {
      const list: BaseOptionType[] = [];
      pagesList.forEach((page: ExpressPlacement) => {
        page.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation && list.push({ label: page.name, value: page.id });
      })
      return list;
    }
    return [{ label: 'Company do not have page type', key: 'no pages', disabled: true }]
  }, [pagesList]);


  const handlePreview = (url: string) => {
    window.open(`${url}?bsw_preview=1`, '_blank');
  }

  const openConfirmModal = (url: string) => {
    Modal.confirm({
      title: 'This is not a preview of your order confirmation page but it’s a demonstration of how deals are presented on the order confirmation page in general.',
      content: 'On your actual page, we will use the deals you have selected and also the design you have selected.',
      okText: 'Continue',
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
      cancelText: 'Cancel',
      cancelButtonProps: {
        className: 'button-default'
      },
      okButtonProps: {
        className: 'button-express-success'
      },
      onOk: () => handlePreview(url)
    })
  }


  // Open pages modal
  const onAddPages = () => {
    setAddPagesOpen(true);
  }

  const handleOpenEdit = (placement: ExpressPlacementSettings, company: ExpressCompany) => {
    const _placement = getPagePlacementFromCompany(placement.id, company);
    setCurrentPage(_placement);
    setEditPageOpen(true);
  }

  // Create company placement pages and refetch them
  const handleCreatePage = async () => {
    if (company && campaign) {
      updatePlacementsCompanyCampaign(company.id, campaign.id);
    }
  }

  // Edit company placement pages and refetch them
  const handleEditPage = async () => {
    if (company) {
      const { response } = await myAxios({
        method: 'PUT', url: `/company/pageplacements/${company.id}`, data: currentPage
      });

      if (response?.data?.status) {
        refreshState();
        setEditPageOpen(false);
        setCurrentPage(emptyPage);

        message.success({
          content: "Placements are updated",
          className: 'express-message',
          duration: 1,
        });
      } else {
        message.error(response?.data?.result?.length > 0 ? response?.data?.result[0]?.message : "Failed to update placements", 1);
      }
    }
  }

  // useEffect(() => {
  //   if (currentPlacement && campaign) {
  //     const newPlacements: ExpressPlacementSettings[] = campaign.placementSettings.map((p: ExpressPlacementSettings) => {
  //       return p.id === currentPlacement.id ? currentPlacement : p;
  //     });

  //     setCampaign({ ...campaign, placementSettings: newPlacements });
  //   }
  //   // eslint-disable-next-line
  // }, [currentPlacement]);

  const getPagePlacementFromCompany = (id: string, company: ExpressCompany): ExpressPlacement => {
    return company.pagePlacements.find(f => f.id === id) || company.pagePlacements[0];
  }

  // const handleBackToPlacements = () => {
  //   setCurrentPlacement(undefined);
  //   setExpressPage(ExpressPage.campaign);
  // }

  useEffect(() => {
    document.title = "BrandSwap Express - Campaign";
    setExpressPage(ExpressPage.campaign);
    // eslint-disable-next-line
  }, []);

  return <>
    <Alert
      description="Identify the pages in your website in which you want to promote Deals and then configure how the Deals are presented. 
      Deal Pages are the pages in your website where Deals are promoted to your customers. Deals have to appear on the your Order Confirmation page but you can also promote them in earlier pages (e.g. the basket or product pages) in order to encourage conversion.
      "
      type="info"
      className="content-medium"
      showIcon
    />

    {/* {currentPlacement ?
      <>
        <div className="express-placements">
          <div className="express-placements-info">
            <span className="font-active font-hover" onClick={handleBackToPlacements}><LeftOutlined /> Back to placements</span>
          </div>
        </div>
        <ExpressLayoutSettings currentPlacement={currentPlacement} isSingle={false} setCurrentPlacement={setCurrentPlacement} />
      </>
      :
      <>    </>
    }
    */}
    <div className="express-content">
      <div className="express-placements">
        <div id="guide-placements-info" className="express-placements-info">
          {/* You can add new placements and edit them */}
        </div>
        <div className="express-placements-add">
          <span id="guide-add-new-placements" className="font-active font-hover" onClick={onAddPages}> Add new Deal Page</span> <InfoCircleOutlined className="font-active" id="guide-placement-addnew" />
        </div>
      </div>
      <Collapse accordion>

        {campaign?.placementSettings.map((_placement: ExpressPlacementSettings, index: number) => {
          return company?.pagePlacements[index].identifier === PLACEMENT_IDENTIFIER.orderconfirmation && <CollapsePanel
            showArrow={false}
            className="express-placement-accordion"
            header={
              <>
                <div className="item-accordion">
                  <div className="item-info">
                    <div className="item-title">
                      {_placement.name}
                    </div>
                    <div className={`item-description ${company?.pagePlacements[index]?.url ? 'item-valid' : 'item-invalid'}`}>
                      {company?.pagePlacements[index]?.url ? company?.pagePlacements[index]?.url : 'Please provide a valid url'}
                    </div>
                  </div>
                  <div className="item-acions">
                    <div className="item-toggle" onClick={(e) => e.stopPropagation()}>
                      Required
                    </div>
                    <div className="item-button" onClick={(e) => { e.stopPropagation(); company && handleOpenEdit(_placement, company) }}>
                      Edit URL
                    </div>
                    <div className="item-button" >
                      View layout
                      <DownOutlined className="item-expand item-plus mleft10" />
                      <UpOutlined className="item-expand item-minus mleft10" />
                    </div>
                    <>
                      {company?.pagePlacements[index]?.url &&
                        <div className="item-button" onClick={(e) => { e.stopPropagation(); openConfirmModal('https://brandswap.myshopify.com/pages/order-confirmation-page') }}>
                          Preview
                        </div>
                      }
                    </>
                  </div>
                </div>
              </>
            }
            key={`orderplacement${company?.pagePlacements[index].identifier}${index}`}>
            <>
              <hr className="widget-devider" />
              <div className="item-body">

                <div className="item-title">
                  Appearance
                </div>

                <div className="item-body-description">
                  <span>
                    You can customise your placement to match the look and feel of your store
                  </span>
                  <Button className="express-button-success" type="default" onClick={(e) => { e.stopPropagation(); setCurrentPlacement({ ..._placement, page: company?.pagePlacements[index]?.url }) }} >Customise placement</Button>
                </div>

                <div className="item-body-description">
                  <span>
                    {/* Customers see the placement before a purchase */}
                  </span>
                  <div className="item-body-device">
                    <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.desktop ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.desktop)}>
                      <svg className="desktop" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5693 1H3.12229C2.33203 1 1.69141 1.59695 1.69141 2.33333V9C1.69141 9.73638 2.33203 10.3333 3.12229 10.3333H14.5693C15.3596 10.3333 16.0002 9.73638 16.0002 9V2.33333C16.0002 1.59695 15.3596 1 14.5693 1Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.98242 13H11.7059" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.8457 10.3335V13.0002" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.mobile ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.mobile)}>
                      <svg className="mobile" width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.74358 1.43604H2.4615C1.7676 1.43604 1.20508 1.99855 1.20508 2.69245V12.7438C1.20508 13.4377 1.7676 14.0002 2.4615 14.0002H8.74358C9.43748 14.0002 10 13.4377 10 12.7438V2.69245C10 1.99855 9.43748 1.43604 8.74358 1.43604Z" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.60156 11.4868H5.60806" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="item-preview">
                  {/* <img src={preivewImage} alt="Preview" /> */}
                  <div className={`express-template-layout  ${layoutView === 'desktop' ? 'desktop' : 'mobile'}`} >
                    <div className="template-layout">
                      <ExpressPrePreview confirmation={company?.pagePlacements[index].identifier === PLACEMENT_IDENTIFIER.orderconfirmation} title={_placement.name} />
                    </div>

                    <ExpressTemplatePopup buttonShape={_placement.templateSettings[layoutView].button.shape} settings={getSettingsByType(_placement.templateSettings, layoutView)} offers={campaign?.offers.length > 0 ? campaign?.offers : [offer]} displayOnly={_placement.displayOnly} description={_placement.promptMessage || ''} />


                  </div>
                </div>
              </div>
            </>
          </CollapsePanel>
        })
        }
        {campaign?.placementSettings.map((_placement: ExpressPlacementSettings, index: number) => {
          return company?.pagePlacements[index].identifier !== PLACEMENT_IDENTIFIER.orderconfirmation && <CollapsePanel
            showArrow={false}
            className="express-placement-accordion"
            header={
              <>
                <div className="item-accordion">
                  <div className="item-info">
                    <div className="item-title">
                      {_placement.name}
                    </div>
                    <div className={`item-description ${company?.pagePlacements[index]?.url ? 'item-valid' : 'item-invalid'}`}>
                      {company?.pagePlacements[index]?.url ? company?.pagePlacements[index]?.url : 'Please provide a valid url'}
                    </div>
                  </div>
                  <div className="item-acions">
                    <div className="item-toggle" onClick={(e) => e.stopPropagation()}>
                      <label>
                        <span className="mright10 font-hover">{_placement.isEnabled === false ? 'Disabled' : 'Enabled'}</span>
                        <Switch onChange={(value: boolean) => handleChangeIsEnable(value, _placement.id)} defaultChecked={_placement.isEnabled} />
                      </label>
                    </div>
                    <div className="item-button" onClick={(e) => { e.stopPropagation(); company && handleOpenEdit(_placement, company) }}>
                      Edit URL
                    </div>
                    <div className="item-button" >
                      View layout
                      <DownOutlined className="item-expand item-plus mleft10" />
                      <UpOutlined className="item-expand item-minus mleft10" />
                    </div>
                    {company?.pagePlacements[index]?.url &&
                      <div className="item-button" onClick={(e) => { e.stopPropagation(); handlePreview(company?.pagePlacements[index]?.url) }}>
                        Preview
                      </div>
                    }
                  </div>
                </div>
              </>
            }
            key={`${company?.pagePlacements[index].identifier}${index}`}>
            <>
              <hr className="widget-devider" />
              <div className="item-body">

                <div className="item-title">
                  Appearance
                </div>

                <div className="item-body-description">
                  <span>
                    You can customise your placement to match the look and feel of your store
                  </span>
                  <Button className="express-button-success" type="default" onClick={(e) => { e.stopPropagation(); setCurrentPlacement({ ..._placement, page: company?.pagePlacements[index]?.url }) }} >Customise placement</Button>
                </div>

                <div className="item-body-description">
                  <span>
                    {/* Customers see the placement before a purchase */}
                  </span>
                  <div className="item-body-device">
                    <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.desktop ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.desktop)}>
                      <svg className="desktop" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5693 1H3.12229C2.33203 1 1.69141 1.59695 1.69141 2.33333V9C1.69141 9.73638 2.33203 10.3333 3.12229 10.3333H14.5693C15.3596 10.3333 16.0002 9.73638 16.0002 9V2.33333C16.0002 1.59695 15.3596 1 14.5693 1Z" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.98242 13H11.7059" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M8.8457 10.3335V13.0002" stroke="#ffffff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <div className={`item-body-device-wrapper ${layoutView === LayoutDevices.mobile ? 'active' : ''}`} onClick={() => setLayoutView(LayoutDevices.mobile)}>
                      <svg className="mobile" width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.74358 1.43604H2.4615C1.7676 1.43604 1.20508 1.99855 1.20508 2.69245V12.7438C1.20508 13.4377 1.7676 14.0002 2.4615 14.0002H8.74358C9.43748 14.0002 10 13.4377 10 12.7438V2.69245C10 1.99855 9.43748 1.43604 8.74358 1.43604Z" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.60156 11.4868H5.60806" stroke="#B2B7C3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="item-preview">
                  {/* <img src={preivewImage} alt="Preview" /> */}
                  <div className={`express-template-layout  ${layoutView === 'desktop' ? 'desktop' : 'mobile'}`} >
                    <div className="template-layout">
                      <ExpressPrePreview confirmation={company?.pagePlacements[index].identifier === PLACEMENT_IDENTIFIER.orderconfirmation} title={_placement.name} />
                    </div>

                    <ExpressTemplatePopup buttonShape={_placement.templateSettings[layoutView].button.shape} settings={getSettingsByType(_placement.templateSettings, layoutView)} offers={campaign?.offers.length > 0 ? campaign?.offers : [offer]} displayOnly={_placement.displayOnly} description={_placement.promptMessage || ''} />


                  </div>
                </div>
              </div>
            </>
          </CollapsePanel>
        })
        }
      </Collapse>
    </div>

    {/* Layout settings of placement page */}
    <Modal
      className="modal-full-width express"
      title={<></>}
      footer={<></>}
      maskClosable={true}
      open={currentPlacement ? true : false}
      closable={true}
      width={'100vw'}
      afterClose={() => {
        setCurrentPlacement(undefined);
      }}
      onCancel={() => setCurrentPlacement(undefined)}
      closeIcon={<span className="icon icon-remove"></span>}
    >

      {currentPlacement &&
        <ExpressLayoutSettings currentPlacement={currentPlacement} isSingle={false} setCurrentPlacement={setCurrentPlacement} />
      }

    </Modal>

    {/* Create placement page */}
    <Modal
      className="modal-default modal-express"
      title={<></>}
      footer={<></>}
      maskClosable={true}
      open={isAddPagesOpen}
      width={400}
      closable={true}
      afterClose={() => {
        setCurrentPage(emptyPage);
      }}
      onCancel={() => setAddPagesOpen(false)}
      closeIcon={<span className="icon icon-remove"></span>}
    >
      <form onSubmit={e => e.preventDefault()}>
        <div className="modal-title medium">
          Add placement
        </div>
        <div className="modal-content-space">

          <div className="express-row">

            <div className="express-label">
              Page name <InfoCircleOutlined id="guide-placement-pagename" />
            </div>
            <div className="express-input">
              <Input
                value={currentPage.name || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, name: e?.target.value }))}
                autoComplete="off"
                placeholder=""
              />

              <p
                id="eide"
                className={`error-line ${currentPage.name && currentPage.name.length < 2
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.nameValid}
              </p>
            </div>
          </div>

          <div className="express-row">

            <div className="express-label">
              Page type <InfoCircleOutlined id="guide-placement-pagetype" />
            </div>
            <div className="express-input">
              <Select
                placeholder="Select page"
                value={currentPage.pageType_Id || undefined}
                optionFilterProp="children"
                suffixIcon={<span className="icon icon-arrow-down"></span>}
                options={pageTypes}
                onChange={(value: string) => {
                  setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, pageType_Id: value }))
                }}
              />
            </div>
          </div>

          <div className="express-row">

            <div className="express-label">
              Page URL <InfoCircleOutlined id="guide-placement-pageurl" />
            </div>
            <div className="express-input">
              <Input
                value={currentPage.url || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage(prevState => ({ ...prevState, url: e?.target.value }))}
                autoComplete="off"
                placeholder="https://"
              />

              <p
                id="eide"
                className={`error-line ${currentPage.url && !isUrl(currentPage.url)
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                the URL should start with https://
              </p>
            </div>
          </div>
        </div>

        <div className="modal-buttons flex-center express-button">
          <Button type="primary" className="button-default" onClick={() => setAddPagesOpen(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isCurrentPageDisabled(currentPage)} className="express-button-success mleft10" onClick={handleCreatePage}>Save</Button>
        </div>
      </form>
    </Modal>

    {/* Edit placement page */}
    <Modal
      className="modal-default modal-express"
      title={<></>}
      footer={<></>}
      maskClosable={true}
      open={isEditPageOpen}
      width={400}
      closable={true}
      afterClose={() => setCurrentPage(emptyPage)}
      onCancel={() => setEditPageOpen(false)}
      closeIcon={<span className="icon icon-remove"></span>}
    >
      <div className="modal-title medium">
        Edit placement
      </div>
      <form onSubmit={e => e.preventDefault()}>
        <div className="modal-content-space">

          <div className="express-row">

            <div className="express-label">
              Page name <InfoCircleOutlined id="guide-placement-pagename" />
            </div>
            <div className="express-input">
              <Input
                value={currentPage.name || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, name: e?.target.value }))}
                autoComplete="off"
                placeholder=""
              />

              <p
                id="eide"
                className={`error-line ${currentPage.name && currentPage.name.length < 2
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                {VALIDATION.nameValid}
              </p>
            </div>
          </div>

          {currentPage.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation &&
            <div className="express-row">

              <div className="express-label">
                Page type <InfoCircleOutlined id="guide-placement-pagetype" />
              </div>
              <div className="express-input">
                <Select
                  placeholder="Select page"
                  optionFilterProp="children"
                  suffixIcon={<span className="icon icon-arrow-down"></span>}
                  options={pageTypes}
                  value={currentPage.name}
                  onChange={(value: string) => {
                    setCurrentPage((prevState: ExpressPlacement) => ({ ...prevState, pageType_Id: value }))
                  }}
                />
              </div>
            </div>
          }

          <div className="express-row">

            <div className="express-label">
              Page URL <InfoCircleOutlined id="guide-placement-pageurl" />
            </div>
            <div className="express-input">
              <Input
                value={currentPage.url || ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentPage(prevState => ({ ...prevState, url: e?.target.value }))}
                autoComplete="off"
                placeholder="https://"
              />

              <p
                id="eide"
                className={`error-line ${currentPage.url && !isUrl(currentPage.url)
                  ? "error-show"
                  : "error-hide"
                  }`}
              >
                the URL should start with https://
              </p>
            </div>
          </div>

        </div>

        <div className="modal-buttons flex-center express-button">
          <Button type="primary" className="button-default" onClick={() => setEditPageOpen(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isCurrentPageDisabled(currentPage)} className="express-button-success mleft10" onClick={handleEditPage}>Save</Button>
        </div>

        {currentPage.identifier !== PLACEMENT_IDENTIFIER.orderconfirmation &&
          <div className="flex-center mtop50">
            <span onClick={() => openConfirmRemovePlacementByIndex(currentPage.id)} className="font-hover font-error font-xsmall">  <DeleteOutlined /> Delete placement</span>
          </div>
        }
      </form>
    </Modal>

  </>;
}

export default ExpressCampaignLayout;
