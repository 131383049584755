import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth.hook"

const useLogout = () => {
    const { setAuth, setCompany } = useAuth();
    const navigate = useNavigate();

    const logout = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('company');
        setAuth(undefined);
        setCompany(undefined);
        document.title = "BrandSwap Admin";
        navigate("/login");
    }

    return logout
}

export default useLogout;