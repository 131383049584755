import { message } from "antd";
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig, Method } from "axios";
import { useEffect, useState } from "react";
import { ApiResponse } from "../types/response.interfaces";
import { UserAuth } from "../types/user.interfaces";
import { postUserRefreshtoken } from "../services/users/user.service";
import useAuth from "./useAuth.hook";
import useLogout from "./useLogout.hook";

export interface AxiosPrivateConfig {
    method: Method,
    url: string,
    data?: any,
    enabled?: boolean
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

const useAxios = () => {
    const [response, setResponse] = useState<ApiResponse | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [controller, setController] = useState<AbortController>();
    const { auth, setAuth } = useAuth();

    const logout = useLogout();

    const refreshToken = async (_auth: UserAuth) => {
        if (_auth) {
            const response = await postUserRefreshtoken({ token: _auth.tokens.token, refreshToken: _auth.tokens.refreshToken });
            if (response?.data.status) {
                const _newAuth: UserAuth = { ..._auth, tokens: response.data.result.tokens };
                localStorage.setItem("user", JSON.stringify(_newAuth));
                setAuth(_newAuth);
            } else {
                // using logout method when no token is available
                logout();
                return false;
            }
            return response?.data?.result?.tokens
        }
        return false
    }

    useEffect(() => {
        // Handling request interceptor
        const requestInterceptor = axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
            const user = localStorage.getItem("user");
            if (user) {
                const userAuth: UserAuth = JSON.parse(user);
                if (userAuth?.tokens) {
                    config.headers.setAuthorization(`bearer ${userAuth.tokens.token}`);
                    config.headers.setContentType("application/json");
                }
            }
            return config;
        }, (error: any) => Promise.reject(error))

        // Handling response interceptor ( adding refresh token if 403 happens )
        const responseInterceptor =
            axiosInstance.interceptors.response.use(
                (config: AxiosResponse) => {
                    return config;
                },
                async (error: any) => {
                    if (error?.response) {
                        const prevRequest = error?.config;
                        if (error.response?.status === 403 && !prevRequest.sent && auth) {
                            prevRequest.sent = true;
                            prevRequest.headers = {
                                ...prevRequest.headers,
                                "Content-Type": "application/json",
                            };
                            await refreshToken(auth);
                            return axiosInstance(prevRequest)
                        }

                        if (error.response?.code === "ERR_NETWORK") {
                            message.error('Network unavailable', 1);
                            logout();
                        }

                        if (error.response?.status === 401) {
                            message.error('Unauthorized action', 1);
                            // using logout method when no unauthorize is happening
                            logout();
                        }
                        return error.response;
                    }
                    return Promise.reject(error);
                }
            );
        return () => {
            axiosInstance.interceptors.request.eject(requestInterceptor);
            axiosInstance.interceptors.response.eject(responseInterceptor);
        }
        // eslint-disable-next-line
    }, [axiosInstance, auth])

    const axiosFetch = async ({ method, url, data }: AxiosPrivateConfig) => {
        if (axiosInstance) {
            try {
                const ctrl = new AbortController();
                setController(ctrl)
                setLoading(true);
                const res: AxiosResponse<ApiResponse, any> = await axiosInstance.request({
                    url,
                    method,
                    data,
                    signal: ctrl.signal,
                })
                setResponse(res.data);
            } catch (error: any) {
                error?.code !== "ERR_CANCELED" && console.log(error);
                error?.code === "ERR_NETWORK" && logout();
            } 
            setLoading(false);
        }
    }

    const myAxios = async ({ method, url, data }: AxiosPrivateConfig) => {
        let response, error, loading = true;
        if (axiosInstance) {
            try {
                setLoading(true);
                const res: AxiosResponse<any, any> = await axiosInstance.request({
                    url,
                    method,
                    data,
                })
                response = res;
            } catch (er: any) {
                console.log(er);
                error = er;
            } 
            loading = false;
            setLoading(false);
        }
        return { response, error, loading }
    }

    useEffect(() => {

        return () => {
            controller?.abort();
        }
    }, [controller])

    return { response, loading, axiosFetch, myAxios }
}

export default useAxios;