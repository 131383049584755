import { Alert, Tabs } from "antd";
import {  useState } from "react";
import useExpress from "../../../hooks/useExpress.hook";
import { EXPRESS_ONBOARDING } from "../types/express.enum";
import ExpressDetails from "./expressDetails.component";
import ExpressOnboardingPartners from "./expressPartners/expressPartners.component";
import ExpressPlacements from "./expressPlacements.component";
import successIcon from "../../../assets/images/icons/onboarding-success-icon.svg";


const ExpressOnboarding = () => {
    const { onboardingStatus, company } = useExpress();
    const [currentStep, setCurrentStep] = useState<EXPRESS_ONBOARDING>(onboardingStatus?.next ? onboardingStatus?.next : EXPRESS_ONBOARDING.details);

    const checkCompletedTab = (tab: EXPRESS_ONBOARDING, step: EXPRESS_ONBOARDING): boolean => {
        var isCompleted: boolean = false;
        switch (tab) {
            case EXPRESS_ONBOARDING.details:
                isCompleted = step !== EXPRESS_ONBOARDING.details;
                break;
            case EXPRESS_ONBOARDING.partners:
                isCompleted = (step !== EXPRESS_ONBOARDING.details && step !== EXPRESS_ONBOARDING.partners);
                break;
            case EXPRESS_ONBOARDING.placements:
                isCompleted = step === EXPRESS_ONBOARDING.layout;
                break;

            default:
                break;
        }

        return isCompleted;
    }

    return <>
        <div className="express-onboarding">

            <div className="express-onboarding-info">
                {currentStep === EXPRESS_ONBOARDING.details && <>
                    You are almost ready
                </>}
                {currentStep === EXPRESS_ONBOARDING.partners && <>

                </>}
                {currentStep === EXPRESS_ONBOARDING.placements && <>
                    Third step
                </>}
                {currentStep === EXPRESS_ONBOARDING.layout && <>
                    Last step
                </>}
            </div>
            <div className="express-onboarding-description">
                {currentStep === EXPRESS_ONBOARDING.details && <>
                    <Alert
                        description=" Please fill the details below to complete your registration"
                        type="info"
                        showIcon
                    />

                </>}
                {currentStep === EXPRESS_ONBOARDING.partners && <>
                    <Alert
                        description="Please select which partners you want to work with."
                        type="info"
                        showIcon
                    />
                </>}
                {currentStep === EXPRESS_ONBOARDING.placements && <>
                    {/* <Alert
                        description="Please select on which pages you want the deals to appear. Your customers will only be able to claim the deals on your order confirmation page but we strongly recommend also showing deals on your shopping basket to increase your own conversion. All you need to do is to enter the URLs of your order confirmation page and any other pages you want the deals to appear on and we take care of the rest. You will be able to change the way it will look in the next step."
                        type="info"
                        showIcon
                    /> */}
                    <div data-show="true" className="ant-alert ant-alert-info ant-alert-with-description content-medium" role="alert">
                        <span role="img" aria-label="info-circle" className="anticon anticon-info-circle ant-alert-icon">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="info-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                            </svg>
                        </span>
                        <div className="ant-alert-content">
                            <div className="ant-alert-description">
                                Identify the pages in your website in which you want to promote Deals and then configure how the Deals are presented.  Deal Pages are the pages in your website where Deals are promoted to your customers. Deals have to appear on the your Order Confirmation page but you can also promote them in earlier pages (e.g. the basket or product pages) in order to encourage conversion.
                                {company?.platform === "shopify" &&
                                    <>
                                        <br /> <br />
                                        <b>
                                            We notice that your website is built with Shopify. To make the setup even easier for you, we have added two deal pages. Your shopping basket/cart and your order confirmation page. So all you have to do now is click to finalise the setup and you're almost ready to go.
                                        </b>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </>}
                {currentStep === EXPRESS_ONBOARDING.layout && <>
                    <Alert
                        description="Now it’s time to decide how your want the partner deals to look on your site. You will be able to see a preview on the next step and nothing will be live on your site until you start the campaign."
                        type="info"
                        showIcon
                    />
                </>}
            </div>

            <div className="express-onboarding-stesp">
                <Tabs
                    className={`express-tabs express-tab-${currentStep}`}
                    centered
                    moreIcon={false}
                    animated={false}
                    activeKey={currentStep}
                    items={[
                        {
                            label: <span className={`${checkCompletedTab(EXPRESS_ONBOARDING.details, currentStep) ? 'express-completed-tab' : ''}`}>
                                {checkCompletedTab(EXPRESS_ONBOARDING.details, currentStep) &&
                                    <img
                                        className="mright10"
                                        src={successIcon}
                                        alt="success"
                                    />
                                }
                                1. Your Details</span>,
                            key: EXPRESS_ONBOARDING.details,
                            children: <ExpressDetails setCurrentStep={setCurrentStep} />
                        },
                        {
                            label: <span className={`${checkCompletedTab(EXPRESS_ONBOARDING.partners, currentStep) ? 'express-completed-tab' : ''}`}>
                                {checkCompletedTab(EXPRESS_ONBOARDING.partners, currentStep) &&
                                    <img
                                        className="mright10"
                                        src={successIcon}
                                        alt="success"
                                    />
                                }2. Deals</span>,
                            key: EXPRESS_ONBOARDING.partners,
                            children: <ExpressOnboardingPartners setCurrentStep={setCurrentStep} />
                        },
                        {
                            label: <span className={`${checkCompletedTab(EXPRESS_ONBOARDING.placements, currentStep) ? 'express-completed-tab' : ''}`}>
                                {checkCompletedTab(EXPRESS_ONBOARDING.placements, currentStep) &&
                                    <img
                                        className="mright10"
                                        src={successIcon}
                                        alt="success"
                                    />
                                }3. Placements</span>,
                            key: EXPRESS_ONBOARDING.placements,
                            children: <ExpressPlacements setCurrentStep={setCurrentStep} />
                        },
                        // {
                        //     label: <span className={`${checkCompletedTab(EXPRESS_ONBOARDING.layout, currentStep) ? 'express-completed-tab' : ''}`}>
                        //         {checkCompletedTab(EXPRESS_ONBOARDING.layout, currentStep) &&
                        //             <img
                        //                 className="mright10"
                        //                 src={successIcon}
                        //                 alt="success"
                        //             />
                        //         }4. Layout</span>,
                        //     key: EXPRESS_ONBOARDING.layout,
                        //     children: <ExpressLayout setCurrentStep={setCurrentStep} />
                        // }
                    ]}>
                </Tabs>
            </div>
        </div>
    </>;
}

export default ExpressOnboarding;
