import { Dropdown, Slider, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { LayoutSettings } from "../../../../../../types/campaign.interfaces";
import { ButtonSettings, ButtonShapes, ButtonStyleSettingsT, LocationPosition, TextTransform } from "../../../../../templates-previews/types/params.interfaces";
import { getNumberFromFontWeight, itemsFontFamily, itemsFontSize, itemsFontWeight } from "../../../../const/layout-options.enum";
import { LayoutClass, LayoutDevices } from "../../../../const/views";

interface Props {
    updateSettings: (value: LayoutSettings) => void,
    layoutDevice: LayoutDevices,
    settings: LayoutSettings,
    layoutClass: LayoutClass,
}

const ButtonTextStyleSettings = ({ updateSettings, layoutDevice, layoutClass, settings }: Props) => {

    const uniqueKey = new Date().getTime();

    const setNewSettings = (key: keyof ButtonSettings, value: string | LocationPosition | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                [layoutClass]: {
                    ...settings[layoutDevice][layoutClass],
                    [key]: value
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewSettings('text', value);
    }

    const setNewStyleSettings = (key: keyof ButtonStyleSettingsT, value: string | LocationPosition | number) => {
        const newSettings: LayoutSettings = {
            ...settings, [layoutDevice]: {
                ...settings[layoutDevice],
                button: {
                    ...settings[layoutDevice].button,
                    style: {
                        ...settings[layoutDevice].button?.style,
                        [key]: value
                    }
                }
            }
        }

        updateSettings(newSettings);
    }

    const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewStyleSettings('backgroundColor', color);
    }

    const handleBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewStyleSettings('borderColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewStyleSettings('textColor', color);
    }

    const handleChangeFontFamily = (props: DropdownMenuProps) => {
        setNewStyleSettings('fontFamily', props.key);
    }

    const handleChangeFontSize = (props: DropdownMenuProps) => {
        setNewStyleSettings('fontSize', props.key);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewStyleSettings('fontWeight', props.key);
    }

    const handleClickTextTransform = (textTransform: TextTransform) => {
        setNewStyleSettings('textTransform', textTransform);
    }

    const handleChangeTopLeftRounding = (value: number) => {
        setNewStyleSettings('borderTopLeftRadius', value);
    }

    const handleChangeTopRightRounding = (value: number) => {
        setNewStyleSettings('borderTopRightRadius', value);
    }

    const handleChangeBottomLeftRounding = (value: number) => {
        setNewStyleSettings('borderBottomLeftRadius', value);
    }

    const handleChangeBottomRightRounding = (value: number) => {
        setNewStyleSettings('borderBottomRightRadius', value);
    }

    return <>
        <div className="option-box">

            <div className="option-content">
                <div className="option-single-col">
                    <input
                        type="text"
                        className="default-input"
                        placeholder="Offers"
                        value={settings[layoutDevice]?.button?.text || ''}
                        onChange={handleChangeText}
                    />
                </div>
            </div>

            <div className="option-content">
                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonBgColor${uniqueKey}`}
                            value={settings[layoutDevice]?.button?.style?.backgroundColor || ''}
                            onChange={handleBgChange}
                        />
                        <label htmlFor={`buttonBgColor${uniqueKey}`}>
                            Background: {settings[layoutDevice]?.button?.style?.backgroundColor}
                        </label>
                    </div>
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonBorder${uniqueKey}`}
                            value={settings[layoutDevice]?.button?.style?.borderColor || settings[layoutDevice]?.button?.style?.backgroundColor}
                            onChange={handleBorderChange}
                        />
                        <label htmlFor={`buttonBorder${uniqueKey}`}>
                            Border: {settings[layoutDevice]?.button?.style?.borderColor || settings[layoutDevice]?.button?.style?.backgroundColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <input
                            type="color"
                            className="color-box"
                            id={`buttonText${uniqueKey}`}
                            value={settings[layoutDevice]?.button?.style?.textColor || ''}
                            onChange={handleTextChange}
                        />
                        <label htmlFor={`buttonText${uniqueKey}`}>
                            Text: {settings[layoutDevice]?.button?.style?.textColor}
                        </label>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontFamily,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice].button.style.fontFamily],
                            onClick: handleChangeFontFamily
                        }} trigger={['click']}>
                            <Typography.Link>
                                {settings[layoutDevice]?.button?.style.fontFamily}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontSize,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.button.style.fontSize],
                            onClick: handleChangeFontSize
                        }} trigger={['click']}>
                            <Typography.Link>
                                {settings[layoutDevice]?.button?.style?.fontSize}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>

                <div className="option-two-col">
                    <div className="option-col">
                        <Dropdown menu={{
                            items: itemsFontWeight,
                            selectable: true,
                            defaultSelectedKeys: [settings[layoutDevice]?.button?.style?.fontWeight.toString()],
                            onClick: handleChangeFontWeight
                        }} trigger={['click']}>
                            <Typography.Link>
                                {getNumberFromFontWeight(settings[layoutDevice]?.button?.style?.fontWeight.toString())}
                            </Typography.Link>
                        </Dropdown>
                    </div>
                    <div className="option-col">
                        <span onClick={() => handleClickTextTransform(TextTransform.uppercase)} className={`text-select ${settings[layoutDevice]?.button?.style?.textTransform === TextTransform.uppercase ? 'active' : ''}`}>AB</span>
                        <span onClick={() => handleClickTextTransform(TextTransform.capitalize)} className={`text-select ${settings[layoutDevice]?.button?.style?.textTransform === TextTransform.capitalize ? 'active' : ''}`}>Ab</span>
                    </div>
                </div>
            </div>
        </div >

        {settings[layoutDevice]?.button?.shape !== ButtonShapes.circle && <>

            <div className="option-box">
                <Dropdown trigger={['click']} dropdownRender={
                    () =>
                        <div className="dropdown min150">
                            <div className="flex-space-between">

                                <div className="grow mright20">
                                    <div className="font-xsmall font-color">Top left: {settings[layoutDevice]?.button?.style.borderTopLeftRadius}</div>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={handleChangeTopLeftRounding}
                                        value={typeof settings[layoutDevice]?.button?.style.borderTopLeftRadius === 'number' ? settings[layoutDevice]?.button?.style.borderTopLeftRadius : 0}
                                    />
                                </div>

                                <div className="grow">
                                    <div className="font-xsmall font-color">Top right: {settings[layoutDevice]?.button?.style.borderTopRightRadius}</div>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={handleChangeTopRightRounding}
                                        value={typeof settings[layoutDevice]?.button?.style.borderTopRightRadius === 'number' ? settings[layoutDevice]?.button?.style.borderTopRightRadius : 0}
                                    />
                                </div>

                            </div>

                            <div className="flex-space-between">
                                <div className="grow mright20">
                                    <div className="font-xsmall font-color">Bottom left: {settings[layoutDevice]?.button?.style.borderBottomLeftRadius}</div>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={handleChangeBottomLeftRounding}
                                        value={typeof settings[layoutDevice]?.button?.style.borderBottomLeftRadius === 'number' ? settings[layoutDevice]?.button?.style.borderBottomLeftRadius : 0}
                                    />
                                </div>

                                <div className="grow">
                                    <div className="font-xsmall font-color">Bottom right: {settings[layoutDevice]?.button?.style.borderBottomRightRadius}</div>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={handleChangeBottomRightRounding}
                                        value={typeof settings[layoutDevice]?.button?.style.borderBottomRightRadius === 'number' ? settings[layoutDevice]?.button?.style.borderBottomRightRadius : 0}
                                    />
                                </div>
                            </div>
                        </div>
                }>
                    <Typography.Link>
                        <div className="option-label">
                            Set rounding
                        </div>
                    </Typography.Link>
                </Dropdown>
            </div>
        </>}
    </>
}

export default ButtonTextStyleSettings
