import { Slider } from "antd";
import { LocationPosition, LocationSettings } from "../../../../../templates-previews/types/params.interfaces";
import { LayoutDevices } from "../../../../const/views";

interface Props {
    updateLocationSettings: (value: LocationSettings) => void,
    layoutDevice: LayoutDevices,
    locationSettings: LocationSettings | undefined,
}

const LocationSettingsComponent = ({ updateLocationSettings, layoutDevice, locationSettings }: Props) => {

    const setNewSettings = (key: keyof LocationSettings, value: string | LocationPosition | number, reset: boolean) => {
        if (locationSettings) {
            const location: LocationSettings = {
                ...locationSettings,
                [key]: value,
            }

            if (reset) {
                location.x = 0;
                location.y = 0;
            }

            updateLocationSettings(location);
        }
    }

    const handleChangeLocation = (location: LocationPosition) => {
        setNewSettings('location', location, true);
    }

    const getLimitsByLocation = (location: LocationPosition): { minY: number, maxY: number, minX: number, maxX: number } => {
        let minY = 0;
        let maxY = 0;

        let minX = 0;
        let maxX = 0;

        switch (location) {
            case LocationPosition.topLeft:
                minY = 0;
                minX = 0;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.top:
                minY = 0;
                minX = -500;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.topRight:
                minY = 0;
                minX = -500;
                maxY = 500;
                maxX = 0;
                break;
            case LocationPosition.left:
                minY = -500;
                minX = 0;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.right:
                minY = -500;
                minX = -500;
                maxY = 500;
                maxX = 0;
                break;
            case LocationPosition.center:
                minY = -500;
                minX = -500;
                maxY = 500;
                maxX = 500;
                break;
            case LocationPosition.bottomLeft:
                minY = -500;
                minX = 0;
                maxY = 0;
                maxX = 500;
                break;
            case LocationPosition.bottom:
                minY = -500;
                minX = -500;
                maxY = 0;
                maxX = 500;
                break;
            case LocationPosition.bottomRight:
                minY = -500;
                minX = -500;
                maxY = 0;
                maxX = 0;
                break;
            default:
                break;
        }

        return { minY, maxY, minX, maxX };
    }

    const handleChangeLocationX = (x: number) => {
        setNewSettings('x', x, false);
    }

    const handleChangeLocationY = (y: number) => {
        setNewSettings('y', y, false);
    }

    return <>
        <div className="option-box">
            {/* <div className="option-label">
                Location
            </div> */}
            <div className="option-content">
                <div className="vertical-tabs">
                        <div className="vertical-tab-list">
                            <div>
                                <div className="font-xsmall font-color">X: {locationSettings?.x || 0} </div>
                                <Slider
                                    min={locationSettings?.location ? getLimitsByLocation(locationSettings?.location).minX : 0}
                                    max={locationSettings?.location ? getLimitsByLocation(locationSettings?.location).maxX : 0}
                                    step={1}
                                    className="min50"
                                    onChange={handleChangeLocationX}
                                    value={locationSettings?.x || 0}
                                />
                            </div>
                            <div>
                                <div className="font-xsmall font-color">Y: {locationSettings?.y || 0} </div>
                                <Slider
                                    min={locationSettings?.location ? getLimitsByLocation(locationSettings?.location).minY : 0}
                                    max={locationSettings?.location ? getLimitsByLocation(locationSettings?.location).maxY : 0}
                                    step={1}
                                    className="min50"
                                    onChange={handleChangeLocationY}
                                    value={locationSettings?.y || 0}
                                />
                            </div>
                        </div>
                    
                    <div className="vertical-tab-content">
                        {layoutDevice === LayoutDevices.desktop ?
                            (
                                <div className="top-content">
                                    <div className="desktop-screen">
                                        <div className="desktop-placement">


                                            <span
                                                className={`placement-topLeft ${locationSettings?.location === LocationPosition.topLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                            ></span>
                                            <span
                                                className={`placement-left ${locationSettings?.location === LocationPosition.left ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.left)}
                                            ></span>
                                            <span
                                                className={`placement-top ${locationSettings?.location === LocationPosition.top ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.top)}
                                            ></span>
                                            <span
                                                className={`placement-topRight ${locationSettings?.location === LocationPosition.topRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                            ></span>
                                            <span
                                                className={`placement-right ${locationSettings?.location === LocationPosition.right ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.right)}
                                            ></span>


                                            <span
                                                className={`placement-bottomLeft ${locationSettings?.location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                            ></span>
                                            <span
                                                className={`placement-center ${locationSettings?.location === LocationPosition.center ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.center)}
                                            ></span>
                                            <span
                                                className={`placement-bottom ${locationSettings?.location === LocationPosition.bottom ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                            ></span>


                                            <span
                                                className={`placement-bottomRight ${locationSettings?.location === LocationPosition.bottomRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className="desktop-tail">

                                    </div>
                                    <div className="desktop-legs">

                                    </div>
                                </div>
                            )
                            :
                            (
                                <div className="bottom-content">
                                    <div className="mobile-screen">
                                        <div className="mobile-placement">

                                            <span
                                                className={`placement-topLeft ${locationSettings?.location === LocationPosition.topLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topLeft)}
                                            ></span>
                                            <span
                                                className={`placement-left ${locationSettings?.location === LocationPosition.left ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.left)}
                                            ></span>
                                            <span
                                                className={`placement-top ${locationSettings?.location === LocationPosition.top ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.top)}
                                            ></span>

                                            <span
                                                className={`placement-topRight ${locationSettings?.location === LocationPosition.topRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.topRight)}
                                            ></span>
                                            <span
                                                className={`placement-center ${locationSettings?.location === LocationPosition.center ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.center)}
                                            ></span>
                                            <span
                                                className={`placement-right ${locationSettings?.location === LocationPosition.right ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.right)}
                                            ></span>

                                            <span
                                                className={`placement-bottomLeft ${locationSettings?.location === LocationPosition.bottomLeft ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomLeft)}
                                            ></span>
                                            <span
                                                className={`placement-bottom ${locationSettings?.location === LocationPosition.bottom ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottom)}
                                            ></span>

                                            <span
                                                className={`placement-bottomRight ${locationSettings?.location === LocationPosition.bottomRight ? 'active' : ''}`}
                                                onClick={() => handleChangeLocation(LocationPosition.bottomRight)}
                                            ></span>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LocationSettingsComponent
