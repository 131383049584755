import './css/preview.scss';
import phoneSrc from './../../../../assets/images/phone.jpg';
import laptopSrc from './../../../../assets/images/laptop.jpg';

interface Props {
    confirmation: boolean,
    title: string,
}

const ExpressPrePreview = ({ confirmation, title }: Props) => {

    return <>
        <div className="express-adv-body">
            <div className="express-adv-browser-line">
                <span className="express-adv-broswer-circle close"></span>
                <span className="express-adv-broswer-circle minimize"></span>
                <span className="express-adv-broswer-circle resize"></span>
            </div>
            <div className="express-adv-header">
                <div className="express-adv-logo">
                    Logo
                </div>
                <div className="express-adv-header-menu">
                    <ul>
                        <li>
                            Home
                        </li>
                        <li>
                            About
                        </li>
                        <li>
                            Shop
                        </li>
                    </ul>

                    <div className="express-adv-header-cart">
                        5 items, $349
                    </div>
                </div>
            </div>
            <div className="express-adv-page">
                <ul>
                    <li className="active">
                        {confirmation ? <>
                            1. Order confirmation
                        </>
                            :
                            <>
                                {title}
                            </>}
                    </li>
                </ul>

                <div className="express-adv-content">
                    <div className="express-adv-product-row">
                        <div className="express-adv-product-image">
                            <img src={laptopSrc} alt="Laptop" />
                        </div>
                        <div className="express-adv-product-content">
                            <span className="express-adv-pre-content large"></span>
                            <span className="express-adv-pre-content"></span>
                            <span className="express-adv-pre-content"></span>
                        </div>
                        <div className="express-adv-product-actions">
                            <span className="express-adv-pre-action"></span>
                            <span className="express-adv-pre-action"></span>
                        </div>
                    </div>

                    <div className="express-adv-product-row">
                        <div className="express-adv-product-image">
                            <img src={phoneSrc} alt="Phone" />
                        </div>
                        <div className="express-adv-product-content">
                            <span className="express-adv-pre-content large"></span>
                            <span className="express-adv-pre-content"></span>
                            <span className="express-adv-pre-content"></span>
                        </div>
                        <div className="express-adv-product-actions">
                            <span className="express-adv-pre-action"></span>
                            <span className="express-adv-pre-action"></span>
                        </div>
                    </div>

                    <div className="express-adv-shopping-buttons">
                        <div className="express-adv-buttons">
                            <span className="express-adv-success-button">Next</span>
                            <span className="express-adv-default-button">Back to shop</span>
                        </div>
                        <div className="express-adv-shopping-actions">
                            <span className="express-adv-pre-action"></span>
                            <span className="express-adv-pre-action"></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>;
}

export default ExpressPrePreview;
