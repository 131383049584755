import { FileUnknownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Empty, message, Tooltip } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth.hook";
import useAxios from "../../../../hooks/useAxios.hook";
import { CampaignType } from "../../../../types/campaign.interfaces";
import { ACCESS_TYPES } from "../../../../utility/enums/user.enums";

interface Props {
    isLoading: boolean,
    campaigns?: CampaignType[],
    fetchCampaigns: () => {}
}

const CampaignsWidget = ({ isLoading, campaigns, fetchCampaigns }: Props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isLoading);
    const { myAxios } = useAxios();
    const { company } = useAuth();

    const handleStartCampaign = async (id: string | undefined) => {
        if (id) {
            setLoading(true);
            const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${id}/true` });

            if (response?.status) {
                message.success('Campaign is started', 1);
            } else {
                message.error('Campaign failed to start', 1);
                console.log(error);
            }
            fetchCampaigns();
        }
    }

    const handleStopCampaign = async (id: string | undefined) => {
        if (id) {
            setLoading(true);
            const { response, error } = await myAxios({ method: 'post', url: `/campaign/active/${id}/false` });
            if (response?.status) {
                message.success('Campaign is stopped', 1);
            } else {
                message.error('Campaign failed to stop', 1);
                console.log(error);
            }
            fetchCampaigns();
        }
    }

    return <>
        <div className="home-widget">
            <div className="widget-title">
                Campaigns
            </div>
            <div className="widget-description">
                You have {campaigns?.length || 0} campaigns
            </div>
            <div className="widget-content widget-table">
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <span>
                                        Name
                                    </span>
                                </th>
                                <th>
                                    <span>
                                        Status
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <>
                                {company?.userAccess?.access.includes(ACCESS_TYPES.campaignread) ?
                                    <>
                                        {loading ?
                                            <tr className={campaigns && campaigns.length > 0 ? 'loader-absolute' : ''}>
                                                <td className="text-center" colSpan={5}>
                                                    <LoadingOutlined />
                                                </td>
                                            </tr>
                                            : (
                                                campaigns?.length === 0 &&
                                                <tr>
                                                    <td className="text-center" colSpan={5}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        {campaigns && campaigns?.length > 0 && campaigns?.map((campaign: CampaignType, index: number) => {
                                            return <React.Fragment key={campaign.name + index}>
                                                <tr className={`table-row-parent ${campaign.isOpen ? 'table-row-open' : ''}`}>
                                                    <td>
                                                        <Link to={`/campaigns/single/${campaign.id}`} className="table-hover table-link"> {campaign.name}</Link>
                                                    </td>
                                                    <td>
                                                        {campaign.completionStatus.status ?
                                                            <span className="table-actions">
                                                                {campaign.isActive ?
                                                                    <>
                                                                        <span className="label label-success label-fixed">
                                                                            Active
                                                                        </span>
                                                                        {company?.userAccess?.access.includes(ACCESS_TYPES.campaignwrite) &&
                                                                            <span className="table-icon icon-stop mleft20" onClick={() => handleStopCampaign(campaign.id)}></span>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span className="label label-fail label-fixed">
                                                                            Stopped
                                                                        </span>
                                                                        {company?.userAccess?.access.includes(ACCESS_TYPES.campaignwrite) &&
                                                                            <span className="table-icon icon-start mleft20" onClick={() => handleStartCampaign(campaign.id)}></span>
                                                                        }
                                                                    </>
                                                                }
                                                            </span>
                                                            :
                                                            <>
                                                                <Tooltip placement="bottom" title="Draft">
                                                                    <FileUnknownOutlined />
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        })}
                                    </>
                                    :
                                    <>
                                        <tr>
                                            <td className="text-center" colSpan={5}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You are not authorized to access this resourse" />
                                            </td>
                                        </tr>
                                    </>

                                }
                            </>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="widget-footer">
                {company?.userAccess?.access.includes(ACCESS_TYPES.campaignread) &&
                    <Button type="primary" onClick={() => navigate('/campaigns')}> Go to all campaigns</Button>
                }
            </div>
        </div>
    </>
}

export default CampaignsWidget;