import { Button, Radio, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { DaySlots, Offer } from "../../../../../types/offer.interfaces";
import {
  DeviceType,
  DeviceOS,
  dayNames,
  timeSlots,
  timeSlotsText,
  timeSlotsBoxes,
} from "../../../../express/const/campaigns.enum";

interface Props {
  setConditionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  offer: Offer;
  selectedOffers: Offer[];
  setSelectedOffers: React.Dispatch<React.SetStateAction<Offer[]>>;
}

const OfferDeviceAndScheduling = ({
  setConditionModalOpen,
  offer,
  setSelectedOffers,
}: Props) => {
  const [deviceType, setDeviceType] = useState<string>(
    offer.appearanceRules.deviceCondition.deviceType
      ? offer.appearanceRules.deviceCondition.deviceType
      : DeviceType.Both
  );
  const [deviceOS, setDeviceOS] = useState<string>(
    offer.appearanceRules.deviceCondition.deviceOS
      ? offer.appearanceRules.deviceCondition.deviceOS
      : DeviceOS.All
  );

  const [isEnabled, setIsEnabled] = useState<boolean>(
    offer.appearanceRules.dateTimeCondition.isEnabled
      ? offer.appearanceRules.dateTimeCondition.isEnabled
      : false
  );

  const updateSlots = () => {
    // Create a map from dayIndex to timeSlots for quick lookup
    const dayIndexMap = new Map();
    (offer.appearanceRules.dateTimeCondition.daySlots || []).forEach((slot) => {
      dayIndexMap.set(slot.dayIndex, slot.timeSlots);
    });

    const emptyDaySlots = [
      {
        dayIndex: 1,
        timeSlots: [],
      },
      {
        dayIndex: 2,
        timeSlots: [],
      },
      {
        dayIndex: 3,
        timeSlots: [],
      },
      {
        dayIndex: 4,
        timeSlots: [],
      },
      {
        dayIndex: 5,
        timeSlots: [],
      },
      {
        dayIndex: 6,
        timeSlots: [],
      },
      {
        dayIndex: 7,
        timeSlots: [],
      },
    ];

    // Update targetSlots based on dayIndex
    emptyDaySlots.forEach((slot) => {
      if (dayIndexMap.has(slot.dayIndex)) {
        slot.timeSlots = dayIndexMap.get(slot.dayIndex);
      }
    });

    return emptyDaySlots;
  };

  const [daySlots, setDaySlots] = useState<DaySlots[]>(updateSlots());

  const addTimeSlot = (idx: number, timeSlot: string) => {
    setDaySlots((prevDaySlots) => {
      const newDaySlots = prevDaySlots.map((daySlot) => {
        if (daySlot.dayIndex === idx) {
          return {
            ...daySlot,
            timeSlots: [...daySlot.timeSlots, timeSlot],
          };
        }
        return daySlot;
      });
      return newDaySlots;
    });
  };

  const removeTimeSlot = (idx: number, timeSlot: string) => {
    setDaySlots((prevDaySlots) => {
      const newDaySlots = prevDaySlots.map((daySlot) => {
        if (daySlot.dayIndex === idx) {
          return {
            ...daySlot,
            timeSlots: daySlot.timeSlots.filter((ts) => ts !== timeSlot),
          };
        }
        return daySlot;
      });
      return newDaySlots;
    });
  };

  const handleToggleTimeSlot = (idx: number, timeSlot: string) => {
    const slotExists = checkIfSlotExists(idx, timeSlot);
    if (slotExists) {
      removeTimeSlot(idx, timeSlot);
    } else {
      addTimeSlot(idx, timeSlot);
    }
  };

  const checkIfSlotExists = (idx: number, timeSlot: string) => {
    if (idx === 0) {
      return false;
    }

    return daySlots[idx - 1].timeSlots.includes(timeSlot);
  };

  const handleUpdate = () => {
    const filledDaySlots = daySlots.filter(
      (daySlot) => daySlot.timeSlots.length > 0
    );

    setSelectedOffers((prevState: Offer[]) =>
      prevState.map((o: Offer) =>
        o.id === offer.id
          ? {
              ...offer,
              appearanceRules: {
                ...offer.appearanceRules,
                deviceCondition: {
                  deviceOS: deviceOS,
                  deviceType: deviceType,
                },
                dateTimeCondition: {
                  isEnabled: isEnabled,
                  daySlots: filledDaySlots,
                },
              },
            }
          : o
      )
    );
    setConditionModalOpen(false);
  };

  useEffect(() => {
    const dayIndexMap = new Map();
    offer?.appearanceRules?.dateTimeCondition?.daySlots?.forEach((slot) => {
      dayIndexMap.set(slot.dayIndex, slot.timeSlots);
    });

    const emptyDaySlots = [
      {
        dayIndex: 1,
        timeSlots: [],
      },
      {
        dayIndex: 2,
        timeSlots: [],
      },
      {
        dayIndex: 3,
        timeSlots: [],
      },
      {
        dayIndex: 4,
        timeSlots: [],
      },
      {
        dayIndex: 5,
        timeSlots: [],
      },
      {
        dayIndex: 6,
        timeSlots: [],
      },
      {
        dayIndex: 7,
        timeSlots: [],
      },
    ];

    // Update targetSlots based on dayIndex
    emptyDaySlots.forEach((slot) => {
      if (dayIndexMap.has(slot.dayIndex)) {
        slot.timeSlots = dayIndexMap.get(slot.dayIndex);
      }
    });

    setDaySlots(emptyDaySlots);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetDaySlots = () => {
    setDaySlots([
      {
        dayIndex: 1,
        timeSlots: [],
      },
      {
        dayIndex: 2,
        timeSlots: [],
      },
      {
        dayIndex: 3,
        timeSlots: [],
      },
      {
        dayIndex: 4,
        timeSlots: [],
      },
      {
        dayIndex: 5,
        timeSlots: [],
      },
      {
        dayIndex: 6,
        timeSlots: [],
      },
      {
        dayIndex: 7,
        timeSlots: [],
      },
    ]);
  };

  const handleOnDayClick = (index: number) => {
    const dayExists = checkIfDayExists(index);

    if (!dayExists) {
      addDayTimeSlot(index + 1);
    } else {
      removeDayTimeSlot(index + 1);
    }
  };

  const addDayTimeSlot = (index: number) => {
    setDaySlots((prevDaySlots) => {
      const newDaySlots = prevDaySlots.map((daySlot) => {
        if (daySlot.dayIndex === index) {
          return {
            ...daySlot,
            timeSlots: timeSlots,
          };
        }
        return daySlot;
      });
      return newDaySlots;
    });
  };

  const removeDayTimeSlot = (index: number) => {
    setDaySlots((prevDaySlots) => {
      const newDaySlots = prevDaySlots.map((daySlot) => {
        if (daySlot.dayIndex === index) {
          return {
            ...daySlot,
            timeSlots: [],
          };
        }
        return daySlot;
      });
      return newDaySlots;
    });
  };

  const checkIfDayExists = (index: number) => {
    return daySlots[index].timeSlots.length === 12;
  };

  return (
    <div className="campaign-badges">
      <div className="campaign-badges-subtitle mbot20">
        You can restrict the offer to only appear on specific devices.
      </div>
      <div className="condition-rules-inputs mbot20">
        <div className="frame">
          <div className="campaign-badges-subtitle campaign-badges-subtitle-bold">
            Devices
          </div>
          <div className="campaign-badges-radio mbot20">
            <Radio.Group
              onChange={(e) => {
                if (
                  e.target.value !== DeviceType.Mobile &&
                  deviceOS !== DeviceOS.All
                ) {
                  setDeviceOS(DeviceOS.All);
                }
                setDeviceType(e.target.value);
              }}
              value={deviceType}
            >
              <Radio
                className="campaign-badges-subtitle"
                value={DeviceType.Both}
              >
                Show on all devices
              </Radio>
              <Radio
                className="campaign-badges-subtitle"
                value={DeviceType.Desktop}
              >
                Show only on desktop
              </Radio>
              <Radio
                className="campaign-badges-subtitle"
                value={DeviceType.Mobile}
              >
                Show only on mobile
              </Radio>
            </Radio.Group>
          </div>
          <div
            className="campaign-badges-subtitle campaign-badges-subtitle-bold"
            style={{
              opacity: deviceType === DeviceType.Mobile ? 1 : 0.5,
            }}
          >
            Mobile specific
          </div>
          <div className="campaign-badges-radio mbot20">
            <Radio.Group
              disabled={deviceType === DeviceType.Desktop}
              onChange={(e) => {
                setDeviceOS(e.target.value);
              }}
              value={deviceOS}
            >
              <Radio className="campaign-badges-subtitle" value={DeviceOS.All}>
                Show on all Operating Systems
              </Radio>
              <Radio
                className="campaign-badges-subtitle"
                value={DeviceOS.Android}
              >
                Show only on Android devices
              </Radio>
              <Radio className="campaign-badges-subtitle" value={DeviceOS.IOS}>
                Show only on iOS devices
              </Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className="campaign-badges-subtitle campaign-badges-subtitle-bold mbot20">
        By activating scheduling, the offer will be displayed to customers only
        on the selected hours during of the week.
      </div>

      <div className="condition-rules-inputs">
        <div className="frame">
          <div className="scheduling-header">
            <div className="campaign-badges-subtitle campaign-badges-subtitle-bold">
              Offer’s display weekly schedule
            </div>
            <div>
              <span className="campaign-badges-subtitle mright10">
                Activate scheduling
              </span>
              <Switch
                size="default"
                checked={isEnabled}
                onChange={(checked: boolean) => {
                  setIsEnabled(checked);
                }}
              />
            </div>
          </div>
          <table
            className="scheduling-table"
            style={{
              opacity: isEnabled ? 1 : 0.6,
              transition: "opacity 0.3s ease-in-out",
            }}
          >
            <thead>
              <tr>
                <th className="scheduling-button" onClick={resetDaySlots}>
                  Deselect all
                </th>
                {dayNames.map((day, index) => (
                  <th
                    onClick={() => handleOnDayClick(index)}
                    key={index}
                    style={{
                      color: checkIfDayExists(index) ? "#3ab78f" : "",
                    }}
                  >
                    {day.substring(0, 3)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {timeSlots.map((day, index) => (
                <tr key={index}>
                  {timeSlotsBoxes.map((timeSlot, idx) => (
                    <td
                      key={idx}
                      onClick={() => {
                        if (idx !== 0) {
                          handleToggleTimeSlot(idx, timeSlots[index]);
                        }
                      }}
                      className={
                        index === 0
                          ? "rows-with-border-top"
                          : index === 11
                          ? "rows-with-border-bottom"
                          : ""
                      }
                      style={{
                        backgroundColor: checkIfSlotExists(
                          idx,
                          timeSlots[index]
                        )
                          ? "#B3E4D4"
                          : "",
                      }}
                    >
                      {idx === 0 && timeSlotsText[index]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="ant-modal-footer">
        <Button
          onClick={handleUpdate}
          type="primary"
          className="success-button mtop20"
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default OfferDeviceAndScheduling;
