import { ButtonSettings, ButtonShapes, LocationPosition } from "../types/params.interfaces";
import '../css/initial-layout.scss';

interface Props {
  settings: ButtonSettings,
  displayOnly: boolean,
  count: number;
}

const ButtonLayout = ({ settings, displayOnly, count }: Props) => {
  return (
    <>
      <div className={`oli-wrapper ${settings?.location.location}`} >
        <div className="button-wrapper"
          style={{
            transform: `translate(${settings.location.x}px, ${settings.location.y}px)`,
            width: settings.shape === ButtonShapes.circle ?
              settings.sizes.circle :
              (settings.location.location === LocationPosition.left || settings.location.location === LocationPosition.right) && (
                settings.shape !== ButtonShapes.squareImage && settings.shape !== ButtonShapes.squareImageLock
              ) ?
                settings.sizes.h :
                settings.sizes.w
          }}
        >
          <button
            className={`${settings.shape === ButtonShapes.circle || settings.shape === ButtonShapes.squareImage || settings.shape === ButtonShapes.squareImageLock ? 'no-rotation' : ''}`}
            style={{
              width: `${settings.shape === ButtonShapes.circle ? settings.sizes.circle : settings.sizes.w}px`,
              height: `${settings.shape === ButtonShapes.circle ? settings.sizes.circle : settings.sizes.h}px`,
              backgroundColor: settings?.style.backgroundColor,
              border: `2px solid ${settings.style?.borderColor || settings?.style.backgroundColor}`,
              color: settings.style.textColor,
              textTransform: settings.style.textTransform,
              fontFamily: settings.style.fontFamily,
              fontWeight: settings.style.fontWeight,
              fontSize: `${settings.style.fontSize}px`,
              borderTopLeftRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderTopLeftRadius + 'px',
              borderTopRightRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderTopRightRadius + 'px',
              borderBottomLeftRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderBottomLeftRadius + 'px',
              borderBottomRightRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderBottomRightRadius + 'px',

            }}>
            {settings?.number?.display &&
              <span className="button-number" style={
                (settings.location.y !== 0 && settings.location.x !== 0) ? {
                  backgroundColor: settings?.number?.backgroundColor || '#ff0000',
                  color: settings?.number?.textColor || '#ffffff',
                  top: '-5px',
                  right: '-5px',
                  bottom: 'auto',
                  left: 'auto',
                } :
                  {
                    backgroundColor: settings?.number?.backgroundColor || '#ff0000',
                    color: settings?.number?.textColor || '#ffffff',
                  }}>
                {count}
              </span>
            }
            <span className="inner-button"
              style={{
                borderTopLeftRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderTopLeftRadius + 'px',
                borderTopRightRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderTopRightRadius + 'px',
                borderBottomLeftRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderBottomLeftRadius + 'px',
                borderBottomRightRadius: settings.shape === ButtonShapes.circle ? '100%' : settings.style.borderBottomRightRadius + 'px',
                maxWidth: `${settings.shape === ButtonShapes.circle ? settings.sizes.circle : settings.sizes.w}px`,
                maxHeight: `${settings.shape === ButtonShapes.circle ? settings.sizes.circle : settings.sizes.h}px`,
              }}
            >
              {settings.shape === ButtonShapes.squareImage &&
                <>
                  <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.5 23.5V46H5.5V23.5M23.5 46V12.25M23.5 12.25H13.375C11.8832 12.25 10.4524 11.6574 9.39752 10.6025C8.34263 9.54758 7.75 8.11684 7.75 6.625C7.75 5.13316 8.34263 3.70242 9.39752 2.64752C10.4524 1.59263 11.8832 1 13.375 1C21.25 1 23.5 12.25 23.5 12.25ZM23.5 12.25H33.625C35.1168 12.25 36.5476 11.6574 37.6025 10.6025C38.6574 9.54758 39.25 8.11684 39.25 6.625C39.25 5.13316 38.6574 3.70242 37.6025 2.64752C36.5476 1.59263 35.1168 1 33.625 1C25.75 1 23.5 12.25 23.5 12.25ZM1 12.25H46V23.5H1V12.25Z" stroke={settings.style.textColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </>
              }
              {settings.shape === ButtonShapes.squareImageLock &&
                <>
                  {displayOnly ?
                    <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z" stroke={settings.style.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    :
                    <svg width="42" height="47" viewBox="0 0 42 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z" stroke={settings.style.textColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  }
                </>
              }
              <span className="inner-button-text">
                {settings.text}
              </span>
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default ButtonLayout;