import { DatePicker, Dropdown, message } from "antd";
import { RangeValue } from 'rc-picker/lib/interface';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Layout, Layouts, Responsive, WidthProvider } from "react-grid-layout";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import usePage from "../../hooks/usePage.hook";
import { AggregatedDailyEvent, AggregatedEvent } from "../../types/events.interfaces";
import { DropDown, Pagination } from "../../types/global.interfaces";
import { insightAdminLayout, insightLayout, INSIGHT_WIDGET, INSIGHT_WIDGET_ADMIN } from "../../utility/enums/layout-insight.enums";
import { COMPANY_TYPES } from "../../utility/enums/user.enums";
import Footer from "../footer/footer.component";
import "./css/insight.scss";
import AdvertiserOffersEvents from "./widgets/advertiserOffersEvents/advertiserOffersEvents.component";
import AdvertiserRetailersEvents from "./widgets/advertiserRetailersEvents/advertiserRetailersEvents.component";
import RetailerCampaignsEvents from "./widgets/retailerCampaignsEvents/retailerCampaignsEvents.component";
import RetailerChartEvents from "./widgets/retailerChartEvents/retailerChartEvents.component";
import OffersEventsWidget from "./widgets/retailerOffersEvents/retailerOffersEvents.component";
import { stringDateToMoment } from "../../utility/date";
import AdvertiserChartEvents from "./widgets/advertiserChartEvents/advertiserChartEvents.component";
import AdminChartEvents from "./widgets/adminChartEvents/adminAdvertiserChartEvents.component";

const { RangePicker } = DatePicker;

const Insight = () => {
  const { setPage } = usePage();
  const { auth } = useAuth();
  const [layouts] = useState<Layouts>(insightLayout);
  const [adminLayout] = useState<Layouts>(insightAdminLayout);
  const [layoutWidth, setLayoutWidth] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const { company } = useAuth();
  const [loadingCampaigns, setLoadingCampaigns] = useState<boolean>(false);
  const [loadingOffers, setLoadingOffers] = useState<boolean>(false);

  const [loadingRetailers, setLoadingRetailers] = useState<boolean>(false);
  const [loadingRetailerChart, setLoadingRetailerChart] = useState<boolean>(false);

  const [loadingAdvertiserChart, setLoadingAdvertiserChart] = useState<boolean>(false);
  const [loadingAdvertiserOffers, setLoadingAdvertiserOffers] = useState<boolean>(false);

  const [retailersEvents, setAdvertiserRetailersEvents] = useState<AggregatedEvent>();
  const [advertiserOffersEvent, setAdvertiserOffersEvent] = useState<AggregatedEvent>();
  const [advertiserDailyOffersEvent, setAdvertiserDailyOffersEvent] = useState<AggregatedDailyEvent>();

  const [pagination] = useState<Pagination>({
    page: 1,
    pageSize: 20
  });

  const [campaignsEvents, setCampaignsEvets] = useState<AggregatedEvent>();
  const [offersEvents, setOffersEvents] = useState<AggregatedEvent>();
  const [dailyCampaignsEvent, setDailyCampaignsEvent] = useState<AggregatedDailyEvent>();


  const [currentDateFilterType, setDateFilterType] = useState<DropDown>({ label: "Yesterday", key: "yesterday" });

  const [filters, setFilters] = useState({
    dateFilterType: "yesterday",
    fromDate: "",
    endDate: ""
  });

  const { myAxios } = useAxios();

  const fetchRetailerCamapigns = async (companyId: string) => {
    setLoadingCampaigns(true);
    const { response } = await myAxios({ method: 'post', url: `insights/retailer/campaigns/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setCampaignsEvets(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingCampaigns(false);
  }

  const fetchRetailerOffers = async (companyId: string) => {
    setLoadingOffers(true);
    const { response } = await myAxios({ method: 'post', url: `insights/retailer/offers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setOffersEvents(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingOffers(false);
  }

  const fetchRetailerCampaignsDaily = async (companyId: string) => {
    setLoadingRetailerChart(true);
    const { response } = await myAxios({ method: 'post', url: `insights/retailer/campaignstotaldaily/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setDailyCampaignsEvent(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingRetailerChart(false);
  }

  const fetchAdvertiserRetailers = async (companyId: string) => {
    setLoadingRetailers(true);
    const { response } = await myAxios({ method: 'post', url: `insights/advertiser/retailers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setAdvertiserRetailersEvents(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingRetailers(false);
  }

  const fetchAdvertiserOffers = async (companyId: string) => {
    setLoadingAdvertiserOffers(true);
    const { response } = await myAxios({ method: 'post', url: `insights/advertiser/offers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setAdvertiserOffersEvent(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingAdvertiserOffers(false);
  }

  const fetchAdvertiserOffersDaily = async (companyId: string) => {
    setLoadingAdvertiserChart(true);
    const { response } = await myAxios({ method: 'post', url: `insights/advertiser/offerstotaldaily/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });

    if (response?.status === 200) {
      setAdvertiserDailyOffersEvent(response.data.result);
    } else {
      message.error(`Something went wrong!`);
    }

    setLoadingAdvertiserChart(false);
  }

  // Set campaigns and offers toggle array
  useEffect(() => {
    if (company?.company_Id) {
      if (company.companyCurrentType === COMPANY_TYPES.advertiser) {
        fetchAdvertiserRetailers(company.company_Id);
        fetchAdvertiserOffers(company.company_Id);
        fetchAdvertiserOffersDaily(company.company_Id);
      } else {
        fetchRetailerCamapigns(company.company_Id);
        fetchRetailerOffers(company.company_Id);
        fetchRetailerCampaignsDaily(company.company_Id);
      }
    }
    // eslint-disable-next-line
  }, [pagination, company, filters]);

  useEffect(() => {
    setPage({ title: <>Insight</> });
    // eslint-disable-next-line 
  }, []);

  // Setting width if wrapper size has changed
  const handleElementResized = () => {
    if (wrapperRef?.current?.offsetWidth && wrapperRef?.current?.offsetWidth !== layoutWidth) {
      setLayoutWidth(wrapperRef?.current?.offsetWidth || 0);
    }
  }

  // Set callback function on resize observer
  const resizeObserver = new ResizeObserver(handleElementResized);


  // Observe element and react to it if its changing
  useEffect(() => {
    if (wrapperRef?.current)
      resizeObserver.observe(wrapperRef.current)

    return () => {
      resizeObserver.disconnect();
    }
  });

  const filterByDate: DropDown[] = [
    { label: 'Last week', key: 'lastweek' },
    { label: 'This week', key: 'thisweek' },
    { label: 'Last month', key: 'lastmonth' },
    { label: 'This month', key: 'thismonth' },
    { label: 'Last 90 days', key: 'last90days' },
    { label: 'Yesterday', key: 'yesterday' },
    { label: 'Today', key: 'today' },
  ];

  const handleChangeDateFilterType = (label: string) => {
    const newDateFilter = filterByDate.find((value) => value.key === label);
    if (newDateFilter) {
      setDateFilterType(newDateFilter);
      setFilters({ dateFilterType: newDateFilter.key, fromDate: "", endDate: "" })
    }
  }

  const handleChangeDateStartEnd = (value: RangeValue<moment.Moment> | null) => {
    if (value) {
      const fromDate = value[0]?.format("YYYY-MM-DD");
      const endDate = value[1]?.format("YYYY-MM-DD");
      if (fromDate && endDate) {
        setFilters({ dateFilterType: 'custom', fromDate: fromDate, endDate: endDate })
        setDateFilterType({ label: 'Custom', key: 'custom' });
      }
    } else {
      setFilters({ dateFilterType: 'thisweek', fromDate: "", endDate: "" })
      setDateFilterType({ label: 'This week', key: 'thisweek' });
    }
  }


  const switchWidgetComponent = (widget: INSIGHT_WIDGET) => {
    let component = <></>;

    switch (widget) {
      case INSIGHT_WIDGET.tableEvents:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserRetailersEvents loading={loadingRetailers} events={retailersEvents} /> : <RetailerCampaignsEvents loading={loadingCampaigns} events={campaignsEvents} />
        break;
      case INSIGHT_WIDGET.table2Events:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserOffersEvents loading={loadingAdvertiserOffers} events={advertiserOffersEvent} /> : <OffersEventsWidget loading={loadingOffers} events={offersEvents} />
        break;
      case INSIGHT_WIDGET.chartEvents:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserChartEvents loading={loadingAdvertiserChart} events={advertiserDailyOffersEvent} /> : <RetailerChartEvents loading={loadingRetailerChart} events={dailyCampaignsEvent} />

        break;
      default:
        break;
    }
    return component;
  }

  const switchAdminWidgetComponent = (widget: INSIGHT_WIDGET_ADMIN) => {
    let component = <></>;

    switch (widget) {
      case INSIGHT_WIDGET_ADMIN.tableEvents:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserRetailersEvents loading={loadingRetailers} events={retailersEvents} /> : <RetailerCampaignsEvents loading={loadingCampaigns} events={campaignsEvents} />
        break;
      case INSIGHT_WIDGET_ADMIN.table2Events:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserOffersEvents loading={loadingAdvertiserOffers} events={advertiserOffersEvent} /> : <OffersEventsWidget loading={loadingOffers} events={offersEvents} />
        break;
      case INSIGHT_WIDGET_ADMIN.chartEvents:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdvertiserChartEvents loading={loadingAdvertiserChart} events={advertiserDailyOffersEvent} /> : <RetailerChartEvents loading={loadingRetailerChart} events={dailyCampaignsEvent} />
        break;
      case INSIGHT_WIDGET_ADMIN.adminChartEvents:
        component = company?.companyCurrentType === COMPANY_TYPES.advertiser ? <AdminChartEvents loading={loadingAdvertiserChart} events={advertiserDailyOffersEvent} /> : <AdminChartEvents loading={loadingRetailerChart} events={dailyCampaignsEvent} />;

        break;
      default:
        break;
    }
    return component;
  }

  return <>
    <div className="content">
      <div className="content-layout content-insight">
        <div className="layout-wrapper" ref={wrapperRef}>
          <div className="layout-options">

            <div className="layout-actions">

            </div>
            <div className="filters">
              <Dropdown className="min150 mright20" menu={{ items: filterByDate, selectable: true, selectedKeys: ['yesterday'], onClick: (data) => { handleChangeDateFilterType(data.key) } }} trigger={['click']} >
                <div className="button-dropdown button-icon flex-center-space-between">
                  {currentDateFilterType?.label} <span className="icon small icon-arrow-down"></span>
                </div>
              </Dropdown>

              <RangePicker
                value={[filters.fromDate ? stringDateToMoment(filters.fromDate) : null, filters.endDate ? stringDateToMoment(filters.endDate) : null]}
                className="filter-date"
                format="DD-MM-YYYY"
                onChange={handleChangeDateStartEnd}
              />
            </div>
          </div>

          {auth && auth?.isSuperAdmin ?
            <>
              {adminLayout &&
                <ResponsiveGridLayout
                  className="layout"
                  isResizable={true}
                  layouts={adminLayout}
                  rowHeight={50}
                  isDraggable={layoutWidth > 700}
                  margin={{ desktop: [15, 15], mobile: [15, 15] }}
                  breakpoints={{ desktop: 700, mobile: 1 }}
                  cols={{ desktop: 12, mobile: 6 }}
                  width={layoutWidth}
                >
                  {layoutWidth > 700 ?
                    adminLayout?.desktop?.map((value: Layout) => {
                      return <div key={value.i} className="widget">
                        {switchAdminWidgetComponent(value.i as INSIGHT_WIDGET_ADMIN)}
                      </div>
                    })
                    :
                    adminLayout?.mobile?.map((value: Layout) => {
                      return <div key={value.i} className="widget">
                        {switchAdminWidgetComponent(value.i as INSIGHT_WIDGET_ADMIN)}
                      </div>
                    })
                  }
                </ResponsiveGridLayout>
              }
            </>
            :
            <>
              {layouts &&
                <ResponsiveGridLayout
                  className="layout"
                  isResizable={true}
                  layouts={layouts}
                  rowHeight={50}
                  isDraggable={layoutWidth > 700}
                  margin={{ desktop: [15, 15], mobile: [15, 15] }}
                  breakpoints={{ desktop: 700, mobile: 1 }}
                  cols={{ desktop: 12, mobile: 6, }}
                  width={layoutWidth}
                >
                  {layoutWidth > 700 ?
                    layouts?.desktop?.map((value: Layout) => {
                      return <div key={value.i} className="widget">
                        {switchWidgetComponent(value.i as INSIGHT_WIDGET)}
                      </div>
                    })
                    :
                    layouts?.mobile?.map((value: Layout) => {
                      return <div key={value.i} className="widget">
                        {switchWidgetComponent(value.i as INSIGHT_WIDGET)}
                      </div>
                    })
                  }
                </ResponsiveGridLayout>
              }
            </>}



        </div>
      </div>
      <Footer />
    </div>
  </>;
}

export default Insight;
