import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { EXPRESS_ONBOARDING } from "../../types/express.enum";
import { Button, Dropdown, Empty, Input, message, Modal, Switch, Tabs, Typography } from "antd";
import { ExpressCampaign, ExpressCategoryItem } from "../../types/express.types";
import useExpress from "../../../../hooks/useExpress.hook";
import { Offer } from "../../../../types/offer.interfaces";
import { Pagination } from "../../../../types/global.interfaces";
import useAxios from "../../../../hooks/useAxios.hook";
import defaultOfferImg from "../../../../assets/images/offer-image.jpg";
import { InfoCircleOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { CompanyCategory } from "../../../../types/company.interfaces";

interface Props {
    setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>,
}

const ExpressOnboardingPartners = ({ setCurrentStep }: Props) => {
    const { myAxios } = useAxios();
    const { campaign, company, setCampaign, setCompany, setOnboardingStatus, loading: expressLoading } = useExpress();

    const [selectedOffers, setSelectedOffers] = useState<Offer[]>(campaign?.offers || []);
    const [triggers, setTriggers] = useState<string[]>([]);
    const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]);
    const [offers, setOffers] = useState<Offer[]>([]);
    const [loading, setLoading] = useState<boolean>(expressLoading);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [pagination, setPagination] = useState<Pagination>({
        page: 1,
        pageSize: 20
    });
    const [filters, setFilters] = useState({
        advertiserIds: [],
        regionCodes: [],
        categories: [] as string[],
        excludedCategories: [],
        brandswopExclusive: 'all',
        type: "all",
    });
    const [offersTab, setOffersTab] = useState<string>("available");

    const { response, axiosFetch } = useAxios();
    const { response: responseApproval, axiosFetch: axiosApproval } = useAxios();

    const fetchOffers = async (companyId: string) => {
        setLoading(true);
        await axiosFetch({ method: 'post', url: `offers/getactiveoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });
    }

    const fetchApprovalOffers = async (companyId: string) => {
        setLoading(true);
        await axiosApproval({ method: 'post', url: `offers/pendingapproval/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`, data: filters });
    }

    const [categories, setCategories] = useState<CompanyCategory[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<CompanyCategory[]>([]);
    const [searchCategories, setSearchCategories] = useState<string>('');
    const [selectedCategories, setSelectedCategories] = useState<ExpressCategoryItem[]>(company?.excludedCategories || []);
    const categoriesRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (company) {
            switch (offersTab) {
                case "available":
                    fetchOffers(company.id);
                    break;
                case "needapprove":
                    fetchApprovalOffers(company.id);
                    break;

                default:
                    // fetchOffers(company.id)
                    break;
            }
        }
        if (campaign?.offers.length === 0) {
            // setDisabled(true);
        }
        // eslint-disable-next-line
    }, [filters, pagination, offersTab])

    useEffect(() => {
        setCampaign(prevState => (prevState && { ...prevState, offers: selectedOffers }));
        setSelectedTriggersFromOffers(selectedOffers);
        // setDisabled(selectedOffers.length === 0);
        // eslint-disable-next-line 
    }, [selectedOffers])

    useEffect(() => {
        if (response) {
            if (response.status) {
                const _offers = response.result?.data;
                setTotalPages(response.result?.totalPages);
                setOffers(_offers);
                setTriggersFromOffers(_offers);
                setLoading(false);
            } else {
                message.error(`Something went wrong!`);
            }
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [response]);

    useEffect(() => {
        if (responseApproval) {
            if (responseApproval.status) {
                const _offers = responseApproval.result?.data;
                setTotalPages(responseApproval.result?.totalPages);
                setOffers(_offers);
                setTriggersFromOffers(_offers);
                setLoading(false);
            } else {
                message.error(`Something went wrong!`);
            }
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [responseApproval]);

    const handleChangeTabs = (activeKey: string) => {
        setOffersTab(activeKey)
    }

    // Functions to toggle offers description 
    const setTriggersFromOffers = (_offer: Offer[]) => {
        _offer.forEach((value: Offer) => {
            !triggers.includes(value.id) && setTriggers(prevState => ([...prevState, value.id]));
        });
    }

    const setSelectedTriggersFromOffers = (_offer: Offer[]) => {
        _offer.forEach((value: Offer) => {
            !selectedTriggers.includes(value.id) && setSelectedTriggers(prevState => ([...prevState, value.id]));
        });
    }

    const handleSelect = (_offer: Offer) => {
        selectedOffers.length < 3 && setSelectedOffers(prevState => ([...prevState, _offer]));
    }

    const handleRemove = (id: string) => {
        setSelectedOffers(prevState => prevState.filter((value: Offer) => value.id !== id))
    }

    const getOfferCategoriesName = (cat?: ExpressCategoryItem[] | undefined): string => {
        const catName: string[] = cat ? cat.map((value: ExpressCategoryItem) => value.name) : [];
        return catName.join(', ');
    }

    const handleCheckedCategories = (id: string): boolean => {
        const isChecked: boolean = !!selectedCategories?.find((value: ExpressCategoryItem) => value.categoryId === id);
        return isChecked
    }

    const handleRemoveCategoryExclude = (categoryId: string) => {
        const _selectedCategories: ExpressCategoryItem[] = structuredClone(selectedCategories).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
        setSelectedCategories(_selectedCategories);
    }

    const handleChangeCategoryExclude = (checked: boolean, category: CompanyCategory) => {
        let _selectedCategories: ExpressCategoryItem[] = [];

        if (checked) {
            _selectedCategories = [...selectedCategories, { categoryId: category.id, name: category.name }];
        } else {
            selectedCategories?.forEach((value: ExpressCategoryItem) => {
                if (value.categoryId !== category.id) {
                    _selectedCategories.push(value);
                }
            });
        }

        setSelectedCategories(_selectedCategories);
        if (company) {
            if (campaign?.autoOffers) {
                setCompany({ ...company, excludedCategories: _selectedCategories });
            } else {
                setFilters({
                    advertiserIds: [],
                    regionCodes: [],
                    categories: _selectedCategories?.map((i: ExpressCategoryItem) => i.categoryId) || [],
                    excludedCategories: [],
                    brandswopExclusive: 'all',
                    type: "all",
                });
            }
        }
    }

    const openConfirmModal = (id: string) => {
        Modal.confirm({
            title: 'Do you want to remove this offer from the selected offers?',
            content: 'You can add it again from the available offers',
            okText: 'Remove',
            icon: <></>,
            maskClosable: true,
            width: 520,
            closable: true,
            closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-danger'
            },
            onOk: () => handleRemove(id)
        })
    }

    const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSelectedCategories([]);
        setCompany(prevState => (prevState && { ...prevState, excludedCategories: [] }));
        categoriesRef?.current?.focus();
    }

    const checkOfferSelected = (id: string): boolean => {
        const promotionIds = selectedOffers.map((offer: Offer) => offer.id);
        return promotionIds.includes(id)
    }

    const handleBack = () => {
        setCurrentStep(EXPRESS_ONBOARDING.details);
    }

    const handleContinue = () => {
        if (campaign && company) {
            postCampaignExpress(campaign, company.id);
        }
    }

    const postCampaignExpress = async (campaign: ExpressCampaign, id: string) => {
        setLoading(true);
        const { response } = await myAxios({ method: 'POST', url: `onboarding/express/campaign`, data: { company, campaign, onboardingStatus: { completed: false, next: EXPRESS_ONBOARDING.placements }, password: '' } });
        if (response?.data?.status) {
            response?.data?.result?.campaign && setCampaign(response?.data?.result?.campaign);
            message.success({
                content: "Your offers are saved",
                className: 'express-message',
                duration: 1,
            });
            setCurrentStep(EXPRESS_ONBOARDING.placements);
        } else {
            message.error("Failed to continue", 1);
        }
        setLoading(false);
    }

    const getCompanyCategories = async () => {
        const { response } = await myAxios({ method: 'GET', url: 'CompanyCategory' });
        if (response?.data?.status) {
            setCategories(response?.data?.result);
            setFilteredCategories(response?.data?.result);
        } else {
            message.error("Failed to get categories", 1);
        }
    }

    useEffect(() => {
        if (categories) {
            if (searchCategories) {
                const newCategories: CompanyCategory[] = structuredClone(categories);
                setFilteredCategories(newCategories.filter(f => f.name.toLowerCase().includes(searchCategories.toLowerCase())) || []);
            } else {
                setFilteredCategories(categories);
            }
        }
        // eslint-disable-next-line
    }, [searchCategories]);

    useEffect(() => {
        getCompanyCategories();
        document.title = "BrandSwap Express - Onboarding partners";
        setOnboardingStatus(prevState => (prevState && { completed: false, next: EXPRESS_ONBOARDING.placements }))
        // eslint-disable-next-line 
    }, []);

    const handleSwitchAutoOffer = (value: boolean) => {
        setSelectedCategories([]);
        if (campaign && company) {
            const _newCampaign = { ...campaign, autoOffers: value };
            value && setCompany({ ...company, excludedCategories: [] });
            setCampaign(_newCampaign);
        }
    }

    return <>
        <div className="express-layout">
            <div className="widget widget-express">
                <div className="partners-options">
                    <div className="partners-content">
                        <div className="express-onboard-offers">
                            <div className="switch-wrap">
                                <Switch id="autoOffers" size="default" checked={campaign?.autoOffers} onChange={handleSwitchAutoOffer} />
                                <label className="mleft5 font-hover" htmlFor="open-access">

                                </label>
                            </div>
                            <div className="content-wrap">
                                <label htmlFor="autoOffers" className="eontitle">
                                    {campaign?.autoOffers ? <>Automatically selected <i>(Recommended)</i></> : <>Manually selected</>}
                                </label>
                                <div className="eoncontent">
                                    {campaign?.autoOffers ? <> Allow our AI to choose the Ad Partner Deals that will maximise your revenue</> : <> Select the Ad Partners Deals that are aligned with your brand and objectives</>}
                                </div>
                                <Link to="#" id="guide-express-partners-link" className="eonlink">
                                    Learn more about it
                                </Link>
                            </div>
                        </div>
                        {campaign?.autoOffers &&
                            <div className="express-row mtop20">
                                <div className="express-label">
                                    Categories you want to exclude: {selectedCategories.length} <InfoCircleOutlined id="guide-deals-exclude" />
                                </div>
                                <div className="express-input express-dropdown">

                                    <div className="label-input-dropdown">
                                        <Dropdown
                                            dropdownRender={(menu) => {
                                                return <div className="dropdown">
                                                    <div className="dropdown-search">
                                                        <SearchOutlined className="search-icon" />
                                                        <input ref={categoriesRef} type="text" placeholder="Search" className="default-input" value={searchCategories} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCategories(event.target.value)} />
                                                    </div>
                                                    {selectedCategories.length > 0 &&

                                                        <>
                                                            <div className="dropdown-title">Selected Categories:</div>
                                                            <ul className="list-tags">
                                                                {selectedCategories?.map((category: ExpressCategoryItem) => {
                                                                    return <li key={`selected-cat-${category.categoryId}`}>
                                                                        {category.name}
                                                                        <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCategoryExclude(category.categoryId)}></span>

                                                                    </li>
                                                                })}
                                                                <span className="font-xsmall font-hover clear-categories" onClick={handleClearCategories}>Clear all</span>
                                                            </ul>
                                                        </>

                                                    }
                                                    <div className="dropdown-title">Categories found:</div>
                                                    {filteredCategories.length > 0 ?
                                                        <>
                                                            <ul>
                                                                {filteredCategories?.map((category: CompanyCategory) => {
                                                                    return <li key={`details-cat-${category.id}`}>
                                                                        <Checkbox checked={handleCheckedCategories(category.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCategoryExclude(e.target.checked, category)} className="checkbox-active checkbox-express">{category.name}</Checkbox>
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="font-xsmall">No item found</span>
                                                        </>}
                                                </div>
                                            }}
                                            trigger={['click']}>
                                            <Typography.Link>
                                                <div className="label-input-wrapper label-input-select">
                                                    <label htmlFor="categories">
                                                        <Input disabled={true} value={getOfferCategoriesName(selectedCategories)} className="express-disabled" placeholder="Excluded categories" />
                                                        <span className="express-dropdown-icon"></span>
                                                    </label>
                                                </div>
                                            </Typography.Link>
                                        </Dropdown>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                    <div className="partners-icon">
                        <div className="d-icon-holder">
                            <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10.4448L11.1804 16.8998C11.3008 16.9693 11.427 17.0196 11.5555 17.0514V29.5706L0.797389 23.2039C0.303146 22.9114 0 22.3798 0 21.8055V10.4448ZM25.9996 10.2734V21.8057C25.9996 22.38 25.6965 22.9116 25.2022 23.2041L14.4441 29.5708V16.942C14.4703 16.9289 14.4963 16.9149 14.522 16.9001L25.9996 10.2734Z" fill="#B2B7C3" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.351563 7.31518C0.488127 7.14273 0.660497 6.99698 0.861458 6.88994L12.2364 0.831378C12.714 0.577041 13.2867 0.577041 13.7643 0.831378L25.1393 6.88994C25.2942 6.97245 25.4321 7.07797 25.5497 7.20099L13.0783 14.4014C12.9963 14.4487 12.9206 14.5029 12.8517 14.5628C12.7828 14.5029 12.7072 14.4487 12.6252 14.4014L0.351563 7.31518Z" fill="#DCDCDC" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="express-row">
                <div className="express-label">
                    Advertisers you would like to work with (optional): {selectedAdvertisers.length}
                </div>
                <div className="express-input express-dropdown">

                    <div className="label-input-dropdown">
                        <Dropdown
                            dropdownRender={(menu) => {
                                return <div className="dropdown">
                                    <div className="dropdown-search">
                                        <SearchOutlined className="search-icon" />
                                        <input ref={advertisersRef} type="text" className="default-input" value={searchAdvertisers} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchAdvertisers(event.target.value)} />
                                        <span className="close-icon" onClick={handleClearAdvertisers}>
                                            <span className="icon icon-remove"></span>
                                        </span>
                                    </div>

                                    {selectedAdvertisers.length > 0 && <>
                                        <div className="dropdown-title">Selected Categories:</div>
                                        <ul className="list-tags">
                                            {selectedAdvertisers?.map((company: ExpressCompanyItem) => {
                                                return <li key={`selected-cat-${company.companyId}`}>
                                                    {company.name}
                                                    <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveAdvertiser(company.companyId)}></span>

                                                </li>
                                            })}
                                        </ul>
                                    </>}
                                    <div className="dropdown-title">Advertisers found:</div>

                                    {filteredAdvertisers.length > 0 ?
                                        <>
                                            <ul>
                                                {filteredAdvertisers?.map((company: ExpressCompanyItem) => {
                                                    return <li key={`details-cat-${company.companyId}`}>
                                                        <Checkbox checked={handleCheckedAdvertisers(company.companyId)} onChange={(e: CheckboxChangeEvent) => handleChangeAdvertiser(e.target.checked, company)} className="checkbox-active">{company.name}</Checkbox>
                                                    </li>
                                                })}
                                            </ul>
                                        </>
                                        :
                                        <>
                                            <span className="font-xsmall">No item found</span>
                                        </>}
                                </div>
                            }}
                            trigger={['click']}>
                            <Typography.Link>
                                <div className="label-input-wrapper label-input-select">
                                    <label htmlFor="categories">
                                        <Input disabled={true} value={getOfferAdvertisersName(selectedAdvertisers)} className="express-disabled" placeholder="Advertisers you would like" />
                                        <span className="express-dropdown-icon"></span>
                                    </label>
                                </div>
                            </Typography.Link>
                        </Dropdown>
                    </div>
                </div>
            </div> */}

            {!campaign?.autoOffers &&
                <>

                    <div className="table-wrapper table-scoll-wrapper">
                        <div className="table-title">
                            Deals selected: {selectedOffers.length}/3
                        </div>
                        <div className="table-scrollable table-offers">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            Partner
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th>
                                            CPA <InfoCircleOutlined id="guide-selected-deals-cpa" />
                                        </th>
                                        <th>
                                            Status <InfoCircleOutlined id="guide-selected-deals-status" />
                                        </th>
                                        <th>

                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {selectedOffers.map((offer: Offer, index: number) => {
                                        return <tr key={`selected-offers${index}`}>
                                            <td className="td-250">
                                                <span className="flex">
                                                    <img className="table-image table-image-big mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />
                                                    <span className="break-word">
                                                        {offer.advertiser.name}
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="break-word">
                                                <span className="font-color max-4lines">{offer.description}</span>
                                                {/* <Tooltip title={offer.description.length > 50 && selectedTriggers.includes(offer.id) ? offer.description : ''} >
                                                    {offer.description.length > 50 && selectedTriggers.includes(offer.id) &&
                                                        <span className="font-active font-hover" onClick={() => removeSelectedTriggerById(offer.id)}>...</span>
                                                    }
                                                </Tooltip> */}
                                            </td>
                                            <td>
                                                {offer.offerCpa.display}
                                            </td>
                                            <td>
                                                {offer?.displayStatus || ''}
                                            </td>
                                            <td>
                                                <span className="table-actions">
                                                    <span className="table-icon icon-remove-square mleft20" onClick={() => openConfirmModal(offer.id)} ></span>
                                                </span>
                                            </td>
                                        </tr>
                                    })}

                                    {selectedOffers.length === 0 &&
                                        <tr>
                                            <td className="text-center" colSpan={6}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No deals" />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="layout-options">
                        <div className="filters">
                            <div className="filter-dropdown">
                                <Dropdown
                                    dropdownRender={(menu) => {
                                        return <div className="dropdown">
                                            <div className="dropdown-search">
                                                <SearchOutlined className="search-icon" />
                                                <input ref={categoriesRef} type="text" className="default-input" value={searchCategories} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchCategories(event.target.value)} />
                                                <span className="close-icon" onClick={handleClearCategories}>
                                                    <span className="icon icon-remove"></span>
                                                </span>
                                            </div>

                                            {selectedCategories.length > 0 &&

                                                <>
                                                    <div className="dropdown-title">Selected Categories:</div>
                                                    <ul className="list-tags">
                                                        {selectedCategories?.map((category: ExpressCategoryItem) => {
                                                            return <li key={`selected-cat-${category.categoryId}`}>
                                                                {category.name}
                                                                <span className="icon icon-remove mleft10 font-hover" onClick={() => handleRemoveCategoryExclude(category.categoryId)}></span>

                                                            </li>
                                                        })}
                                                    </ul>
                                                </>

                                            }
                                            <div className="dropdown-title">Categories found:</div>
                                            {filteredCategories.length > 0 ?
                                                <>
                                                    <ul>
                                                        {filteredCategories?.map((category: CompanyCategory) => {
                                                            return <li key={`details-cat-${category.id}`}>
                                                                <Checkbox checked={handleCheckedCategories(category.id)} onChange={(e: CheckboxChangeEvent) => handleChangeCategoryExclude(e.target.checked, category)} className="checkbox-active">{category.name}</Checkbox>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </>
                                                :
                                                <>
                                                    <span className="font-xsmall">No item found</span>
                                                </>}
                                        </div>
                                    }}
                                    trigger={['click']}>
                                    <Typography.Link>
                                        <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                                            Category:
                                            <span className="label-count">
                                                {selectedCategories.length === 0 ? 'all' : selectedCategories.length}
                                            </span>
                                        </span>
                                    </Typography.Link>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="views">
                            {/* <span className="filter-icon list active"></span>
                    <span className="filter-icon grid"></span> */}
                        </div>
                    </div>

                    <div className="table-wrapper table-scrollable-wrapper">

                        <Tabs
                            className="express-offers-tabs"
                            moreIcon={false}
                            animated={false}
                            onChange={handleChangeTabs}
                            activeKey={offersTab}
                            items={[
                                {
                                    label: <>Deals available immediately <InfoCircleOutlined id="guide-available-offers" className="mleft10" /></>,
                                    key: 'available',
                                    children: <>

                                        <div className="eoninfo">
                                            Manually select your partner deals
                                        </div>

                                        {/* <div className="table-title">
                                            Offers available: {response?.result?.count || 0}
                                        </div> */}
                                        <div className="table-scrollable">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Partner
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                        <th>
                                                            CPA <InfoCircleOutlined id="guide-approved-deals-cpa" />
                                                        </th>
                                                        <th>
                                                            Status <InfoCircleOutlined id="guide-approved-deals-status" />
                                                        </th>
                                                        <th>

                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {offers.map((offer: Offer, index: number) => {
                                                        return <tr key={`offer-${index}`}>
                                                            <td className="td-250">
                                                                <span className="flex">
                                                                    <img className="table-image table-image-big mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />
                                                                    <span className="break-word">
                                                                        {offer.advertiser.name}
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td className="break-word">
                                                                {/* <Tooltip title={offer.description.length > 50 && triggers.includes(offer.id) ? offer.description : ''} >
                                                                    <span className="font-color">{triggers.includes(offer.id) ? sliceText(offer.description) : offer.description}</span>
                                                                    {offer.description.length > 50 && triggers.includes(offer.id) &&
                                                                        <span className="font-active font-hover" onClick={() => removeTriggerById(offer.id)}>...</span>
                                                                    }
                                                                </Tooltip> */}
                                                                <span className="font-color max-4lines">{offer.description}</span>
                                                            </td>
                                                            <td>
                                                                {offer.offerCpa.display}
                                                            </td>

                                                            <td>
                                                                {offer?.displayStatus || ''}
                                                            </td>
                                                            <td>
                                                                <span className="table-actions">
                                                                    {checkOfferSelected(offer.id) ? (
                                                                        <span className="label label-select active table-label" >Selected</span>
                                                                    ) : (
                                                                        <span className={`label label-select table-label ${selectedOffers.length === 3 ? 'disabled' : ''}`} onClick={() => handleSelect(offer)}>Select</span>
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    {loading ?
                                                        <tr className={offers.length > 0 ? 'loader-absolute' : ''}>
                                                            <td className="text-center loader-td" colSpan={6}>
                                                                <LoadingOutlined />
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                            {offers.length === 0 &&
                                                                <tr>
                                                                    <td className="text-center" colSpan={6}>
                                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No deals" />
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                },
                                {
                                    label: <>Deals that need partner approval <InfoCircleOutlined id="guide-needaproval-offers" className="mleft10" /></>,
                                    key: 'needapprove',
                                    children: <>


                                        <div className="eoninfo">
                                            Manually select your partner deals
                                        </div>

                                        <div className="table-scrollable">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Partner
                                                        </th>
                                                        <th>
                                                            Description
                                                        </th>
                                                        <th>
                                                            CPA <InfoCircleOutlined id="guide-needaproval-cpa" />
                                                        </th>
                                                        <th>
                                                            Status <InfoCircleOutlined id="guide-needaproval-status" />
                                                        </th>
                                                        <th>

                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {offers.map((offer: Offer, index: number) => {
                                                        return <tr key={`offer-${index}`}>
                                                            <td className="td-250">
                                                                <span className="flex">
                                                                    <img className="table-image table-image-big mright10" src={offer.imageUrl ? offer.imageUrl : defaultOfferImg} alt="" />
                                                                    <span className="break-word">
                                                                        {offer.advertiser.name}
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td className="break-word">
                                                                {/* <Tooltip title={offer.description.length > 50 && triggers.includes(offer.id) ? offer.description : ''} >
                                                                    <span className="font-color">{triggers.includes(offer.id) ? sliceText(offer.description) : offer.description}</span>
                                                                    {offer.description.length > 50 && triggers.includes(offer.id) &&
                                                                        <span className="font-active font-hover" onClick={() => removeTriggerById(offer.id)}>...</span>
                                                                    }
                                                                </Tooltip> */}
                                                                <span className="font-color max-4lines">{offer.description}</span>
                                                            </td>
                                                            <td>
                                                                {offer.offerCpa.display}
                                                            </td>

                                                            <td>
                                                                {offer?.displayStatus || ''}
                                                            </td>
                                                            <td>
                                                                <span className="table-actions">
                                                                    {checkOfferSelected(offer.id) ? (
                                                                        <span className="label label-select active table-label" >Selected</span>
                                                                    ) : (
                                                                        <span className={`label label-select table-label ${selectedOffers.length === 3 ? 'disabled' : ''}`} onClick={() => handleSelect(offer)}>Select</span>
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    {loading ?
                                                        <tr className={offers.length > 0 ? 'loader-absolute' : ''}>
                                                            <td className="text-center loader-td" colSpan={6}>
                                                                <LoadingOutlined />
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                            {offers.length === 0 &&
                                                                <tr>
                                                                    <td className="text-center" colSpan={6}>
                                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No offers" />
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </>
                                },
                            ]}
                        >

                        </Tabs>
                    </div>
                    <div className="pagination">

                        <div className="pagination-prev" onClick={() => pagination.page > 1 && setPagination(prevState => ({ ...prevState, page: prevState.page - 1 }))}>
                            <span className="pagination-box"></span>
                            <span className="pagination-label">Preview</span>
                        </div>

                        <ul>
                            <>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page: number) => {
                                    if ((page < pagination.page + 3 && page > pagination.page - 3) || page === 1 || page === totalPages) {
                                        return <React.Fragment key={page}> {page > 2 && page + 2 <= pagination.page && <span className="icon icon-pagination"></span>}
                                            <li onClick={() => page !== pagination.page && setPagination(prevState => ({ ...prevState, page }))} className={page === pagination.page ? 'active' : ''}>
                                                <span className="pagination-box">{page}</span>
                                            </li>
                                            {page + 1 < totalPages && page - 2 >= pagination.page && <span className="icon icon-pagination"></span>}
                                        </React.Fragment>
                                    }
                                    return <React.Fragment key={`rf${page}`}></React.Fragment>
                                })}
                            </>
                        </ul>

                        <div className="pagination-next" onClick={() => pagination.page < totalPages && setPagination(prevState => ({ ...prevState, page: prevState.page + 1 }))}>
                            <span className="pagination-label">Next</span>
                            <span className="pagination-box"></span>
                        </div>

                    </div>
                </>
            }
            <div className="express-button">
                <Button className="express-button-back mright10" onClick={handleBack} type="default">Back</Button>
                <Button className="express-button-success" loading={loading} disabled={!campaign?.autoOffers && campaign?.offers.length === 0} type="default" onClick={handleContinue}>Continue</Button>
            </div>
        </div>
    </>;
}

export default ExpressOnboardingPartners;
