import { SearchOutlined } from "@ant-design/icons";
import {
  Checkbox,
  DatePicker,
  Dropdown,
  Radio,
  RadioChangeEvent,
  Select,
  Switch,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import {
  Dispatch,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CompanyCategory,
  CompanyEmailTemplate,
  CompanyNetwork,
} from "../../../../types/company.interfaces";
import { SelectOption } from "../../../../types/global.interfaces";
import { OfferCategory, OfferCreate } from "../../../../types/offer.interfaces";
import { stringDateToMoment } from "../../../../utility/date";
import { DelayType, RedeemBy } from "../../../../utility/enums/offer.enums";

interface Props {
  offer: OfferCreate;
  setOffer: Dispatch<React.SetStateAction<OfferCreate>>;
  categories: CompanyCategory[] | undefined;
  networks: CompanyNetwork[];
  emailTemplates: CompanyEmailTemplate[];
}

const CreateOfferSettings = ({
  offer,
  setOffer,
  categories,
  networks,
  emailTemplates,
}: Props) => {
  const [filteredCategories, setCategories] = useState<CompanyCategory[]>([]);
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<OfferCategory[]>(
    []
  );
  const categoriesRef = useRef<HTMLInputElement>(null);

  const getOfferCategoriesName = (categories?: OfferCategory[]): string => {
    const catName: string[] = categories
      ? categories.map((value: OfferCategory) => value.name)
      : [];
    return catName.join(", ");
  };

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchCategories.toLowerCase())
          ) || []
        );
      } else {
        setCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);

  useEffect(() => {
    if (categories) {
      setCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    setSelectedCategories(offer.categories);
  }, [offer.categories]);

  const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSearchCategories("");
    categoriesRef?.current?.focus();
  };

  const handleChangeCategory = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: OfferCategory[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedCategories,
        { category_id: category.id, name: category.name },
      ];
    } else {
      selectedCategories?.forEach((value: OfferCategory) => {
        if (value.category_id !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
    setOffer(
      (prevState: OfferCreate) =>
        prevState && { ...prevState, categories: _selectedCategories }
    );
  };

  const handleRemoveCategory = (categoryId: string) => {
    const _selectedCategories: OfferCategory[] = structuredClone(
      selectedCategories
    ).filter((f: OfferCategory) => f.category_id !== categoryId);
    setSelectedCategories(_selectedCategories);
    setOffer(
      (prevState: OfferCreate) =>
        prevState && { ...prevState, categories: _selectedCategories }
    );
  };

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!offer?.categories.find(
      (value: OfferCategory) => value.category_id === id
    );
    return isChecked;
  };

  const disabledEndDate: RangePickerProps["disabledDate"] = (
    current: moment.Moment
  ) => {
    // To recheck
    // const _startDate = startDate ? stringDateToMoment(startDate).add(-1, 'day') : stringDateToMoment(startDate);
    // Can not select date before today and before start date
    return (
      current &&
      (current < stringDateToMoment(offer.startDate || "") ||
        current < moment().add(-1, "day"))
    );
  };

  const typeList: SelectOption[] = [
    {
      label: "Percentage",
      value: "percentage",
    },
    {
      label: "Number",
      value: "number",
    },
  ];

  const networksList: SelectOption[] = useMemo(() => {
    const _list = networks.map((value: CompanyNetwork) => {
      return {
        label: value.name || value.uniqueIdentifier,
        value: value.id,
      };
    });
    return _list;
  }, [networks]);

  const emailTemplateList: SelectOption[] = useMemo(() => {
    const _list = emailTemplates.map((value: CompanyEmailTemplate) => {
      return {
        label: value.emailTemplateName,
        value: value.emailTemplateId,
      };
    });
    _list.unshift({ label: "Brandswap email", value: "" });
    return _list;
  }, [emailTemplates]);

  const reedemByTypes: SelectOption[] = [
    {
      label: 'Web & Email',
      value: RedeemBy.webemail
    },
    {
      label: 'Email Only',
      value: RedeemBy.email
    },
  ];

  const delayTypes: SelectOption[] = [
    {
      label: 'None',
      value: DelayType.none
    },
    {
      label: 'Minutes',
      value: DelayType.minutes
    },
    {
      label: 'Days',
      value: DelayType.days
    },
    {
      label: 'Months',
      value: DelayType.months
    }
  ];

  const handleChangeRedeemBy = (value: RedeemBy) => {
    setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, redeemBy: value } }))
  }

  const handleChangeDelayType = (value: DelayType) => {
    setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, delayType: value } } }))
  }

  return (
    <>
      <div className="widget widget-icon mbot20">
        <div className="widget-header">
          <div className="grow">
            <h3 className="widget-s-title">Offer settings</h3>

          </div>
        </div>

        <div className="widget-content-two">
          <div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Categories *</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={categoriesRef}
                            type="text"
                            id="categories"
                            className="default-input"
                            value={searchCategories}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchCategories(event.target.value)}
                          />
                          <span
                            className="close-icon"
                            onClick={handleClearCategories}
                          >
                            <span className="icon icon-remove"></span>
                          </span>
                        </div>

                        {selectedCategories.length > 0 && (
                          <>
                            <div className="dropdown-title">
                              Selected Categories:
                            </div>
                            <ul className="list-tags">
                              {selectedCategories?.map(
                                (category: OfferCategory) => {
                                  return (
                                    <li
                                      key={`selected-cat-${category.category_id}`}
                                    >
                                      {category.name}
                                      <span
                                        className="icon icon-remove mleft10 font-hover"
                                        onClick={() =>
                                          handleRemoveCategory(category.category_id)
                                        }
                                      ></span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        )}
                        <div className="dropdown-title">Categories found:</div>
                        <ul>
                          {filteredCategories?.map((category: CompanyCategory) => {
                            return (
                              <li key={`details-cat-${category.id}`}>
                                <Checkbox
                                  checked={handleCheckedCategories(category.id)}
                                  onChange={(e: CheckboxChangeEvent) =>
                                    handleChangeCategory(e.target.checked, category)
                                  }
                                  className="checkbox-active"
                                >
                                  {category.name}
                                </Checkbox>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  }}
                  autoFocus={true}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="select">
                      <label htmlFor="categories" tabIndex={0}>
                        <span className="icon icon-arrow-down"></span>
                        <input
                          disabled
                          value={getOfferCategoriesName(offer?.categories)}
                          type="text"
                          className="input-select"
                        />
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>

            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Business type</span>
              </div>

              <div className="mbot20">
                <Radio.Group onChange={(e: RadioChangeEvent) => {
                  console.log(e.target.value);
                  setOffer((prevState: OfferCreate) => ({
                    ...prevState,
                    offerSettings: {
                      ...offer.offerSettings,
                      isB2C: e.target.value === 'B2C',
                    },
                  }));
                }} value={offer.offerSettings.isB2C ? 'B2C' : 'B2B'}>
                  <Radio value={'B2B'}>B2B</Radio>
                  <Radio value={'B2C'}>B2C</Radio>
                </Radio.Group>
              </div>
            </div>

          </div>

          <div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Affiliate network</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <Select
                  suffixIcon={<span className="icon icon-arrow-down"></span>}
                  className="label-select-normal"
                  value={offer.advertiser.affNetwork_Id || networksList[0]?.value}
                  onChange={(id: string) => {
                    setOffer((prevState: OfferCreate) => ({
                      ...prevState,
                      advertiser: { ...prevState.advertiser, affNetwork_Id: id },
                    }));
                  }}
                  options={networksList}
                ></Select>
              </div>
            </div>

            <div className="multiple-label-input">
              <div className="widget-form-row grow mright10">
                <div className="unauth-label">
                  <span>CPA type</span>
                  <span className="unauth-label-error">
                  </span>
                </div>
                <div className="widget-input">
                  <Select
                    suffixIcon={<span className="icon icon-arrow-down"></span>}
                    className="label-select-normal"
                    value={offer.cpa.valueType}
                    onChange={(valueType: string) => {
                      setOffer(
                        (prevState: OfferCreate) =>
                          prevState && {
                            ...prevState,
                            cpa: { ...prevState.cpa, valueType },
                          }
                      );
                    }}
                    options={typeList}
                  ></Select>
                </div>
              </div>
              <div className="widget-form-row">
                <div className="unauth-label">
                  <span>Value</span>
                  <span className="unauth-label-error">
                  </span>
                </div>
                <div className="widget-input">
                  <input
                    type="number"
                    tabIndex={0}
                    className="label-input"
                    step="0.01"
                    value={offer.cpa.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setOffer(
                        (prevState: OfferCreate) =>
                          prevState && {
                            ...prevState,
                            cpa: { ...prevState.cpa, value: e.target.value },
                          }
                      )
                    }
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="widget widget-icon mbot20">
        <div className="widget-header">
          <div className="grow">
            <h3 className="widget-s-title">Sharing settings & Scheduling</h3>

          </div>
        </div>

        <div className="widget-content-two">
          <div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Email template</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <Select
                  suffixIcon={<span className="icon icon-arrow-down"></span>}
                  className="label-select-normal"
                  value={offer.advertiser.emailTemplateId}
                  onChange={(id: string) => {
                    setOffer((prevState: OfferCreate) => ({
                      ...prevState,
                      advertiser: {
                        ...prevState.advertiser,
                        emailTemplateId: id,
                      },
                    }));
                  }}
                  options={emailTemplateList}
                ></Select>
              </div>
            </div>

            <div className="widget-form-row">
              <label htmlFor="allowshare" className="label-bordered flex-space-between">
                <span>Allow sharing</span>

                <Switch
                  id="allowshare"
                  className="displayswitch"
                  checked={offer.offerSettings.allowShare}
                  onChange={(checked: boolean) => {
                    setOffer((prevState: OfferCreate) => ({
                      ...prevState,
                      offerSettings: {
                        ...offer.offerSettings,
                        allowShare: checked,
                      },
                    }));
                  }}
                />
              </label>
            </div>
          </div>

          <div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Start date *</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <DatePicker
                  value={
                    offer.startDate
                      ? stringDateToMoment(offer.startDate)
                      : undefined
                  }
                  onChange={(value: moment.Moment | null) => {
                    setOffer((prevState: OfferCreate) => ({
                      ...prevState,
                      startDate: value?.format("YYYY-MM-DD") || "",
                    }));
                  }}
                  onFocus={() => { }}
                  onBlur={() => { }}
                  placeholder=""
                  showTime={false}
                  format="DD-MM-YYYY"
                />
              </div>
            </div>

            <div className="widget-form-row">
              <div className="unauth-label">
                <span>End date *</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <DatePicker
                  value={
                    offer.endDate
                      ? stringDateToMoment(offer.endDate)
                      : undefined
                  }
                  disabledDate={disabledEndDate}
                  onChange={(value: moment.Moment | null) => {
                    setOffer((prevState: OfferCreate) => ({
                      ...prevState,
                      endDate: value?.format("YYYY-MM-DD") || "",
                    }));
                  }}
                  onFocus={() => { }}
                  onBlur={() => { }}
                  placeholder=""
                  showTime={false}
                  format="DD-MM-YYYY"
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="widget widget-icon mbot20">
        <div className="widget-header">
          <div className="grow">
            <h3 className="widget-s-title">Bundle offer settings</h3>

          </div>
        </div>

        <div className="widget-form-row" style={{ marginTop: 24 }}>
          <div className="unauth-label">
            <span>Redeem by</span>
          </div>
          <div className="widget-input" style={{ marginBottom: 0 }}>
            <Select
              suffixIcon={<span className="icon icon-arrow-down"></span>}
              className="label-select-normal"
              value={offer?.offerSettings?.redeemBy || 'Webmail'}
              onChange={handleChangeRedeemBy}
              options={reedemByTypes}
            >
            </Select>
          </div>
        </div>

        <div className="widget-content-two">


          <div>
            <div className="widget-form-row">
              <div className="unauth-label">
                <span>Email template to inform for the delay</span>
                <span className="unauth-label-error">
                </span>
              </div>
              <div className="widget-input">
                <Select
                  suffixIcon={<span className="icon icon-arrow-down"></span>}
                  className="label-select-normal"
                  value={offer?.offerSettings?.delaySetting?.emailTemplateId || ''}
                  onChange={(id: string) => {
                    setOffer(prevState => ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, emailTemplateId: id } } }))
                  }}
                  options={emailTemplateList}
                >
                </Select>
              </div>
            </div>
          </div>

          <div>
            {offer.offerSettings?.redeemBy === RedeemBy.email &&
              <div className="multiple-label-input">
                <div className="widget-form-row grow mright10">
                  <div className="unauth-label">
                    <span>Delay</span>
                    <span className="unauth-label-error">
                    </span>
                  </div>
                  <div className="widget-input">
                    <Select
                      suffixIcon={<span className="icon icon-arrow-down"></span>}
                      className="label-select-normal"
                      value={offer?.offerSettings?.delaySetting?.delayType || 'None'}
                      onChange={handleChangeDelayType}
                      options={delayTypes}
                    >
                    </Select>
                  </div>
                </div>
                {offer.offerSettings?.delaySetting?.delayType !== DelayType.none &&
                  <div className="widget-form-row">
                    <div className="unauth-label">
                      <span>Value</span>
                      <span className="unauth-label-error">
                      </span>
                    </div>
                    <div className="widget-input">
                      <input type="number" min="0" className="label-input" step="1" value={offer.offerSettings?.delaySetting?.delayValue || 0} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOffer(prevState => prevState && ({ ...prevState, offerSettings: { ...prevState.offerSettings, delaySetting: { ...prevState.offerSettings.delaySetting, delayValue: (e.target.value).toString() } } }))} />
                      {offer.offerSettings?.redeemBy === RedeemBy.email && offer.offerSettings?.delaySetting?.delayValue === '0' &&
                        <span className="label-error">Must be greater than 0</span>
                      }
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>

      </div>
    </>
  );
};

export default CreateOfferSettings;
