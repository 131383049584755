import "./css/style.scss";
import { ButtonShapes } from "../../../../../../templates-previews/types/params.interfaces";
import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../types/campaign.interfaces";
import SwiperCore from "swiper";
import { Navigation, Controller } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperComponent from "../swiper/Swiper.component";
import phoneSrc from "./../../../../../../../assets/images/phone.jpg";
import { useEffect, useRef, useState } from "react";

SwiperCore.use([Navigation, Controller]);

interface Props {
  description: string;
  settings: TemplateOptionSettings | undefined;
  campaign: CampaignType;
  displayOnly: boolean;
  buttonShape: ButtonShapes;
}

const PreviewNewLookHorizontal = ({
  description,
  settings,
  campaign,
  displayOnly,
  buttonShape,
}: Props) => {
  const swiperParentRef = useRef<HTMLDivElement>(null);
  const [swiperWidth, setSwiperWidth] = useState<number | undefined>();

  const getElementWidth = (): number => {
    let width: number = 100;
    const elm: HTMLElement | null = document.getElementById("interaction-view");
    if (elm) {
      width = elm.offsetWidth - 170;
    }
    return width;
  };

  useEffect(() => {
    if (settings?.embeddedElement?.fullWidth) {
      setSwiperWidth(getElementWidth());
    } else {
      const width = Number(settings?.embeddedElement?.width) - 70;
      setSwiperWidth(width);
    }
    // eslint-disable-next-line
  }, [settings?.embeddedElement?.width, settings?.embeddedElement?.fullWidth]);

  useEffect(() => {
    setSwiperWidth(getElementWidth());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="adv-body">
        <div className="adv-page">
          <div className="adv-content">
            <div className="adv-embedd-wrapper">
              <div
                className={`inline-nlh-wrapper ${settings?.mainLayout?.align}`}
                style={{
                  width: settings?.embeddedElement?.fullWidth
                    ? "100%"
                    : settings?.embeddedElement?.width,
                  padding: `0 ${settings?.embeddedElement?.margin || "0"}${
                    settings?.embeddedElement?.marginType || "%"
                  }`,
                }}
              >
                <div
                  className={`inline-nlh-popup`}
                  style={{
                    backgroundColor: settings?.mainLayout.backgroundColor,
                    fontFamily: settings?.mainLayout.fontFamily,
                    color: settings?.mainLayout.textColor,
                    height: `${settings?.embeddedElement?.height}px`,
                  }}
                >
                  <div className={`inline-nlh-title`}>
                    {buttonShape === ButtonShapes.squareImage && (
                      <>
                        <div className="inline-nlh-title-icon">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                              stroke={settings?.mainLayout.headerTextColor}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </>
                    )}

                    {buttonShape === ButtonShapes.squareImageLock && (
                      <>
                        <div className="inline-nlh-title-icon">
                          {displayOnly ? (
                            <svg
                              width="42"
                              height="47"
                              viewBox="0 0 42 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                                stroke={settings?.mainLayout.headerTextColor}
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="42"
                              height="47"
                              viewBox="0 0 42 47"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                                stroke={settings?.mainLayout.headerTextColor}
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </>
                    )}

                    <span
                      className={`inline-nlh-title-label`}
                      style={{
                        fontSize: settings?.mainLayout.headerFontSize + "px",
                        fontWeight: settings?.mainLayout.headerFontWeight,
                        color: settings?.mainLayout.headerTextColor,
                      }}
                    >
                      {settings?.mainLayout.headerText || ""}
                    </span>
                  </div>

                  <div
                    className={`inline-nlh-description`}
                    style={{
                      fontSize: settings?.mainLayout.subHeaderFontSize + "px",
                      fontWeight: settings?.mainLayout.subHeaderFontWeight,
                      color: settings?.mainLayout.subHeaderTextColor,
                    }}
                  >
                    {settings?.mainLayout.subHeaderText || ""}
                  </div>

                  <div className={`inline-nlh-swiper`} ref={swiperParentRef}>
                    {campaign?.offers.length > 0 && settings && swiperWidth && (
                      <SwiperComponent
                        width={swiperWidth}
                        campaign={campaign}
                        settings={settings}
                        displayOnly={displayOnly}
                      ></SwiperComponent>
                    )}
                  </div>

                  <div className="inline-nlh-footer">
                    {displayOnly ? (
                      <>
                        {campaign?.generalOptions.emailCaptureOnOffers && (
                          <>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                r="12"
                                fill={settings?.dealLayout.backgroundColor}
                              />
                              <path
                                d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125"
                                stroke={settings?.dealLayout.textColor}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <span>Pick your gifts and receive by e-mail</span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                            fill={settings?.mainLayout.textColor}
                          />
                        </svg>
                        <span>
                          By selecting the option above you will be directed to
                          a third party website. Any use of third party websites
                          are not subject to our Privacy Policy, and we
                          encourage you to also read their privacy statements
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <TemplateInline layoutView={layoutView} description={description} settings={settings} campaign={campaign} displayOnly={displayOnly || false} /> */}
            </div>

            <div className="adv-product-row">
              <div className="adv-product-image">
                <img src={phoneSrc} alt="Phone" />
              </div>
              <div className="adv-product-content">
                <span className="adv-pre-content large"></span>
                <span className="adv-pre-content"></span>
                <span className="adv-pre-content"></span>
              </div>
              <div className="adv-product-actions">
                <span className="adv-pre-action"></span>
                <span className="adv-pre-action"></span>
              </div>
            </div>

            <div className="adv-shopping-buttons">
              <div className="adv-buttons">
                <span className="adv-success-button">Next</span>
                <span className="adv-default-button">Back to shop</span>
              </div>
              <div className="adv-shopping-actions">
                <span className="adv-pre-action"></span>
                <span className="adv-pre-action"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewNewLookHorizontal;
