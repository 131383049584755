import { useEffect, useRef, useState } from "react";
import { EMAIL_REGEX, NO_SPACE_EMPTY, PHONE_REGEX, URL_REGEX } from "../../../../utility/regexp";
import { OnboardingSteps } from "../../interfaces";
import failIcon from "../../../../assets/images/icons/fail.svg";
import successIcon from "../../../../assets/images/icons/success.svg";
import { ONBOARDING } from "../../const/onboarding.enum";
import { OnboardingCompnay } from "../../../../types/onboarding.interfaces";
import { VALIDATION } from "../../../../utility/enums/validation.enums";
import { UserLoginResponse } from "../../../../types/user.interfaces";
import { Button } from "antd";
import useAxios from "../../../../hooks/useAxios.hook";
import { ApiErrorMessages } from "../../../../types/response.interfaces";

export interface Props {
    changeStep: React.Dispatch<React.SetStateAction<OnboardingSteps>>
    setCompany: React.Dispatch<React.SetStateAction<OnboardingCompnay | undefined>>,
    user: Partial<UserLoginResponse>,
    company: OnboardingCompnay,
}

const StepCompanyDetails = ({ changeStep, setCompany, user, company }: Props) => {
    const userRef = useRef<HTMLInputElement>(null);
    const { response, loading, axiosFetch } = useAxios();
    const [companyName, setCompanyName] = useState<string>(company?.name || "");
    const [errorMessage, setErrorMessage] = useState<undefined | string>();
    const [validCompanyName, setValidCompanyName] = useState<boolean>(false);

    const [website, setWebsite] = useState<string>(company?.website || "");
    const [validWebsite, setValidWebsite] = useState<boolean>(false);

    const [email, setEmail] = useState<string>(user.email || "");
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const emailRef = useRef<HTMLInputElement>(null);

    const [phoneNumber, setPhoneNumber] = useState<string>(company?.phone || "");
    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);

    const [address, setAddress] = useState<string>(company?.address || "");
    const [validAddress, setValidAddress] = useState<boolean>(false);

    // Set focus to company name
    useEffect(() => {
        if (userRef.current) userRef.current.focus();
    }, []);

   // Clear error message on any input change
    useEffect(() => {
        setErrorMessage('')
    }, [companyName, website, email, address, phoneNumber])

    // Validate company name
    useEffect(() => {
        const isValid = NO_SPACE_EMPTY.test(companyName);
        setValidCompanyName(isValid);
    }, [companyName]);

    // Validate website
    useEffect(() => {
        const isValid = URL_REGEX.test(website);
        setValidWebsite(isValid);
    }, [website]);

    // Validate email
    useEffect(() => {
        const isValid = EMAIL_REGEX.test(email);
        setValidEmail(isValid);
    }, [email]);

    // Validate phone number
    useEffect(() => {
        const isValid = PHONE_REGEX.test(phoneNumber);
        setValidPhoneNumber(isValid);
    }, [phoneNumber]);

    // Validate address
    useEffect(() => {
        setValidAddress(address.length > 3);
    }, [address]);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (validCompanyName && validWebsite && validEmail && validEmail && validPhoneNumber && validAddress) {

            axiosFetch({ method: 'post', url: '/onboarding/validatecompany', data: { email: email } });

        }

    }

    // Handel response of onboarding company post
    useEffect(() => {
        if (response?.status) {
            setCompany(prevState => ({
                ...prevState,
                name: companyName,
                website: website,
                email: email,
                phone: phoneNumber,
                address: address,
            }))
            changeStep({ currentStep: ONBOARDING.affiliate });
        } else {
            response?.result?.length > 0 && response?.result.forEach((value: ApiErrorMessages) => {
                if (value.errorCode === 2100) {
                    setValidEmail(false);
                    emailRef?.current?.focus();
                }

                setErrorMessage(value.message);
            });
        }
        // eslint-disable-next-line 
    }, [response])

    return <>
        <div className="widget unauth-widget">
            <form className="widget-form large-form user-details">
                <h3 className="widget-form-title">Enter company details, please</h3>
                <div className="widget-form-description">
                    <span className=" font-small">
                        All fields are required
                    </span>
                </div>

                <div className="widget-form-row">
                    <div className="unauth-label">
                        <span>Company name</span>
                        {companyName.length > 0 && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={validCompanyName ? successIcon : failIcon}
                                        alt={validCompanyName ? "success" : "fail"}
                                    />
                                </span>
                                {/* {!validCompanyName && companyNameFocus && (
                                    <span id="nide">Must be 2 to 24 characters.</span>
                                )} */}
                            </span>
                        )}
                    </div>
                    <div
                        className={`
          widget-input ${companyName && !validCompanyName ? "widget-input-invalid" : ""
                            }`}
                    >
                        <input
                            type="text"
                            id="company-name"
                            ref={userRef}
                            name="company-name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setCompanyName(e.target.value)
                            }
                            value={companyName}
                            aria-invalid={validCompanyName ? "true" : "false"}
                            aria-describedby="cnide"
                            autoComplete="off"
                        />

                        <p
                            id="cnide"
                            className={`error-line ${companyName && !validCompanyName
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.notValid}
                        </p>
                    </div>
                </div>
                <div className="widget-form-row">
                    <div className="unauth-label">
                        <span>Website</span>
                        {website.length > 0 && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={validWebsite ? successIcon : failIcon}
                                        alt={validWebsite ? "success" : "fail"}
                                    />
                                </span>
                                {/* {!validWebsite && websiteFocus && (
                                    <span id="nide">Is requeired.</span>
                                )} */}
                            </span>
                        )}
                    </div>
                    <div
                        className={`
          widget-input ${website && !validWebsite ? "widget-input-invalid" : ""
                            }`}
                    >
                        <input
                            type="text"
                            id="website"
                            name="website"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setWebsite(e.target.value)
                            }
                            value={website}
                            aria-invalid={validWebsite ? "true" : "false"}
                            aria-describedby="wide"
                            autoComplete="off"
                        />

                        <p
                            id="wide"
                            className={`error-line ${website && !validWebsite
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            Must be a valid url!
                        </p>
                    </div>
                </div>
                <div className="widget-multiple-form-row">

                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Email</span>
                            {email.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validEmail ? successIcon : failIcon}
                                            alt={validEmail ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validEmail && emailFocus && (
                                        <span id="nide">Is not a valid email.</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${email && !validEmail ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="text"
                                id="Email"
                                name="Email"
                                value={email}
                                ref={emailRef}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setEmail(e.target.value)
                                }

                                aria-invalid={validEmail ? "true" : "false"}
                                aria-describedby="eide"
                                autoComplete="off"
                            />
                            <p
                                id="eide"
                                className={`error-line ${email && !validEmail && !errorMessage
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                Must be a valid email!
                            </p>
                        </div>
                    </div>
                    <div className="widget-form-row">
                        <div className="unauth-label">
                            <span>Phone number</span>
                            {phoneNumber.length > 0 && (
                                <span className="unauth-label-error">
                                    <span>
                                        <img
                                            src={validPhoneNumber ? successIcon : failIcon}
                                            alt={validPhoneNumber ? "success" : "fail"}
                                        />
                                    </span>
                                    {/* {!validPhoneNumber && phoneNumberFocus && (
                                        <span id="nide">Is requeired.</span>
                                    )} */}
                                </span>
                            )}
                        </div>
                        <div
                            className={`
          widget-input ${phoneNumber && !validPhoneNumber ? "widget-input-invalid" : ""
                                }`}
                        >
                            <input
                                type="text"
                                id="phone-number"
                                name="phone-number"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPhoneNumber(e.target.value)
                                }
                                value={phoneNumber}
                                aria-invalid={validPhoneNumber ? "true" : "false"}
                                aria-describedby="phide"
                                autoComplete="off"
                            />
                            <p
                                id="phide"
                                className={`error-line ${phoneNumber && !validPhoneNumber
                                    ? "error-show"
                                    : "error-hide"
                                    }`}
                            >
                                {VALIDATION.validPhone}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="widget-form-row">
                    <div className="unauth-label">
                        <span>Registered business address</span>
                        {address.length > 0 && (
                            <span className="unauth-label-error">
                                <span>
                                    <img
                                        src={validAddress ? successIcon : failIcon}
                                        alt={validAddress ? "success" : "fail"}
                                    />
                                </span>
                                {/* {!validAddress && addressFocus && (
                                    <span id="nide">Is requeired.</span>
                                )} */}
                            </span>
                        )}
                    </div>
                    <div
                        className={`
          widget-input ${address && !validAddress ? "widget-input-invalid" : ""
                            }`}
                    >
                        <input
                            type="text"
                            id="address"
                            name="address"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setAddress(e.target.value)
                            }
                            value={address}
                            aria-invalid={validAddress ? "true" : "false"}
                            aria-describedby="aide"
                            autoComplete="off"
                        />
                        <p
                            id="aide"
                            className={`error-line ${address && !validAddress
                                ? "error-show"
                                : "error-hide"
                                }`}
                        >
                            {VALIDATION.fieldTooShort}
                        </p>
                    </div>
                </div>

                {errorMessage &&
                    <div className="widget-form-error">
                        <p className="error-show error-line">
                            {errorMessage}
                        </p>
                    </div>
                }

                <div className="widget-form-actions unauth-button">
                    <div className="widget-form-button">
                        <Button
                            type="default"
                            className="button-success"
                            onClick={handleSubmit}
                            htmlType="submit"
                            loading={loading}
                            disabled={
                                !validCompanyName ||
                                !validWebsite ||
                                !validEmail ||
                                !validEmail ||
                                !validPhoneNumber ||
                                !validAddress
                            }
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    </>
}

export default StepCompanyDetails