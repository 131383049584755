import { Dropdown, Slider, Typography } from "antd";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import { ButtonShowMore, showMoreButtonSettings } from "../../../../../../types/campaign.interfaces";
import { getNumberFromFontWeight, itemsFontWeight, uniqueKey } from "../../../../const/layout-options.enum";

interface Props {
    updateShowMoreSettings: (value: ButtonShowMore) => void,
    buttonShowMore: ButtonShowMore | undefined,
}

const ShowMoreSettingsComponent = ({ updateShowMoreSettings, buttonShowMore }: Props) => {

    const setNewSettings = (key: keyof ButtonShowMore, value: string | number) => {
        const _buttonShowMore: ButtonShowMore = buttonShowMore ? buttonShowMore : showMoreButtonSettings;

        const newSettings: ButtonShowMore = {
            ..._buttonShowMore,
            [key]: value
        }

        updateShowMoreSettings(newSettings);
    }


    const handleBackgroundChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('backgroundColor', color);
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('textColor', color);
    }

    const handleBorderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const color = event.target.value;
        setNewSettings('borderColor', color);
    }

    const handleChangeFontWeight = (props: DropdownMenuProps) => {
        setNewSettings('fontWeight', props.key);
    }

    const handleChangeRounding = (value: number) => {
        setNewSettings('rounding', value);
    }


    return <>
        <div className="option-box">

            <div className="option-two-col">
                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`dealBg${uniqueKey}`}
                        value={buttonShowMore?.backgroundColor || '#383838'}
                        onChange={handleBackgroundChange}
                    />
                    <label htmlFor={`dealBg${uniqueKey}`}>
                        Background: {buttonShowMore?.backgroundColor}
                    </label>
                </div>

                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`showMoreText${uniqueKey}`}
                        value={buttonShowMore?.textColor || '#ffffff'}
                        onChange={handleTextChange}
                    />
                    <label htmlFor={`showMoreText${uniqueKey}`}>
                        Text: {buttonShowMore?.textColor || '#ffffff'}
                    </label>
                </div>

            </div>

            <div className="option-two-col">
                <div className="option-col">
                    <Dropdown menu={{
                        items: itemsFontWeight,
                        selectable: true,
                        defaultSelectedKeys: [buttonShowMore?.fontWeight?.toString() || '400'],
                        onClick: handleChangeFontWeight
                    }} trigger={['click']}>
                        <Typography.Link>
                           {getNumberFromFontWeight(buttonShowMore?.fontWeight?.toString() || '400')}
                        </Typography.Link>
                    </Dropdown>
                </div>
                <div className="option-col">
                    <Dropdown trigger={['click']} dropdownRender={
                        () =>
                            <div className="dropdown min150">
                                <div className="dropdown-title option-label">Set Rounding</div>
                                <div className="flex-center-space-between">
                                    <Slider
                                        min={0}
                                        max={100}
                                        className="min100"
                                        onChange={handleChangeRounding}
                                        value={buttonShowMore?.rounding || 6}
                                    />
                                </div>
                            </div>
                    }>
                        <Typography.Link>
                            Rounding: {buttonShowMore?.rounding || 6}
                        </Typography.Link>
                    </Dropdown>
                </div>
            </div>

            <div className="option-two-col">
                <div className="option-col">
                    <input
                        type="color"
                        className="color-box"
                        id={`dealBorder${uniqueKey}`}
                        value={buttonShowMore?.borderColor || '#383838'}
                        onChange={handleBorderChange}
                    />
                    <label htmlFor={`dealBorder${uniqueKey}`}>
                        Border: {buttonShowMore?.borderColor || '#383838'}
                    </label>
                </div>
            </div>

        </div>
    </>
}

export default ShowMoreSettingsComponent
