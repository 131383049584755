import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import { AggregatedEvent, AggregatedEventData } from "../../../../types/events.interfaces";

interface Props {
    loading: boolean,
    events?: AggregatedEvent
}

const OffersEventsWidget = ({ loading, events }: Props) => {
    return <>
        <div className="home-widget">
            <div className="widget-title">
                Offers  <InfoCircleOutlined id="guide-insights-offer-events" />
            </div>
            <div className="widget-description">
            </div>
            <div className="widget-content widget-table">
                <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Offer
                                </th>
                                <th>
                                    Impressions
                                </th>
                                <th>
                                    Read more
                                </th>
                                <th>
                                    Interested
                                </th>
                                <th>
                                    Get offer
                                </th>
                                <th>
                                    Pending Transactions
                                </th>
                                <th>
                                    Pending Sales
                                </th>
                                <th>
                                    Approved Transactions
                                </th>
                                <th>
                                    Approved Sales
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {events && events?.data?.map((event: AggregatedEventData, index: number) => {
                                return <tr key={`retailerevent-${index}`}>
                                    <td>
                                        {event.item.name}
                                    </td>
                                    <td>
                                        {event.metrics.onofferview}
                                    </td>
                                    <td>
                                        {event.metrics.onreadmoreclick}
                                    </td>
                                    <td>
                                        {event.metrics.onselectoffer}
                                    </td>
                                    <td>
                                        {event.metrics.totalOfferClicks}
                                    </td>
                                    <td>
                                        {event.transactionData?.transactionsPendingCount || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalAmountPending  || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.transactionsApprovedCount || 0}
                                    </td>
                                    <td>
                                        {event.transactionData?.currency}{event.transactionData?.totalAmountApproved || 0}
                                    </td>
                                </tr>
                            })}
                            {loading ?
                                <tr className={events && events?.count > 0 ? 'loader-absolute' : ''}>
                                    <td className="text-center" colSpan={9}>
                                        <LoadingOutlined />
                                    </td>
                                </tr>
                                :
                                <>
                                    {events && events?.count === 0 &&
                                        <tr>
                                            <td className="text-center" colSpan={9}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="widget-footer">
            </div>
        </div>
    </>
}

export default OffersEventsWidget;