const Footer = () => {
    return <div className="footer">
        <span>Copyright © BrandSwap </span>
        <ul>
            <li className="font-color font-small">Cookies</li>
            <li className="font-color font-small">Terms of use</li>
            <li className="font-color font-small">Privacy Policy</li>
        </ul>
    </div>
}

export default Footer;