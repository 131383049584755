import { Dropdown, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout.hook";
import "./css/style.scss";
import collapseIcon from "./../../../assets/images/icons/collapse-icon.svg";
import useAuth from "../../../hooks/useAuth.hook";
import usePage from "../../../hooks/usePage.hook";
import { ACCESS_TYPES, COMPANY_TYPES } from "../../../utility/enums/user.enums";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { UserCompany } from "../../../types/company.interfaces";
import { LoadingOutlined } from "@ant-design/icons";
import { PLATFORM_VIEW_MODE } from "../../express/types/express.enum";

import Home from "./../../../assets/images/icons/sidebar/home.svg";
import Insights from "./../../../assets/images/icons/sidebar/insights.svg";
import Campaigns from "./../../../assets/images/icons/sidebar/campaigns.svg";
import Offers from "./../../../assets/images/icons/sidebar/offers.svg";
import Customization from "./../../../assets/images/icons/sidebar/customization.svg";
// import Users from "./../../../assets/images/icons/sidebar/users.svg";
import Financial from "./../../../assets/images/icons/sidebar/financial.svg";
import Settings from "./../../../assets/images/icons/sidebar/settings.svg";
import Logo from "./../../../assets/images/icons/sidebar/logo.svg";
import LogoIcon from "./../../../assets/images/icons/sidebar/logo-icon.svg";

const AuthLayout = () => {
  const { page } = usePage();
  const { auth, company, setCompany } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const [closed, setClosed] = useState<boolean>(false);


  const handleLogout = (event?: React.MouseEvent) => {
    event?.preventDefault();
    logout();
  }

  const profileList: ItemType[] = [
    {
      label: <span>Logout</span>,
      key: 'logout',
      onClick: () => handleLogout()
    },
  ];

  const handleChangeCompanyType = (_comp: UserCompany, type: COMPANY_TYPES) => {
    setLoading(true);
    const newCompany: UserCompany = {
      ..._comp,
      companyCurrentType: type,
      platformViewMode: PLATFORM_VIEW_MODE.full,
    };
    setCompany(newCompany);
    localStorage.setItem('company', JSON.stringify(newCompany));
    navigate('/');
    setTimeout(() => {
      setLoading(false);
    }, 1e3);
  }

  const handleClickCompany = (_company: UserCompany) => {
    let type = _company.companyType;
    switch (_company.companyType) {
      case COMPANY_TYPES.advertiser:
        type = COMPANY_TYPES.advertiser
        break;
      case COMPANY_TYPES.publisher:
        type = COMPANY_TYPES.publisher
        break;

      case COMPANY_TYPES.both:
        type = company?.company_Id === _company.company_Id ? company?.companyCurrentType === COMPANY_TYPES.advertiser ? COMPANY_TYPES.publisher : COMPANY_TYPES.advertiser : COMPANY_TYPES.advertiser;

        break;

      default:
        break;
    }
    handleChangeCompanyType(_company, type);
  }

  const companiesList: ItemType[] = useMemo((): ItemType[] => {
    const _list = auth?.companies?.map((_company: UserCompany) => {
      return {
        label: <div className="capitalize company-list-type">
          <span className="grow font-hover" onClick={(e) => handleClickCompany(_company)}>
            {_company.name}
          </span>
          {(_company?.companyType === COMPANY_TYPES.advertiser || _company?.companyType === COMPANY_TYPES.both) &&
            <span onClick={() => handleChangeCompanyType(_company, COMPANY_TYPES.advertiser)} className={`char ${_company.company_Id === company?.company_Id && company?.companyCurrentType === COMPANY_TYPES.advertiser ? 'active' : ''}`}>A</span>
          }

          {(_company?.companyType === COMPANY_TYPES.publisher || _company?.companyType === COMPANY_TYPES.both) &&
            <span onClick={() => handleChangeCompanyType(_company, COMPANY_TYPES.publisher)} className={`char ${_company.company_Id === company?.company_Id && company?.companyCurrentType === COMPANY_TYPES.publisher ? 'active' : ''}`}>H</span>
          }
        </div>,
        key: _company.company_Id,
      }
    }
    );
    return _list || [];
    // eslint-disable-next-line
  }, [auth]);

  return (
    <div className="auth">

      {loading &&
        <div className="loading-overlay">
          <LoadingOutlined />
        </div>
      }

      <div className={`sidebar ${closed ? "closed" : ""}`}>
        <div className="logo">
          <img src={closed ? LogoIcon : Logo} alt="BrandSwap" />
        </div>

        <ul className="sidebar-list main">
          <li>
            <Tooltip placement="right" title={closed ? "Home" : ""}>
              <NavLink to="/dashboard">
                <span className="list-link">
                  <img src={Home} alt="home" />
                  <span className="list-label">Home</span>
                </span>
              </NavLink>
            </Tooltip>
          </li>
          {/* <li>
            {company?.userAccess?.access.includes(ACCESS_TYPES.insightsread) &&
              <Tooltip placement="right" title={closed ? "Insight" : ""}>
                <NavLink to="/insight">
                  <span className="list-link">
                    <img src={equalIcon} alt="Insight" />
                    <span className="list-label">Insight</span>
                  </span>
                </NavLink>
              </Tooltip>
            }
          </li> */}
          <li>
            {company?.userAccess?.access.includes(ACCESS_TYPES.insightsread) &&
              <Tooltip placement="right" title={closed ? "Insight" : ""}>
                <NavLink to="/insight">
                  <span className="list-link">
                    <img src={Insights} alt="Insight" />
                    <span className="list-label">Insight</span>
                  </span>
                </NavLink>
              </Tooltip>
            }
          </li>

          {company?.companyCurrentType === COMPANY_TYPES.publisher &&
            <>
              <li>
                {company?.userAccess?.access.includes(ACCESS_TYPES.campaignread) &&
                  <Tooltip placement="right" title={closed ? "Campaigns" : ""}>
                    <NavLink to="/campaigns">
                      <span className="list-link">
                        <img src={Campaigns} alt="Campaigns" />
                        <span className="list-label">Campaigns</span>
                      </span>
                    </NavLink>
                  </Tooltip>
                }
              </li>
            </>
          }
          {company?.companyCurrentType === COMPANY_TYPES.advertiser &&
            <>
              <li>
                {company?.userAccess?.access.includes(ACCESS_TYPES.offerread) &&
                  <Tooltip placement="right" title={closed ? "Offers" : ""}>
                    <NavLink to="/offers">
                      <span className="list-link">
                        <img src={Offers} alt="Offers" />
                        <span className="list-label">Offers</span>
                      </span>
                    </NavLink>
                  </Tooltip>
                }
              </li>
            </>
          }
          {/* <li>
            <Tooltip placement="right" title={closed ? "Users" : ""}>
              <NavLink to="/users">
                <span className="list-link">
                  <img src={Users} alt="Users" />
                  <span className="list-label">Users</span>
                </span>
              </NavLink>
            </Tooltip>
          </li>
          <li>
            <Tooltip placement="right" title={closed ? "Settings" : ""}>
              <NavLink to="/settings">
                <span className="list-link">
                  <img src={settingsIcon} alt="Settings" />
                  <span className="list-label">Settings</span>
                </span>
              </NavLink>
            </Tooltip>
          </li> */}

          {company?.companyCurrentType === COMPANY_TYPES.publisher &&
            <>
              <li>
                {company?.userAccess?.access.includes(ACCESS_TYPES.offerread) &&
                  <Tooltip placement="right" title={closed ? "Offers" : ""}>
                    <NavLink to="/offers-host">
                      <span className="list-link">
                        <img src={Offers} alt="Offers" />
                        <span className="list-label">Offers</span>
                      </span>
                    </NavLink>
                  </Tooltip>
                }
              </li>
            </>
          }

          {(company?.userAccess?.access.includes(ACCESS_TYPES.brandread) ||
            company?.userAccess?.access.includes(ACCESS_TYPES.brandwrite)) && (
              <li>
                <Tooltip placement="right" title={closed ? "Customizations" : ""}>
                  <NavLink to={`/customisation/edit/${company.company_Id}`}>
                    <span className="list-link">
                      <img src={Customization} alt="Customisation" />
                      <span className="list-label">Customisation</span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            )}

          {company?.userAccess?.access.includes(ACCESS_TYPES.paymentsread) && (
            <li>
              <Tooltip placement="right" title={closed ? "Payments" : ""}>
                <NavLink to={`/payments/edit/${company.company_Id}`}>
                  <span className="list-link">
                    <img src={Financial} alt="Payments" />
                    <span className="list-label">Payments</span>
                  </span>
                </NavLink>
              </Tooltip>
            </li>
          )}

          {(company?.userAccess?.access.includes(ACCESS_TYPES.companyread) ||
            company?.userAccess?.access.includes(
              ACCESS_TYPES.companywrite
            )) && (
              <li>
                <Tooltip placement="right" title={closed ? "Settings" : ""}>
                  <NavLink to={`/company/edit/${company.company_Id}`}>
                    <span className="list-link">
                      <img src={Settings} alt="Settings" />
                      <span className="list-label">Settings</span>
                    </span>
                  </NavLink>
                </Tooltip>
              </li>
            )}
        </ul>

        <ul className="sidebar-list toggler">
          <li onClick={() => setClosed(prevState => !prevState)}>
            <Tooltip placement="right" title={closed ? "Expand menu" : ""}>
              <div className="a-link">
                <span className="list-link">
                  <img src={collapseIcon} alt={closed ? "Expand menu" : "Collapse menu"} />
                  <span className="list-label">Collapse menu</span>
                </span>
              </div>
            </Tooltip>
          </li>



        </ul>
      </div>

      <div className="wrapper">
        <div className="header">
          <div className="header-title">
            {page?.title}
          </div>

          <div className="header-icons">
            <ul>
              <li>
                <Dropdown placement="bottom" trigger={['click']}
                  menu={{
                    items: profileList,
                    selectable: false,
                  }}>
                  <span className="flex-center">
                    <span className="icon icon-user-image"></span>
                    <span className="header-name">
                      {`${auth?.firstName} ${auth?.lastName}`}
                    </span>
                    <span className="icon icon-arrow-down"></span>
                  </span>
                </Dropdown>
              </li>
              <li>
                <Dropdown placement="bottomLeft" trigger={['click']}
                  menu={{
                    items: companiesList,
                    activeKey: company?.company_Id,
                    className: "dropdown-buttons"
                  }}
                >
                  <span className="dropdown-trigger">
                    <span>{company?.name}</span>
                    {(company?.companyType === COMPANY_TYPES.advertiser || company?.companyType === COMPANY_TYPES.both) &&
                      <span onClick={() => handleChangeCompanyType(company, COMPANY_TYPES.advertiser)} className={`char ${company?.companyCurrentType === COMPANY_TYPES.advertiser ? 'active' : ''}`}>A</span>
                    }

                    {(company?.companyType === COMPANY_TYPES.publisher || company?.companyType === COMPANY_TYPES.both) &&
                      <span onClick={() => handleChangeCompanyType(company, COMPANY_TYPES.publisher)} className={`char ${company?.companyCurrentType === COMPANY_TYPES.publisher ? 'active' : ''}`}>H</span>
                    }
                    <span className="icon icon-arrow-down"></span>
                  </span>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>

        <Outlet />

      </div>
    </div>
  );
}

export default AuthLayout;
