import React, { useState } from "react";
import "./PaymentsDetails.scss";
import { Empty, Select } from "antd";
import {
  transactionStatusArray,
  paymentStatusArray,
} from "../../../types/payments.interfaces";

interface PaymentsDetailsProps {}

const PaymentsDetails: React.FC<PaymentsDetailsProps> = (props) => {
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [paymentStatus, setPaymentStatus] = useState<string>("");

  return (
    <div className="flex payments">
      <div className="payments-left">
        {/* <div className="payments-item">
          <div className="payments-item-date">May 2024</div>
          <div className="payments-item-price">£23,460.12</div>
        </div> */}
      </div>
      <div className="payments-right">
        <div>
          <div className="layout-options">
            <div className="filters">
              <div className="filter-dropdown flex payments-filters">
                <div className="label-input-wrapper">
                  <label htmlFor="page">
                    <span className="label-input-name">Transaction status</span>
                    <Select
                      suffixIcon={
                        <span className="icon icon-arrow-down"></span>
                      }
                      className="label-select"
                      value={transactionStatus ? transactionStatus : "All"}
                      onChange={(value: string) => {
                        setTransactionStatus(value);
                      }}
                      options={transactionStatusArray}
                    ></Select>
                  </label>
                </div>
                <div className="label-input-wrapper">
                  <label htmlFor="page">
                    <span className="label-input-name">Payment status</span>
                    <Select
                      suffixIcon={
                        <span className="icon icon-arrow-down"></span>
                      }
                      className="label-select"
                      value={paymentStatus ? paymentStatus : "All"}
                      onChange={(value: string) => {
                        setPaymentStatus(value);
                      }}
                      options={paymentStatusArray}
                    ></Select>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-wrapper table-scrollable-wrapper">
            <div className="table-scrollable">
              <table>
                <thead>
                  <tr>
                    <th>Date / Time</th>
                    <th>Advertiser</th>
                    <th>Amount</th>
                    <th>Network</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center" colSpan={7}>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="There are no payments."
                      />
                    </td>
                  </tr>
                </tbody>

                {/* <tbody>
                  <>
                    {company.emailTemplates.map(
                      (item: EmailTemplate, index: number) => {
                        return (
                          <tr key={`offer-${index}`}>
                            <td className="td-min-100">
                              {item.emailTemplateName}
                            </td>
                            <td className="td-min-100">
                              {item.emailTemplateId}
                            </td>
                            <td className="td-min-100">
                              <div className="actions">
                                <Button
                                  className="edit-button"
                                  onClick={() => {
                                    handleOpenEditModal(item);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  className="remove-button mleft20"
                                  onClick={() =>
                                    openConfirmModal(item.emailTemplateId)
                                  }
                                >
                                  Remove
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}

                    {loading ? (
                      <tr
                        className={
                          company.emailTemplates.length > 0
                            ? "loader-absolute"
                            : ""
                        }
                      >
                        <td className="text-center" colSpan={7}>
                          <LoadingOutlined />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {company.emailTemplates.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan={7}>
                              <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description="No email templates."
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                </tbody> */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsDetails;
